import heart from './heart.svg';
import star from './star.svg';
import infinity from './infinity.svg';
import giaCertificate from './gia_certificate.svg';
import downArrow from './down_arrow.svg';
import leftArrow from './left_arrow.svg';
import rightArrow from './right_arrow.svg';
import newTabArrow from './new_tab_arrow.svg';
import assistance from './assistance.svg';
import tick from './tick.svg';
import diamond from './diamond.svg';

const icons = {
  heart,
  star,
  infinity,
  giaCertificate,
  downArrow,
  leftArrow,
  rightArrow,
  newTabArrow,
  assistance,
  tick,
  diamond,
};

export default icons;
