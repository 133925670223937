const countryISOCodes = {
  us: 'US',
  uk: 'GB',
  fr: 'FR',
  be: 'BE',
  nl: 'NL',
  es: 'ES',
  lu: 'LU',
  de: 'DE',
  pt: 'PT',
  ch: 'CH',
  it: 'IT',
  ca: 'CA',
  at: 'AT',
  mc: 'MC',
  ie: 'IE',
  tw: 'TW',
  hk: 'HK',
  sg: 'SG',
  au: 'AU',
  sa: 'SA',
  ae: 'AE',
  my: 'MY',
  th: 'TH',
};

const languageISOCodes = {
  'en-gb': 'en',
  'en-us': 'en',
  fr: 'fr',
  de: 'de',
  pt: 'pt',
  nl: 'nl',
  es: 'es',
  it: 'it',
  zh: 'zh',
  ko: 'ko',
  'zh-tw': 'zh-tw',
  'zh-hk': 'zh-hk',
  th: 'th',
};

const getPathWithoutLanguage = (path: string) => path.split('/').slice(2).join('/');

const getPageType = (path: string) => {
  const pathWithoutLang = getPathWithoutLanguage(path);

  if (pathWithoutLang === '') return 'Homepage';
  if (pathWithoutLang.includes('4c')) return 'Cartier Diamonds';
  if (pathWithoutLang.includes('matching-bands')) return 'Matching Bands';
  if (pathWithoutLang.includes('set-for-you')) return 'Personalization';
  if (pathWithoutLang.includes('book-an-appointment')) return 'Book appointment';

  return '';
};

// const getEnglishPath = (path: string) => '/en-gb/' + getPathWithoutLanguage(path);

const getPageViewData = (payload: { path: string; country: string; language: string }) => ({
  pageURI: payload.path,
  msn_cgGroup: getPageType(payload.path),
  rcms_cgGroup: getPageType(payload.path),
  pageType: getPageType(payload.path),
  platformType: 'ESFY',
  country: countryISOCodes[payload.country.toLowerCase() as keyof typeof countryISOCodes],
  language: languageISOCodes[payload.language.toLowerCase() as keyof typeof languageISOCodes],
});

export const pushPageViewEvent = (payload: { path: string; country: string; language: string }) => {
  // if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  (window as any).dataLayer.push({
    event: 'virtualPageview',
    ...getPageViewData(payload),
  });
  // }
};

export const pushTrackingEvent = (event: string, payload = {}) => {
  // if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  (window as any).dataLayer.push({
    event,
    ...payload,
  });
  // }
};
