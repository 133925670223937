import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BRIDAL_API_URL;
const language = localStorage.getItem('language');

export const backendApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'x-market': localStorage.getItem('location') || '',
    'x-language': language === 'en-us' || language === 'en-gb' ? 'en' : language || 'en',
  },
});

export const getCreationProducts = (
  filter: ICreationProductQueryFilter,
  includeFacets: boolean,
  language: string,
  market: string,
) => {
  return backendApi
    .post(
      '/creationproducts',
      {
        ...filter,
        includeFacets,
      },
      {
        headers: {
          'x-language': language,
          'x-market': market,
        },
      },
    )
    .then((res) => res.data);
};

export const sendCreationEmail = (creation: ICreation, language: string, market: string) => {
  return backendApi
    .post(
      '/email/creation',
      {
        creation: creation,
      },
      {
        headers: {
          'x-language': language,
          'x-market': market,
        },
      },
    )
    .then((res) => res.data);
};

export const getModels = (ids: string[], language: string) => {
  return backendApi
    .get(`/models?ids=${ids}`, {
      headers: {
        'x-language': language,
      },
    })
    .then((res) => res.data);
};
