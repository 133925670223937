import { useState, useLayoutEffect } from 'react';

interface IScrollProgressConfig {
  startOffset?: number;
  endOffset?: number;
}

export const useRefScrollProgress = (inputRef: any, config?: IScrollProgressConfig) => {
  const ref = inputRef;
  const [start, setStart] = useState<number | null>(null);
  const [end, setEnd] = useState<number | null>(null);

  // eslint-disable-next-line
  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offsetTop = rect.top + scrollTop;

    const startOffset = rect.height * (config?.startOffset || 0);
    const endOffset = rect.height * (config?.endOffset || 0);

    setStart((config?.startOffset ? offsetTop - startOffset : offsetTop) / document.body.clientHeight);
    setEnd((config?.endOffset ? offsetTop + endOffset : offsetTop + rect.height) / document.body.clientHeight);
  });

  return { ref, start, end };
};
