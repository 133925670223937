import { useRef } from 'react';
import { Text } from 'ui';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import imageSrc from 'media/cta/cartierexpertise/cartier_expertise_1.jpg';

import { useRefScrollProgress } from 'hooks/useRefScrollProgress';
import { useLocation } from 'providers';

const CartierExpertise = () => {
  const ref = useRef<any>();
  const { start, end } = useRefScrollProgress(ref);
  const scrollYProgress = useViewportScroll();
  const { translations } = useLocation();

  const textTransforms = {
    y: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [100, 0]),
    opacity: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [0, 1]),
  };
  const yText = useSpring(textTransforms.y, { stiffness: 1000, damping: 90 });
  const opacityText = useSpring(textTransforms.opacity, { stiffness: 1000, damping: 90 });

  const imageTransforms = {
    x: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 1.1], [-50, -100]),
  };
  const xImage = useSpring(imageTransforms.x, { stiffness: 1000, damping: 90 });

  return (
    <Container className="container" ref={ref}>
      <ImageContainer>
        <StyledImage image={imageSrc} style={{ x: xImage }} />
      </ImageContainer>
      <TextContainer style={{ y: yText, opacity: opacityText }}>
        <Text level="h1" $mono $uppercase>
          {translations?.firstpage_cartierExpertise || 'Cartier Expertise'}
        </Text>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacings.xl} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 100px 0;
  }
`;

const TextContainer = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 0;
  }
`;

const ImageContainer = styled.div`
  width: 450px;
  height: 650px;
  overflow: hidden;
`;

const StyledImage = styled(motion.div)<{ image: string }>`
  width: 80%;
  height: 650px;
  max-width: none;
  background-image: url(${({ image }) => image});
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 600px;
  }
`;

export default CartierExpertise;
