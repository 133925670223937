import styled, { css } from 'styled-components';

const variantOptions = {
  size: {
    s: '10px',
    m: '20px',
    l: '24px',
  },
};

const Icon = styled.span<{ imgUrl: string; size?: 's' | 'm' | 'l'; light?: boolean; width?: number }>`
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 16px;
  width: 16px;
  padding-top: 24px;

  ${({ size }) =>
    size &&
    variantOptions.size[size] &&
    css`
      height: ${variantOptions.size[size]} !important;
      width: ${variantOptions.size[size]} !important;
    `}

  ${({ light }) =>
    light &&
    css`
      filter: invert(100%) sepia(0%) saturate(1029%) hue-rotate(275deg) brightness(111%) contrast(100%);
    `}
  
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

export default Icon;
