import React, { FC, useRef } from 'react';
import { Text } from 'ui';
import styled, { css } from 'styled-components';
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import imageSrc from 'media/cta/beauty/editorial_1.jpg';
import image2Src from 'media/cta/beauty/editorial_2.jpg';

import { useRefScrollProgress } from 'hooks/useRefScrollProgress';
import { useLocation } from 'providers';

const Beauty = () => {
  const ref = useRef<any>();
  const imageRef = useRef<any>();
  const { start, end } = useRefScrollProgress(ref, { endOffset: 0.5 });
  const scrollYProgress = useViewportScroll();
  const { translations } = useLocation();

  const textTransforms = {
    y: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 1.1], [`${90}%`, `${0}%`]),
  };

  const imageTransforms = {
    x: useTransform(scrollYProgress.scrollYProgress, [start! * 0.5, end! * 1.1], [`${-30}%`, `${-60}%`]),
    scale: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 1.1], [1.2, 1]),
    opacity: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 0.9, end!], [0.5, 1, 1]),
  };
  const scaleImage = useSpring(imageTransforms.scale, { stiffness: 1000, damping: 90 });
  const opacityImage = useSpring(imageTransforms.opacity, { stiffness: 1000, damping: 90 });

  return (
    <Container ref={ref}>
      <div className="container">
        <ImageContainer>
          <motion.img ref={imageRef} style={{ scale: scaleImage, opacity: opacityImage }} src={imageSrc} alt="Beauty" />
        </ImageContainer>
      </div>
      <TitleContainer style={{ y: textTransforms.y }}>
        <BeautyQuote>{translations?.firstpage_recogniseBeautyWhereeverItMayBe?.toUpperCase()}</BeautyQuote>
      </TitleContainer>
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
          <StyledText className="py-4">{translations?.firstpage_recogniseBeautyWhereeverItMayBe_Text}</StyledText>
        </div>
        <div className="col-12 col-md-6">
          <ImageContainerEnd>
            <motion.img
              ref={imageRef}
              style={{ x: imageTransforms.x, y: '-50%', scale: 1.2 }}
              src={image2Src}
              alt="Beauty"
            />
          </ImageContainerEnd>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 80px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    background-color: ${({ theme }) => theme.colors.fill};
    z-index: -1;
  }
`;

const TitleContainer = styled(motion.div)`
  width: 100%;
  position: relative;
  z-index: 10;
`;

const BeautyQuote: FC = (props) => {
  const { location } = useLocation();
  const squareQuoteLangCodes = ['zh-tw', 'zh-hk'];
  const isSquareQuote = () => {
    return squareQuoteLangCodes.indexOf(location?.language.locationLanguageCode) !== -1;
  };

  return (
    <StyledQuote $mono $uppercase $issquare={isSquareQuote()}>
      {props.children}
    </StyledQuote>
  );
};

const StyledQuote = styled(Text)<{ $issquare: boolean }>`
  text-align: center;
  width: 100%;
  line-height: 1;
  font-size: 40px;
  line-height: 40px;
  user-select: none;
  font-family: ${({ theme }) => theme.typography.fonts.mono};

  &:before,
  &:after {
    content: '“';
  }

  ${({ $issquare }) => $issquare &&
    css`
      &:before {
        content: '「';
      }

      ,

      &: after {
        content: '」';
      }
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 60px;
    line-height: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 100px;
    line-height: 120px;
  }
`;

const StyledText = styled(motion.div)`
  max-width: 300px;
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 70%;
  }
`;

const ImageContainerEnd = styled.div`
  width: 100vw;
  height: 40vh;
  overflow: hidden;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 560px;
    height: 560px;
    transform: translateX(-10%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 760px;
    height: 560px;
    transform: translateX(-15%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 900px;
    height: 560px;
    transform: translateX(-20%);
  }

  img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
  }
`;

export default Beauty;
