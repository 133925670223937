import { createGlobalStyle } from 'styled-components';

import BrilliantCutProRegular from './fonts/brilliant-cut/BrilliantCutProB7-Regular.woff';
import BrilliantCutProMedium from './fonts/brilliant-cut/BrilliantCutProB7-Medium.woff';
import FancyCutPro from './fonts/fancy-cut/FancyCutProB7-Regular.woff';
import AvantGarde from './fonts/avant-garde/avant_garde.woff';
import Script412 from './fonts/script-412/script_412.woff';
import Sackers from './fonts/sackers/Cartier-Sackers-Gothic-Medium.woff';
import Celeste from './fonts/celeste/CelesteOffcPro-Ita.woff';
import CartierLogos from './fonts/cartier-logos/CartierLogos.woff2';

export default createGlobalStyle`
    html {
        overflow-x: hidden;
        scroll-behavior: unset !important;
        &.is-locked,
        &.is-locked body {
          height: calc(var(--window-inner-height) - 1px) !important;
          overflow: hidden !important;
          box-sizing: border-box !important;
        }
    }
    body {
        margin: 0;
        font-family: ${({ theme }) => theme.typography.fonts.base};
        font-size:  ${({ theme }) => theme.typography.sizes.base};
        line-height:  ${({ theme }) => theme.typography.lineHeights.base};
        color: ${({ theme }) => theme.colors.text};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: transparent;
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        & *, & *::before, & *::after {
          box-sizing: border-box;
        }
    }
    a {
        color: ${({ theme }) => theme.colors.text};

        &:hover {
            color: ${({ theme }) => theme.colors.text};
            text-decoration: none;
        }
    }
    img {
        max-width: 100%;
    }
    @font-face {
        font-family: 'BrilliantCutPro';
        src: url(${BrilliantCutProRegular});
    }
    @font-face {
        font-family: 'BrilliantCutProMedium';
        src: url(${BrilliantCutProMedium});
    }
    @font-face {
        font-family: 'FancyCutPro';
        src: url(${FancyCutPro});
    }
    @font-face {
        font-family: 'AvantGarde';
        src: url(${AvantGarde});
    }
    @font-face {
        font-family: 'Script412';
        src: url(${Script412});
    }
    @font-face {
        font-family: 'Sackers';
        src: url(${Sackers});
    }
    @font-face {
        font-family: 'Celeste';
        src: url(${Celeste});
    }
    @font-face {
        font-family: 'CartierLogos';
        src: url(${CartierLogos});
    }
`;
