import { useQuery } from 'react-query';
import { getModels } from 'api';

const getApiFriendlyLanguage = (languageCode: string) => {
  return languageCode === 'en-us' || languageCode === 'en-gb' ? 'en' : languageCode || 'en';
};

export function useModelsQuery(productIds: string[], disabled?: boolean) {
  const languageCode = localStorage.getItem('language') || 'en';
  return useQuery<DestinationModel[], Error>(
    ['models', productIds],
    () => getModels(productIds, getApiFriendlyLanguage(languageCode)),
    {
      cacheTime: 30000,
      enabled: !disabled,
    },
  );
}
