import { useSFY } from 'providers';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

// Todo: Switch to use 'ringSelection?.model' as 'pId' parameter in iframe URL. This is currently set to 'N4751600' as it is the only supported model in the module so far. See 'src' property value on 'StyledIframe' component.

interface IModelVisualizer {
  variant: 'EMBOSSING' | 'ENGRAVING';
  distanceRatio?: number;
  moveIcon?: boolean;
}

const IFRAME_URL = process.env.REACT_APP_3DCONFIGURATOR_URL;

const IFRAME_FONTS = {
  EMBOSSING: {
    block: 'SACKERS',
    cursive: 'CELESTE',
  },
  ENGRAVING: {
    block: 'AVANT',
    cursive: 'SCRIPT412',
  },
};

const ModelVisualizer: React.FC<IModelVisualizer> = ({ variant, distanceRatio, moveIcon }) => {
  const { ringSelection, configuration } = useSFY();
  const iframeRef = useRef<any>();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [inputValues, setInputValues] = useState<EngravingConfig>();

  const getSymbol = useCallback(() => {
    const supportedSymbols = ['Heart', 'Star', 'Infinity', '.', '&'];
    const symbol = inputValues?.symbol && supportedSymbols.includes(inputValues.symbol) ? inputValues?.symbol : '';

    // * Format the symbol code differently for Engraving
    if (variant === 'ENGRAVING' && symbol.length) {
      return `symbol:'${symbol}'`;
    }

    return symbol;
  }, [variant, inputValues?.symbol]);

  /**
   * * Post message input to iframe
   * Message consists of 3 values. 'message', 'symbol', and 'messageAfterSymbol'.
   */
  const setMessage = useCallback(() => {
    const target = `${variant.toLowerCase()}Msg_1`;
    const message = inputValues?.message;
    const messageAfterSymbol = inputValues?.messageAfterSymbol;
    const symbol = getSymbol();

    const compoundMessage = `${message || ' '}${symbol || ' '}${messageAfterSymbol || ' '}`;
    iframeRef.current.contentWindow.postMessage(`{"${target}" : "${compoundMessage}"}`, '*');
  }, [inputValues, getSymbol, variant]);

  /**
   * * Post font selection to iframe
   */
  const setFont = useCallback(() => {
    const target = `${variant.toLowerCase()}Font`;
    const fontFamily = inputValues?.font === 'Cursive' ? IFRAME_FONTS[variant].cursive : IFRAME_FONTS[variant].block;

    iframeRef.current.contentWindow.postMessage(`{"${target}" : "${fontFamily}"}`, '*');
  }, [inputValues?.font, variant]);

  /**
   * * Post angle setting to iframe.
   * Can be either 'EMBOSSING' or 'ENGRAVING'.
   */
  const setAngle = useCallback(() => {
    iframeRef.current.contentWindow.postMessage(`{"${variant.toLowerCase()}DistanceRatio" : "0"}`, '*');
    iframeRef.current.contentWindow.postMessage(`{"view" : "${variant}"}`, '*');
  }, [variant]);

  /**
   * * Act on iframe state message
   * On state 'SUCCESS', post font and message input values to iframe to set the initial state.
   * The initial state can either be empty or populated, since the user can go back and edit their personalisation config.
   */
  const onMessageReceivedFromIframe = useCallback(
    (event: any) => {
      const iframe = iframeRef.current?.contentWindow;
      if (iframe !== event.source) return;

      const iframeState = JSON.parse(event.data).iframeStates;

      if (iframeState === 'SUCCESS') {
        setIframeLoaded(true);
        setFont();
        setMessage();

        // // * Render the Box instead of the Product for Embossing
        // if (variant && variant === 'EMBOSSING') setAngle();
      }
    },
    [setFont, setMessage],
  );

  /**
   * * Iframe listener
   * Listen to iframe state messages
   */
  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () => window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  /**
   * * Context listener
   * Set input values on context state changes
   */
  useEffect(() => {
    if (variant && variant === 'ENGRAVING') setInputValues(ringSelection?.engraving);
    if (variant && variant === 'EMBOSSING') setInputValues(ringSelection?.embossing);
  }, [variant, ringSelection?.engraving, ringSelection?.embossing]);

  /**
   * * Change handler
   * Post input values to iframe on input change
   */
  useEffect(() => {
    if (iframeLoaded && inputValues) {
      setMessage();
      setFont();
      setAngle();
    }
  }, [inputValues, setAngle, setMessage, setFont, iframeLoaded]);

  return configuration?.model ? (
    <StyledIframe
      show={iframeLoaded}
      ref={iframeRef}
      src={`${IFRAME_URL}?pId=${variant === 'EMBOSSING' ? 'Box' : configuration?.model.id}&background=F6F6F6${
        distanceRatio ? '&distanceRatio=' + distanceRatio : ''
      }${moveIcon ? '&disclaimer=TRUE' : ''}`}
    />
  ) : null;
};

const StyledIframe = styled.iframe<{ show?: boolean }>`
  width: 100%;
  min-height: 30vh;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s ease;
  transition-delay: 0.3s;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    min-height: 50vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    min-height: 65vh;
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;

export default ModelVisualizer;
