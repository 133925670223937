import { useEffect, useRef } from 'react';
import { Button, Text } from 'ui';
import styled from 'styled-components';
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import videoSrc from 'media/cta/cartierexpertise/Diamant-7-noblur.mp4';
import { Tabs } from 'components';

import { useOnScreen } from 'hooks/useOnScreen';
import { useRefScrollProgress } from 'hooks/useRefScrollProgress';
import { useLocation } from 'providers';
import { useNavigate } from 'react-router-dom';

const FourCDetails = () => {
  const containerRef = useRef<any>();
  const videoRef = useRef<any>();
  const { start, end } = useRefScrollProgress(containerRef, { endOffset: 0.5 });
  const scrollYProgress = useViewportScroll();
  const onScreen = useOnScreen(containerRef, 0);
  const { translations, location } = useLocation();
  const navigate = useNavigate();

  const textTransforms = {
    y: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [100, 0]),
    opacity: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [0, 1]),
  };
  const yText = useSpring(textTransforms.y, { stiffness: 1000, damping: 90 });
  const opacityText = useSpring(textTransforms.opacity, { stiffness: 1000, damping: 90 });

  useEffect(() => {
    onScreen && videoRef.current.play();
  }, [onScreen]);

  const tabs = [
    {
      name: translations?.fourC_carat?.toUpperCase() || 'CARAT',
      content: (
        <Text color="white" className="py-4">
          {translations?.fourC_carat_Text}
        </Text>
      ),
    },
    {
      name: translations?.fourC_clarity?.toUpperCase() || 'CLARITY',
      content: (
        <Text color="white" className="py-4">
          {translations?.fourC_clarity_Text}
        </Text>
      ),
    },
    {
      name: translations?.fourC_colour?.toUpperCase() || 'COLOUR',
      content: (
        <Text color="white" className="py-4">
          {translations?.fourC_colour_Text}
        </Text>
      ),
    },
    {
      name: translations?.fourC_cut?.toUpperCase() || 'CUT',
      content: (
        <Text color="white" className="py-4">
          {translations?.fourC_cut_Text}
        </Text>
      ),
    },
  ];

  return (
    <Background ref={containerRef}>
      <Container className="container">
        <VideoContainer className="mb-4 mb-md-0">
          <StyledVideo ref={videoRef} src={videoSrc} muted loop playsInline>
            Your browser does not support the video tag
          </StyledVideo>
        </VideoContainer>

        <ContentContainer>
          <Content>
            <motion.div
              style={{
                opacity: opacityText,
                y: yText,
              }}
            >
              <Tabs containWidth items={tabs} color="white" />
            </motion.div>
            <Button
              color="white"
              variant="secondary"
              onClick={() => {
                navigate(`/${location?.language.locationLanguageCode}/4c`);
              }}
            >
              {translations?.fourC_discoverCartierDiamonds || 'Discover Cartier diamonds'}
            </Button>
          </Content>
        </ContentContainer>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  background: rgb(40, 63, 74);
  background: linear-gradient(
    180deg,
    rgba(40, 63, 74, 1) 0%,
    rgba(25, 50, 62, 1) 20%,
    rgba(25, 50, 62, 1) 50%,
    rgba(68, 90, 100, 1) 100%
  );

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    background: rgb(42, 65, 76);
    background: linear-gradient(50deg, rgba(42, 65, 76, 1) 0%, rgba(20, 45, 57, 1) 30%, rgba(89, 108, 117, 1) 100%);
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 60px;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: row;
    padding: 0 24px 60px;
  }  
  
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 1140px;
  } 
  
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 1320px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Content = styled(motion.div)<{ flip?: boolean }>`
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
  }
`;

const VideoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  max-height: 50vh;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 50%;
    max-height: 80vh;
  }
`;

const StyledVideo = styled(motion.video)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: auto;
  }
`;

export default FourCDetails;
