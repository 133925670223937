import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface IHeading extends React.HtmlHTMLAttributes<HTMLElement> {
  color?: 'white' | 'black' | 'light';
  center?: boolean;
}

const variantOptions = {
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
    light: appTheme.colors.text_light,
  },
};

const H2 = styled.h2<IHeading>`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
  text-transform: uppercase;

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};
    `}

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

export default H2;
