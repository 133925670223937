import React, { useContext, useEffect, useState } from 'react';
import { defaultLocation, LocationLanguageCode, locations } from 'constants/locations';
import { translationLabels } from 'constants/translations';
import { appToCSVLanguageCodesMap, appToCSVLinksMap, linksFromCSV } from '../constants/links';

interface ILocalizedPath {
  contactUs: string;
  findBoutique: string;
  mainCartier: string;
  termsOfUse: string;
  privacyPolicy: string;
  cookiePolicy: string;
  services: string;
  help: string;
  sizingGuide: string;
  bookAnAppointment: string;
  storeLocator: string;
}

interface IContext {
  location: ILocation;
  translations: TranslationLabels;
  localizedPath: ILocalizedPath;
  setLocation: (location: ILocation) => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
}

const LocationContext = React.createContext<Partial<IContext>>({});

// const FIND_BOUTIQUE: { [key in LocationLanguageCode]: string } = {
//   'en-gb': 'https://stores.cartier.com',
//   'de-de': 'https://stores.cartier.com/de/search?category=storeLocatorSearch',
//   'de-ch': 'https://stores.cartier.com/de/search?category=storeLocatorSearch',
//   'en-us': 'https://stores.cartier.com/en_us/search?category=storeLocatorSearch',
//   'es-es': 'https://stores.cartier.com/es/search?category=storeLocatorSearch',
//   'fr-fr': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'en-fr': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'fr-be': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'en-be': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'fr-lu': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'en-lu': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'fr-ch': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'it-it': 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
//   'it-ch': 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
//   'pt-pt': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'en-pt': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'nl-nl': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'en-nl': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'en-ca': '',
//   'fr-ca': '',
//   'en-at': 'https://stores.cartier.com/search?category=storeLocatorSearch',
//   'de-at': 'https://stores.cartier.com/de_at/search?category=storeLocatorSearch',
//   'fr-mc': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
//   'en-mc': 'https://stores.cartier.com/en_mc/search?category=storeLocatorSearch',
//   'en-ie': 'https://stores.cartier.com/en_ie/search?category=storeLocatorSearch',
//   'ko-kr':
//     'https://stores.cartier.com/ko/search?category=storeLocatorSearch&_ga=2.167474552.717561068.1655806915-1124020227.1652716806',
//   'zh-tw':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.167007864.717561068.1655806915-1124020227.1652716806',
//   'zh-hk':
//     'https://stores.cartier.com/zh_hk/search?category=storeLocatorSearch&geocoderBias=47.1449%2C8.1551%2C100&q=%E9%A6%99%E6%B8%AF&r=10',
//   'en-hk':
//     'https://stores.cartier.com/en_hk/search?q=hong+kong&category=storeLocatorSearch&r=10&geocoderBias=47.1449%2C8.1551%2C100',
//   'zh-sg':
//     'https://www.cartier.sg/zh-sg/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC.html?_ga=2.65041262.1113221344.1655818093-1124020227.1652716806&cc=SG',
//   'en-sg': 'https://stores.cartier.com/en_sg/search?category=storeLocatorSearch',
//   'en-au': 'https://stores.cartier.com/en_au/search?category=storeLocatorSearch',
//   'en-sa':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.57255530.1525207585.1655745303-1124020227.1652716806',
//   'en-ae':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.201488855.717561068.1655806915-1124020227.1652716806',
//   'en-my':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.155530749.717561068.1655806915-1124020227.1652716806',
//   'en-th':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.134484407.717561068.1655806915-1124020227.1652716806',
//   'th-th':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.134484407.717561068.1655806915-1124020227.1652716806',
//     'th':
//     'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.134484407.717561068.1655806915-1124020227.1652716806',
// };

const getLocationId = () => {
  return localStorage.getItem('location');
};
const getLanguageId = () => {
  return localStorage.getItem('language');
};

const saveSelectedLocation = (location: ILocation) => {
  localStorage.setItem('location', location.id);
  localStorage.setItem('language', location.language.id);
  localStorage.setItem('locale', location.language.locationLanguageCode);
};

const getTranslationsByLanguage = (language: string) => {
  return translationLabels.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item[language as keyof Translation] }),
    {},
  ) as TranslationLabels;
};

const getLocalizedPath = (location: ILocation) => {
  const locationLanguageCode: LocationLanguageCode = location?.language?.locationLanguageCode;

  let bookAnAppointmentRoute = `/${locationLanguageCode}/book-an-appointment`;

  if (locationLanguageCode === 'en-sa') {
    bookAnAppointmentRoute = 'https://www.cartier.com/en-sa/rdv-bookings';
  }
  if (locationLanguageCode === 'en-ae') {
    bookAnAppointmentRoute = 'https://www.cartier.com/en-ae/rdv-bookings';
  }

  let links = Object.assign(
    {},
    Object.keys(appToCSVLinksMap).reduce((acc: any, linkName: any) => {
      //@ts-ignore
      acc[linkName] = `${linksFromCSV[appToCSVLinksMap[linkName]][appToCSVLanguageCodesMap[locationLanguageCode]]}`;
      return acc;
    }, {}),
    { bookAnAppointment: bookAnAppointmentRoute },
  );

  return links;
};

export const LocationProvider = ({ ...props }) => {
  const [location, setSelectedLocation] = useState<ILocation | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [translations, setTranslations] = useState<TranslationLabels>(
    getTranslationsByLanguage(getLanguageId() || 'en-gb'),
  );
  const [localizedPath, setLocalizedPath] = useState<ILocalizedPath>({
    contactUs: 'https://www.cartier.com/contact-us',
    findBoutique: 'https://stores.cartier.com/search?category=storeLocatorSearch',
    mainCartier: 'https://www.cartier.com',
    termsOfUse: `https://www.cartier.com/en-gb/legal-and-privacy/terms-of-use`,
    privacyPolicy: `https://www.cartier.com/en-gb/legal-and-privacy/privacy-policy`,
    cookiePolicy: `https://www.cartier.com/en-gb/legal-and-privacy/cookie-policy`,
    services: `https://www.cartier.com/en-gb/services`,
    help: `https://www.cartier.com/en-gb/services/your-purchase-concierge/faq/orders-payment`,
    sizingGuide: `https://www.cartier.com/en-gb/services/request-service/care-adjust-repair/jewellery-sizing-guides`,
    storeLocator: 'https://stores.cartier.com/search',
    bookAnAppointment: `/book-an-appointment`,
  });

  useEffect(() => {
    const locationId = getLocationId();
    const languageId = getLanguageId();

    if (!locationId || !languageId) return;

    const location = locations.find((l) => l.id === locationId);
    const language = location?.languages.find((l) => l.id === languageId);

    location &&
      language &&
      setSelectedLocation({
        id: location.id,
        language: language,
        customerService: location.customerService,
      });
  }, []);

  useEffect(() => {
    const locationId = getLocationId();
    const languageId = getLanguageId();

    if (languageId) {
      setTranslations(getTranslationsByLanguage(languageId));
      location && setLocalizedPath(getLocalizedPath(location));
    }

    if (!locationId) {
      setSelectedLocation(defaultLocation);
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [location]);

  const setLocation = (location: ILocation) => {
    setSelectedLocation(location);
    saveSelectedLocation(location);
    setTranslations(getTranslationsByLanguage(location.language.id));
  };

  return (
    <LocationContext.Provider
      value={{
        location,
        translations,
        localizedPath,
        setLocation,
        setModalOpen,
        modalOpen,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);

  if (context === null) {
    throw new Error("'useLocation' must be used inside 'LocationProvider'");
  }

  return context;
};

export default LocationProvider;
