import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation as useRouterLocation, useParams } from 'react-router-dom';
import { PageHeader, PageFooter } from 'components';
import styled from 'styled-components';
import { useIsMobile } from 'hooks/useIsMobile';
import { motion } from 'framer-motion';
import { useLocation } from 'providers';
import { PageNotFoundView } from 'views';
import { Modal } from 'components';
import { Text, Icon, Link, H3 } from 'ui';
import bagIcon from 'media/icons/bag.svg';
import { defaultLocation, locations, supportedLanguages, LocationInfo } from 'constants/locations';
import { pushPageViewEvent } from 'utils/trackingUtil';

const locationAreas = {
  america: {
    name: 'america',
    locations: locations.filter((l) => ['US', 'CA'].includes(l.id)),
  },
  europe: {
    name: 'europe',
    locations: locations.filter((l) =>
      ['UK', 'FR', 'BE', 'NL', 'PT', 'LU', 'DE', 'ES', 'IT', 'AT', 'MC', 'IE', 'CH'].includes(l.id),
    ),
  },
  middleEast: {
    name: 'middle east',
    locations: locations.filter((l) => ['SA', 'AE'].includes(l.id)),
  },
  asia: {
    name: 'asia',
    locations: locations.filter((l) => ['SG', 'HK', 'MY', 'KR', 'TW', 'TH'].includes(l.id)),
  },
  oceania: {
    name: 'oceania',
    locations: locations.filter((l) => ['AU'].includes(l.id)),
  },
};

const AppLayout = () => {
  const [pageExists, setPageExists] = useState(true);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { pathname } = useRouterLocation();
  const { lang } = useParams();
  const { location, setLocation, modalOpen, setModalOpen, translations } = useLocation();

  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, transition: { duration: 0 } },
  };

  useEffect(() => {
    const savedLanguage = location?.language.locationLanguageCode;

    /**
     * Handle redirect on home page to saved language
     * If no language is saved, set default language
     */
    if (pathname === '/') {
      const savedLanguage = localStorage.getItem('locale');

      if (savedLanguage) {
        navigate(`/${savedLanguage}${pathname}`);
      } else {
        setLocation && setLocation(defaultLocation);
      }
    }

    /**
     * Switch language layer if other language is provided in url
     */
    if (lang && lang !== savedLanguage && supportedLanguages.includes(lang)) {
      const targetLocation = locations.find((l) =>
        l.languages.find((language) => language.locationLanguageCode === lang),
      );
      const targetLang = targetLocation?.languages.find((language) => language.locationLanguageCode === lang);

      const newLocation = targetLocation && {
        id: targetLocation.id,
        language: targetLang,
        customerService: targetLocation.customerService,
      };

      newLocation && setLocation && setLocation(newLocation);
    }

    /**
     * Render 404 component if path doesn't match any route
     */
    setPageExists(!!lang && supportedLanguages.includes(lang));

    // eslint-disable-next-line
  }, [navigate, lang, pathname, location?.language.locationLanguageCode]);

  /**
   * Push page_view events for Google Tracking
   */
  useEffect(() => {
    const savedLocation = localStorage.getItem('location');
    const savedLanguage = localStorage.getItem('language');

    if (location?.id && savedLocation && savedLanguage) {
      pushPageViewEvent({
        path: pathname,
        country: savedLocation,
        language: savedLanguage,
      });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Set new localized path when new language is selected
   * @param newLocation
   */
  const handleLocationClick = (newLocation: ILocation) => {
    let newPath;

    if (lang && supportedLanguages.includes(lang)) {
      newPath = pathname.replace(
        `${location?.language.locationLanguageCode}`,
        `${newLocation.language.locationLanguageCode}`,
      );
    } else {
      // redirect to localized home path if the current path contains unsupported langauge
      newPath = `/${newLocation.language.locationLanguageCode}`;
    }

    setModalOpen && setModalOpen(false);

    navigate(newPath);
  };

  const handleModalClose = () => {
    if (!location) {
      setLocation && setLocation(defaultLocation);
    }

    setModalOpen && setModalOpen(false);
  };

  return (
    <Container
      className={pathname === '/' ? 'pt-0' : ''}
      $isMobile={isMobile}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      <PageHeader />
      {pageExists ? <Outlet /> : <PageNotFoundView />}
      <PageFooter />

      <Modal title={translations?.language_chooseYourLocation} onClose={() => handleModalClose()} isOpen={!!modalOpen}>
        <ModalContainer>
          <div className="container text-start">
            <div className="px-md-3 py-5">
              <div className="row">
                <div className="col-12 col-md-4">
                  <LocationList
                    areaName={locationAreas.america.name}
                    locations={locationAreas.america.locations}
                    onLocationClick={(location) => handleLocationClick(location)}
                  />
                </div>

                <div className="col-12 col-md-4">
                  <LocationList
                    areaName={locationAreas.europe.name}
                    locations={locationAreas.europe.locations}
                    onLocationClick={(location) => handleLocationClick(location)}
                  />
                </div>

                <div className="col-12 col-md-4">
                  <LocationList
                    areaName={locationAreas.middleEast.name}
                    locations={locationAreas.middleEast.locations}
                    onLocationClick={(location) => handleLocationClick(location)}
                  />

                  <LocationList
                    areaName={locationAreas.asia.name}
                    locations={locationAreas.asia.locations}
                    onLocationClick={(location) => handleLocationClick(location)}
                  />

                  <LocationList
                    areaName={locationAreas.oceania.name}
                    locations={locationAreas.oceania.locations}
                    onLocationClick={(location) => handleLocationClick(location)}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

const LocationList = ({
  areaName,
  locations,
  onLocationClick,
}: {
  areaName: string;
  locations: LocationInfo[];
  onLocationClick: (location: ILocation) => void;
}) => {
  return (
    <div key={areaName} className="mb-5">
      <H3 className="mb-3 d-flex align-items-center" style={{ fontSize: 16 }}>
        {areaName}
      </H3>
      {locations?.map((location) => (
        <div className="d-flex align-items-start mb-3" key={location.id}>
          {location.ecom && <Icon size="m" imgUrl={bagIcon} className="me-2 flex-shrink-0" />}
          <div className="d-flex flex-wrap ">
            {location.languages.map((lang, index) => (
              <Location
                key={index}
                onClick={() =>
                  onLocationClick({
                    id: location.id,
                    language: lang,
                    customerService: location.customerService,
                  })
                }
              >
                <Text $mono $uppercase size="xs">
                  <Link>{lang.country}</Link>
                  {index === location.languages.length - 1 ? '' : <span className="mx-3">|</span>}
                </Text>
              </Location>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const Container = styled(motion.div)<{ $isMobile: boolean }>`
  padding-top: ${({ $isMobile, theme }) => ($isMobile ? theme.sizes.header_mobile : theme.sizes.header)};
`;

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: scroll;
`;

const Location = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default AppLayout;
