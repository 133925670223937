import React from 'react';
import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';
import { Text } from 'ui';

interface IPageSection extends React.HtmlHTMLAttributes<HTMLElement> {
  background: 'white' | 'gray' | 'blue';
  title?: string;
}

const colorVariants = {
  white: 'black',
  gray: 'black',
  blue: 'white',
};

const PageSection: React.FC<IPageSection> = ({ background, title, children, ...rest }) => {
  return (
    <Container background={background} {...rest}>
      {title && (
        <TitleHeader>
          <Text level="h1" $uppercase $mono color={colorVariants[background] as any}>
            {title}
          </Text>
        </TitleHeader>
      )}
      {children}
    </Container>
  );
};

const variantOptions = {
  background: {
    white: appTheme.colors.white,
    gray: appTheme.colors.gray_light,
    blue: appTheme.colors.blue,
  },
};

const Container = styled.div<IPageSection>`
  /* min-height: 80vh; */
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 80px 0;
  }

  ${({ background }) =>
    background &&
    variantOptions.background[background] &&
    css`
      background-color: ${variantOptions.background[background]};
    `}
`;

const TitleHeader = styled.div`
  padding: 0 30px;
  margin-bottom: 60px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 80px;
  }
`;

export default PageSection;
