import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Arrow } from 'ui';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

const Slideshow = ({ ...props }) => {
  return (
    <Container>
      <Prev className={'prev ' + props.id}>
        <Arrow direction="left" />
      </Prev>
      <StyledSwiper
        modules={[Pagination, Navigation]}
        slidesPerView={'auto'}
        spaceBetween={60}
        centeredSlides
        speed={1000}
        navigation={{
          prevEl: '.prev.' + props.id,
          nextEl: '.next.' + props.id,
        }}
        draggable={false}
        allowTouchMove={false}
      >
        {props.children.map((child: any, index: number) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </StyledSwiper>
      <Next className={'next ' + props.id}>
        <Arrow direction="right" />
      </Next>
    </Container>
  );
};

const Container = styled.div`
  .swiper-button-disabled {
    display: none;
  }
`;

const StyledSwiper = styled(Swiper)`
  overflow: visible;
`;

const Nav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: 0;
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: 0;
`;

export default Slideshow;
