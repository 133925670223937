import React, { useContext, useState } from 'react';

interface IHeaderConfig {
  transparent?: boolean;
  expanded?: boolean;
  hide?: boolean;
  collapsed?: boolean;
}

interface IContext {
  headerConfig: IHeaderConfig;
  updateHeaderConfig: (config: IHeaderConfig) => void;
}

const PageHeaderContext = React.createContext<Partial<IContext>>({
  headerConfig: { transparent: false, expanded: true, hide: false, collapsed: false },
});

export const PageHeaderProvider = ({ ...props }) => {
  const [headerConfig, setHeaderConfig] = useState<IHeaderConfig>();

  const updateHeaderConfig = (config: IHeaderConfig) => {
    config && setHeaderConfig({ ...headerConfig, ...config });
  };

  return (
    <PageHeaderContext.Provider
      value={{
        headerConfig,
        updateHeaderConfig,
      }}
    >
      {props.children}
    </PageHeaderContext.Provider>
  );
};

export const useHeaderConfig = () => {
  const context = useContext(PageHeaderContext);

  if (context === null) {
    throw new Error("'useHeaderConfig' must be used inside 'PageHeaderProvider'");
  }

  return context;
};

export default PageHeaderProvider;
