import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { css } from 'styled-components';
import { useIsMobile } from 'hooks/useIsMobile';
import { useLocation } from 'providers/LocationProvider';
import { footerImages, social } from 'constants/navigation';
import useNavigationItems, { INavigationItem } from '../../hooks/useNavigationItems';
import { Text, Link, HyperLink, H3, Icon } from 'ui';
import { useNavigate } from 'react-router-dom';
import royalWarrantImg from 'media/images/logos/logo_cartier_royal_warrant.jpg';
import icons from 'media/icons';

const PageFooter = () => {
  const [openList, setOpenList] = useState<INavigationItem | undefined>(undefined);
  const isMobile = useIsMobile();
  const { setModalOpen, location, translations, localizedPath } = useLocation();
  const navigationItems = useNavigationItems();
  const currentYear = new Date().getFullYear();

  const listIsOpen = (item: INavigationItem) => {
    return openList?.name === item.name;
  };

  useEffect(() => {
    setOpenList(navigationItems.footer[0]);
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <InfoBar>
        <Text $uppercase $monoMedium size="xs">
          {translations?.footer_browseOtherCreationAtcarier}{' '}
          <a href={localizedPath?.mainCartier} target="_blank" rel="noreferrer">
            cartier.com
          </a>
        </Text>
      </InfoBar>
      <NavSection>
        <div className="container py-0 py-md-5">
          <div className="row">
            <LinkSection className="col-12 col-md-9 py-4 py-md-0">
              <div className="row py-3 py-md-0">
                {navigationItems.footer.map((item, index) => (
                  <div key={index} className="col-12 col-md-4 my-3 my-md-0">
                    <H3
                      className="mb-4 mb-md-4 d-flex align-items-center"
                      style={{ fontSize: 16 }}
                      onClick={() => (listIsOpen(item) ? setOpenList(undefined) : setOpenList(item))}
                    >
                      {item.name}
                      <StyledIcon $open={listIsOpen(item)} imgUrl={icons.downArrow} className="d-md-none ms-auto" />
                    </H3>
                    <AnimatePresence initial={false}>
                      {isMobile
                        ? listIsOpen(item) && item.children && <MobileMenu items={item.children} />
                        : item.children?.map((item, index) => <NavigationItem key={index} item={item} />)}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </LinkSection>
            <SocialSection className="col-12 col-md-3 py-5 py-md-0 text-center text-md-start">
              <H3 className="mb-4" style={{ fontSize: 16 }}>
                {translations?.footer_connectWithUs}
              </H3>
              <div className="mb-4">
                {social.map((item, index) => (
                  <a key={index} href={item.path} target="_blank" rel="noreferrer">
                    <Icon imgUrl={item.icon} width={25} className="mx-3 mx-md-0 me-md-4" />
                  </a>
                ))}
              </div>
              {location?.id === 'UK' && (
                <HyperLink href="https://www.royalwarrant.org/company/cartier-ltd" target="_blank" rel="norefferer">
                  <img src={royalWarrantImg} alt="Cartier Royal Warrant" style={{ width: 160 }} />
                </HyperLink>
              )}
            </SocialSection>
          </div>
        </div>
      </NavSection>
      <div className="container py-4">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
          {footerImages.map((item, index) => (
            <HyperLink key={index} href={item.path} target="_blank" rel="norefferer">
              <img className="mx-3 my-2" src={item.img} alt={item.name} style={{ height: item.height }} />
            </HyperLink>
          ))}
        </div>
      </div>
      <BottomBar className="py-4 py-md-3">
        <div className="container">
          <div className="d-md-flex align-items-center">
            <div className="d-flex align-items-center mb-4 mb-md-0">
              <Text $mono $uppercase size="xs" color="white" className="mr-3">
                {translations?.footer_shopIn || 'Shop in'}: {location?.language.name}
              </Text>
              <div className="px-2"></div>
              <Link color="white" reversedUnderline={true} onClick={() => setModalOpen && setModalOpen(true)}>
                {translations?.footer_changeLangugae || 'Change language'}
              </Link>
            </div>
            <Text $mono $uppercase size="xs" color="white" className="ms-md-auto">
              Copyright @ {currentYear} Cartier
            </Text>
          </div>
        </div>
      </BottomBar>
    </Container>
  );
};

const MobileMenu = ({ items }: { items: INavigationItem[] }) => {
  return (
    <motion.div
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: 'auto' },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.3 }}
    >
      {items?.map((item, index) => (
        <NavigationItem key={index} item={item} />
      ))}
    </motion.div>
  );
};

const NavigationItem = ({ item }: { item: INavigationItem }) => {
  const navigate = useNavigate();

  return (
    <Text color="light" $mono $uppercase size="xs" className="pb-4 pb-md-3">
      {item.externalPath ? (
        <HyperLink underline href={item.externalPath} target="_blank" rel="noreferrer">
          {item.name}
        </HyperLink>
      ) : (
        <Link mono onClick={() => navigate(item.path || '')}>
          {item.name}
        </Link>
      )}
    </Text>
  );
};

const Container = styled.div``;

const LinkSection = styled.div``;

const NavSection = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const SocialSection = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const BottomBar = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const InfoBar = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_light};
  padding: ${({ theme }) => theme.spacings.m};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  text-align: center;
`;

const StyledIcon = styled(Icon)<{ $open?: boolean }>`
  height: 15px;
  width: 15px;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;

export default PageFooter;
