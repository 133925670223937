import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation as useRouterLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import { collectionIds, TranslationsCollectionNames } from 'constants/ids';
import { Helmet } from 'react-helmet';

// Global styles
import GlobalStyles from 'styles/globalStyles';

// Providers
import { LocationProvider, PageHeaderProvider, SFYProvider, useLocation } from 'providers';

// Layouts
import AppLayout from 'layouts/AppLayout';

// Views
import {
  BookAnAppointmentView,
  CollectionsView,
  CollectionView,
  DiamondsView,
  HomeView,
  MatchingBandsView,
  PageNotFoundView,
  SetForYouView,
} from 'views';

// Theme
import { appTheme } from 'styles/theme';

// Global styles
import 'styles/app.scss';

// External scripts
const scripts = {
  production: {
    klarna: '/klarna.js',
  },
  test: {
    klarna: '/klarna.js',
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const App = () => {
  useEffect(() => {
    function syncHeight() {
      document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
    }
    window.addEventListener('resize', syncHeight);
    return () => {
      window.removeEventListener('resize', syncHeight);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <LocationProvider>
          <PageHeaderProvider>
            <SFYProvider>
              <Scripts />
              <GlobalStyles />
              <Router>
                <ScrollToTop />
                <AnimatedRoutes />
              </Router>
            </SFYProvider>
          </PageHeaderProvider>
        </LocationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const AnimatedRoutes = () => {
  const location = useRouterLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<AppLayout />}>
          <Route path={`/:lang/`} element={<HomeView />} />
          <Route path={`/:lang/matching-bands`} element={<MatchingBandsView />} />
          <Route path={`/:lang/collections/:collectionId`} element={<CollectionsView />} />
          <Route path={`/:lang/4c`} element={<DiamondsView />} />
          <Route path={`/:lang/book-an-appointment`} element={<BookAnAppointmentView />} />
          <Route path={`/:lang/set-for-you/*`} element={<SetForYouView />} />

          {(Object.keys(collectionIds) as (keyof typeof TranslationsCollectionNames)[]).map((collectionKey) => {
            // collection routes
            return (
              <Route
                key={collectionKey}
                path={'/:lang/' + collectionIds[collectionKey]}
                element={<CollectionView id={collectionIds[collectionKey]} />}
              />
            );
          })}

          <Route path="*" element={<PageNotFoundView />} />
        </Route>
        {/* <Route path="*" element={<Navigate to="/page-not-found" />} /> */}
      </Routes>
    </AnimatePresence>
  );
};

const ScrollToTop = () => {
  const { pathname } = useRouterLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Scripts = () => {
  const { location } = useLocation();

  useEffect(() => {
    if (location?.id !== 'TW') {
      const elem = document.getElementById('hero-iframe-container');
      elem?.remove();
    }
  }, [location]);

  return (
    <Helmet>
      {location?.id === 'TW' && (
        <script src={scripts[process.env.REACT_APP_ENVIRONMENT as keyof typeof scripts].klarna}></script>
      )}
    </Helmet>
  );
};

export default App;
