import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link as ScrollLink, Element as ScrollElement } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { getFormattedPrice } from 'utils/priceFormat';
import { useSFY } from 'providers';
import { CollectionGallery, Tabs, Dropdown, CollectionMaterials } from 'components';
import { Button, H1, H2, Icon, Text } from 'ui';
import YourCreation from './components/YourCreation';
import { collections } from 'constants/collections';
import { useLocation } from 'providers/LocationProvider';

import yourPurchase from 'media/images/services/your-purchase.jpg';
import payment from 'media/images/services/payment.jpg';
import giftWrapping from 'media/images/services/gift-wrapping.jpg';
import deliveryAndReturns from 'media/images/services/delivery-and-returns.jpg';
import icons from 'media/icons';
import SaveCreationModal from './components/SaveCreationModal';
import ModelVisualizer from './components/ModelVisualizer';
import { useIsMobile } from 'hooks/useIsMobile';
import { pushTrackingEvent } from 'utils/trackingUtil';
import { useOnScreen } from 'hooks/useOnScreen';

// used as replacement img for models missing images with front angle
import replacementImg from 'media/images/models/N4162900.png';

const SetForYouViewAndBook = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const creationDetailsRef = useRef<HTMLDivElement>(null);
  const { configuration, updateConfiguration, ringSelection } = useSFY();
  const navigate = useNavigate();
  const { location, translations } = useLocation();
  const creationDetailsIsInView = useOnScreen(creationDetailsRef);

  // collection data is stored locally in app.
  // we use it to display app specific data like aestethic description and other properties.
  const [collection, setCollection] = useState<ICollection | undefined>(undefined);

  useEffect(() => {
    // set collection on render by ringSelection's collection value
    ringSelection &&
      ringSelection.collection &&
      setCollection(collections.find((c) => c.apiId === ringSelection.collection));
  }, [ringSelection]);

  const handleEditDiamondSettings = () => {
    updateConfiguration &&
      updateConfiguration({
        ...configuration,
        currentStep: 2,
      });

    navigate(`/${location?.language.locationLanguageCode}/set-for-you/diamond`);
  };

  const getDistanceRatio = () => (window.innerWidth > 992 ? 0.8 : 0.2);

  const tabs = [
    {
      name: translations?.sfySetting_DiamondSummary?.toUpperCase() || 'DIAMOND SUMMARY',
      content: (
        <div className="py-5">
          <H1 className="my-md-5">{translations?.sfySetting_DiamondSummary_header}</H1>
          <div className="row">
            <div className="col-12 col-md-4 pe-md-5">
              <Text className="mb-4">{translations?.sfySetting_DiamondSummary_Text}</Text>
            </div>
            <div className="col-12 col-md-4 px-md-5">
              <div className="d-flex mb-3">
                <Text>{translations?.sfySetting_carat || 'Carat'}:</Text>
                <Text size="s" className="ms-auto" $monoMedium>
                  {ringSelection?.stoneCarat}
                </Text>
              </div>
              <div className="d-flex mb-3">
                <Text>{translations?.sfySetting_colour || 'Colour'}:</Text>
                <Text size="s" className="ms-auto" $monoMedium>
                  {ringSelection?.stoneColor}
                </Text>
              </div>
              <div className="d-flex mb-3">
                <Text>{translations?.sfySetting_clarity || 'Clarity'}:</Text>
                <Text size="s" className="ms-auto" $monoMedium>
                  {ringSelection?.stoneClarity}
                </Text>
              </div>
              <div className="d-flex mb-3">
                <Text>{translations?.sfySetting_price || 'Price'}:</Text>
                <Text size="s" className="ms-auto" $monoMedium>
                  {ringSelection && getFormattedPrice(ringSelection.price, ringSelection.currency)}
                </Text>
              </div>
              <hr className="mb-5 d-block d-md-none" />
            </div>
            <div className="col-12 col-md-4 px-md-5">
              <div className="text-center text-md-start">
                <Text
                  size="s"
                  $mono
                  className="d-flex align-items-center justify-content-center justify-content-md-start mb-3"
                >
                  <Icon imgUrl={icons.giaCertificate} className="me-3" />
                  {translations?.sfySetting_DiamondSummary_GIACertificate || 'GIA Certificate included'}
                </Text>
                <Button variant="underline" onClick={() => handleEditDiamondSettings()}>
                  {translations?.sfySetting_DiamondSummary_Amendyourselection || 'Amend your diamond selection'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: translations?.footer_service?.toUpperCase() || 'SERVICES',
      content: (
        <div className="py-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-md-flex my-3 my-md-5">
                <img src={yourPurchase} alt="Your purchase" className="mb-3 mb-md-0 me-5 w-100" />
                <div>
                  <H2>{translations?.sfySetting_Service_YourPurchase || 'Your purchase'}</H2>
                  <Text>{translations?.sfySetting_Service_YourPurchase_Text}</Text>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-md-flex my-3 my-md-5">
                <img src={giftWrapping} alt="Gift Wrapping" className="mb-3 mb-md-0 me-5 w-100" />
                <div>
                  <H2>{translations?.sfySetting_Service_GiftWrapping || 'Gift Wrapping'}</H2>
                  <Text>{translations?.sfySetting_Service_GiftWrapping_Text}</Text>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-md-flex my-3 my-md-5">
                <img src={deliveryAndReturns} alt="Delivery & Returns" className="mb-3 mb-md-0 me-5 w-100" />
                <div>
                  <H2>{translations?.sfySetting_Service_DeliveryReturns || 'Delivery & Returns'}</H2>
                  <Text>{translations?.sfySetting_Service_DeliveryReturns_Text}</Text>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-md-flex my-3 my-md-5">
                <img src={payment} alt="Payment" className="mb-3 mb-md-0 me-5 w-100" />
                <div>
                  <H2>{translations?.sfySetting_Service_Payment || 'Payment'}</H2>
                  <Text>{translations?.sfySetting_Service_Payment_text}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div ref={containerRef}>
        <Fill>
          <BannerContainer ref={creationDetailsRef}>
            <IframeContainer>
              <ModelVisualizer variant={'ENGRAVING'} distanceRatio={getDistanceRatio()} moveIcon={true} />
            </IframeContainer>
            <BannerFooter>
              <ImageDisclaimerText className="order-2 order-lg-4">
                {translations?.sfySetting_disclaimer}
              </ImageDisclaimerText>
            </BannerFooter>
            <BannerColumn $left>
              <CreationSummaryForm />
            </BannerColumn>
            <BannerColumn $right>
              <CreationSummaryInfo />
            </BannerColumn>
          </BannerContainer>
        </Fill>
        <ScrollElement name="summary">
          <div className="container">
            <Tabs color="red" items={tabs} className="mt-5" />
          </div>
        </ScrollElement>

        <div className="container">{collection && <CollectionGallery collection={collection} />}</div>

        {collection && <CollectionMaterials collection={collection} backgroundColor="gray" />}
      </div>
      {ringSelection && (
        <YourCreation
          creation={{
            name: ringSelection.productName,
            reference: ringSelection.model,
            imageUrl: ringSelection?.productMainImageUrl || replacementImg,
            price: ringSelection.price,
            currency: ringSelection.currency,
          }}
          isSummary
          containerElement={containerRef}
          shouldBeFixed={!creationDetailsIsInView}
        />
      )}
    </>
  );
};

const CreationSummaryForm = () => {
  const { ringSelection, updateRingSelection } = useSFY();
  const { location, translations, localizedPath } = useLocation();
  const [showCreationModal, setShowCreationModal] = useState(false);

  const handleSizeSelect = (size: string) => {
    ringSelection &&
      updateRingSelection &&
      updateRingSelection({
        ...ringSelection,
        selectedSize: size,
      });
  };

  const handleBookAnAppointmentClick = () => {
    pushTrackingEvent('esfy_intention_book_appointment');
    window.open(localizedPath?.bookAnAppointment, '_blank', 'noopener,noreferrer');
  };

  return (
    <BannerContentContainer className="py-4">
      <H2>{ringSelection?.productName}</H2>
      <Text className="mb-2">{ringSelection?.productShortDescription}</Text>
      <Text className="mb-3" size="s" $mono>
        {translations?.sfySetting_Reference || 'Reference'}: {ringSelection?.model}
      </Text>
      {ringSelection?.sizes && (
        <>
          <div className="w-100 d-flex align-items-center justify-content-end mb-2">
            <Text $mono $uppercase size="xs" className="me-2 w-100 text-end">
              <a href={localizedPath?.sizingGuide} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                {translations?.sfySetting_sizeGuide || 'Size guide'}
              </a>
            </Text>
            <Icon imgUrl={icons.newTabArrow} size="s" />
          </div>
          <Dropdown
            className="mb-4 w-100"
            label={translations?.sfySetting_selectRingSize || 'Select ring size'}
            items={ringSelection.sizes
              .sort((a, b) => Number(a) - Number(b))
              .map((s) => ({ name: s, action: () => handleSizeSelect(s) }))}
            defaultSelected={ringSelection.selectedSize || undefined}
          />
        </>
      )}
      {!location?.language.hidePrice && (
        <div className="d-flex mb-4">
          <Text className="me-2" $monoMedium>
            {ringSelection && getFormattedPrice(ringSelection.price, ringSelection.currency)}
          </Text>
          <Text color="lighter">{location?.language.priceSuffix || translations?.sfySetting_IncludesVAT || ''}</Text>
        </div>
      )}
      <Button onClick={() => handleBookAnAppointmentClick()} className="mb-3 w-100">
        {translations?.sfySetting_BookanAppointment || 'Book an appointment'}
      </Button>
      <Button className="mb-4 w-100" variant="secondary" onClick={() => setShowCreationModal(true)}>
        {translations?.savemycreation_button_savemycreation || 'Save my creation'}
      </Button>
      <Text className="mb-3" size="s" $mono>
        {translations?.sfySetting_sizenotavalible || 'Size not available or need help?'}
      </Text>
      <div className="d-flex align-items-center">
        <img src={icons.assistance} alt="assistance" className="me-3" />
        <Text size="xs" $mono $uppercase>
          {translations?.sfySetting_contactanambassador || 'Contact an ambassador'} <br />{' '}
          <a
            href={
              'tel:' +
              (location?.customerService.ambassadorPhone
                ? location?.customerService.ambassadorPhone?.number
                : location?.customerService.phone.number)
            }
            onClick={() => pushTrackingEvent('esfy_contact_ambassador')}
          >
            {location?.customerService.ambassadorPhone
              ? location?.customerService.ambassadorPhone?.displayNumber
              : location?.customerService.phone.displayNumber}
          </a>
        </Text>
      </div>
      <SaveCreationModal isOpen={showCreationModal} onClose={() => setShowCreationModal(false)} />
    </BannerContentContainer>
  );
};

const CreationSummaryInfo = () => {
  const { ringSelection } = useSFY();
  const { location, translations } = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleStartNewCreation = () => {
    navigate(`/${location?.language.locationLanguageCode}/set-for-you`);
  };

  return (
    <BannerContentContainer className="py-4">
      <Text className="mb-3">{ringSelection?.collectionDescription || '- Collection description here -'}</Text>
      <div className="d-flex align-items-center mb-3">
        <Icon imgUrl={icons.diamond} className="me-2 mt-2" size="l" />
        <Button variant="underline">
          <ScrollLink delay={0} offset={isMobile ? 0 : -100} smooth to="summary">
            {translations?.sfySetting_DiamondSummary || 'Diamond summary'}
          </ScrollLink>
        </Button>
      </div>
      <Button variant="underline" onClick={() => handleStartNewCreation()}>
        {translations?.sfySetting_StartNewCreation || 'Start a new creation'}
      </Button>
    </BannerContentContainer>
  );
};

const bannerHeight = {
  l: {
    vh: '90vh',
    px: '800px',
  },
  xxl: {
    vh: '80vh',
    px: '900px',
  },
};

const Fill = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_light};
`;

const BannerContainer = styled.div`
  max-width: 540px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 18px;
  padding-right: 18px;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 720px;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    min-height: ${bannerHeight.l.px};
    max-height: ${bannerHeight.l.vh};
    max-width: 1600px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    min-height: ${bannerHeight.xxl.px};
    max-height: ${bannerHeight.xxl.vh};
  }
`;

const BannerColumn = styled.div<{ $left?: boolean; $right?: boolean }>`
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    max-width: 300px;
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    ${({ $left }) =>
      $left &&
      css`
        left: 30px;
        max-width: ;
      `}

    ${({ $right }) =>
      $right &&
      css`
        right: 30px;
        max-width: ;
      `}
  }
`;

const BannerContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  button {
    white-space: nowrap;
  }
`;

const BannerFooter = styled.div`
  padding: 15px 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 0 30px 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ImageDisclaimerText = styled(Text)`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  user-select: none;
  font-size: 15px;
`;

const IframeContainer = styled.div`
  cursor: grab;

  iframe {
    @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
      min-height: ${bannerHeight.l.px};
      max-height: ${bannerHeight.l.vh};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      min-height: ${bannerHeight.xxl.px};
      max-height: ${bannerHeight.xxl.vh};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export default SetForYouViewAndBook;
