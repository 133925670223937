import { CartierLogoText } from 'ui';
import reactStringReplace from 'react-string-replace';

const CollectionName = ({ name }: { name: string }) => {
  return (
    <span>
      {reactStringReplace(name, '#LOVE#', () => (
        <CartierLogoText>Love</CartierLogoText>
      ))}
    </span>
  );
};

export default CollectionName;
