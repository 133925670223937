import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, H2, Text } from 'ui';
import { useLocation } from 'providers';

interface ICollectionBox {
  cardContent: {
    title: string;
    description?: string;
    targetPath?: string;
    imageUrl: string;
  };
}

const CollectionBox: React.FC<ICollectionBox> = ({ cardContent }) => {
  const navigate = useNavigate();
  const { translations } = useLocation();

  return (
    <div className="text-center">
      <ImageContainer className="mb-4">
        <StyledImage src={cardContent.imageUrl} alt={cardContent.title} />
      </ImageContainer>
      <H2 className="mb-0">{cardContent.title}</H2>
      {cardContent.description && <Text className="mb-3">{cardContent.description}</Text>}
      {cardContent.targetPath && (
        <Button onClick={() => navigate(`/${cardContent.targetPath}`)} variant="underline">
          {translations?.pageNotFound_discovery || 'Discover'}
        </Button>
      )}
    </div>
  );
};

const ImageContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray_light};
  max-height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  padding: 0 40px;
  width: 100%;
`;

export default CollectionBox;
