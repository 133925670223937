import model_1895 from './1895.png';
import model_1895_2 from './1895_2.png';
import model_1895_3 from './1895_3.png';
import model_1895_4 from './1895_4.png';
import model_1895_5 from './1895_5.png';
import model_1895_6 from './1895_6.png';

import model_ballerine from './ballerine.png';
import model_ballerine_2 from './ballerine_2.png';
import model_ballerine_3 from './ballerine_3.png';

import model_etincelledecartier from './etincelledecartier.png';

import model_cartierdestinee from './cartierdestinee.png';
import model_cartierdestinee_2 from './cartierdestinee_2.png';
import model_cartierdestinee_3 from './cartierdestinee_3.png';
import model_cartierdestinee_4 from './cartierdestinee_4.png';
import model_cartierdestinee_5 from './cartierdestinee_5.png';

import model_love from './love.png';
import model_love_2 from './love_2.png';
import model_love_3 from './love_3.png';

import model_trinityruban from './trinityruban.png';

import model_vendomelouiscartier from './vendomelouiscartier.png';

const modelImages = {
  model_1895,
  model_1895_2,
  model_1895_3,
  model_1895_4,
  model_1895_5,
  model_1895_6,

  model_ballerine,
  model_ballerine_2,
  model_ballerine_3,

  model_etincelledecartier,

  model_cartierdestinee,
  model_cartierdestinee_2,
  model_cartierdestinee_3,
  model_cartierdestinee_4,
  model_cartierdestinee_5,

  model_love,
  model_love_2,
  model_love_3,

  model_trinityruban,

  model_vendomelouiscartier,
};

export default modelImages;
