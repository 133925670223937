import { useRef } from 'react';
import { Arrow, Text } from 'ui';
import styled, { css } from 'styled-components';
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion';

import { useRefScrollProgress } from 'hooks/useRefScrollProgress';

import { Swiper, SwiperSlide } from 'swiper/react';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
import { useLocation } from '../../providers';
import { collectionIds } from 'constants/ids';

const CollectionGallery = ({ collection }: { collection: ICollection }) => {
  const ref = useRef<any>();
  const { end } = useRefScrollProgress(ref, { startOffset: 0.5, endOffset: 0.2 });
  const scrollYProgress = useViewportScroll();
  const { translations } = useLocation();
  const collection_Name = translations?.[`collection_${collection.translationTextId}`];

  const xRange = useTransform(scrollYProgress.scrollYProgress, [end! * 0.6, end! * 0.9], [window.innerWidth, 0]);
  const x = useSpring(xRange, { stiffness: 1000, damping: 90 });

  const hasModelWithScore = (score: number) => {
    return !!collection.models?.find((i) => i.score === score);
  };

  return (
    <Container ref={ref} className="py-5 mt-md-5">
      <Text $mono $uppercase>
        <StyledHeading style={{ x }}>{collection_Name}</StyledHeading>
      </Text>
      <div className="d-none d-md-block">
        <Slideshow>
          {hasModelWithScore(1) && <CollectionsBox collection={collection} score={1} />}
          {collection.models?.find((i) => i.score === 2 || i.score === 3 || i.score === 4 || i.score === 5) && (
            <CollectionsGrid collection={collection} />
          )}
          {hasModelWithScore(6) && <CollectionsBox collection={collection} score={6} />}
        </Slideshow>
      </div>
      <div className="d-md-none">
        <GridRow gap={10}>
          {hasModelWithScore(1) && <CollectionsBox collection={collection} score={1} />}
          <CollectionsGrid collection={collection} />
          {hasModelWithScore(6) && <CollectionsBox collection={collection} score={6} />}
        </GridRow>
      </div>
    </Container>
  );
};

const CollectionsBox = ({ collection, score }: { collection: ICollection; score: number }) => {
  const getCollectionModelByScore = (score: number) => {
    return collection.models?.find((i) => i.score === score);
  };

  return getCollectionModelByScore(score) ? (
    <CollectionBox size="fullscreen">
      <CollectionImageBox>
        <CollectionImage
          src={getCollectionModelByScore(score)?.mainImageUrl}
          alt={getCollectionModelByScore(score)?.id}
        />
      </CollectionImageBox>
    </CollectionBox>
  ) : null;
};

const CollectionsGrid = ({ collection }: { collection: ICollection }) => {
  const getCollectionModelByScore = (score: number) => {
    return collection.models?.find((i) => i.score === score);
  };

  const collectionModels = () =>
    collection.models?.filter((i) => i.score === 2 || i.score === 3 || i.score === 4 || i.score === 5);

  return (
    <>
      {collectionModels()?.length ? (
        <Grid colCount={(collectionModels()?.length || 2) / 2}>
          {getCollectionModelByScore(2) ? (
            <CollectionBox size="small">
              <CollectionImageBox>
                <CollectionImage
                  $closeup
                  $smallCloseup={
                    collectionModels()?.length === 2 ||
                    collection.id === collectionIds.ballerine ||
                    collection.id === collectionIds.destinee
                  }
                  src={getCollectionModelByScore(2)?.mainImageUrl}
                  alt={getCollectionModelByScore(2)?.id}
                />
              </CollectionImageBox>
            </CollectionBox>
          ) : null}
          {getCollectionModelByScore(4) ? (
            <CollectionBox size="small">
              <CollectionImageBox>
                <CollectionImage
                  src={getCollectionModelByScore(4)?.mainImageUrl}
                  alt={getCollectionModelByScore(4)?.id}
                />
              </CollectionImageBox>
            </CollectionBox>
          ) : null}
          {getCollectionModelByScore(3) ? (
            <CollectionBox size="small">
              <CollectionImageBox>
                <CollectionImage
                  src={getCollectionModelByScore(3)?.mainImageUrl}
                  alt={getCollectionModelByScore(3)?.id}
                />
              </CollectionImageBox>
            </CollectionBox>
          ) : null}
          {getCollectionModelByScore(5) ? (
            <CollectionBox size="small">
              <CollectionImageBox>
                <CollectionImage
                  $closeup
                  $smallCloseup={
                    collectionModels()?.length === 2 ||
                    collection.id === collectionIds.ballerine ||
                    collection.id === collectionIds.destinee
                  }
                  src={getCollectionModelByScore(5)?.mainImageUrl}
                  alt={getCollectionModelByScore(5)?.id}
                />
              </CollectionImageBox>
            </CollectionBox>
          ) : null}
        </Grid>
      ) : null}
    </>
  );
};

const Slideshow = ({ ...props }) => (
  <SwiperContainer className="my-3 my-md-5">
    <Prev className="prev-collection-gallery">
      <Arrow direction="left" />
    </Prev>
    <StyledSwiper
      modules={[Pagination, Navigation]}
      slidesPerView={'auto'}
      spaceBetween={60}
      centeredSlides
      speed={1000}
      navigation={{
        prevEl: '.prev-collection-gallery',
        nextEl: '.next-collection-gallery',
      }}
    >
      {/* {React.Children.map(props.children, (child, index) => child && <SwiperSlide key={index}>{child}</SwiperSlide>)} */}
      {props.children.map((child: any, index: number) => child && <SwiperSlide key={index}>{child}</SwiperSlide>)}
    </StyledSwiper>
    <Next className="next-collection-gallery">
      <Arrow direction="right" />
    </Next>
  </SwiperContainer>
);

const SwiperContainer = styled.div`
  .swiper-button-disabled {
    display: none;
  }
`;

const collectionBoxSizeVariants = {
  fullscreen: {
    height: 380,
    height_desktop: 680,
  },
  small: {
    height: 130,
    height_desktop: 310,
  },
};

const Grid = styled.div<{ colCount: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${({ colCount }) => colCount}, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    column-gap: 60px;
    row-gap: 60px;
  }
`;

const GridRow = styled.div<{ gap: number }>`
  display: grid;
  row-gap: ${({ gap }) => gap && `${gap}px`};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CollectionBox = styled.div<{ size?: 'fullscreen' | 'small' }>`
  ${({ size }) =>
    size &&
    collectionBoxSizeVariants[size] &&
    css`
      height: ${collectionBoxSizeVariants[size].height}px;

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        height: ${collectionBoxSizeVariants[size].height_desktop}px;
      }
    `}
`;

const CollectionImageBox = styled.div<{ size?: 'fullscreen' | 'small' }>`
  position: relative;
  background-color: #f6f6f6;
  text-align: center;
  cursor: grab;
  height: 100%;
  overflow: hidden;
`;

const CollectionImage = styled.img<{ $closeup?: boolean; $smallCloseup?: boolean }>`
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  user-select: none;

  ${({ $closeup, $smallCloseup }) =>
    $closeup &&
    css`
      top: 10%;
      transform: translateX(-50%);
      width: ${$smallCloseup ? '80%' : '150%'};
      max-height: none;
      max-width: none;
    `}
`;

const StyledHeading = styled(motion.h1)`
  font-size: 45px;
  color: #f6f5f6;
  user-select: none;
  position: absolute;
  text-align: left;
  /* left: -80px; */
  bottom: calc(100% - 75px);
  z-index: -1;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 120px;
    bottom: calc(100% - 160px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    font-size: 140px;
  }
`;

const StyledSwiper = styled(Swiper)`
  overflow: visible;
`;

const Nav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: -25px;
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: -25px;
`;

export default CollectionGallery;
