import materialImages from 'media/images/materials';
import modelImages from 'media/images/models';
import { collectionIds } from './ids';

export const materials: IMaterial[] = [
  {
    id: 'PT',
    name: 'Platinum',
    images: [
      {
        url: modelImages.N4162900_front,
        perspective: 'FRONT',
        collectionId: collectionIds._1895,
      },
      {
        url: modelImages.N4197900_front,
        perspective: 'FRONT',
        collectionId: collectionIds.ballerine,
      },
      {
        url: modelImages.N4246000_front,
        perspective: 'FRONT',
        collectionId: collectionIds.destinee,
      },
      {
        url: modelImages.N4744600_front,
        perspective: 'FRONT',
        collectionId: collectionIds.etincelle,
      },
      {
        url: modelImages.N4724500_front,
        perspective: 'FRONT',
        collectionId: collectionIds.love,
      },
      {
        url: modelImages.N4250400_front,
        perspective: 'FRONT',
        collectionId: collectionIds.ruban,
      },
      {
        url: materialImages.pt_x1,
        perspective: 'DEFAULT',
      },
      {
        url: materialImages.pt_x2,
        perspective: 'CLOSEUP',
      },
    ],
  },
  {
    id: 'PG',
    name: 'Rose Gold',
    images: [
      {
        url: modelImages.N4743600_front,
        perspective: 'FRONT',
        collectionId: collectionIds._1895,
      },
      {
        url: modelImages.N4725100_front,
        perspective: 'FRONT',
        collectionId: collectionIds.ballerine,
      },
      {
        url: modelImages.B4085200_front,
        perspective: 'FRONT',
        collectionId: collectionIds.love,
      },
      {
        url: materialImages.rg_x1,
        perspective: 'DEFAULT',
      },
      {
        url: materialImages.rg_x2,
        perspective: 'CLOSEUP',
      },
    ],
  },
  {
    id: 'WG',
    name: 'White Gold',
    images: [],
  },
  {
    id: 'RG',
    name: 'Rose Gold',
    images: [],
  },
  {
    id: 'YG',
    name: 'Yellow Gold',
    images: [
      {
        url: modelImages.B4031200_front,
        perspective: 'FRONT',
        collectionId: collectionIds._1895,
      },
      {
        url: materialImages.yg_x1,
        perspective: 'DEFAULT',
      },
      {
        url: materialImages.yg_x2,
        perspective: 'CLOSEUP',
      },
    ],
  },
  {
    id: 'TG',
    name: 'White gold, Yellow gold, Rose gold',
    images: [
      {
        url: modelImages.N4204200_front,
        perspective: 'FRONT',
        collectionId: collectionIds.vendome,
      },
      {
        url: materialImages.tg_x1,
        perspective: 'DEFAULT',
      },
    ],
  },
  {
    id: 'paved',
    name: 'Paved',
    images: [
      {
        url: modelImages.N4761400_front,
        perspective: 'FRONT',
      },
      {
        url: materialImages.paved_x1,
        perspective: 'DEFAULT',
      },
      {
        url: materialImages.paved_x2,
        perspective: 'CLOSEUP',
      },
    ],
  },
  {
    id: 'classicPaved',
    name: 'Classic Paved',
    images: [
      {
        url: modelImages.N4163900_front,
        perspective: 'FRONT',
      },
      {
        url: materialImages.classicpaved_x5,
        perspective: 'CLOSEUP',
      },
    ],
  },
];
