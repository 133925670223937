import styled, { css } from 'styled-components';
import { H2, Text, ViewSection } from 'ui';
import { useSFY } from 'providers/SFYProvider';
import diamondLeftImg from 'media/images/sfy/diamond-left.png';
import diamondRightImg from 'media/images/sfy/diamond-right.png';
import { useLocation } from 'providers';
import RangeSlider from './RangeSlider';

const Carat = ({ onTouch }: { onTouch?: () => void }) => {
  const { configuration, defaultConfiguration, updateConfiguration } = useSFY();
  const { translations } = useLocation();

  const handleChange = (range: number | readonly number[]) => {
    const caratRange = range as number[];

    updateConfiguration &&
      updateConfiguration({
        ...configuration,
        carat: caratRange,
      });

    onTouch && onTouch();
  };

  return (
    <ViewSection background="white">
      <div className="container py-5 px-4">
        <div className="col-12 col-md-6 mx-auto text-center mb-5">
          <H2 className="mb-4">{translations?.sfySetting_carat || 'CARAT'}</H2>
          <Text className="mb-4">
            {translations?.sfySetting_carat_text ||
              'Carat, the unit of weight for diamonds, is derived from the carob (carob) fruit that was once used to weigh diamonds. One carat is equivalent to 0.20 grams. Cartier offers a very wide range of carat diamonds, depending on the pedestal.'}
          </Text>
          {/* <Button variant="underline">{translations?.sfySetting_LearnMore || 'Learn more'}</Button> */}
        </div>
        <div className="row d-flex align-items-center pb-5 py-md-5">
          <div className="col-6 col-md-2 order-1 d-flex align-items-center justify-content-end">
            <Diamond $direction="LEFT">
              <img src={diamondLeftImg} alt="diamond left" />
            </Diamond>
          </div>
          <div className="col-12 col-md-8 order-3 order-md-2">
            <RangeSlider
              defaultValue={configuration?.carat || [0, 5]}
              min={(defaultConfiguration?.carat && defaultConfiguration.carat[0]) || 0}
              max={(defaultConfiguration?.carat && defaultConfiguration.carat[1]) || 5}
              step={0.01}
              pearling
              onAfterChange={(value) => handleChange(value)}
              disabled={
                (defaultConfiguration?.carat && defaultConfiguration.carat[0]) ===
                (defaultConfiguration?.carat && defaultConfiguration.carat[1])
              }
              valueFormatter={(value) => value.toFixed(2)}
            />
          </div>
          <div className="col-6 col-md-2 order-2 order-md-3 d-flex align-items-center justify-content-start">
            <Diamond $direction="RIGHT">
              <img src={diamondRightImg} alt="diamond left" />
            </Diamond>
          </div>
        </div>
      </div>
    </ViewSection>
  );
};

const Diamond = styled.div<{ $direction: 'LEFT' | 'RIGHT' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 180px;

  ${({ $direction, theme }) =>
    $direction &&
    $direction === 'LEFT' &&
    css`
      @media (max-width: ${theme.breakpoints.m}) {
        margin-right: -40px;
      }

      @media (min-width: ${theme.breakpoints.m}) {
        border-right: 1px solid ${theme.colors.border};
      }

      img {
        width: 60%;
      }
    `}

  ${({ $direction, theme }) =>
    $direction &&
    $direction === 'RIGHT' &&
    css`
      @media (max-width: ${theme.breakpoints.m}) {
        margin-left: -40px;
      }

      @media (min-width: ${theme.breakpoints.m}) {
        border-left: 1px solid ${theme.colors.border};
      }
    `}
`;

export default Carat;
