import { H1, H2, StandardView, Text } from '../../ui';
import IntroCropImg from 'media/images/diamonds/intro_crop.jpg';
import IntroFullImg from 'media/images/diamonds/intro_full_image.jpg';
import { useIsMobile } from '../../hooks/useIsMobile';
import CutSwiper from './components/CutSwiper';
import Beauty from '../HomeView/components/Beauty';
import SetForYouSection from './components/SetForYouSection';
import FourCGrid from './components/FourCGrid';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'providers';

const DiamondsView = () => {
  const isMobile = useIsMobile();
  const { translations } = useLocation();

  const DIAMONDS_CONFIG = {
    header: {
      title: translations?.diamond_header || 'CARTIER DIAMONDS',
      description: translations?.diamond_Text,
    },
    the4c: {
      title: translations?.diamond_header_4c || "THE 4 C'S",
      description: translations?.diamond_Text_4c,
    },
    carat: {
      values: [0.3, 4.51],
      title: translations?.fourC_carat || 'CARAT',
      description:
        translations?.fourC_carat_Text ||
        'Carat, the unit of weight for diamonds, is derived from the carob (carob) fruit that was once used to weigh diamonds. One carat is equivalent to 0.20 grams. Cartier offers a very wide range of carat diamonds, depending on the pedestal.',
    },
    color: {
      values: ['D', 'E', 'F', 'G', 'H', 'IJ...Z'],
      valuesDesc: {
        D: translations?.diamond_colour_d || 'D description',
        E: translations?.diamond_colour_e || 'E description',
        F: translations?.diamond_colour_f || 'F description',
        G: translations?.diamond_colour_g || 'G description',
        H: translations?.diamond_colour_h || 'H description',
        'IJ...Z': translations?.diamond_colour_iz || 'IJ...Z description',
      },
      title: translations?.fourC_colour || 'COLOUR',
      description:
        translations?.fourC_colour_Text ||
        'Diamonds are said to be more valuable as they are as colorless as possible. GIA evaluates diamond colors in alphabetical order from D (Exceptional White) to Z (Tinted Color).' +
          'Cartier uses only colorless diamonds in grades D to H. Cartier diamonds are selected from diamonds that are either non-fluorescent or contain very little.',
    },
    clarity: {
      values: ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1...I3'],
      valuesDesc: {
        FL: translations?.diamond_clarity_f || 'FL description',
        IF: translations?.diamond_clarity_if || 'IF description',
        VVS1: translations?.diamond_clarity_vvs1 || 'VVS1 description',
        VVS2: translations?.diamond_clarity_vvs2 || 'VVS2 description',
        VS1: translations?.diamond_clarity_vs1 || 'VS1 description',
        VS2: translations?.diamond_clarity_vs2 || 'VS2 description',
        'SI1...I3': translations?.diamond_clarity_s1113 || 'SI1...I3 description',
      },
      title: translations?.fourC_clarity || 'CLARITY',
      description:
        translations?.fourC_clarity_Text ||
        'Inclusions are impurities that naturally occur inside a diamond and affect the quality of the diamond. Diamond clarity is determined by the number of' +
          'inclusions, their size and position. In the clarity grade table set by GIA, diamonds are rated in grades from FL (Flores = no inclusions even when magnified 10 times) to ' +
          'I3 (or P3 included = inclusions visible to the naked eye). I am. Cartier chooses only VS2 center diamonds from IF, and uses only extremely clarity stones with no' +
          'visible inclusions.',
    },
    cut: {
      values: [],
      title: translations?.fourC_cut || 'CUT',
      description:
        translations?.fourC_cut_Text ||
        "The cuts made on diamonds, as well as their symmetry and balance, are the most important criteria in 4C. GIA's grades for" +
          "diamond cutting have five levels, from 'excellent' to 'poor', all depending on the talent and skill of the diamond cutting craftsman.",
    },
  };

  return (
    <StandardView title={DIAMONDS_CONFIG.header.title} subTitle={DIAMONDS_CONFIG.header.description || ''}>
      <div className="mb-0 mb-md-5 container-xl px-0">
        <AnimatePresence>
          <motion.img
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.8,
            }}
            style={{
              height: isMobile ? '300px' : '600px',
              objectFit: 'cover',
            }}
            src={isMobile ? IntroCropImg : IntroFullImg}
            className="mb-2 w-100"
          />
        </AnimatePresence>
      </div>
      <div className="container text-center py-5 mb-0 mb-md-5">
        <H1 className="mb-4 mb-md-5">{DIAMONDS_CONFIG.the4c.title}</H1>
        <div className="col-12 col-md-6 mx-auto">
          <Text>{DIAMONDS_CONFIG.the4c.description}</Text>
        </div>
      </div>
      <FourCGrid config={DIAMONDS_CONFIG} />
      {/*<Divider />*/}
      <div className="mb-5 mt-0 mt-md-5">
        <div className="container text-center pt-5">
          <H2 className="mb-3">{DIAMONDS_CONFIG.cut.title}</H2>
          <div className="col-12 col-md-6 mx-auto">
            <Text>{DIAMONDS_CONFIG.cut.description}</Text>
          </div>
        </div>
        <CutSwiper />
      </div>
      {/*<Divider />*/}
      {/*<Faq />*/}
      <SetForYouSection />
      <Beauty />
    </StandardView>
  );
};

// const Divider = styled.div`
//   border-top: 1px solid ${({ theme }) => theme.colors.border};
// `;

export default DiamondsView;
