import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface IQuoute extends React.HtmlHTMLAttributes<HTMLElement> {
  color?: 'white' | 'black' | 'light';
}

const variantOptions = {
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
    light: appTheme.colors.text_light,
  },
};

const QuoteText = styled.h2<IQuoute>`
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 1px;
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  text-transform: uppercase;

  &:before,
  &:after {
    content: '“';
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 36px;
    line-height: 68px;
    letter-spacing: 3px;
  }

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};
    `}
`;

const Quote = ({ text }: { text: string }) => <QuoteText>{text}</QuoteText>;

export default Quote;
