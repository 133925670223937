import { useState } from 'react';
import { Route, Routes, useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import { Button, H2, StandardView, Text } from 'ui';
import step1Image from 'media/images/sfy/step-1.jpg';
import step2Image from 'media/images/sfy/step-2.jpg';
import step4Image from 'media/images/sfy/step-4.jpg';
import { SetForYouDiamondView, SetForYouMakeItYours, SetForYouSettingView, SetForYouViewAndBook } from '.';
import ProgressBar from './components/ProgressBar';
import { useSFY } from 'providers/SFYProvider';
import { Modal } from 'components';
import { useLocation } from 'providers';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { pushTrackingEvent } from 'utils/trackingUtil';

const SetForYou = () => {
  const { pathname } = useRouterLocation();
  const { location } = useLocation();

  return (
    <>
      {pathname !== `/${location?.language.locationLanguageCode}/set-for-you` && <ProgressBar />}

      <Routes>
        <Route path="/" element={<StartView />} />
        <Route path="setting" element={<SetForYouSettingView />} />
        <Route path="diamond" element={<SetForYouDiamondView />} />
        <Route path="make-it-yours" element={<SetForYouMakeItYours />} />
        <Route path="results" element={<SetForYouViewAndBook />} />
      </Routes>
    </>
  );
};

const StartView = () => {
  const queryParameters = useQueryParameters();
  const { configuration, updateConfiguration, clearConfiguration } = useSFY();
  const navigate = useNavigate();
  const { location, translations } = useLocation();
  const [modal, setModal] = useState<{ isOpen: boolean; message: string | undefined }>({
    isOpen: false,
    message: undefined,
  });

  const steps = [
    {
      title: translations?.sfyLandingPageStep1 || 'Step 1',
      text: translations?.sfyLandingPageStep1_text || 'Choose a setting',
      imgSrc: step1Image,
    },
    {
      title: translations?.sfyLandingPageStep2 || 'Step 2',
      text: translations?.sfyLandingPageStep2_text || 'Select a diamond',
      imgSrc: step2Image,
    },
    {
      title: translations?.sfyLandingPageStep4 || 'Step 3',
      text: translations?.sfyLandingPageStep4_text || 'View your creation & book an appointment',
      imgSrc: step4Image,
    },
  ];

  const handleBeginClick = () => {
    pushTrackingEvent('esfy_converter_start');

    if (configuration?.currentStep && configuration.currentStep > 1) {
      setModal({
        isOpen: true,
        message:
          translations?.sfy_creationInProgress ||
          'You have an unfinished creation. Would you like to continue on this or start over?',
      });

      return;
    }

    handleNewCreation();
  };

  const handleNewCreation = () => {
    clearConfiguration && clearConfiguration();

    updateConfiguration &&
      updateConfiguration({
        currentStep: 1,
      });

    const collectionParam = queryParameters.get('collection');

    navigate(
      `/${location?.language.locationLanguageCode}/set-for-you/setting` +
        (collectionParam?.length ? `?collection=${collectionParam}` : ''),
    );
  };

  const handleContinueCreation = () => {
    pushTrackingEvent('esfy_converter_finish_creation');

    const currentStep = configuration?.currentStep!;

    if (currentStep === 2) {
      navigate(`/${location?.language.locationLanguageCode}/set-for-you/diamond`);
    }

    if (currentStep === 3) {
      navigate(`/${location?.language.locationLanguageCode}/set-for-you/results`);
    }
  };

  const handleCloseModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  return (
    <StandardView
      title={translations?.diamond_header_setForYou || 'Set For You'}
      subTitle={translations?.diamond_text_setForYou || ''}
    >
      <div className="container pt-0 pb-5">
        <div className="row">
          <div className="col-9 col-sm-12 col-md-10 mx-auto">
            <div className="row">
              {steps.map((step, index) => (
                <div key={index} className="col-12 col-sm-4 col-lg-4 text-center mb-5 mb-md-0">
                  <img src={step.imgSrc} alt={step.title} className="mb-3 mb-sm-4" />
                  <H2>{step.title}</H2>
                  <Text>{step.text}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Button className="d-block mt-3 mx-auto" onClick={() => handleBeginClick()}>
          {translations?.sfyLandingPageBeginCreating || 'Begin creating'}
        </Button>
      </div>

      <Modal isOpen={modal.isOpen} onClose={() => handleCloseModal()} maxwidth={800}>
        <div className="py-5 px-4 p-md-5">
          <Text className="mb-4">{modal.message}</Text>
          <div className="d-md-flex flex-wrap justify-content-between">
            <Button onClick={() => handleContinueCreation()} className="my-2 me-3">
              {translations?.sfy_finishCreation || 'Finish current creation'}
            </Button>
            <Button onClick={() => handleNewCreation()} variant="secondary" className="my-2">
              {translations?.sfy_startNew || 'Start over'}
            </Button>
          </div>
        </div>
      </Modal>
    </StandardView>
  );
};

export default SetForYou;
