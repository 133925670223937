import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface IHeading extends React.HtmlHTMLAttributes<HTMLElement> {
  color?: 'white' | 'black' | 'light';
  introTitle?: boolean;
}

const variantOptions = {
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
    light: appTheme.colors.text_light,
  },
};

const H1 = styled.h1<IHeading>`
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  text-transform: uppercase;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 3px;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 6px;
  }

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};
    `}

  ${({ introTitle }) =>
    introTitle &&
    css`
      font-family: 30px;

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        font-size: 80px;
        line-height: 120px;
        letter-spacing: 10px;
      }
    `}
`;

export default H1;
