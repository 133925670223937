import styled from 'styled-components';
import Button from './Button';

const StyledButton = styled(Button)`
  padding: 0;
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
`;

const TextButton = ({...props}) => <StyledButton {...props} />;

export default TextButton;
