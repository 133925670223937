import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Arrow } from 'ui';

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

const Slideshow = ({ ...props }) => {
  return (
    <Container className="d-flex align-items-center mt-4">
      <Prev className="prev">
        <Arrow direction="left" />
      </Prev>
      <Swiper
        preloadImages={true}
        modules={[Pagination, Navigation]}
        breakpoints={{
          768: {
            slidesPerGroup: 4,
            slidesPerView: 4,
          },
          992: {
            slidesPerGroup: 6,
            slidesPerView: 6,
          },
        }}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
      >
        {props.children.map((child: any, index: number) => (
          <SwiperSlide
            style={{
              minWidth: '140px',
            }}
            key={index}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      <Next className="next">
        <Arrow direction="right" />
      </Next>
    </Container>
  );
};

const Container = styled.div`
  .swiper-button-disabled {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;
  }
  

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: ${({ theme }) => theme.breakpoints.l};
  }
`;

const Nav = styled.div`
  top: calc(50%);
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;
  margin-bottom: 70px;
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
`;

export default Slideshow;
