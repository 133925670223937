export enum TranslationsCollectionNames {
  _1895 = '1895',
  amour = "Cartier d'Amour", //todo: ask for translations
  ballerine = 'Ballerine',
  broderie = 'Broderie de Cartier', //todo: ask for translations
  cDeCartier = 'C de Cartier', //todo: ask for translations
  destinee = 'CartierDestinee',
  etincelle = 'Etincelle',
  love = 'Love',
  ruban = 'TrinityRuban',
  vendome = 'VendomeLouisCartier',
}
export const collectionIds: { [key in keyof typeof TranslationsCollectionNames]: string } = {
  _1895: '1895',
  amour: 'amour',
  ballerine: 'ballerine',
  broderie: 'broderie-de-cartier',
  cDeCartier: 'c-de-cartier',
  destinee: 'cartier-destinee',
  etincelle: 'etincelle-de-cartier',
  love: 'love',
  ruban: 'trinity-ruban',
  vendome: 'vendome-louis-cartier',
};

export const collectionApiIds: { [key in keyof typeof TranslationsCollectionNames]: string } = {
  _1895: '1895',
  amour: "Cartier d'Amour",
  ballerine: 'Ballerine',
  broderie: 'Broderie de Cartier',
  cDeCartier: 'C de Cartier',
  destinee: 'Cartier Destinée',
  etincelle: 'Etincelle de Cartier',
  love: 'Love',
  ruban: 'Trinity Ruban',
  vendome: 'Vendôme Louis Cartier',
};
