import cutImages from './../media/images/diamonds/cut'

const cuts = [
    {
        id: 'emerald',
        image: cutImages.cut_emerald
    }, {
        id: 'marquis',
        image: cutImages.cut_marquis
    }, {
        id: 'oval',
        image: cutImages.cut_oval
    }, {
        id: 'pear',
        image: cutImages.cut_pear
    }, {
        id: 'round',
        image: cutImages.cut_round
    }, {
        id: 'square',
        image: cutImages.cut_square
    },
]

export default cuts;