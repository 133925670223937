import { useState } from 'react';
import styled from 'styled-components';
import cartierLogo from 'media/cartier-logo-black.png';
import { Button, Text } from 'ui';
import { useAuth } from 'providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useIsMobile } from 'hooks/useIsMobile';

const AuthView = () => {
  const { setAuthenticated } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [inputs, setInputs] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  });

  const [message, setMessage] = useState<string | undefined>(undefined);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (
      setAuthenticated &&
      inputs.username === process.env.REACT_APP_UN &&
      inputs.password === process.env.REACT_APP_PW
    ) {
      setMessage(undefined);
      localStorage.setItem('isAuthenticated', 'true');
      setAuthenticated(true);
      navigate('/');
    } else {
      setMessage('Invalid credentials, please try again.');
    }
  };

  const handleInputChange = (e: any) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container
      isMobile={isMobile}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 0.1, delay: 0.5 }}
    >
      <img style={{ width: 150 }} className="mb-3" src={cartierLogo} alt="cartier" />
      <StyledForm onSubmit={(input) => handleSubmit(input)}>
        <StyledInput
          type="text"
          placeholder="Username"
          name="username"
          className="mb-3"
          value={inputs.username}
          onChange={handleInputChange}
        />
        <StyledInput
          type="password"
          placeholder="Password"
          name="password"
          className="mb-3"
          value={inputs.password}
          onChange={handleInputChange}
        />
        <AnimatePresence initial={false}>
          {message && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { opacity: 1, height: 'auto' },
                hidden: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.1, delay: 0.2 }}
            >
              <Text className="mb-2 py-2" color="error">
                {message}
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
        <Button className="d-block">Sign in</Button>
      </StyledForm>
    </Container>
  );
};

const Container = styled(motion.div)<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100vh - ${({ theme, isMobile }) => (isMobile ? theme.sizes.header_mobile : theme.sizes.header)});
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;
`;

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0;
  height: 40px;
  background-color: white;
  text-align: center;
  outline: none;
  width: 200px;
`;

export default AuthView;
