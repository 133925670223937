import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Text } from '../../../ui';
import FourCGridItem from './FourCGridItem';
import CaratSize from '../../../media/images/diamonds/carat_size.png';
import colorImages, { IColorImages } from '../../../media/images/diamonds/color';
import clarityImages, { IClarityImages } from '../../../media/images/diamonds/clarity';

import ReactSlider from 'react-slider';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useLocation } from 'providers';

type TColor = 'D' | 'E' | 'F' | 'G' | 'H' | 'IJ...Z';
export type TClarity = 'FL' | 'IF' | 'VVS1' | 'VVS2' | 'VS1' | 'VS2' | 'SI1...I3';

const FourCGrid = ({ config: DIAMONDS_CONFIG }: { config: any }) => {
  const isMobile = useIsMobile();
  const { translations } = useLocation();
  const [carat, setCarat] = useState<number[]>([0.3]);
  const [caratScale, setCaratScale] = useState<number>(0.5);
  const [color, setColor] = useState<string>(DIAMONDS_CONFIG.color.values[0]);
  const [clarity, setClarity] = useState<string>(DIAMONDS_CONFIG.clarity.values[0]);

  const handleSliderUpdate = (range: number | readonly number[]) => {
    const minValue = DIAMONDS_CONFIG.carat.values[0];
    const maxValue = DIAMONDS_CONFIG.carat.values[1];
    const total = (maxValue - minValue) / 0.01;
    const caratNum: number = range as number;
    let caratIndex = ((caratNum - minValue) * 100 + 1).toFixed(0);
    let scale = parseInt(caratIndex) / total > 1 ? 1 : parseInt(caratIndex) / total;
    scale += 0.5;
    setCaratScale(scale);
    setCarat(range as number[]);
  };
  const handleColourUpdate = (range: number | readonly number[]) => {
    setColor(DIAMONDS_CONFIG.color.values[range as number]);
  };

  const handleClarityUpdate = (range: number | readonly number[]) => {
    setClarity(DIAMONDS_CONFIG.clarity.values[range as number]);
  };

  const getColorImage = () => {
    return colorImages[color.toLowerCase() as keyof IColorImages] || colorImages.h;
  };

  const getClarityImage = () => {
    return clarityImages[clarity.toLowerCase() as keyof IClarityImages] || clarityImages.vs2;
  };

  return (
    <div className="container-xl px-0">
      {/*carat*/}
      <div className="row w-100 g-0">
        <div
          className="col-md-6 col-xs-12 g-0 d-flex justify-content-center flex-column align-items-center"
          style={{ backgroundColor: '#f8f7f8', zIndex: 1 }}
        >
          <img
            alt="Carat size"
            src={CaratSize}
            style={{ padding: '20% 0', width: '200px', transform: `scale(${caratScale})` }}
          />
          <div
            className="d-flex w-100 justify-content-center align-items-center mb-0 mb-sm-4"
            style={{ height: '60px' }}
          >
            <div className="col-10 col-md-8">
              <StyledSlider
                marks
                min={DIAMONDS_CONFIG.carat.values[0]}
                max={DIAMONDS_CONFIG.carat.values[1]}
                step={0.01}
                defaultValue={carat}
                renderThumb={(props) => <div {...props}>{carat}</div>}
                onChange={(value) => {
                  handleSliderUpdate(value);
                }}
                renderMark={(props: any) => {
                  return <span {...props} />;
                }}
              />
            </div>
          </div>
        </div>
        <FourCGridItem
          title={translations?.fourC_carat || 'CARAT'}
          description={translations?.fourC_carat_Text || ''}
        />
      </div>
      {/*colour*/}
      <div className="row w-100 g-0">
        <div
          className="col-md-6 col-xs-12 order-md-2 d-flex flex-column justify-content-center align-items-center"
          style={{ backgroundColor: '#f8f7f8', zIndex: 1 }}
        >
          <motion.img
            key={getColorImage()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={getColorImage()}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: isMobile ? '300px' : '400px',
              filter: !colorImages[color.toLowerCase() as keyof IColorImages] ? `blur(4px)` : '',
            }}
          />
          <div className="d-flex w-100 flex-column justify-content-center align-items-center mb-0 mb-sm-4">
            <div className="col-7">
              <Text size={'xs'} $monoMedium $uppercase $center>
                {(DIAMONDS_CONFIG.color.valuesDesc as { [key in TColor]: string })[color as TColor]}
              </Text>
            </div>
            <div className="col-10 col-md-8">
              <Stringslider
                marks={Array.from(Array(DIAMONDS_CONFIG.color.values.length).keys())}
                step={1}
                min={0}
                max={DIAMONDS_CONFIG.color.values.length - 1}
                defaultValue={0}
                onAfterChange={(value) => handleColourUpdate(value)}
                renderThumb={(props: any, state) => {
                  return (
                    <StyledThumb {...props} $size="45px" key={props.key}>
                      {DIAMONDS_CONFIG.color.values[state.valueNow as number]}
                    </StyledThumb>
                  );
                }}
                renderMark={(props: any) => {
                  return (
                    <StringLabel $size="45px" key={props.key} {...props}>
                      {DIAMONDS_CONFIG.color.values[props.key]}
                    </StringLabel>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <FourCGridItem
          flip={true}
          title={translations?.fourC_colour || 'COLOUR'}
          description={translations?.fourC_colour_Text || ''}
        />
      </div>
      {/*clarity*/}
      <div className="row w-100 g-0">
        <div
          className="col-md-6 col-xs-12 d-flex flex-column justify-content-center align-items-center"
          style={{ backgroundColor: '#f8f7f8', zIndex: 1 }}
        >
          <motion.img
            key={getClarityImage()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={getClarityImage()}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: isMobile ? '270px' : '400px',
              filter: !clarityImages[clarity.toLowerCase() as keyof IClarityImages] ? `blur(4px)` : '',
            }}
          />
          <div className="d-flex w-100 flex-column justify-content-center align-items-center mb-0 mb-sm-4">
            {/*<div className="col-7">*/}
            {/*  <Text size={'xs'} $monoMedium $uppercase $center>*/}
            {/*    {(DIAMONDS_CONFIG.clarity.valuesDesc as { [key in TClarity]: string })[clarity as TClarity]}*/}
            {/*  </Text>*/}
            {/*</div>*/}
            <div className="col-11 col-md-10 col-lg-10">
              <Stringslider
                marks={Array.from(Array(DIAMONDS_CONFIG.clarity.values.length).keys())}
                step={1}
                min={0}
                max={DIAMONDS_CONFIG.clarity.values.length - 1}
                defaultValue={0}
                onAfterChange={(value) => handleClarityUpdate(value)}
                renderThumb={(props: any, state) => {
                  return (
                    <StyledThumb {...props} key={props.key}>
                      {DIAMONDS_CONFIG.clarity.values[state.valueNow as number]}
                    </StyledThumb>
                  );
                }}
                renderMark={(props: any) => {
                  return (
                    <StringLabel key={props.key} {...props}>
                      {DIAMONDS_CONFIG.clarity.values[props.key]}
                    </StringLabel>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <FourCGridItem
          title={translations?.fourC_clarity || 'CLARITY'}
          description={translations?.fourC_clarity_Text || ''}
        />
      </div>
    </div>
  );
};

const StringLabel = styled.div<{ $size: string }>`
  font-family: BrilliantCutProMedium;
  border-radius: 50px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.dark_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: ${({ $size }) => ($size && $size) || '60px'};
  height: ${({ $size }) => ($size && $size) || '60px'};
  font-size: 14px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
`;

const Stringslider = styled(ReactSlider)`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .track {
    height: 1px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.dark_gray};
    box-shadow: inset 0 2px 5px -1px rgb(0 0 0 / 15%);

    &-1 {
      transform: translateX(-5px);
    }
  }
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  .track {
    height: 1px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.dark_gray};
    box-shadow: inset 0 2px 5px -1px rgb(0 0 0 / 15%);

    &-0:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px;
      height: 10px;
      background-color: ${({ theme }) => theme.colors.dark_gray};
      top: 50%;
      transform: translateY(-50%);
    }

    &--dark {
      background-color: ${({ theme }) => theme.colors.dark_gray};
    }

    &-1 {
      background-color: ${({ theme }) => theme.colors.dark_gray};

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1px;
        height: 10px;
        background-color: ${({ theme }) => theme.colors.dark_gray};
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .thumb {
    top: 5px;
    font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
    height: 60px;
    width: 20px;
    text-align: center;
    cursor: pointer;
    outline: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -7px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      background-color: black;
      border: 1px solid ${({ theme }) => theme.colors.border};
      z-index: -1;
      box-shadow: 0 7px 12px -4px rgb(0 0 0 / 20%);
    }
  }

  .marks {
    background-color: white;
    width: 3px;
    height: 12px;

    &:first-of-type,
    &:last-of-type {
      background-color: transparent;
    }
  }
`;

const StyledThumb = styled.div<{ $size: string }>`
  font-family: BrilliantCutProMedium;
  border-radius: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: 0 7px 12px -4px rgb(0 0 0 / 20%);

  width: ${({ $size }) => ($size && $size) || '60px'};
  height: ${({ $size }) => ($size && $size) || '60px'};
  font-size: 14px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
`;

export default FourCGrid;
