import { useState, useRef, useEffect } from 'react';
import StyledSlider from './StyledSlider';
import styled, { css } from 'styled-components';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';

interface IRangeSlider {
  defaultValue: number[];
  min: number;
  max: number;
  valueFormatter?: (value: number) => void;
  onAfterChange: (value: number | readonly number[], index: number) => void;
  disabled: boolean;
  prefix?: string;
  step?: number;
  pearling?: boolean;
}

const RangeSlider: React.FC<IRangeSlider> = (props) => {
  const handlesRef = useRef<Array<HTMLDivElement | null>>([]);
  const [handlesDistance, setHandlesDistance] = useState(window.innerWidth);

  const handleHandlesDistance = () => {
    const handlesDistance =
      (handlesRef.current[1]?.getBoundingClientRect().left || 0) -
      (handlesRef.current[0]?.getBoundingClientRect().left || 0);
    setHandlesDistance(handlesDistance);
  };

  return (
    <StyledSlider
      className="horizontal-slider"
      thumbClassName="thumb"
      trackClassName="track--dark track"
      defaultValue={props.defaultValue}
      min={props.min}
      max={props.max}
      step={props.step}
      renderThumb={(thumbProps, state) => {
        return (
          <div {...thumbProps}>
            <div ref={(el) => (handlesRef.current[state.index] = el)}>
              <SliderHandle direction={state.index === 0 ? 'LEFT' : 'RIGHT'} distanceBetween={handlesDistance}>
                {props.prefix}
                {props.valueFormatter ? props.valueFormatter(state.valueNow) : state.valueNow}
              </SliderHandle>
            </div>
          </div>
        );
      }}
      pearling={props.pearling}
      onChange={handleHandlesDistance}
      onAfterChange={props.onAfterChange}
      disabled={props.disabled}
    />
  );
};

interface ISliderHandle {
  distanceBetween: number;
  direction: 'LEFT' | 'RIGHT';
}

const SliderHandle: React.FC<React.HtmlHTMLAttributes<HTMLDivElement> & ISliderHandle> = ({
  distanceBetween,
  direction,
  children,
}) => {
  const output = {
    LEFT: [0, -25, -30],
    RIGHT: [0, 25, 30],
  };

  const motionValue = useMotionValue(distanceBetween);
  const xTransform = useTransform(motionValue, [60, 20, 0], output[direction]);
  const x = useSpring(xTransform, { stiffness: 1000, damping: 90 });

  useEffect(() => {
    motionValue.set(distanceBetween);
  }, [distanceBetween, motionValue]);

  return (
    <StyledPrice style={{ x }} $direction={direction}>
      {children}
    </StyledPrice>
  );
};

const StyledPrice = styled(motion.div)<{ $direction?: 'LEFT' | 'RIGHT' }>`
  position: absolute;
  bottom: 37px;

  ${({ $direction }) =>
    $direction === 'LEFT'
      ? css`
          position: absolute;
          right: 0;
        `
      : css`
          left: 0;
        `}
`;

export default RangeSlider;
