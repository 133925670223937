import React from 'react';
import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'underline';
  color?: 'white';
  disabled?: boolean;
}

const variantOptions = {
  primary: {
    backgroundColor: appTheme.colors.primary,
    color: appTheme.colors.white,
    fontFamily: appTheme.typography.fonts.monoMedium,
    border: `1px solid ${appTheme.colors.primary}`,
    borderBottom: `1px solid ${appTheme.colors.primary}`,
    textTransform: 'uppercase',
    padding: '0 30px',
    hover: {
      opacity: 0.7,
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    color: appTheme.colors.primary,
    fontFamily: appTheme.typography.fonts.monoMedium,
    border: `1px solid ${appTheme.colors.primary}`,
    borderBottom: `1px solid ${appTheme.colors.primary}`,
    textTransform: 'uppercase',
    padding: '0 30px',
    hover: {
      opacity: 0.6,
    },
  },
  underline: {
    backgroundColor: 'transparent',
    color: appTheme.colors.text,
    fontFamily: appTheme.typography.fonts.base,
    border: 'none',
    borderBottom: `1px solid ${appTheme.colors.text}`,
    textTransform: 'initial',
    padding: '0',
    hover: {
      opacity: 0.6,
    },
  },
  color: {
    white: {
      color: appTheme.colors.white,
      borderColor: appTheme.colors.white,
    },
  },
};

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  line-height: 36px;
  padding: 0 30px;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  letter-spacing: 1px;
  font-size: ${({ theme, variant }) =>
    variant === 'underline' ? theme.typography.sizes.base : theme.typography.sizes.xs};
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.typography.fonts.mono};
  background-color: ${(props) => props.theme.colors.white};
  transition: 0.3s ease opacity;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2 !important;
    `}

  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      font-family: ${variantOptions[variant].fontFamily};
      background-color: ${variantOptions[variant].backgroundColor};
      color: ${variantOptions[variant].color};
      border: ${variantOptions[variant].border};
      border-bottom: ${variantOptions[variant].borderBottom};
      text-transform: ${variantOptions[variant].textTransform};
      padding: ${variantOptions[variant].padding};

      &:hover {
        opacity: ${variantOptions[variant].hover.opacity};
      }

      &:disabled {
      }
    `}

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color].color};
      border-color: ${variantOptions.color[color].borderColor};
    `}
`;

const Button: React.FC<React.HtmlHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({
  variant = 'primary',
  ...rest
}) => <StyledButton variant={variant} {...rest} />;

export default Button;
