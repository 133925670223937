import styled, { css } from 'styled-components';
import MaterialBox from 'components/MaterialBox';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, Navigation, Pagination } from 'swiper';
import { materials } from 'constants/materials';
import { useLocation } from 'providers';
import { appTheme } from 'styles/theme';
import { Text } from 'ui';

const CollectionMaterials = ({
  collection,
  backgroundColor,
}: {
  collection: ICollection;
  backgroundColor: 'blue' | 'gray' | 'white';
}) => {
  const isMobile = useIsMobile();
  const { translations } = useLocation();

  const includesPavedProducts = (paving: 'Paved' | 'Classic Paved') => {
    return collection.models?.find((m) => m.paving === paving) !== undefined;
  };

  const collectionMaterials = () => {
    const collectionMaterials = materials.filter((m) => collection.materialIds.includes(m.id));

    const paved = includesPavedProducts('Paved') ? [materials.find((m) => m.id === 'paved')!] : [];
    const classicPaved = includesPavedProducts('Classic Paved')
      ? [materials.find((m) => m.id === 'classicPaved')!]
      : [];

    return [...collectionMaterials, ...paved, ...classicPaved];
  };

  return (
    <PageSection
      title={translations?.collection_preciousMaterial || 'Precious materials'}
      background={backgroundColor}
      className="text-center"
    >
      <div className="container">
        <div className="row">
          {isMobile ? (
            <StyledMaterialsSwiper
              modules={[EffectCreative, Pagination, Navigation]}
              effect={'creative'}
              creativeEffect={{
                limitProgress: 2,
                prev: {
                  // opacity: 0.5,
                  scale: 0.9,
                  translate: ['-100%', -20, 0],
                },
                next: {
                  // opacity: 0.5,
                  scale: 0.9,
                  translate: ['100%', -20, 0],
                },
              }}
              roundLengths={true}
              preloadImages={true}
              centeredSlides={true}
              slidesPerView={1.2}
              pagination={{
                clickable: true,
                type: 'bullets',
              }}
              $light={backgroundColor === 'blue'}
              $autoHeight={collectionMaterials().length === 1}
            >
              {collectionMaterials()?.map((m) => (
                <SwiperSlide key={m.id}>
                  <div
                    key={m.id}
                    className={`col-12 col-md-${12 / collectionMaterials().length} mb-5 text-start flex-column`}
                  >
                    <MaterialBox
                      material={m}
                      size={collectionMaterials().length}
                      lightText={backgroundColor === 'blue'}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </StyledMaterialsSwiper>
          ) : (
            <MaterialGrid colCount={collectionMaterials ? collectionMaterials().length : 1}>
              {collectionMaterials()?.map((m) => (
                <MaterialBox
                  key={m.id}
                  material={m}
                  size={collectionMaterials() ? collectionMaterials().length : 1}
                  lightText={backgroundColor === 'blue'}
                />
              ))}
            </MaterialGrid>
          )}
        </div>
      </div>
    </PageSection>
  );
};

interface IPageSection extends React.HtmlHTMLAttributes<HTMLElement> {
  background: 'white' | 'gray' | 'blue';
  title?: string;
}

const colorVariants = {
  white: 'black',
  gray: 'black',
  blue: 'white',
};

const PageSection: React.FC<IPageSection> = ({ background, title, children, ...rest }) => {
  return (
    <Container background={background} {...rest}>
      {title && (
        <TitleHeader>
          <Text level="h1" $uppercase $mono color={colorVariants[background] as any}>
            {title}
          </Text>
        </TitleHeader>
      )}
      {children}
    </Container>
  );
};

const variantOptions = {
  background: {
    white: appTheme.colors.white,
    gray: appTheme.colors.gray_light,
    blue: appTheme.colors.blue,
  },
};

const Container = styled.div<IPageSection>`
  /* min-height: 80vh; */
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 80px 0 120px;
  }

  ${({ background }) =>
    background &&
    variantOptions.background[background] &&
    css`
      background-color: ${variantOptions.background[background]};
    `}
`;

const TitleHeader = styled.div`
  padding: 0 30px;
  margin-bottom: 60px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 80px;
  }
`;

const StyledMaterialsSwiper = styled(Swiper)<{ $light?: boolean; $autoHeight?: boolean }>`
  min-height: ${({ $autoHeight }) => ($autoHeight ? 'auto' : '40vh')};

  .swiper-pagination-bullet {
    border: 1px solid ${({ $light, theme }) => ($light ? theme.colors.white : theme.colors.text)};
    background: none;
    margin: 0 8px !important;

    &-active {
      background-color: ${({ $light, theme }) => ($light ? theme.colors.white : theme.colors.text)};
    }
  }
`;

const MaterialGrid = styled.div<{ colCount: number }>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
  grid-template-columns: ${({ colCount }) => colCount && `repeat(${colCount}, 1fr)`};
`;

export default CollectionMaterials;
