// ER
import B4012500_front from './1895_B4012500_Front.png';
import B4031200_front from './1895_B4031200_Front.png';
import B4071400_front from './1895_B4071400_Front.png';
import B4088200_front from './1895_B4088200_Front.png';
import B4093600_front from './Amour_B4093600_Front.png';
import B4093900_front from './Amour_B4093900_Front.png';
import B4072000_front from './Ballerine_B4072000_Front.png';
import B4092800_front from './Ballerine_B4092800_Front.png';
import B4093000_front from './Ballerine_B4093000_Front.png';
import N4744900_front from './Broderie_N4744900_Front.png';
import B4225600_front from './Broderie_B4225600_Front.png';
import B4232500_front from './Cdecartier_B4232500_Front.png';
import B4054000_front from './Cdecartier_B4054000_Front.png';
import B4086400_front from './Cdecartier_B4086400_Front.png';
import N4127500_front from './Destinee_N4127500_Front.png';
import B4077900_front from './Etincelle_B4077900_Front.png';
import B4221900_front from './Etincelle_B4221900_Front.png';
import B4050800_front from './Love_B4050800_Front.png';
import B4083300_front from './Love_B4083300_Front.png';
import B4085200_front from './Love_B4085200_Front.png';
import N4724500_front from './Love_N4724500_Front.png';
import N4774600_front from './Love_N4774600_Front.png';
import B4099400_front from './Ruban_B4099400_Front.png';
import B4209900_front from './Vendome_B4209952_Front.png';

// WR
import N4162900_front from './1895_N4162900_Front.png';
import N4235100_front from './1895_N4235100_Front.png';
import N4743600_front from './1895_N4743600_Front.png';
import N4163900_front from './1895_N4163900_Front.png';
import N4761400_front from './1895_N4761400_Front.png';
import N4759400_front from './1895_N4759400_Front.png';
import N4759000_front from './1895_N4759000_Front.png';
import N4197900_front from './Ballerine_N4197900_Front.png';
import N4725100_front from './Ballerine_N4725100_Front.png';
import N4188400_front from './Ballerine_N4188400_Front.png';
import N4246000_front from './Destinee_N4246000_Front.png';
import N4751600_front from './Destinee_N4751600_Front.png';
import N4751800_front from './Destinee_N4751800_Front.png';
import N4751700_front from './Destinee_N4751700_Front.png';
import N4751000_front from './Destinee_N4751000_Front.png';
import N4744600_front from './Etincelle_N4744600_Front.png';
import N4774200_front from './Love_N4774200_Front.png';
// import N4777600_front from './Love_N4777600_Front.png';
import N4250400_front from './Ruban_N4250400_Front.png';
import N4204200_front from './Vendome_N4204200_Front.png';
import N4162900_side from './1895_N4162900_Side.png';
import N4235100_side from './1895_N4235100_Side.png';
import N4743600_side from './1895_N4743600_Side.png';
import N4163900_side from './1895_N4163900_Side.png';
import N4761400_side from './1895_N4761400_Side.png';
import N4759400_side from './1895_N4759400_Side.png';
import N4759000_side from './1895_N4759000_Side.png';
import N4197900_side from './Ballerine_N4197900_Side.png';
import N4725100_side from './Ballerine_N4725100_Side.png';
import N4188400_side from './Ballerine_N4188400_Side.png';
import N4246000_side from './Destinee_N4246000_Side.png';
import N4751600_side from './Destinee_N4751600_Side.png';
import N4751800_side from './Destinee_N4751800_Side.png';
import N4751700_side from './Destinee_N4751700_Side.png';
import N4751000_side from './Destinee_N4751000_Side.png';
import N4744600_side from './Etincelle_N4744600_Side.png';
import N4724500_side from './Love_N4724500_Side.png';
import N4774600_side from './Love_N4774600_Side.png';
import N4774300_side from './Love_N4774300_Side.png';
import N4774200_side from './Love_N4774200_Side.png';
// import N4777600_side from './Love_N4777600_Side.png';
import N4250400_side from './Ruban_N4250400_Side.png';
import N4204200_side from './Vendome_N4204200_Side.png';

interface IModelMedia {
  [key: string]: string;
}

const modelImages: IModelMedia = {
  B4012500_front,
  B4031200_front,
  B4071400_front,
  B4088200_front,
  B4093600_front,
  B4093900_front,
  B4072000_front,
  B4092800_front,
  B4093000_front,
  N4744900_front,
  B4225600_front,
  B4232500_front,
  B4054000_front,
  B4086400_front,
  N4127500_front,
  B4077900_front,
  B4221900_front,
  B4050800_front,
  B4083300_front,
  B4085200_front,
  B4099400_front,
  B4209900_front,

  N4162900_front,
  N4235100_front,
  N4743600_front,
  N4163900_front,
  N4761400_front,
  N4759400_front,
  N4759000_front,
  N4197900_front,
  N4725100_front,
  N4188400_front,
  N4246000_front,
  N4751600_front,
  N4751800_front,
  N4751700_front,
  N4751000_front,
  N4744600_front,
  N4724500_front,
  N4774600_front,
  N4774200_front,
  // N4777600_front,
  N4250400_front,
  N4204200_front,
  N4162900_side,
  N4235100_side,
  N4743600_side,
  N4163900_side,
  N4761400_side,
  N4759400_side,
  N4759000_side,
  N4197900_side,
  N4725100_side,
  N4188400_side,
  N4246000_side,
  N4751600_side,
  N4751800_side,
  N4751700_side,
  N4751000_side,
  N4744600_side,
  N4724500_side,
  N4774200_side,
  // N4777600_side,
  N4250400_side,
  N4204200_side,
  N4774600_side,
  N4774300_side,
};

export default modelImages;
