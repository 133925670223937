import { Text } from 'ui';
import styled, { css } from 'styled-components';
import { useLocation, useSFY } from 'providers';
import { useNavigate, useLocation as useRouterLocation } from 'react-router-dom';
import { useEffect } from 'react';

type SFYStep = {
  name: string;
  path: string;
  position: number;
};

const ProgressBar = () => {
  const { location, translations } = useLocation();
  const { pathname } = useRouterLocation();
  const { configuration, updateConfiguration } = useSFY();
  const navigate = useNavigate();

  const steps: SFYStep[] = [
    {
      name: translations?.sfySetting_1 || '',
      path: 'setting',
      position: 1,
    },
    {
      name: translations?.sfySetting_2 || '',
      path: 'diamond',
      position: 2,
    },
    {
      name: translations?.sfySetting_4 || '',
      path: 'results',
      position: 3,
    },
  ];

  useEffect(() => {
    // set current step
    updateConfiguration &&
      updateConfiguration({
        ...configuration,
        currentStep: steps.find((s) => pathname.includes(s.path))?.position,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClick = (step: SFYStep) => {
    // prevent moving forward if step is not configured yet
    if (step.position > (configuration?.configuredSteps || configuration?.currentStep!)) return;

    // set configured steps if moving back to edit previously configured steps
    if (!configuration?.configuredSteps) {
      updateConfiguration &&
        updateConfiguration({
          ...configuration,
          configuredSteps: configuration?.currentStep,
        });
    }

    let targetPath = `/${location?.language.locationLanguageCode}/set-for-you/${step.path}`;

    // if navigating to first step, set collection and model param in route
    if (step.position < 2) {
      targetPath += `?collection=${configuration?.collection?.id}&model=${configuration?.model?.id}`;
    }

    navigate(targetPath);
  };

  return (
    <Fill gray={configuration?.currentStep === 3} $absolute={configuration?.currentStep === 3}>
      <div className="container py-4 pb-0 pb-md-4">
        <DotContainer>
          {steps.map((step, index) => (
            <StepContainer
              borderDirection={index === 0 ? 'RIGHT' : index === 2 ? 'LEFT' : 'BOTH'}
              key={index}
              onClick={() => handleClick(step)}
            >
              <StepDot
                active={configuration?.currentStep === step.position}
                done={
                  step.position !== (configuration?.currentStep || 1) &&
                  (step.position < (configuration?.currentStep || 1) ||
                    (configuration?.configuredSteps && step.position <= configuration.configuredSteps) ||
                    false)
                }
              >
                {step.position}
              </StepDot>
              <TextContainer className="d-none d-md-block mt-2">
                <Text size={'xs'} $monoMedium $uppercase $center>
                  {step.name}
                </Text>
              </TextContainer>
            </StepContainer>
          ))}
        </DotContainer>
        <div className="d-block d-md-none text-center py-3">
          <Text size={'s'} $monoMedium $uppercase>
            {steps.find((step) => step.position === configuration?.currentStep)?.name}
          </Text>
        </div>
      </div>
    </Fill>
  );
};

const Fill = styled.div<{ gray?: boolean; $absolute?: boolean }>`
  ${({ gray }) =>
    gray &&
    css`
      background-color: ${({ theme }) => theme.colors.gray_light};
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    ${({ $absolute }) =>
      $absolute &&
      css`
        position: absolute;
        left: 0;
        right: 0;
        background-color: transparent;
        z-index: 10;
      `}
  }
`;

const DotContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
`;

const StepContainer = styled.div<{ borderDirection: 'LEFT' | 'RIGHT' | 'BOTH' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 13px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border};
    z-index: -1;

    ${({ borderDirection }) =>
      borderDirection &&
      borderDirection === 'BOTH' &&
      css`
        left: 0;
        right: 0;
      `}

    ${({ borderDirection }) =>
      borderDirection &&
      borderDirection === 'LEFT' &&
      css`
        left: 0;
        right: 50%;
      `}

    ${({ borderDirection }) =>
      borderDirection &&
      borderDirection === 'RIGHT' &&
      css`
        left: 50%;
        right: 0;
      `}
  }
`;

const TextContainer = styled.div`
  max-width: 250px;
  user-select: none;
`;

const StepDot = styled.div<{ active: boolean; done?: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  line-height: 26px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.black};
      border-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ done }) =>
    done &&
    css`
      background-color: #b6b6b6;
      border-color: #b6b6b6;
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export default ProgressBar;
