import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { H3, Text } from 'ui';

const ProductCard = ({ product }: { product: ICollectionModel }) => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(product.id)}>
      <ImageContainer className="mb-4">
        <Image src={product.mainImageUrl} />
      </ImageContainer>
      <H3>{product.name || product.id}</H3>
      {product.material && <Text>{product.material.name}</Text>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background-color: ${({ theme }) => theme.colors.fill_light};
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
`;

export default ProductCard;
