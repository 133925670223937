import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Logo from 'media/images/logos/cartier_diamonds.svg';
import BurgerMenu from './components/BurgerMenu';
import MegaMenu from './components/MegaMenu';
import { useIsMobile } from 'hooks/useIsMobile';
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { useScroll } from 'hooks/useScroll';
import { Icon, Link, Text } from 'ui';
import { useHeaderConfig } from 'providers/PageHeaderProvider';
import findBoutiqueIcon from '../../media/icons/find-boutique.svg';
import { useLocation } from 'providers';
import { useLocation as useRouterLocation } from 'react-router-dom';

const logoHeight = 50;

const PageHeader = ({
  $transparent,
  $expanded,
  $hidden,
  ...props
}: {
  $transparent?: boolean;
  $expanded?: boolean;
  $hidden?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { scrollY, scrollDirection } = useScroll();
  const { location, translations, localizedPath } = useLocation();
  const { headerConfig, updateHeaderConfig } = useHeaderConfig();
  const { pathname } = useRouterLocation();
  const [restoreScroll, setRestoreScroll] = useState(0);

  useEffect(() => {
    if (updateHeaderConfig && headerConfig) {
      if (isOpen) {
        setRestoreScroll(document.documentElement.scrollTop);
        document.documentElement.classList.add('is-locked');
      } else {
        document.documentElement.classList.remove('is-locked');
        document.documentElement.scrollTop = restoreScroll;
      }
      updateHeaderConfig({
        ...headerConfig,
        collapsed: !isOpen,
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  // control header states on all routes except start view
  useEffect(() => {
    // expand header when scrolling up (only visible on desktop size)
    const expanded = (!isOpen && scrollDirection === 'up') || scrollY === 0;

    // on mobile, hide(collapse) header when scrolling down
    const collapsed = isMobile && !isOpen && scrollDirection === 'down' && scrollY > 50;

    if (updateHeaderConfig && pathname !== '/') {
      updateHeaderConfig({
        ...headerConfig,
        transparent: false,
        expanded,
        collapsed,
      });
    }
    // eslint-disable-next-line
  }, [scrollY, scrollDirection, pathname]);

  const handleLogoClick = () => {
    navigate(`/${location?.language.locationLanguageCode}`);
    setIsOpen(false);
  };

  return (
    <div {...props}>
      <StyledPageHeader
        $transparent={$transparent || headerConfig?.transparent}
        $collapsed={headerConfig?.collapsed}
        $expanded={$expanded || headerConfig?.expanded}
        $hide={$hidden || headerConfig?.hide}
        $forceExpanded={(!isOpen && scrollDirection === 'up') || scrollY === 0}
        $isMobile={isMobile}
        className="px-3"
      >
        <HeaderRow>
          {isMobile && (
            <BurgerMenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
              <span />
              <span />
              <span />
              <span />
            </BurgerMenuButton>
          )}
          <StyledLogo src={Logo} alt="Cartier" className="mx-auto" onClick={() => handleLogoClick()} />
          <ActionsLeft className="d-none d-md-block">
            <a href={localizedPath?.mainCartier} target="_blank" rel="noreferrer" className="me-4">
              <Link mono>{translations?.header_visitCartier || 'Visit Cartier.com'}</Link>
            </a>
            <a href={localizedPath?.contactUs} target="_blank" rel="noreferrer">
              <Link mono>{translations?.header_footer_contactUs || 'Contact us'}</Link>
            </a>
          </ActionsLeft>
          <ActionsRight>
            <Link
              className="d-md-flex align-items-center"
              style={{ marginTop: isMobile ? 0 : -5 }}
              mono
              onClick={() => window.open(localizedPath?.storeLocator, '_blank')}
            >
              <Icon className="d-md-block me-2" imgUrl={findBoutiqueIcon} />
              <Text className="d-none d-md-block" $mono size={'xs'}>
                {translations?.header_boutiques || 'BOUTIQUES'}
              </Text>
            </Link>
          </ActionsRight>
        </HeaderRow>
        {!isMobile ? <MegaMenu /> : <BurgerMenu isOpen={isOpen} shouldCloseMenu={() => setIsOpen(false)} />}
      </StyledPageHeader>
    </div>
  );
};

const StyledPageHeader = styled(motion.div)<{
  $isMobile?: boolean;
  $forceExpanded?: boolean;
  $expanded?: boolean;
  $hide?: boolean;
  $transparent?: boolean;
  $collapsed?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  height: ${({ theme, $isMobile }) => ($isMobile ? theme.sizes.header_mobile : theme.sizes.header)};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-out;

  ${({ $transparent }) =>
    $transparent &&
    css`
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    `}

  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
    `}

  ${({ $expanded, $isMobile }) =>
    $expanded || $isMobile
      ? css`
          transform: translateY(0) !important;
        `
      : css`
          transform: translateY(calc(${({ theme }) => theme.sizes.header} / -2));
        `}

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      transform: translateY(-100%) !important;
    `}

  ${({ $forceExpanded }) =>
    $forceExpanded &&
    css`
      transform: translateY(0) !important;
    `}
`;

const ActionsLeft = styled.div`
  position: absolute;
  top: 15px;
  left: 25px;
  font-size: ${({ theme }) => theme.typography.sizes.xs};

  a {
    text-decoration: none;
  }
`;

const ActionsRight = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: ${({ theme }) => theme.typography.sizes.xs};
  cursor: pointer;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    & a div:hover {
      background-size: 0 1px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 26px;
  }

  a {
    text-decoration: none;
  }

  ${Link} {
    &:hover {
      color: ${({ theme }) => theme.colors.red};
      background-size: 0;

      div {
        color: ${({ theme }) => theme.colors.red};
      }

      span {
        filter: invert(16%) sepia(73%) saturate(6383%) hue-rotate(345deg) brightness(88%) contrast(125%);
      }
    }
  }
`;

const BurgerMenuButton = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  left: 20px;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 80%;
    background: #000;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease;

    &:first-child {
      top: calc(50% - 8px);
      transition: 0.1s ease;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 50%;
    }

    &:nth-child(4) {
      top: calc(50% + 8px);
      transition: 0.1s ease;
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        &:first-child {
          top: calc(50% - 8px);
          width: 0%;
          left: 50%;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: calc(50% + 8px);
          width: 0%;
          left: 50%;
        }
      `}
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: calc(${({ theme }) => theme.sizes.header} / 2);
  }
`;

const StyledLogo = styled.img`
  height: ${logoHeight}px;
  cursor: pointer;
`;

export default PageHeader;
