import { Tabs } from 'components';
import Slideshow from './components/Slideshow';
import { collections } from 'constants/collections';
import { useParams } from 'react-router-dom';
import { useLocation } from 'providers';

const CollectionsView = () => {
  const { collectionId } = useParams();
  const { translations } = useLocation();

  const tabs = [
    // {
    //   name: translations?.collection_allCollection.toUpperCase() || 'ALL COLLECTIONS',
    //   id: 'all',
    //   content: <Slideshow id={'all'} items={collections} />,
    // },
    {
      name: translations?.collection_engagemantRings?.toUpperCase() || 'ENGAGEMENT',
      id: 'ER',
      path: 'engagement-rings',
      content: <Slideshow id={'er'} items={collections.filter((collection) => collection.type === 'ER')} />,
    },
    // {
    //   name: translations?.collection_weddingRings.toUpperCase() || 'WEDDING',
    //   id: 'WR',
    //   path: 'wedding-rings',
    //   content: <Slideshow id={'wr'} items={collections.filter((collection) => collection.type === 'WR')} />,
    // },
  ];

  const defaultActiveIdx = tabs.findIndex((tab) => {
    return tab.path === collectionId;
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-10 d-flex align-items-center justify-content-center py-3 mt-md-4 mx-auto">
          <Tabs className="w-100 d-flex flex-column" items={tabs} defaultActiveIdx={defaultActiveIdx} color="red" />
        </div>
        <div className="col-12 d-flex align-items-center justify-content-center text-center"></div>
      </div>
    </div>
  );
};

export default CollectionsView;
