import { useEffect, useRef } from 'react';
import useSize from '@react-hook/size';
import { Button, H2, H3 } from 'ui';
import Slideshow from './components/Slideshow';
import styled, { css } from 'styled-components';
import { models } from 'constants/models';
import { useLocation } from 'providers';
import { useModelsQuery } from './queries';
import { pushTrackingEvent } from 'utils/trackingUtil';

export const includedRings = {
  er: [
    'N4162900',
    'N4235100',
    'N4743600',
    'N4163900',
    'N4761400',
    'N4759400',
    'N4759000',
    'N4246000',
    'N4751600',
    'N4751000',
    'N4751700',
    'N4751800',
    'N4744600',
    'N4197900',
    'N4725100',
    'N4188400',
    'N4250400',
    'N4724500',
    'N4774200',
    'N4204200',
  ],
  wr: [
    'B4012500',
    'B4071400',
    'B4088200',
    'B4031200',
    'N4127500',
    'B4221900',
    'B4077900',
    'B4092800',
    'B4093000',
    'B4072000',
    'B4093900',
    'B4093600',
    'N4744900',
    'B4225600',
    'B4099400',
    'B4054000',
    'B4232500',
    'B4086400',
    'B4085200',
    'B4050800',
    'B4083300',
    'B4209900',
  ],
};

const MatchingBandsView = () => {
  const slideshowRef = useRef<any>(null);
  const slideshowSize = useSize(slideshowRef);
  const { translations, location, localizedPath } = useLocation();

  const { data, isLoading, refetch } = useModelsQuery(includedRings.er.concat(includedRings.wr));

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [location]);

  const handleButtonClick = () => {
    pushTrackingEvent('esfy_intention_book_appointment');

    window.open(localizedPath?.bookAnAppointment, undefined, 'noopener,noreferrer');
  };

  return (
    <>
      <Container className="container overflow-visible text-center">
        <H2>{translations?.menu_matchingBands || 'Matching bands'}</H2>
        <SlideshowContainer $isLoading={isLoading} className="d-flex flex-column align-items-center">
          <div className="w-100">
            <Slideshow
              id={'slide-1'}
              items={models.filter((m) => m.type === 'ER' && includedRings.er.includes(m.id))}
              productData={data}
            />
          </div>
          <div ref={slideshowRef} className="w-100 mb-0 mb-sm-4 s" style={{ marginTop: -(slideshowSize[1] / 3) }}>
            <Slideshow
              id={'slide-2'}
              items={models.filter((m) => m.type === 'WR' && includedRings.wr.includes(m.id))}
              productData={data}
              flip
            />
          </div>
        </SlideshowContainer>
      </Container>
      <CTA className="d-md-flex align-items-md-center justify-content-md-center text-center py-4 py-md-5">
        <H3 className="mb-4 mb-md-0 me-md-5">{translations?.matchingbands_readyToPurchase || 'Ready to purchase?'}</H3>
        <Button className="mb-2 mb-md-1" onClick={() => handleButtonClick()}>
          {translations?.matchingbands_bookAnAppointment || 'Book an appointment'}
        </Button>
      </CTA>
    </>
  );
};

const Container = styled.div`
  margin: 30px auto 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 70px auto 30px;

    ${H2} {
      margin-bottom: 30px;
    }
  }
`;

const CTA = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const SlideshowContainer = styled.div<{ $isLoading: boolean }>`
  transition: opacity 0.3s ease;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0.3;
    `}
`;

export default MatchingBandsView;
