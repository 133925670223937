import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'components';
import { Text, Input, Button, Icon } from 'ui';
import { useSendCreationEmailQuery } from '../queries';
import { useSFY, useLocation } from 'providers';
import { getFormattedPrice } from 'utils/priceFormat';
import icons from 'media/icons';
import { pushTrackingEvent } from 'utils/trackingUtil';

const SaveCreationModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const { configuration, ringSelection } = useSFY();
  const { location, translations, localizedPath } = useLocation();

  const CUT_TRANSLATIONS: any = {
    CU: translations && translations['diamond_cut_cushion'],
    EC: translations && translations['diamond_cut_emerald'],
    OV: translations && translations['diamond_cut_oval'],
    PS: translations && translations['diamond_cut_pear'],
    RD: translations && translations['diamond_cut_brilliant'],
  };

  const productMaterial = () => {
    return ringSelection?.productMaterial?.length ? ringSelection.productMaterial[0] : '';
  };

  const getSubTitle = () => {
    const material = productMaterial();
    const paving =
      ringSelection?.classicPaved && translations
        ? translations['collection_material_classicpaved']
        : ringSelection?.paved && translations
        ? translations['collection_material_paved']
        : '';
    const cut =
      ringSelection?.stoneCut?.length && translations
        ? `${translations[CUT_TRANSLATIONS[ringSelection.stoneCut]]} ${translations?.sfySetting_cut}`
        : '';

    return `${material} ${paving} ${cut}`;
  };

  const {
    refetch: sendCreationEmail,
    isLoading,
    isError,
    isSuccess,
  } = useSendCreationEmailQuery(
    {
      productDetails: {
        name: ringSelection?.productName || 'Product name missing',
        subTitle:
          (translations && translations[configuration?.model?.aestheticDescription as keyof TranslationLabels]) ||
          getSubTitle(),
        material: productMaterial(),
        paving: ringSelection?.classicPaved ? 'classic paved' : ringSelection?.paved ? 'paved' : '',
        reference: ringSelection?.model || 'Reference number missing',
        description: ringSelection?.productLongDescription || '',
        imageUrl: ringSelection?.productMainImageUrl || '-',
      },
      creationConfig: {
        price: getFormattedPrice(ringSelection!.price!, ringSelection!.currency!),
        size: ringSelection?.selectedSize!,
        stoneCarat: ringSelection?.stoneCarat!,
        stoneClarity: ringSelection?.stoneClarity!,
        stoneColor: ringSelection?.stoneColor!,
        stoneCut: ringSelection?.stoneCut!,
        stoneType: ringSelection?.stoneType!,
      },
      embossing: ringSelection?.embossing,
      engraving: ringSelection?.engraving,
      translations: {
        subject: translations?.savemycreation_email_header || 'Your Diamond Summary',
        contactUs: translations?.header_footer_contactUs?.toUpperCase() || 'CONTACT US',
        visitCartier: translations?.header_visitCartier?.toUpperCase() || 'VISIT CARTIER.COM',
        reference: translations?.savemycreation_email_ref || 'Ref.',
        ringSize: translations?.savemycreation_email_ringsize?.toUpperCase() || 'RING SIZE',
        engraving: translations?.savemycreation_email_engraving || 'ENGRAVING',
        embossing: translations?.savemycreation_email_embossing || 'EMBOSSING',
        block: translations?.savemycreation_email_engraving_blocks || 'Block',
        cursive: translations?.savemycreation_email_embossing_cursive || 'Cursive',
        findABoutique: translations?.savemycreation_email_findaboutique || 'FIND A BOUTIQUE',
        contactAnAmbassador: translations?.savemycreation_email_contactanambassador || 'CONTACT AN AMBASSADOR',
        summaryHeading: translations?.savemycreation_email_header || '',
        summaryText: translations?.savemycreation_email_text || '',
        giaCertificateSupplied: translations?.sfySetting_DiamondSummary_GIACertificate || 'GIA Certificate supplied',
        carat: translations?.savemycreation_email_carat || 'Carat',
        colour: translations?.savemycreation_email_colour || 'Colour',
        clarity: translations?.savemycreation_email_clarity || 'Clarity',
        cut: translations?.sfySetting_cut || 'Cut',
        price: translations?.savemycreation_email_price || 'Price',
        //ambassadorNotes: translations?.savemycreation_email_Ambassodornots || 'Ambassador notes',
        connectWithUs: translations?.footer_connectWithUs || 'CONNECT WITH US',
        imageDisclaimer:
          translations?.savemycreation_email_imageDisclaimer || 'The product may differ from the images shown.',
      },
      contactAmbassadorUrl: localizedPath?.contactUs as string,
      contactUrl: localizedPath?.contactUs as string,
      findInBoutiqueUrl: localizedPath?.findBoutique as string,
      websiteUrl: localizedPath?.mainCartier || 'https://www.cartier.com/',
      emailAddress: emailAddress,
      language: location?.language.id || 'en',
    },
    true,
  );

  // empty input on modal open
  useEffect(() => {
    isOpen && setEmailAddress('');
  }, [isOpen]);

  // send creation details as email to user
  const handleSaveCreation = () => {
    ringSelection &&
      pushTrackingEvent('esfy_converter_complete', {
        item_reference: ringSelection.model,
        item_id: ringSelection.selectedSize
          ? `${ringSelection.model.slice(0, -2)}${ringSelection.selectedSize}`
          : ringSelection.model,
        item_category: 'Rings',
        item_collection: configuration?.collection?.name,
        item_material: configuration?.model?.material?.name,
        item_diamond_carat: ringSelection.stoneCarat,
        item_diamond_clarity: ringSelection.stoneClarity,
        item_diamond_color: ringSelection.stoneColor,
        currency: ringSelection.currency,
        price: ringSelection.price,
        item_engraved: !!ringSelection.engraving ? 'Yes' : 'No',
        item_embossed: !!ringSelection.embossing ? 'Yes' : 'No',
        quantity: 1,
      });

    sendCreationEmail();
  };

  return (
    <Modal
      title={translations?.savemycreation_header || 'Save my creation'}
      onClose={() => onClose()}
      isOpen={isOpen}
      maxwidth={800}
    >
      <ModalContent>
        <Text className="mb-5">
          {translations?.savemycreation_text ||
            'Please enter your email below if you wish to save your creation. We do not collect or store your email address in our database, it will only be used to send you a summary of your creation.'}
        </Text>
        <div className="mb-5">
          {isSuccess ? (
            <Text className="mb-2 d-flex align-items-center justify-content-center">
              <Icon className="me-2" imgUrl={icons.tick} />{' '}
              {translations?.savemycreation_emailSent || 'A summary of your creation has now been sent to your inbox.'}
            </Text>
          ) : (
            <Input
              className="w-100 w-lg-75 w-xl-50"
              type="email"
              placeholder={(translations?.savemycreation_email || 'Email address') + '*'}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          )}
        </div>
        {isError && (
          <Text className="mb-2" color="error">
            {translations?.error || 'Something went wrong. Please try again or contact customer support.'}
          </Text>
        )}
        {isSuccess ? (
          <Button disabled={isLoading} onClick={() => onClose()}>
            {translations?.button_close || 'Close'}
          </Button>
        ) : (
          <Button disabled={isLoading} onClick={() => handleSaveCreation()}>
            {translations?.savemycreation_header || 'Send my creation'}
          </Button>
        )}
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 40px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 60px;
  }
`;

export default SaveCreationModal;
