import React, { useState } from 'react';
import { Portal } from 'react-portal';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
// import { useIsMobile } from 'hooks/useIsMobile';
import { Button, H2, HyperLink, Icon, Text } from 'ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'providers';
import useNavigationItems, { INavigationItem } from '../../../hooks/useNavigationItems';

const BurgerMenu = ({ isOpen, shouldCloseMenu, ...props }: { isOpen: boolean; shouldCloseMenu: () => void }) => {
  const navigate = useNavigate();
  const [activeMenus, setActiveMenus] = useState<INavigationItem[] | undefined>(undefined);
  const navigationItems = useNavigationItems();
  // const isMobile = useIsMobile();

  const handleMenuItemClick = (menuItem: INavigationItem) => {
    if (!menuItem.children && menuItem.path) {
      shouldCloseMenu();
      setActiveMenus(undefined);
      navigate(menuItem.path);
      return;
    }

    const newActiveMenus = activeMenus ? [...activeMenus, menuItem] : [menuItem];
    setActiveMenus(newActiveMenus);
  };

  const handleGoBack = (menuItem: INavigationItem) => {
    const newActiveMenus = activeMenus?.filter((menu) => menu.name !== menuItem.name);
    setActiveMenus(newActiveMenus);
  };

  const isCollection = (menuItem: INavigationItem) => {
    return !!menuItem.children?.find((i) => i.type === 'COLLECTION');
  };

  const handleViewAll = (path: string, menuItem: INavigationItem) => {
    navigate(`${path}/${menuItem?.path}`);
  };

  return (
    <div {...props}>
      <AnimatePresence>
        {isOpen && (
          <Portal>
            <MenuList menuItems={navigationItems.header} handleMenuItemClick={handleMenuItemClick} footer />

            <AnimatePresence>
              {activeMenus?.map((menuItem, index) => (
                <MenuList
                  key={index}
                  isCollection={isCollection(menuItem)}
                  header={menuItem.name}
                  menuItems={menuItem.children}
                  handleMenuItemClick={handleMenuItemClick}
                  handleGoBack={() => handleGoBack(menuItem)}
                  handleViewAll={(path) => {
                    handleViewAll(path, menuItem);
                  }}
                />
              ))}
            </AnimatePresence>
          </Portal>
        )}
      </AnimatePresence>
    </div>
  );
};

interface IMenuList {
  isCollection?: boolean;
  header?: string;
  menuItems?: INavigationItem[];
  footer?: boolean;
  handleMenuItemClick: (menuItem: INavigationItem) => void;
  handleGoBack?: () => void;
  handleViewAll?: (path: string) => void;
}

const MenuList: React.FC<IMenuList> = ({
  isCollection,
  header,
  footer,
  menuItems,
  handleMenuItemClick,
  handleGoBack,
  handleViewAll,
}) => {
  const { location } = useLocation();
  const navigationItems = useNavigationItems();
  const { translations } = useLocation();

  const containerVariants = {
    hidden: {
      transform: 'translateX(-100%)',
      transition: {
        duration: 0.266,
        ease: 'easeOut',
        staggerChildren: -0.1,
      },
    },
    show: {
      transform: 'translateX(0%)',
      transition: {
        duration: 0.266,
        ease: 'easeOut',
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, transform: 'translateX(-50px)', transition: { duration: 0.5, ease: 'easeOut' } },
    show: { opacity: 1, transform: 'translateX(0px)', transition: { duration: 0.5, ease: 'easeOut' } },
  };

  const addressBarOffset = window.outerHeight - window.innerHeight + 30 + 'px';

  return (
    <MenuContainer variants={containerVariants} $hasFooter={footer} initial="hidden" exit="hidden" animate="show">
      {header && (
        <MenuListItem key={'01293'} $isHeader onClick={() => handleGoBack && handleGoBack()} variants={itemVariants}>
          <H2 className="m-0">{header}</H2>
        </MenuListItem>
      )}
      <MenuItemList $hasFooter={footer} style={{ paddingBottom: !footer ? addressBarOffset : '' }}>
        {isCollection ? (
          <div className="row">
            {menuItems?.map((item, index) => (
              <div key={index} className="col-6 text-center" onClick={() => item.path && handleMenuItemClick(item)}>
                <CollectionMenuItem>
                  <img src={item.img} alt="" />
                </CollectionMenuItem>
                <Text $monoMedium size={'s'} className="pt-3 pb-4">
                  {item.name}
                </Text>
              </div>
            ))}
          </div>
        ) : (
          menuItems
            ?.filter((item) => item?.children?.length !== 0)
            .map((item, index) => (
              <MenuListItem key={index} onClick={() => handleMenuItemClick(item)} variants={itemVariants}>
                {item.name}
              </MenuListItem>
            ))
        )}
        {isCollection && (
          <Button
            variant="underline"
            className="align-self-center"
            onClick={() => handleViewAll && handleViewAll('/collections')}
          >
            {translations?.menu_viewall || 'View all'}
          </Button>
        )}
      </MenuItemList>

      {footer && (
        <MenuFooter>
          {navigationItems.burger_footer.map((i, index) => (
            <HyperLink
              key={index}
              style={{ fontFamily: "'BrilliantCutPro', 'Verdana', 'sans-serif'", fontSize: '12px' }}
              color="white"
              className="mb-4 d-flex align-items-center"
              uppercase
              underline
              target={i.externalPath ? '_blank' : '_self'}
              href={i.externalPath || i.path}
              rel="noreferrer"
            >
              {i.icon && <Icon className="me-2" imgUrl={i.icon} light />} {i.name}
            </HyperLink>
          ))}
          <Text className="mb-4" size={'xs'} $mono color={'white'} $uppercase>
            Call an ambassador <br />
            <HyperLink
              color="white"
              uppercase
              underline
              href={'tel:' + location?.customerService.phone.number}
              rel="noreferrer"
            >
              {location?.customerService.phone.displayNumber}
            </HyperLink>
          </Text>
          <Text size={'xs'} $mono color={'white'}>
            <HyperLink
              color="white"
              uppercase
              underline
              target="_blank"
              href={'https://www.cartier.com/'}
              rel="noreferrer"
            >
              Visit cartier.com
            </HyperLink>
          </Text>
        </MenuFooter>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled(motion.div)<{ $hasFooter?: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.sizes.header_mobile};
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  background-color: ${({ theme, $hasFooter }) => ($hasFooter ? theme.colors.red : theme.colors.white)};

  ${({ $hasFooter }) =>
    $hasFooter &&
    css`
      height: calc(100% - (${({ theme }) => theme.sizes.header_mobile}));
    `}
`;

const MenuItemList = styled(motion.div)<{ $hasFooter?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacings.l} ${({ theme }) => theme.spacings.l} ${({ theme }) => theme.spacings.l};
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  text-transform: uppercase;
  overflow: auto;
  height: calc(100vh - (${({ theme }) => theme.sizes.header_mobile}));

  ${({ $hasFooter }) =>
    $hasFooter &&
    css`
      height: auto;
    `}
`;

const MenuListItem = styled(motion.div)<{ $isHeader?: boolean; type?: 'COLLECTION' }>`
  width: 100%;
  margin-left: ${({ theme, $isHeader }) => $isHeader && theme.spacings.l};
  padding: ${({ theme }) => theme.spacings.l} 0;
  font-size: ${({ theme }) => theme.typography.sizes.s};
  position: relative;
  user-select: none;
  cursor: pointer;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);

    ${({ $isHeader }) =>
      $isHeader &&
      css`
        border-width: 0 2px 2px 0;
        width: 15px;
        height: 15px;
        margin-top: -3px;
        transform: rotate(135deg) translate(-50%);
      `}
  }

  ${({ $isHeader, theme }) =>
    $isHeader
      ? `
      padding-left: 40px;
      &:after {
        left: 0;
      }
    `
      : `
      &:after {
        right: 15px;
      }
    `}

  ${({ type }) =>
    type &&
    type === 'COLLECTION' &&
    `
      &:after {
        display: none;
      }
    `}
`;

const CollectionMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.fill};
  padding: 20px;
  aspect-ratio: 1/1;
`;

const MenuFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings.base};
`;

export default BurgerMenu;
