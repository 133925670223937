import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { collections } from 'constants/collections';
import Slideshow from './components/Slideshow';
import { Button, H2, Text } from 'ui';
import { useSFY } from 'providers/SFYProvider';
import { useCreationProductsQuery } from './queries';
import { useLocation } from 'providers';
import { useQueryParameters } from 'hooks/useQueryParameters';
import { useWindowSize } from 'hooks/useWindowSize';
import { collectionIds } from 'constants/ids';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useDrag from 'hooks/useDrag';
import { models } from 'constants/models';
import { pushTrackingEvent } from 'utils/trackingUtil';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface IModelSelection {
  collectionId: string;
  model?: ICollectionModel;
}

const includedModels = [
  {
    collectionId: collectionIds._1895,
    models: ['N4162900', 'N4235100', 'N4743600', 'N4761400', 'N4163900', 'N4759400', 'N4759000'],
  },
  {
    collectionId: collectionIds.ballerine,
    models: ['N4197900', 'N4725100', 'N4188400'],
  },
  {
    collectionId: collectionIds.destinee,
    models: ['N4246000', 'N4751600', 'N4751800', 'N4751700', 'N4751000'],
  },
  {
    collectionId: collectionIds.etincelle,
    models: ['N4744600'],
  },
  {
    collectionId: collectionIds.love,
    models: ['N4724500', 'N4774200', 'N4774600'],
  },
  {
    collectionId: collectionIds.ruban,
    models: ['N4250400'],
  },
  {
    collectionId: collectionIds.vendome,
    models: ['N4204200'],
  },
];

const SetForYouSettingView = () => {
  const queryParameters = useQueryParameters();
  const materialContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { configuration, updateConfiguration, updateDefaultConfiguration, clearRingSelection } = useSFY();
  const { location, translations } = useLocation();
  const windowSize = useWindowSize();
  const { dragStart, dragStop, dragMove } = useDrag();

  const [activeCollection, setActiveCollection] = useState<ICollection | undefined>();
  const [activeModels, setActiveModels] = useState<ICollectionModel[]>();
  const [selectedModel, setSelectedModel] = useState<ICollectionModel>();
  const [modelSelections, setModelSelections] = useState<IModelSelection[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [queryFilter, setQueryFilter] = useState<ICreationProductQueryFilter>({
    collection: configuration?.collection?.id || '',
    paved: configuration?.paved || false,
    classicPaved: configuration?.classicPaved || false,
    material: configuration?.material || '',
    cut: configuration?.cut || '',
  });
  const [materialContainerIsWiderThanScreen, setMaterialContainerIsWiderThanScreen] = useState(false);

  const collection_Name = activeCollection ? translations?.[`collection_${activeCollection?.translationTextId}`] : '';

  const { refetch: fetchStock, isLoading, isError } = useCreationProductsQuery(queryFilter, true, true);

  const handleModelChange = useCallback(
    (model: ICollectionModel) => {
      setSelectedModel(model);

      const index = modelSelections?.findIndex((s) => s.collectionId === activeCollection?.id);

      let newSelections = modelSelections;

      newSelections[index] = {
        collectionId: modelSelections[index].collectionId,
        model: model,
      };

      setModelSelections(newSelections);
    },
    [activeCollection?.id, modelSelections],
  );

  /**
   * Set initial model selections
   * modelSelections array is used to keep track of the selected model for each collection
   * this enables the slideshow to show and keep the correct image displayed during sliding
   */
  useEffect(() => {
    const initialModelSelections = includedModels.map((item) => {
      const model = models.find((m) => m.id === item.models[0]);

      return {
        collectionId: item.collectionId,
        model,
      };
    });

    setModelSelections(initialModelSelections);
  }, []);

  /**
   * Set activeCollection state when slideshow changes
   * Also set the selected model using modelSelections state
   */
  useEffect(() => {
    if (activeCollection && includedModels) {
      const getDefaultModel = () => {
        return modelSelections
          ? modelSelections.find((ms) => ms.collectionId === activeCollection.id)?.model
          : undefined;
      };

      const getModelSelectionByPredefinedValue = (property: string, value: string) => {
        return collectionModelSelection?.find((ms) => ms[property as keyof ICollectionModel] === value);
      };

      // models included in SFY flow for the selected collection
      const includedModelIds = includedModels.find((i) => i.collectionId === activeCollection.id)?.models;

      // find models of the selected collection
      const collectionModelSelection = activeCollection.models
        ?.filter((model) => includedModelIds?.includes(model.id))
        .sort((a, b) => includedModelIds?.indexOf(a.id)! - includedModelIds?.indexOf(b.id)!);

      // set active model options
      setActiveModels(collectionModelSelection);

      /**
       *  Handle predefined selection
       * check if any values are present in url for model or material and set initial selection
       * */
      const predefinedCollection = queryParameters.get('collection');
      const predefinedMaterial = queryParameters.get('material');
      const predefinedModel = queryParameters.get('model');

      let initialModelSelection;
      if (predefinedCollection && predefinedModel) {
        initialModelSelection = getModelSelectionByPredefinedValue('id', predefinedModel);
      }
      if (predefinedCollection && predefinedMaterial && !predefinedModel) {
        initialModelSelection = getModelSelectionByPredefinedValue('materialId', predefinedMaterial);
      }

      // if the predefined model doesn't exist on the current slide, set default model (which is the first model on the current slide)
      if (initialModelSelection) {
        handleModelChange(initialModelSelection);
      } else {
        initialModelSelection = getDefaultModel();
      }

      setSelectedModel(initialModelSelection);
    }

    setErrorMessage(undefined);
  }, [activeCollection, modelSelections, queryParameters, handleModelChange]);

  const getFilter = (activeCollection: ICollection, selectedModel: ICollectionModel) => {
    const selectedCollectionId = selectedModel.collectionId;
    const collectionsWithPavingFilter = [collectionIds._1895, collectionIds.love, collectionIds.ballerine];

    let filter: ICreationProductQueryFilter = {
      collection: activeCollection.apiId,
      cut: selectedModel.stoneCut,
      material: selectedCollectionId === collectionIds.vendome ? 'WG' : selectedModel.materialId,
    };

    if (collectionsWithPavingFilter.includes(selectedCollectionId)) {
      filter = {
        ...filter,
        paved: selectedModel!.paved,
        classicPaved: selectedModel!.classicPaved,
      };
    }

    return filter;
  };

  /**
   * Update query filter when collection- and model selection changes
   */
  useEffect(() => {
    if (activeCollection && selectedModel) {
      setQueryFilter(getFilter(activeCollection, selectedModel));
    }
  }, [activeCollection, selectedModel]);

  /**
   * Enable horizontal scroll when model selection component width exceeds viewport width
   */
  useEffect(() => {
    if (windowSize.width && materialContainerRef.current) {
      setMaterialContainerIsWiderThanScreen(windowSize.width - 60 < materialContainerRef.current.clientWidth);
    }
  }, [windowSize.width, materialContainerRef, activeModels]);

  /**
   * Go to next step
   */
  const handleNextStep = () => {
    if (!activeCollection || !selectedModel) return;

    pushTrackingEvent('esfy_converter_setting', {
      item_reference: selectedModel.id,
      item_id: selectedModel.id,
      item_category: 'Rings',
      item_collection: activeCollection.name,
      item_material: selectedModel.material?.name,
    });

    fetchStock().then(({ data }) => {
      if (!data) return;

      if (!data.products) {
        setErrorMessage(translations?.error || 'No results found, please select a different collection or material.');
        return;
      }

      setErrorMessage(undefined);

      const claritySortingArr = ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2'];

      // initial config
      const initialDiamondConfig: ISFYConfig = {
        collection: activeCollection,
        model: selectedModel,
        material: selectedModel.materialId,
        paved: selectedModel.paved,
        classicPaved: selectedModel.classicPaved,
        cut: selectedModel.stoneCut,
        carat: [data.facets.stoneCarat.from, data.facets.stoneCarat.to],
        color: data.facets.stoneColor,
        clarity: data.facets.stoneClarity.sort((a, b) => claritySortingArr.indexOf(a) - claritySortingArr.indexOf(b)),
        priceMin: data.facets.price.from,
        priceMax: data.facets.price.to,
        currency: data.facets.currency,
      };

      // Update default configuration to be used as default min/max boundaries in SFY input components
      updateDefaultConfiguration &&
        updateDefaultConfiguration({
          ...configuration,
          ...initialDiamondConfig,
        });

      // Update configuration to be used as initial values in SFY input components
      const configuredSteps =
        initialDiamondConfig.model?.id !== configuration?.model?.id ? undefined : configuration?.configuredSteps;
      updateConfiguration &&
        updateConfiguration({
          // reset configuredSteps value if model selection is changed
          ...configuration,
          ...initialDiamondConfig,
          configuredSteps,
        });

      // reset ring selection if model selection is changed
      if (!configuredSteps) {
        clearRingSelection && clearRingSelection();
      }

      // navigate to diamond view
      navigate(`/${location?.language.locationLanguageCode}/set-for-you/diamond`);
    });
  };

  /**
   * Get model image of current model selection to show as thumbnail in model selection component
   */
  const getModelImage = (model: ICollectionModel) => {
    return (
      model.images?.find((img) => img.angle === 'front')?.imageUrl ||
      model.images?.find((img) => img.angle === 'side')?.imageUrl
    );
  };

  /**
   * handle horizontal scroll in model selection component
   */
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div>
      <div className="container text-center" style={{ overflow: 'visible' }}>
        <Slideshow
          modelSelections={modelSelections}
          onSlideChange={(activeCollection) => setActiveCollection(activeCollection)}
          items={collections}
          initialCollection={queryParameters.get('collection') || undefined}
        />
        <H2 className="mt-2 mb-3 mt-md-2 mb-md-4">{collection_Name}</H2>
      </div>
      <MaterialContainer
        className={`mb-3 ${materialContainerIsWiderThanScreen ? '' : 'd-flex justify-content-center'}`}
      >
        {activeModels && activeModels.length > 0 ? (
          <ScrollMenu onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
            <div ref={materialContainerRef} className="d-flex">
              {activeModels?.map((model, index) => (
                <MaterialImage
                  key={index}
                  active={model.id === selectedModel?.id}
                  onClick={() => handleModelChange(model)}
                  imageUrl={getModelImage(model)}
                />
              ))}
            </div>
          </ScrollMenu>
        ) : (
          <MaterialImage active={false} disabled />
        )}
      </MaterialContainer>
      <div className="d-flex align-items-center flex-column">
        <Text className="mb-3">
          {(translations && translations[selectedModel?.aestheticDescription as keyof TranslationLabels]) ||
            selectedModel?.aestheticDescription}
        </Text>
        {isError && (
          <Text color="error" className="mb-2">
            Something went wrong, please try again.
          </Text>
        )}
        {errorMessage && (
          <Text color="error" className="mb-2">
            {errorMessage}
          </Text>
        )}
        <Button disabled={activeModels?.length === 0 || isLoading} className="mb-5" onClick={() => handleNextStep()}>
          {translations?.sfySetting_1_button || 'Select this setting'}
        </Button>
      </div>
    </div>
  );
};

const MaterialContainer = styled.div`
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
`;

const MaterialImage = styled.div<{ imageUrl?: string; active: boolean; disabled?: boolean }>`
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 40px;
  background-image: url(${({ imageUrl }) => imageUrl && imageUrl});
  background-position: 50% 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.fill};
  margin: 0 15px;
  flex-shrink: 0;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 80px;
    height: 80px;
    margin: 0 20px;
  }

  ${({ active }) =>
    active &&
    css`
      border: 3px solid ${({ theme }) => theme.colors.black};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        height: 3px;
        transform: translateY(-50%) rotate(45deg);
        background-color: white;
      }
    `}
`;

export default SetForYouSettingView;
