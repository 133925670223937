import { Swiper, SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';
import { Arrow, H3, Text } from 'ui';
import { CollectionName } from 'components';

// used as replacement img for models missing images with front angle
import replacementImg from 'media/images/models/N4162900.png';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'providers';

interface ISlideshow extends React.HtmlHTMLAttributes<HTMLElement> {
  items: ICollectionModel[];
  productData?: DestinationModel[];
  flip?: boolean;
}

const Slideshow: React.FC<ISlideshow> = ({ items, productData, flip, ...props }) => {
  const navigate = useNavigate();
  const { location } = useLocation();

  const handleImageClick = (model: ICollectionModel) => {
    model.type === 'ER' && navigate(`/${location?.language.locationLanguageCode}/${model.collectionId}`);
  };

  return (
    <Container>
      <Prev className={'prev ' + props.id}>
        <Arrow direction="left" />
      </Prev>
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        navigation={{
          prevEl: '.prev.' + props.id,
          nextEl: '.next.' + props.id,
        }}
        centeredSlides
        speed={1000}
        style={{ overflow: 'visible' }}
      >
        {items.map((item, index: number) => (
          <SwiperSlide key={index} className="text-center">
            <StyledSlide key={index}>
              <div className="row">
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                  {!flip && (
                    <SlideText className="p-0 pb-3 ms-md-5">
                      <H3>
                        <CollectionName
                          name={productData?.find((p) => p.productId === item.id)?.name || item.name || ''}
                        />
                      </H3>
                      <Text $small>{productData?.find((p) => p.productId === item.id)?.shortDescription}</Text>
                    </SlideText>
                  )}
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                  <img
                    src={item.images.find((i) => i.angle === 'front')?.imageUrl || replacementImg}
                    alt={`${item.id} - ${item.name} (front angle)`}
                    onClick={() => handleImageClick(item)}
                    style={{
                      cursor: item.type === 'ER' ? 'pointer' : 'initial',
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                  {flip && (
                    <SlideText className="pb-0 me-md-5">
                      <H3>
                        <CollectionName
                          name={productData?.find((p) => p.productId === item.id)?.name || item.name || ''}
                        />
                      </H3>
                      <Text $small>
                        {productData?.find((p) => p.productId === item.id)?.shortDescription || item.material?.name}
                      </Text>
                    </SlideText>
                  )}
                </div>
              </div>
            </StyledSlide>
          </SwiperSlide>
        ))}
        {/* {props.children.map((child: any, index: number) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))} */}
      </Swiper>
      <Next className={'next ' + props.id}>
        <Arrow direction="right" />
      </Next>
    </Container>
  );
};

const StyledSlide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    /* margin-top: 50px; */
    width: 80%;

    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 100%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-direction: row;
  }
`;

const SlideText = styled.div<{ direction?: 'LEFT' | 'RIGHT' }>`
  user-select: none;
  text-align: center;
  padding: 40px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */

    ${({ direction }) =>
      direction === 'LEFT' &&
      css`
        left: 100px;
      `}

    ${({ direction }) =>
      direction === 'RIGHT' &&
      css`
        right: 100px;
      `}
  }
`;

const Container = styled.div`
  position: relative;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    opacity: 0;
    transition: opacity 1s ease;

    &-active {
      opacity: 1;
    }
  }
`;

const Nav = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: 0;
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: 0;
`;

export default Slideshow;
