import styled from 'styled-components';

const Input = styled.input`
  height: 36px;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.typography.fonts.base};
  outline: none;
`;

export default Input;
