import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { H2, Text } from 'ui';
import { motion, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import { useRefScrollProgress } from 'hooks/useRefScrollProgress';
import { useTextAnimateOnScroll } from '../../../hooks/useTextAnimateOnScroll';

interface ISplitSection {
  title: string;
  text: string;
  imageUrl: string;
  videoUrl?: string;
  flip?: boolean;
  animateVideo?: boolean;
  textAnimationDirection?: 'horizontal' | 'vertical';
  bg?: boolean;
  overflowVideo?: boolean;
  halfWidth?: boolean;
}

const SplitSection: React.FC<ISplitSection> = ({
  title,
  text,
  imageUrl,
  videoUrl,
  flip,
  animateVideo,
  textAnimationDirection,
  bg,
  overflowVideo,
  halfWidth,
}) => {
  const ref = useRef<any>();

  const { start, end } = useRefScrollProgress(ref, { startOffset: 0.5, endOffset: 0.5 });
  const scrollYProgress = useViewportScroll();
  const textAnimation = useTextAnimateOnScroll(ref, flip, textAnimationDirection);

  const videoTransforms = {
    opacity: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [0, 1]),
    y: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 1.1], [100, 0]),
  };
  const opacityVideo = useSpring(videoTransforms.opacity, { stiffness: 1000, damping: 90 });
  const yVideo = useSpring(videoTransforms.y, { stiffness: 1000, damping: 90 });

  const imageTransforms = {
    x: useTransform(scrollYProgress.scrollYProgress, [start!, end! * 1.5], [flip ? 0 : -75, flip ? -50 : 75]),
  };
  const xImage = useSpring(imageTransforms.x, { stiffness: 1000, damping: 90 });

  return (
    <Container ref={ref} $bg={bg} $flip={flip} $halfWidth={halfWidth} className="container">
      <ImageContainer $flip={flip} $halfWidth={halfWidth}>
        <Image style={{ x: xImage }} src={imageUrl} $fullWidth={halfWidth} />
      </ImageContainer>
      <ContentContainer>
        <Content $flip={flip}>
          <motion.div style={textAnimation}>
            <H2>{title}</H2>
            <Text>{text}</Text>
          </motion.div>

          {videoUrl && (
            <VideoContainer style={animateVideo ? { y: yVideo } : {}} $overflowVideo={overflowVideo}>
              <Video style={{ opacity: opacityVideo }} src={videoUrl} muted autoPlay loop playsInline>
                Your browser does not support the video tag
              </Video>
            </VideoContainer>
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div<{ $bg?: boolean; $flip?: boolean; $halfWidth?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-direction: row;

    ${({ $flip }) =>
      $flip &&
      css`
        flex-direction: row-reverse;
      `}
  }

  ${({ $halfWidth }) =>
    $halfWidth &&
    css`
      flex-direction: column-reverse;
    `}

  ${({ $bg }) =>
    $bg &&
    css`
      background-color: ${({ theme }) => theme.colors.fill};
    `}
`;

const ImageContainer = styled.div<{ $flip?: boolean; $halfWidth?: boolean }>`
  background-color: ${({ theme }) => theme.colors.fill_gray};
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 55%;
    text-align: right;

    ${({ $flip }) =>
      $flip &&
      css`
        width: 45%;
        text-align: left;
      `}

    ${({ $halfWidth }) =>
      $halfWidth &&
      css`
        width: 50%;
      `}
  }
`;

const Image = styled(motion.img)<{ $fullWidth?: boolean }>`
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 110%;
      height: auto;
      max-height: 90vh;
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 100%;
    max-width: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Content = styled(motion.div)<{ $flip?: boolean }>`
  max-width: 350px;
  margin: 0 auto;
  padding: 40px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 150px 0;
  }

  ${({ $flip }) =>
    $flip &&
    css`
      margin: 0 auto;

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        margin: 0;
      }
    `}
`;

const VideoContainer = styled(motion.div)<{ $overflowVideo?: boolean }>`
  width: 100%;
  height: 350px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 150px;
  position: relative;

  ${({ $overflowVideo }) =>
    $overflowVideo &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        margin-bottom: -150px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        margin-bottom: -200px;
      }
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 100px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 350px;
    height: 350px;
  }
`;

const Video = styled(motion.video)`
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default SplitSection;
