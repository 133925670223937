import React from 'react';
import { Button, H1, Text } from '../../../ui';
import sfy_hand_mobile from '../../../media/images/diamonds/sfy_hand_mobile.png';
import sfy_hand from '../../../media/images/diamonds/sfy_hand.png';
import { useIsMobile } from '../../../hooks/useIsMobile';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../../providers';
import { pushTrackingEvent } from 'utils/trackingUtil';

const StyledImage = styled(({ isMobile, alt, ...props }) => <img alt={alt} {...props} />)<{
  isMobile?: boolean;
}>`
  height: ${({ isMobile }) => (isMobile ? '300px' : '600px')};
  object-fit: cover;
`;

const SetForYouSection = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { translations, location } = useLocation();

  const handleButtonClick = () => {
    pushTrackingEvent('esfy_intention_converter');

    navigate(`/${location?.language.locationLanguageCode}/set-for-you`);
  };

  return (
    <div className="col-12 mb-5 text-center">
      <StyledImage
        isMobile={isMobile}
        src={isMobile ? sfy_hand_mobile : sfy_hand}
        alt="Gift Wrapping"
        className="w-100"
      />
      <div className="container text-center py-5">
        <H1 className="mb-5">{translations?.diamond_header_setForYou || 'Set For You'}</H1>
        <div className="col-12 col-md-6 mx-auto">
          <Text>{translations?.diamond_text_setForYou || ''}</Text>
        </div>
      </div>
      <Button onClick={() => handleButtonClick()}>
        {translations?.firstpage_beginYourJourney || 'Begin your journey'}
      </Button>
    </div>
  );
};

export default SetForYouSection;
