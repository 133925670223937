export type LocationLanguageCode =
  | 'en-gb'
  | 'de-de'
  | 'de-ch'
  | 'en-us'
  | 'es-es'
  | 'fr-fr'
  | 'fr-be'
  | 'en-be'
  | 'en-fr'
  | 'fr-lu'
  | 'en-lu'
  | 'fr-ch'
  | 'it-it'
  | 'it-ch'
  | 'pt-pt'
  | 'en-pt'
  | 'nl-nl'
  | 'en-nl'
  | 'en-ca'
  | 'fr-ca'
  | 'en-at'
  | 'de-at'
  | 'fr-mc'
  | 'en-mc'
  | 'en-ie'
  | 'ko-kr'
  | 'zh-tw'
  | 'zh-hk'
  | 'en-hk'
  | 'zh-sg'
  | 'en-sg'
  | 'en-au'
  | 'en-sa'
  | 'en-ae'
  | 'en-my'
  | 'th-th'
  | 'th'
  | 'en-th'
  | 'en-de'
  | 'en-es'
  | 'en-it'
  | 'en-ch'
  | 'nl-be';

export type LocationLanguage = {
  id: string;
  name: string;
  country: string;
  locationLanguageCode: LocationLanguageCode;
  priceSuffix: string;
  hidePrice: boolean;
};

export type LocationInfo = {
  id: string;
  languages: LocationLanguage[];
  customerService: {
    phone: Phone;
    ambassadorPhone?: Phone;
  };
  ecom: Boolean;
};

export const locations: LocationInfo[] = [
  {
    id: 'US',
    languages: [
      {
        id: 'en-us',
        locationLanguageCode: 'en-us',
        name: 'English',
        country: 'United States',
        priceSuffix: 'Excluding sales tax',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: ' +44 (0)20 3780 8169',
        number: '+442037808169',
      },
    },
    ecom: true,
  },
  {
    id: 'FR',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-fr',
        name: 'Français',
        country: 'France (EN)',
        priceSuffix: 'TVA incluse',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-fr',
        name: 'English',
        country: 'France (EN)',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: ' +33 1 42 18 43 83',
        number: '+33142184383',
      },
      ambassadorPhone: {
        displayNumber: '+33158181838',
        number: '+33 158181838',
      },
    },
    ecom: true,
  },
  {
    id: 'BE',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-be',
        name: 'Français',
        country: 'Belgique',
        priceSuffix: 'TVA incluse',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-be',
        name: 'English',
        country: 'Belgium',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
      {
        id: 'nl',
        locationLanguageCode: 'nl-be',
        name: 'Dutch',
        country: 'België',
        priceSuffix: 'Inclusief BTW',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+32 2 400 2600',
        number: '+3224002600',
      },
    },
    ecom: true,
  },
  {
    id: 'LU',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-lu',
        name: 'Français',
        country: 'Luxembourg (FR)',
        priceSuffix: 'TVA incluse',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-lu',
        name: 'English',
        country: 'Luxembourg (EN)',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+352 27 86 45 48',
        number: '+3527864548',
      },
    },
    ecom: true,
  },
  {
    id: 'UK',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-gb',
        name: 'English',
        country: 'United Kingdom',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+44 20 3147 4850',
        number: '+442031474850',
      },
      ambassadorPhone: {
        displayNumber: '+44 20 7408 9197',
        number: '+442074089197',
      },
    },
    ecom: true,
  },
  {
    id: 'DE',
    languages: [
      {
        id: 'de',
        locationLanguageCode: 'de-de',
        name: 'Deutsch',
        country: 'Deutschland',
        priceSuffix: 'Inklusive Mehrwertsteuer',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-de',
        name: 'English',
        country: 'Germany',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+49 89 55 98 42 21',
        number: '+498955984221',
      },
    },
    ecom: true,
  },
  {
    id: 'ES',
    languages: [
      {
        id: 'es',
        locationLanguageCode: 'es-es',
        name: 'Español',
        country: 'España',
        priceSuffix: 'IVA incluido',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-es',
        name: 'English',
        country: 'Spain',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+34 900 505 403',
        number: '+34900505403',
      },
    },
    ecom: true,
  },
  {
    id: 'PT',
    languages: [
      {
        id: 'pt',
        locationLanguageCode: 'pt-pt',
        name: 'Português',
        country: 'Portugal (PT)',
        priceSuffix: 'Inclui IVA',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-pt',
        name: 'English',
        country: 'Portugal (EN)',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+351 21 020 1688',
        number: '+351210201688',
      },
    },
    ecom: true,
  },
  {
    id: 'NL',
    languages: [
      {
        id: 'nl',
        locationLanguageCode: 'nl-nl',
        name: 'Dutch',
        country: 'Nederland',
        priceSuffix: 'Inclusief btw',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-nl',
        name: 'English',
        country: 'Netherlands',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+31 (0)20 719 24 55',
        number: '+31207192455',
      },
    },
    ecom: true,
  },
  {
    id: 'CH',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-ch',
        name: 'Français',
        country: 'Suisse',
        priceSuffix: 'TVA incluse',
        hidePrice: false,
      },
      {
        id: 'it',
        locationLanguageCode: 'it-ch',
        name: 'Italiano',
        country: 'Svizzera',
        priceSuffix: 'IVA inclusa',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-ch',
        name: 'English',
        country: 'Switzerland',
        priceSuffix: 'VAT Included',
        hidePrice: false,
      },
      {
        id: 'de',
        locationLanguageCode: 'de-ch',
        name: 'Deutsch',
        country: 'Schweiz',
        priceSuffix: 'Inklusive Mehrwertsteuer',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+41 44 580 90 90',
        number: '+41445809090',
      },
    },
    ecom: true,
  },
  {
    id: 'IT',
    languages: [
      {
        id: 'it',
        locationLanguageCode: 'it-it',
        name: 'Italiano',
        country: 'Italia',
        priceSuffix: 'IVA inclusa',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-it',
        name: 'English',
        country: 'Italy',
        priceSuffix: 'VAT Included',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+39 02 30 26 548',
        number: '+39023026548',
      },
    },
    ecom: true,
  },
  {
    id: 'CA',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-ca',
        name: 'Français',
        country: 'Canada (FR)',
        priceSuffix: 'hors taxes',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-ca',
        name: 'English',
        country: 'Canada (EN)',
        priceSuffix: 'excluding sales tax',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '1-855-888-8798',
        number: '18558888798',
      },
    },
    ecom: true,
  },
  {
    id: 'AT',
    languages: [
      {
        id: 'de',
        locationLanguageCode: 'de-at',
        name: 'Deutsch',
        country: 'Österreich',
        priceSuffix: 'Inklusive Mehrwertsteuer',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-at',
        name: 'English',
        country: 'Austria',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+43 1 417 0450',
        number: '+4314170450',
      },
    },
    ecom: true,
  },
  {
    id: 'MC',
    languages: [
      {
        id: 'fr',
        locationLanguageCode: 'fr-mc',
        name: 'Français',
        country: 'Monaco (FR)',
        priceSuffix: 'TVA incluse',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-mc',
        name: 'English',
        country: 'Monaco (EN)',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+33 142184383',
        number: '+33142184383',
      },
      ambassadorPhone: {
        displayNumber: '+33 158181838',
        number: '+33158181838',
      },
    },
    ecom: true,
  },
  {
    id: 'IE',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-ie',
        name: 'English',
        country: 'Ireland',
        priceSuffix: 'Includes VAT',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+44 20 3147 4850',
        number: '+442031474850',
      },
    },
    ecom: true,
  },
  // Temporary hidden according to task #8079 Hide Korea in footer
  // {
  //   id: 'KR',
  //   languages: [
  //     {
  //       id: 'ko',
  //       locationLanguageCode: 'ko-kr',
  //       name: 'Korean', // todo: translation
  //       country: 'South Korea',
  //       priceSuffix: '부가세 포함',
  //     },
  //   ],
  //   customerService: {
  //     phone: {
  //       displayNumber: '15667277',
  //       number: '15667277',
  //     },
  //   },
  //   ecom: true,
  // },
  {
    id: 'TW',
    languages: [
      {
        id: 'zh-tw',
        locationLanguageCode: 'zh-tw',
        name: 'Chinese', // todo: translate
        country: 'Taiwan, China',
        priceSuffix: '',
        hidePrice: true,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+886 0 800 373 888',
        number: '+8860800373888',
      },
    },
    ecom: false,
  },
  {
    id: 'HK',
    languages: [
      {
        id: 'zh-hk',
        locationLanguageCode: 'zh-hk',
        name: 'Chinese', // todo: translate
        country: '中国香港特别行政区',
        priceSuffix: '',
        hidePrice: true,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-hk',
        name: 'English',
        country: 'Hong Kong SAR, China',
        priceSuffix: '',
        hidePrice: true,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+852 8105 5008',
        number: '+85281055008',
      },
    },
    ecom: true,
  },
  {
    id: 'SG',
    languages: [
      {
        id: 'zh',
        locationLanguageCode: 'zh-sg',
        name: 'Chinese', // todo: translate
        country: '新加坡',
        priceSuffix: '含商品及服务税',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-sg',
        name: 'English',
        country: 'Singapore',
        priceSuffix: 'incl. GST',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+65 6929 8703',
        number: '+6569298703',
      },
      ambassadorPhone: {
        displayNumber: '+65 6929 8703',
        number: '+6569298703',
      },
    },
    ecom: true,
  },
  {
    id: 'AU',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-au',
        name: 'English',
        country: 'Australia',
        priceSuffix: 'incl. GST',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '1800 13 0000',
        number: '1800130000',
      },
    },
    ecom: true,
  },
  {
    id: 'SA',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-sa',
        name: 'English',
        country: 'Saudi Arabia',
        priceSuffix: 'including sales tax',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '9 668 008 912 020',
        number: '9668008912020',
      },
    },
    ecom: true,
  },
  {
    id: 'AE',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-ae',
        name: 'English',
        country: 'United Arab Emirates',
        priceSuffix: 'including sales tax',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '8 000 321 180',
        number: '8000321180',
      },
    },
    ecom: true,
  },
  {
    id: 'MY',
    languages: [
      {
        id: 'en-gb',
        locationLanguageCode: 'en-my',
        name: 'English',
        country: 'Malaysia',
        priceSuffix: '',
        hidePrice: true,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '60 3 2303 5088',
        number: '60323035088',
      },
    },
    ecom: false,
  },
  {
    id: 'TH',
    languages: [
      {
        id: 'th',
        locationLanguageCode: 'th-th',
        name: 'ภาษาไทย',
        country: 'Thailand',
        priceSuffix: 'รวมภาษีการขาย',
        hidePrice: false,
      },
      {
        id: 'en-gb',
        locationLanguageCode: 'en-th',
        name: 'English',
        country: 'Thailand (EN)',
        priceSuffix: 'including sales tax',
        hidePrice: false,
      },
    ],
    customerService: {
      phone: {
        displayNumber: '+662-430-4488',
        number: '+6624304488',
      },
    },
    ecom: false,
  },
];

export const defaultLocation: ILocation = {
  id: 'UK',
  language: {
    id: 'en-gb',
    locationLanguageCode: 'en-gb',
    name: 'English',
    country: 'United Kingdom',
    priceSuffix: 'Includes VAT',
  },
  customerService: {
    phone: {
      displayNumber: '+44 20 3147 4850',
      number: '+442031474850',
    },
    ambassadorPhone: {
      displayNumber: '+44 20 7408 9197',
      number: '+442074089197',
    },
  },
};

export const supportedLanguages = ([] as string[]).concat.apply(
  [],
  locations.map((location) => location.languages.map((language) => language.locationLanguageCode)),
);
