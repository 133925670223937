import collection_1895 from './1895.png';
import collection_1895_shadow from './1895_shadow.png';
import collection_ballerine_shadow from './ballerine_shadow.png';
import collection_ballerine from './ballerine.png';
import collection_cartierdestinee from './cartierdestinee.png';
import collection_cartierdestinee_shadow from './cartierdestinee_shadow.png';
import collection_etincelledecartier from './etincelledecartier.png';
import collection_etincelledecartier_shadow from './etincelledecartier_shadow.png';
import collection_love from './love.png';
import collection_love_shadow from './love_shadow.png';
import collection_trinityruban from './trinityruban.png';
import collection_trinityruban_shadow from './trinityruban_shadow.png';
import collection_vendomelouiscartier from './vendomelouiscartier.png';
import collection_vendomelouiscartier_shadow from './vendomelouiscartier_shadow.png';

const collectionImages = {
  collection_1895,
  collection_1895_shadow,
  collection_ballerine,
  collection_ballerine_shadow,
  collection_cartierdestinee,
  collection_cartierdestinee_shadow,
  collection_etincelledecartier,
  collection_etincelledecartier_shadow,
  collection_love,
  collection_love_shadow,
  collection_trinityruban,
  collection_trinityruban_shadow,
  collection_vendomelouiscartier,
  collection_vendomelouiscartier_shadow,
};

export default collectionImages;
