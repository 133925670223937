import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Arrow, Button, H2, Text } from 'ui';
import { useIsMobile } from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

// import Swiper core and required modules
import { EffectCreative, Navigation, Pagination } from 'swiper';
import { useLocation } from 'providers';

interface ISlideshow extends React.HtmlHTMLAttributes<HTMLElement> {
  items: ICollection[];
}

const Slideshow: React.FC<ISlideshow> = ({ items, ...props }) => {
  const swiper = useRef(null);
  const [activeCollection, setActiveCollection] = useState<ICollection>(items[0]);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { translations, location } = useLocation();

  const collection_Quote = translations?.[`collection_${activeCollection.translationTextId}_Quote`];
  const collection_Name = translations?.[`collection_${activeCollection.translationTextId}`];

  useEffect(() => {
    setActiveCollection(items[0]);
    //@ts-ignore
    swiper.current?.swiper.slideToLoop(0);
  }, [items]);

  return items ? (
    <>
      <Container>
        <Prev className={'prev ' + props.id}>
          <Arrow direction="left" />
        </Prev>
        <Swiper
          //@ts-ignore
          ref={swiper}
          modules={[EffectCreative, Pagination, Navigation]}
          effect={'creative'}
          slidesPerView={3}
          loopPreventsSlide={true}
          preloadImages={true}
          roundLengths={true}
          virtualTranslate={true}
          watchSlidesProgress={true}
          creativeEffect={{
            limitProgress: 2,
            prev: {
              opacity: 0.3,
              scale: 0.5,
              translate: [isMobile ? '-180%' : '-200%', -100, 0],
            },
            next: {
              opacity: 0.3,
              scale: 0.5,
              translate: [isMobile ? '180%' : '200%', -100, 0],
            },
          }}
          navigation={{
            prevEl: '.prev.' + props.id,
            nextEl: '.next.' + props.id,
          }}
          pagination={{
            clickable: true,
            type: 'bullets',
          }}
          centeredSlides={true}
          speed={1000}
          loop={true}
          onRealIndexChange={(swiper) => setActiveCollection(items[swiper.realIndex])}
          style={{ overflow: 'visible' }}
        >
          {items.map((item, index: number) => (
            <SwiperSlide key={index} className="text-center">
              <img
                src={item.mainImageUrlShadow}
                alt={item.name}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/${location?.language.locationLanguageCode}/${item?.id || ''}`)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Next className={'next ' + props.id}>
          <Arrow direction="right" />
        </Next>
      </Container>
      {activeCollection && (
        <div className="container text-center text-md-start pt-2 pb-3 pb-md-5 pt-md-0">
          <div className="row">
            <div className="col-12 col-md-4 py-md-2">
              <H2>{collection_Name}</H2>
              <Button
                variant="underline"
                onClick={() => navigate(`/${location?.language.locationLanguageCode}/${activeCollection.id}`)}
              >
                {translations?.collection_exploreCollection || 'Explore collection'}
              </Button>
            </div>
            <div className="col-12 col-md-8">
              <Text className="py-4 py-md-0 mb-md-4">{collection_Quote}</Text>
              <Button
                onClick={() =>
                  navigate(`/${location?.language.locationLanguageCode}/set-for-you?collection=${activeCollection.id}`)
                }
              >
                {translations?.collection_selectAndBeginCreating || 'Select and begin creating'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};

const Container = styled.div`
  position: relative;

  .swiper-pagination-bullets {
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      bottom: 30px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
      bottom: 60px;
    }
  }

  .swiper-pagination-bullet {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: none;
    margin: 0 8px !important;

    &-active {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .swiper {
    margin-top: 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      margin-top: 0;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      margin-top: 0;
      margin-bottom: -30px;
    }

    &-slide {
      display: flex;
      justify-content: center;
      overflow: visible;

      img {
        height: 100%;
        max-width: none;
        max-height: 320px;

        @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
          max-height: 400px;
          margin-top: -30px;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
          max-height: 500px;
          margin-top: -50px;
          margin-bottom: -30px;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
          max-height: 650px;
        }
      }
    }
  }
`;

const Nav = styled.div`
  position: absolute;
  bottom: -25px;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
    top: 50%;
    bottom: initial;
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: 0;
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: 0;
`;

export default Slideshow;
