import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Arrow } from 'ui';

import { EffectCreative } from 'swiper';
// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';
import 'swiper/css/effect-creative';

// import Swiper core and required modules
import { Pagination, Navigation } from 'swiper';
import { AnimatePresence, motion } from 'framer-motion';
import { useWindowSize } from 'hooks/useWindowSize';

interface IModelSelection {
  collectionId: string;
  model?: ICollectionModel;
}

interface ISlideshow extends React.HtmlHTMLAttributes<HTMLElement> {
  items: ICollection[];
  modelSelections?: IModelSelection[];
  initialCollection?: string;
  onSlideChange: (activeCollection: ICollection) => void;
}

const Slideshow: React.FC<ISlideshow> = ({ items, modelSelections, onSlideChange, initialCollection, ...props }) => {
  const [activeCollection, setActiveCollection] = useState<ICollection>(items[0]);
  const [initialSlideIndex, setInitialSlideIndex] = useState<number | undefined>(undefined);
  const windowSize = useWindowSize();

  const handleSlideChange = (activeSlide: number) => {
    setActiveCollection(items[activeSlide]);
  };

  useEffect(() => {
    let initialIndex = 0;

    if (initialCollection) {
      initialIndex = items.findIndex((item) => item.id === initialCollection);
    }

    setInitialSlideIndex(initialIndex);
  }, [initialCollection, items, windowSize]);

  useEffect(() => {
    onSlideChange(activeCollection);
  }, [activeCollection, onSlideChange]);

  const getMaterialImage = (collection: ICollection) => {
    const model = modelSelections?.find((m) => m.collectionId === collection.id)?.model;

    return (
      model?.images.find((img) => img.angle === 'side')?.imageUrl ||
      model?.images.find((img) => img.angle === 'front')?.imageUrl ||
      model?.mainImageUrl
    );
  };

  return items && initialSlideIndex !== undefined ? (
    <>
      <Container>
        <Prev className={'prev ' + props.id}>
          <Arrow direction="left" />
        </Prev>
        <Swiper
          modules={[EffectCreative, Pagination, Navigation]}
          effect={'creative'}
          slidesPerView={2}
          initialSlide={initialSlideIndex}
          creativeEffect={{
            limitProgress: 2,
            prev: {
              opacity: 0.3,
              scale: 0.5,
              translate: ['-105%', -100, 0],
            },
            next: {
              opacity: 0.3,
              scale: 0.5,
              translate: ['105%', -100, 0],
            },
          }}
          navigation={{
            prevEl: '.prev.' + props.id,
            nextEl: '.next.' + props.id,
          }}
          pagination={{
            clickable: true,
          }}
          roundLengths={true}
          // loopPreventsSlide={false}
          loop={true}
          centeredSlides={true}
          speed={1000}
          onRealIndexChange={(swiper) => handleSlideChange(swiper.realIndex)}
          style={{ overflow: 'visible' }}
        >
          {items.map((item, index: number) => (
            <SwiperSlide key={index} className="text-center">
              <AnimatePresence>
                <motion.img
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  src={getMaterialImage(item)}
                  alt={item.id}
                />
              </AnimatePresence>
            </SwiperSlide>
          ))}
        </Swiper>
        <Next className={'next ' + props.id}>
          <Arrow direction="right" />
        </Next>
      </Container>
    </>
  ) : null;
};

const Container = styled.div`
  position: relative;

  .swiper {
    :after {
      content: '';
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%) translate3d(0, 0, 0);
      backface-visibility: hidden;
      width: 150px;
      height: 5px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.8);
      filter: blur(15px);

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        width: 300px;
        bottom: 70px;
      }
    }

    &-slide {
      img {
        margin: 20px 0 80px;
        height: 100%;
        max-height: 180px;
        transition: all 0.3s ease;

        @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
          margin: 0 0 110px;
          max-height: 250px;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
          margin: 0 0 120px;
          max-height: 320px;
        }

        @media (min-width: 1920px) {
          margin: 30px 0 120px;
          max-height: 400px;
        }
      }
    }

    &-button-disabled {
      display: none;
    }

    &-pagination-bullet {
      border: 1px solid ${({ theme }) => theme.colors.primary};
      background: none;
      margin: 0 8px !important;

      &-active {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const Nav = styled.div`
  position: absolute;
  bottom: -25px;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
    top: 50%;
    bottom: initial;
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: 0;
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: 0;
`;

export default Slideshow;
