import { H2, Text, ViewSection } from 'ui';
import { useSFY } from 'providers/SFYProvider';
import { useLocation } from 'providers';
import RangeSlider from './RangeSlider';
import { getCurrencySymbol } from 'utils/priceFormat';

function numberWithCommas(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? numberWithCommas(Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(1)) + 'k'
    : numberWithCommas(Math.sign(num) * Math.abs(num));
};

const Price = ({ onTouch }: { onTouch?: () => void }) => {
  const { configuration, defaultConfiguration, updateConfiguration } = useSFY();
  const { translations } = useLocation();

  const handleChange = (range: number | readonly number[]) => {
    const minValue = (range as number[])[0];
    const maxValue = (range as number[])[1];

    updateConfiguration &&
      updateConfiguration({
        ...configuration,
        priceMin: minValue,
        priceMax: maxValue,
      });

    onTouch && onTouch();
  };

  const getDefaultValue = () => {
    return [
      configuration?.priceMin || defaultConfiguration?.priceMin || 0,
      configuration?.priceMax || defaultConfiguration?.priceMax || 500000,
    ];
  };

  return (
    <ViewSection background="gray">
      <div className="container py-5 px-4">
        <div className="col-12 col-md-6 mx-auto text-center mb-5">
          <H2 className="mb-4">{translations?.sfySetting_price || 'PRICE'}</H2>
          <Text className="mb-4">
            {translations?.sfySetting_price_text || 'Your price will change depending on your selection above.'}
          </Text>
          {/* <Button variant="underline">{translations?.sfySetting_LearnMore || 'Learn more'}</Button> */}
        </div>
        <div className="row pb-5 py-md-5">
          <div className="col-12 col-md-8 mx-auto">
            <RangeSlider
              defaultValue={getDefaultValue()}
              min={defaultConfiguration?.priceMin || 0}
              max={defaultConfiguration?.priceMax || 150000}
              pearling
              onAfterChange={(value) => handleChange(value)}
              disabled={defaultConfiguration?.priceMin === defaultConfiguration?.priceMax}
              valueFormatter={(value) => kFormatter(value)}
              prefix={defaultConfiguration?.currency && getCurrencySymbol(defaultConfiguration.currency)}
            />
          </div>
        </div>
      </div>
    </ViewSection>
  );
};

export default Price;
