import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import video from 'media/cta/artofgiving/artofgivingdiamonds.mp4';

import { useOnScreen } from 'hooks/useOnScreen';
import { Text } from 'ui';
import { useLocation } from 'providers';

const ArtOfGiving = () => {
  const videoRef = useRef<any>();
  const containerRef = useRef<any>();
  const onScreen = useOnScreen(containerRef, 0);
  const { translations } = useLocation();

  useEffect(() => {
    onScreen && videoRef.current.play();
  }, [onScreen]);

  return (
    <Container ref={containerRef}>
      <StyledVideo ref={videoRef} src={video + '#t=27'} muted loop playsInline>
        Your browser does not support the video tag
      </StyledVideo>
      <TextContainer>
        <Text level="h1" $uppercase $mono className="container">
          {translations?.firstpage_theArtOfGivingDiamond || 'The art of giving diamonds'}
        </Text>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding-top: 80vh;
`;

const StyledVideo = styled(motion.video)`
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const TextContainer = styled.div`
  position: relative;
  height: 50vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(248, 247, 248, 1) 15%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default ArtOfGiving;
