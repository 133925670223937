import React, { FC, useRef } from 'react';
import { H2, Text } from '../../../ui';
import { motion } from 'framer-motion';
import { useTextAnimateOnScroll } from '../../../hooks/useTextAnimateOnScroll';
import { useIsMobile } from '../../../hooks/useIsMobile';

interface IFourGridItem {
  title: string;
  description: string;
  flip?: boolean;
}

const FourCGridItem: FC<IFourGridItem> = ({ title, description, flip }) => {
  const containerRef = useRef<any>();
  const isMobile = useIsMobile();
  const textAnimation = useTextAnimateOnScroll(containerRef, flip, 'horizontal', {
    startOffset: isMobile ? 10 : 4,
    endOffset: isMobile ? -2 : 0.3,
  });

  return (
    <div
      ref={containerRef}
      className="col-md-6 col-xs-12 order-md-1 my-5 d-flex flex-column justify-content-center align-items-center"
    >
      <div className="col-7 text-center text-md-start">
        <motion.div style={textAnimation}>
          <H2 className="mb-3">{title}</H2>
          <Text>{description}</Text>
        </motion.div>
      </div>
    </div>
  );
};

export default FourCGridItem;
