import fondationImg from 'media/images/logos/logo_fondation.jpg';
import womensInitiative from 'media/images/logos/logo_wi.jpg';
import philanthropy from 'media/images/logos/logo_philanthropy.jpg';
import facebookIcon from 'media/icons/facebook.svg';
import instagramIcon from 'media/icons/instagram.svg';
import twitterIcon from 'media/icons/twitter.svg';
import youtubeIcon from 'media/icons/youtube.svg';

export const footerImages = [
  {
    name: "Cartier Women's initiative",
    img: womensInitiative,
    path: 'https://www.cartierwomensinitiative.com',
    height: 65,
  },
  {
    name: 'Fondation Cartier',
    img: fondationImg,
    path: 'https://www.fondationcartier.com',
    height: 40,
  },
  {
    name: 'Cartier Philanthropy',
    img: philanthropy,
    path: 'https://www.cartierphilanthropy.org',
    height: 60,
  },
];

export const social = [
  {
    name: 'instagram',
    path: 'https://www.instagram.com/cartier/',
    icon: instagramIcon,
  },
  {
    name: 'facebook',
    path: 'https://www.facebook.com/Cartier',
    icon: facebookIcon,
  },
  {
    name: 'twitter',
    path: 'https://twitter.com/cartier',
    icon: twitterIcon,
  },
  {
    name: 'youtube',
    path: 'https://www.youtube.com/cartier',
    icon: youtubeIcon,
  },
];
