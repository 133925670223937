import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface ITabs extends React.HTMLAttributes<HTMLDivElement> {
  items: {
    name: string;
    content: React.ReactNode;
  }[];
  color: 'white' | 'black' | 'red';
  containWidth?: boolean;
  handleTabSelect?: (tab: number) => void;
  defaultActiveIdx?: number;
  bold?: boolean;
}

const Tabs: React.FC<ITabs> = ({
  items,
  color,
  bold,
  containWidth,
  handleTabSelect,
  defaultActiveIdx = 0,
  ...rest
}) => {
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  const [selectedTab, setSelectedTab] = useState(defaultActiveIdx);
  const [left, setLeft] = useState<number | undefined>(undefined);
  const [barLeft, setBarLeft] = useState<number | undefined>(undefined);
  const [tabWidth, setTabWidth] = useState<number | undefined>(undefined);
  const [barWidth, setBarWidth] = useState(0);

  const handleTabClick = (e: any, index: number) => {
    setSelectedTab(index);
    handleTabSelect && handleTabSelect(index);
  };

  const handleBarPosition = useCallback((refs: typeof itemsRef) => {
    const offsetLeft = itemsRef.current[0]?.offsetLeft;
    const totalWidth = refs.current.reduce((partialSum, a) => partialSum + ((a && a.clientWidth) || 0), 0);

    setBarLeft(offsetLeft || 0);
    setBarWidth(totalWidth);
  }, []);

  const handleTabPosition = useCallback(
    (refs: typeof itemsRef) => {
      const tab = refs.current[selectedTab];
      const offsetLeft = tab?.offsetLeft;
      const tabWidth = tab?.clientWidth;

      setLeft(offsetLeft || 0);
      setTabWidth(tabWidth || 0);
    },
    [selectedTab],
  );

  const handleResize = () => {
    handleBarPosition(itemsRef);
    handleTabPosition(itemsRef);
  };

  useEffect(() => {
    if (!itemsRef.current || !itemsRef.current.length) return;

    handleBarPosition(itemsRef);
    handleTabPosition(itemsRef);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <Container {...rest}>
      <TabContainer>
        {items.map((item, index) => (
          <Tab
            $color={color}
            $bold={bold}
            key={index}
            onClick={(e) => handleTabClick(e, index)}
            ref={(el) => (itemsRef.current[index] = el)}
          >
            {item.name}
          </Tab>
        ))}
        {left !== undefined && <Slider style={{ left: left, width: tabWidth }} $color={color} />}
        <Bar style={{ width: barWidth, left: barLeft }} $color={color} />
      </TabContainer>
      {items[selectedTab] && <div style={containWidth ? { width: barWidth } : {}}>{items[selectedTab].content}</div>}
    </Container>
  );
};

const variantOptions = {
  color: {
    white: {
      tabs: appTheme.colors.white,
      bar: appTheme.colors.white,
      slider: appTheme.colors.white,
    },
    black: {
      tabs: appTheme.colors.black,
      bar: appTheme.colors.border,
      slider: appTheme.colors.black,
    },
    red: {
      tabs: appTheme.colors.black,
      bar: appTheme.colors.border,
      slider: appTheme.colors.secondary,
    },
  },
};

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: auto;
    align-items: center;
  }
`;

const Tab = styled.div<{ $color?: 'white' | 'black' | 'red'; $bold?: boolean }>`
  flex: 1;
  padding: 10px 10px;
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  font-size: ${({ theme }) => theme.typography.sizes.xs};
  text-align: center;
  cursor: pointer;
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 10px 20px;
    flex: none;
  }

  ${({ $color }) =>
    $color &&
    variantOptions.color[$color] &&
    css`
      color: ${variantOptions.color[$color].tabs};
    `}

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: bold;
    `}
`;

const Bar = styled.div<{ $color: 'white' | 'black' | 'red' }>`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  margin-bottom: 1px;
  width: 100%;
  opacity: 0.4;

  ${({ $color }) =>
    $color &&
    variantOptions.color[$color] &&
    css`
      background-color: ${variantOptions.color[$color].bar};
    `}
`;

const Slider = styled.div<{ $color: 'white' | 'black' | 'red' }>`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  transition: all 0.8s ease;

  ${({ $color }) =>
    $color &&
    variantOptions.color[$color] &&
    css`
      background-color: ${variantOptions.color[$color].slider};
    `}
`;

export default Tabs;
