import cut_emerald from './4c_cut_emerald.png';
import cut_marquis from './4c_cut_marquis.png';
import cut_oval from './4c_cut_oval.png';
import cut_pear from './4c_cut_pear.png';
import cut_round from './4c_cut_round.png';
import cut_square from './4c_cut_square.png';

const cutImages = {
    cut_emerald,
    cut_marquis,
    cut_oval,
    cut_pear,
    cut_round,
    cut_square
};

export default cutImages;