import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface IHyperLink {
  underline?: boolean;
  uppercase?: boolean;
  color?: 'white' | 'black' | 'light';
}

const variantOptions = {
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
    light: appTheme.colors.text_light,
  },
};

const HyperLink = styled.a<IHyperLink>`
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    text-decoration: none;
  }

  ${({ underline }) =>
    underline &&
    css`
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 98%;
      background-repeat: no-repeat;
      background-size: 0 1px;
      display: inline;
      line-height: 1.6;
      padding-bottom: 2px;
      padding-top: 2px;
      text-decoration: none;
      transition: background-size 0.2s ease-in;

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        &:hover {
          background-size: 100% 1px;
        }
      }
    `}

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};

      &:hover {
        color: ${variantOptions.color[color]};
      }
    `}

${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export default HyperLink;
