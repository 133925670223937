import image1Ballerine from 'media/images/products/ballerine_1.jpg';

import collectionImages from 'media/images/collections';
import videos from 'media/videos';

import { materials } from 'constants/materials';

import { models } from 'constants/models';
import { collectionApiIds, collectionIds, TranslationsCollectionNames } from './ids';

export const collections: ICollection[] = [
  {
    id: collectionIds._1895,
    apiId: collectionApiIds._1895,
    translationTextId: TranslationsCollectionNames._1895,
    name: 'Solitaire 1895',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_1895,
    mainImageUrlShadow: collectionImages.collection_1895_shadow,
    videoUrl: videos.video_1895,
    type: 'ER',
    materialIds: ['PT', 'YG', 'PG'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds._1895).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.destinee,
    apiId: collectionApiIds.destinee,
    translationTextId: TranslationsCollectionNames.destinee,
    name: 'Cartier Destinée',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_cartierdestinee,
    mainImageUrlShadow: collectionImages.collection_cartierdestinee_shadow,
    videoUrl: videos.video_cartierdestinee,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['PT'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.destinee).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.ballerine,
    apiId: collectionApiIds.ballerine,
    translationTextId: TranslationsCollectionNames.ballerine,
    name: 'Ballerine',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_ballerine,
    mainImageUrlShadow: collectionImages.collection_ballerine_shadow,
    videoUrl: videos.video_ballerine,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['PT', 'PG'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.ballerine).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.etincelle,
    apiId: collectionApiIds.etincelle,
    translationTextId: TranslationsCollectionNames.etincelle,
    name: 'Etincelle de Cartier',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_etincelledecartier,
    mainImageUrlShadow: collectionImages.collection_etincelledecartier_shadow,
    videoUrl: videos.video_etincelledecartier,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['PT'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.etincelle).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.love,
    apiId: collectionApiIds.love,
    translationTextId: TranslationsCollectionNames.love,
    name: 'Love',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_love,
    mainImageUrlShadow: collectionImages.collection_love_shadow,
    videoUrl: videos.video_love,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['PT', 'PG'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.love).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.ruban,
    apiId: collectionApiIds.ruban,
    translationTextId: TranslationsCollectionNames.ruban,
    name: 'Trinity Ruban',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_trinityruban,
    mainImageUrlShadow: collectionImages.collection_trinityruban_shadow,
    videoUrl: videos.video_trinityruban,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['PT'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.ruban).sort((a, b) => a.score - b.score),
  },
  {
    id: collectionIds.vendome,
    apiId: collectionApiIds.vendome,
    translationTextId: TranslationsCollectionNames.vendome,
    name: 'Vendôme Louis Cartier',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultrices, diam in placerat lobortis, ex nisl pellentesque nunc, at sodales dolor felis volutpat est.',
    mainImageUrl: collectionImages.collection_vendomelouiscartier,
    mainImageUrlShadow: collectionImages.collection_vendomelouiscartier_shadow,
    videoUrl: videos.video_vendomelouiscartier,
    images: [
      {
        url: image1Ballerine,
        angle: 'CLOSEUP',
      },
    ],
    type: 'ER',
    materialIds: ['TG'],
    get materials() {
      return materials.filter((m) => this.materialIds.includes(m.id));
    },
    models: models.filter((m) => m.collectionId === collectionIds.vendome).sort((a, b) => a.score - b.score),
  },
];
