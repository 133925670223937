import getSymbolFromCurrency from 'currency-symbol-map';

const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? numberWithCommas(Math.sign(num) * ((Math.abs(num) / 1000) as any).toFixed(0)) + 'k'
    : numberWithCommas(Math.sign(num) * Math.abs(num));
};

export const getCurrencySymbol = (currency: keyof typeof CurrencyCode) => {
  const locale = localStorage.getItem('locale') || '';

  // return currency ISO codes for some markets (on english language layers).
  if (['SAR', 'AED'].includes(currency) && ['en-ae', 'en-sa'].includes(locale)) {
    return currency + ' ';
  }

  return getSymbolFromCurrency(currency);
};

export const getFormattedPrice = (priceValue: number, currency: keyof typeof CurrencyCode, formatK?: boolean) =>
  `${getCurrencySymbol(currency)}${formatK ? kFormatter(priceValue) : numberWithCommas(priceValue)}`;
