import React from 'react';
import { Portal } from 'react-portal';
import styled, { css } from 'styled-components';
import { Icon, H2 } from 'ui';
import closeIcon from 'media/icons/close.svg';
import { AnimatePresence, motion } from 'framer-motion';

const Modal = ({
  isOpen,
  title,
  children,
  onClose,
  maxwidth,
}: {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: React.ReactNode;
  maxwidth?: number;
}) => {
  return (
    <Portal>
      <AnimatePresence initial={false}>
        {isOpen ? (
          <>
            <Container
              className="container"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.2,
              }}
              maxwidth={maxwidth}
            >
              {title && (
                <ModalHeader className="py-4 text-center">
                  <H2 className="mb-0">{title}</H2>
                </ModalHeader>
              )}
              {onClose && <CloseIcon imgUrl={closeIcon} onClick={() => onClose()} />}
              {children}
            </Container>
            <Shade onClick={() => onClose && onClose()} />
          </>
        ) : null}
      </AnimatePresence>
    </Portal>
  );
};

const ModalHeader = styled.div`
  border-top: 4px solid ${({ theme }) => theme.colors.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;

  h2 {
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 18px;
    }
  }
`;

const Container = styled(motion.div)<{ maxwidth?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  overflow-y: scroll;
  background-color: #fff;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${({ maxwidth }) =>
    maxwidth &&
    css`
      max-width: ${maxwidth}px;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: 50%;
    left: 50%;
    right: initial;
    bottom: initial;
    transform: translate(-50%, -50%);
    max-height: 100vh;
  }
`;

const Shade = styled.div`
  background-color: ${({ theme }) => theme.colors.shade};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export default Modal;
