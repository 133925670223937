import { forwardRef, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, H1, Icon, Text } from 'ui';
import { motion, useViewportScroll, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import useSize from '@react-hook/size';
import image1 from 'media/cta/cartierdiamonds/image_1.jpg';
import video1 from 'media/cta/cartierdiamonds/intro_Diamonds_savoirfaire_video_EN.mp4';
import video2 from 'media/cta/cartierdiamonds/CARTIER_BE_redBox_comp_169_v008.mp4';
import video3 from 'media/cta/cartierdiamonds/230511_CARTIER_ABOVE_THE_TOP_SS_Pack_15s_600x300.mp4';
import icons from 'media/icons';

import { useRefScrollProgress } from 'hooks/useRefScrollProgress';
import { useScroll } from 'hooks/useScroll';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../../providers';
import { pushTrackingEvent } from 'utils/trackingUtil';

interface ICartierDiamond extends React.RefAttributes<HTMLDivElement> {
  title: string;
  text: string;
  buttonText: string;
  init?: () => void;
  scrolledPastContent?: () => void;
  contentInView?: () => void;
  isAtTop?: (isAtTop: boolean) => void;
}

const CartierDiamond: React.FC<ICartierDiamond> = forwardRef(
  ({ title, text, buttonText, init, scrolledPastContent, contentInView, isAtTop }, ref) => {
    const containerRef = useRef<any>();
    const scrollContainerRef = useRef<any>();
    const contentRef = useRef<any>();
    const videoRef = useRef<any>();
    const scrollHeight = window.innerWidth > 568 ? 2000 : 1500;
    const navigate = useNavigate();
    const { translations, location } = useLocation();

    const [showSkipLink, setShowSkipLink] = useState(true);
    const [containerRect, setContainerRect] = useState<DOMRect>();
    const [startSecondAnimation, setStartSecondAnimation] = useState(false);

    const videoSize = useSize(videoRef);
    const { scrollY } = useScroll();
    const { start, end } = useRefScrollProgress(scrollContainerRef, { endOffset: 0.5 });

    const scrollYProgress = useViewportScroll();

    const opacityRange = useTransform(scrollYProgress.scrollYProgress, [start!, end!], [0, 1]);
    const opacity = useSpring(opacityRange, { stiffness: 400, damping: 90 });

    const yRange = useTransform(scrollYProgress.scrollYProgress, [start!, end!], [-150, 0]);
    const y = useSpring(yRange, { stiffness: 400, damping: 90 });

    // fullscreen video animations
    const width = useTransform(
      scrollYProgress.scrollYProgress,
      [start!, end!],
      [window.innerWidth, window.innerWidth > 568 ? 500 : 400],
    );

    const top = useTransform(
      scrollYProgress.scrollYProgress,
      [start!, end!],
      [window.innerHeight / 2 - videoSize[1] / 2, window.innerWidth > 568 ? scrollHeight - 500 : scrollHeight - 400],
    );

    const right = useTransform(
      scrollYProgress.scrollYProgress,
      [start!, end!],
      [0, window.innerWidth > 568 ? (containerRect?.x || 0) - 100 : -230],
    );

    const videoLinkPos = {
      scale: useTransform(width, [1000, 0], [1, 0.4]),
      marginRight: useTransform(width, (value) => value * 0.035),
      top: useTransform([top, width], ([top, width]) => {
        let margin = 40;
        if ((width as number) > 1800) {
          margin = 100;
        }
        const height = (width as any) * 0.563;
        return height * 0.95 + (top as any) - margin;
      }),
    };

    useEffect(() => {
      init && init();
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      const rect = contentRef.current.getBoundingClientRect();
      setContainerRect(rect);

      isAtTop && isAtTop(scrollY === 0);

      if (scrollY > 500) {
        setShowSkipLink(false);
      } else {
        setShowSkipLink(true);
      }

      if (scrollY > contentRef.current.offsetTop - rect.height * 1.8) {
        setStartSecondAnimation(true);
      }

      if (scrollY > contentRef.current.offsetTop - rect.height) {
        contentInView && contentInView();
      }

      if (scrollY > contentRef.current.offsetTop + 500) {
        scrolledPastContent && scrolledPastContent();
      }

      //eslint-disable-next-line
    }, [contentRef, startSecondAnimation, scrollY]);

    const handleSkipVideo = () => {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const handleButtonClick = () => {
      pushTrackingEvent('esfy_intention_converter');

      navigate(`/${location?.language.locationLanguageCode}/set-for-you`);
    };

    return (
      <Container ref={containerRef}>
        <ScrollContainer style={{ height: scrollHeight }} ref={scrollContainerRef}>
          <StyledVideo
            ref={videoRef}
            style={{ position: 'absolute', bottom: 0, right, top, width }}
            src={video1}
            muted
            autoPlay
            loop
            playsInline
          >
            Your browser does not support the video tag
          </StyledVideo>
          <AnimatePresence>
            {showSkipLink && (
              <motion.div
                style={{
                  position: 'absolute',
                  marginRight: videoLinkPos.marginRight,
                  top: videoLinkPos.top,
                  right,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Button variant="underline" onClick={() => handleSkipVideo()}>
                  {translations?.firstpage_skipIntroVideo || 'Skip'}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {showSkipLink && (
              <SkipIcon initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Icon imgUrl={icons.downArrow} size="m" onClick={() => handleSkipVideo()} />
              </SkipIcon>
            )}
          </AnimatePresence>
          <ContentContainer className="container" ref={contentRef}>
            <TextContainer
              style={{
                opacity,
                y,
              }}
            >
              <StyledH1 introTitle>{title}</StyledH1>
              <Text className="mb-4" style={{ maxWidth: 500 }}>
                {text}
              </Text>
              <Button onClick={() => handleButtonClick()}>{buttonText}</Button>
            </TextContainer>
            <StyledImage
              animate={{
                x: startSecondAnimation ? '-40%' : 0,
                transition: {
                  duration: 10,
                },
              }}
              style={{
                backgroundImage: 'url(' + image1 + ')',
                y: '-90%',
              }}
            />
            <StyledVideo
              animate={{
                x: startSecondAnimation ? '-15%' : 0,
                transition: {
                  duration: 10,
                },
              }}
              style={{ y: '90%' }}
              src={video2}
              muted
              autoPlay
              loop
              pos="1"
              playsInline
            >
              Your browser does not support the video tag
            </StyledVideo>
            <StyledVideo
              animate={{
                x: startSecondAnimation ? '-35%' : 0,
                transition: {
                  duration: 10,
                },
              }}
              src={video3}
              muted
              autoPlay
              loop
              pos="2"
              playsInline
            ></StyledVideo>
          </ContentContainer>
        </ScrollContainer>
      </Container>
    );
  },
);

const Container = styled.div`
  position: relative;
  margin-bottom: 250px;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 500px;
  }
`;

const ScrollContainer = styled(motion.div)`
  position: relative;
`;

const ContentContainer = styled(motion.div)`
  position: absolute;
  z-index: 50;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TextContainer = styled(motion.div)`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 100;
`;

const StyledH1 = styled(H1)`
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 40px;
  }
`;

const SkipIcon = styled(motion.div)`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledImage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  background-size: cover;
  background-position: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: 250px;
    height: 250px;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 400px;
    height: 400px;
  }
`;

interface StyledVideoProps {
  pos?: '1' | '2';
}

const StyledVideo = styled(motion.video)<StyledVideoProps>`
  position: absolute;

  ${({ pos }) =>
    pos === '1' &&
    css`
      width: 300px;
      bottom: 50px;
      left: -200px;

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        width: 350px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        width: 450px;
      }
    `}

  ${({ pos }) =>
    pos === '2' &&
    css`
      width: 300px;
      top: calc(100% + 25px);
      right: -150px;
      /* transform: translateX(60%); */
      /* transform: translate(60%, 110%); */

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        width: 450px;
        top: calc(100% - 38px);
        /* transform: translate(20%, 100%); */
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        width: 600px;
        /* transform: translate(0%, 100%); */
      }
    `}
`;

export default CartierDiamond;
