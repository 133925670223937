import video_1895 from './1895.mp4';
import video_ballerine from './ballerine.mp4';
import video_cartierdestinee from './cartierdestinee.mp4';
import video_etincelledecartier from './etincelledecartier.mp4';
import video_love from './love.mp4';
import video_trinityruban from './trinityruban.mp4';
import video_vendomelouiscartier from './vendomelouiscartier.mp4';

const videos = {
  video_1895,
  video_ballerine,
  video_cartierdestinee,
  video_etincelledecartier,
  video_love,
  video_trinityruban,
  video_vendomelouiscartier,
};

export default videos;
