import { DefaultTheme } from 'styled-components';

const appTheme: DefaultTheme = {
  typography: {
    sizes: {
      xs: '12px',
      s: '14px',
      m: '18px',
      l: '22px',
      base: '16px',
    },
    fonts: {
      base: "'FancyCutPro', 'Times New Roman', 'serif'",
      mono: "'BrilliantCutPro', 'Verdana', 'sans-serif'",
      monoMedium: "'BrilliantCutProMedium', 'Verdana', 'sans-serif'",
      avantGarde: "'AvantGarde'",
      script412: "'Script412'",
      sackers: "'Sackers'",
      celeste: "'Celeste'",
      cartierLogos: "'CartierLogos'",
    },
    lineHeights: {
      none: 1,
      m: 1.5,
      l: 1.625,
      base: 1.4,
    },
    letterSpacings: {
      s: '0.02rem',
      base: '0.03125rem',
    },
  },
  colors: {
    primary: '#1D1C1C',
    secondary: '#c10016',
    red: '#c10016',
    white: '#ffffff',
    black: '#000000',
    gray: '#5A5A5A',
    dark_gray: '#5a5a5a4d',
    gray_light: '#f6f6f6',
    gold: '#AC9852',
    beige: '#F6F5EF',
    blue: '#0c2744',

    fill: '#f8f7f8',
    fill_light: '#fbfbfb',
    fill_dark: '#e7e7e7',
    fill_gray: '#e6e6ec',
    fill_purple: '#ededf3',

    text: '#1D1D1D',
    text_light: '#5A5A5A',
    text_lighter: '#9D9D9D',

    border: '#DFDFDF',

    error: '#c10016',
    warning: '#ffa262',
    success: '#8fd97d',

    shade: 'rgba(0, 0, 0, 0.3)',
  },
  sizes: {
    header: '120px',
    header_mobile: '80px',
    footer: '80px',
    footer_mobile: '260px',
  },
  spacings: {
    s: '10px',
    m: '15px',
    l: '30px',
    xl: '40px',
    base: '30px',
  },
  boxShadow: {
    s: '0 0 20px 0 rgba(0, 0, 0, 0.1)',
    m: '0 0 30px 0 rgba(0, 0, 0, 0.1)',
    l: '0 0 40px 0 rgba(0, 0, 0, 0.1)',
  },
  borderRadius: {
    s: '2px',
    base: '7px',
  },
  breakpoints: {
    xs: '320px',
    s: '375px',
    m: '768px',
    l: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  containerMaxWidths: {
    xs: '345px',
    s: '445px',
    m: '1240px',
    l: '1240px',
  },
};

export { appTheme };
