import styled from 'styled-components';
import { H2, Text, ViewSection } from 'ui';
import StyledSlider from './StyledSlider';
import { useSFY } from 'providers/SFYProvider';
import { useLocation } from 'providers';

const colorValues = ['D', 'E', 'F', 'G', 'H'];

const Color = ({ onTouch }: { onTouch?: () => void }) => {
  const { configuration, updateConfiguration } = useSFY();
  const { translations } = useLocation();

  const handleChange = (range: number | readonly number[]) => {
    const minValue = (range as number[])[0];
    const maxValue = (range as number[])[1];

    const value = colorValues.slice(minValue, maxValue);

    updateConfiguration &&
      updateConfiguration({
        ...configuration,
        color: value,
      });

    onTouch && onTouch();
  };

  const getDefaultValues = () => {
    return (
      configuration?.color && [
        colorValues.findIndex((c) => c === configuration.color![0]),
        colorValues.findIndex((c) => c === configuration.color![configuration.color!.length - 1]) + 1,
      ]
    );
  };

  return (
    <ViewSection background="gray">
      <div className="container py-5 px-4">
        <div className="col-12 col-md-6 mx-auto text-center mb-5">
          <H2 className="mb-4">{translations?.fourC_colour || 'COLOUR'}</H2>
          <Text className="mb-4">
            {translations?.fourC_colour_Text ||
              'Diamonds are said to be more valuable as they are as colorless as possible. GIA evaluates diamond colors in alphabetical order from D (Exceptional White) to Z (Tinted Color).'}
          </Text>
          {/* <Button variant="underline">{translations?.sfySetting_LearnMore || 'Learn more'}</Button> */}
        </div>
        <div className="row pb-5 py-md-5">
          <div className="col-6 col-md-2 order-2 order-md-1 d-flex align-items-md-center">
            <Text size="s" $monoMedium $uppercase>
              {translations?.sfySetting_colourless || 'COLOURLESS'}
            </Text>
          </div>
          <div className="col-12 col-md-8 order-1 order-md-2 position-relative">
            <RangeLabelGrid colCount={5}>
              {colorValues.map((c) => (
                <Text size="s" key={c} $monoMedium $uppercase $center>
                  {c}
                </Text>
              ))}
            </RangeLabelGrid>
            <StyledSlider
              className="horizontal-slider"
              thumbClassName="thumb"
              trackClassName="track--dark track"
              markClassName="marks"
              marks
              defaultValue={getDefaultValues()}
              min={0}
              max={colorValues.length}
              pearling
              minDistance={1}
              onAfterChange={(value) => handleChange(value)}
            />
          </div>
          <div className="col-6 col-md-2 order-3 d-flex text-end align-items-md-center">
            <Text size="s" $monoMedium $uppercase>
              {translations?.sfySetting_nearColourless || 'NEAR COLOURLESS'}
            </Text>
          </div>
        </div>
      </div>
    </ViewSection>
  );
};

const RangeLabelGrid = styled.div<{ colCount: number }>`
  position: absolute;
  left: 30px;
  right: 30px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${({ colCount }) => colCount && `repeat(${colCount}, 1fr)`};
`;

export default Color;
