import React from 'react';
import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

interface IViewSection extends React.HtmlHTMLAttributes<HTMLElement> {
  background: 'white' | 'gray' | 'blue';
}

const ViewSection: React.FC<IViewSection> = ({ background, children, ...rest }) => {
  return (
    <Container background={background} {...rest}>
      {children}
    </Container>
  );
};

const variantOptions = {
  background: {
    white: appTheme.colors.white,
    gray: appTheme.colors.gray_light,
    blue: appTheme.colors.blue,
  },
};

const Container = styled.div<IViewSection>`
  /* min-height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ background }) =>
    background &&
    variantOptions.background[background] &&
    css`
      background-color: ${variantOptions.background[background]};
    `}
`;

export default ViewSection;
