import { useQuery } from 'react-query';
import { getCreationProducts, sendCreationEmail } from 'api';

const getApiFriendlyLanguage = (languageCode: string) => {
  return languageCode === 'en-us' || languageCode === 'en-gb' ? 'en' : languageCode || 'en';
};

export function useCreationProductsQuery(
  filter: ICreationProductQueryFilter,
  includeFacets: boolean,
  disabled?: boolean,
) {
  const languageCode = localStorage.getItem('language') || 'en';
  const marketCode = localStorage.getItem('location') || '';

  return useQuery<ICreationProductsResponse, Error>(
    ['products', filter],
    () => getCreationProducts(filter, includeFacets, getApiFriendlyLanguage(languageCode), marketCode),
    {
      cacheTime: 0,
      enabled: !disabled,
    },
  );
}

export function useSendCreationEmailQuery(creation: ICreation, disabled?: boolean) {
  const languageCode = localStorage.getItem('language') || 'en';
  const marketCode = localStorage.getItem('location') || '';

  return useQuery<boolean, Error>(
    ['sendCreationEmail', creation],
    () => sendCreationEmail(creation, getApiFriendlyLanguage(languageCode), marketCode),
    {
      cacheTime: 0,
      enabled: !disabled,
    },
  );
}
