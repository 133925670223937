import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';

const variantOptions = {
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
  },
};

const Link = styled.div<{ color?: 'black' | 'white'; mono?: boolean; reversedUnderline?: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 98%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  display: inline;
  line-height: 1.6;
  padding-bottom: 2px;
  padding-top: 2px;
  text-decoration: none;
  transition: background-size 0.2s ease-in, opacity 0.2s ease-in;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    &:hover {
      background-size: 100% 1px;
      text-decoration: none;
    }

    ${({ reversedUnderline }) =>
      reversedUnderline &&
      css`
        background-size: 100% 1px;
        &:hover {
          opacity: 0.8;
          background-size: 0 1px;
        }
      `}
  }

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};
    `}

  ${({ mono }) =>
    mono &&
    css`
      font-family: ${({ theme }) => theme.typography.fonts.mono};
      font-size: 12px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    `}
`;

export default Link;
