import styled from 'styled-components';
import { collections } from 'constants/collections';
import { CollectionBox } from 'components';
import { useIsMobile } from 'hooks/useIsMobile';
import { Arrow, Text } from 'ui';

import { Swiper, SwiperSlide } from 'swiper/react';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';
import { useLocation } from 'providers';

const CollectionSlideshow = () => {
  const isMobile = useIsMobile();
  const { translations, location } = useLocation();

  return (
    <Container>
      <Header>
        <Text level="h1" $uppercase $mono className="text-center">
          {translations?.menu_collection || 'Collections'}
        </Text>
        <NavContainer className="d-none d-md-flex">
          <Prev className={'prev'}>
            <Arrow direction="left" />
          </Prev>
          <Next className={'next'}>
            <Arrow direction="right" />
          </Next>
        </NavContainer>
      </Header>
      <StyledSwiper
        modules={[Pagination, Navigation]}
        slidesPerView={isMobile ? 1 : 2}
        spaceBetween={20}
        speed={1000}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
        pagination={isMobile}
      >
        {collections.map((c) => (
          <SwiperSlide key={c.id} style={{ width: '60%', maxWidth: isMobile ? 'none' : '610px' }}>
            <CollectionBox
              cardContent={{
                title: translations?.[`collection_${c.translationTextId}`]!,
                targetPath: `${location?.language.locationLanguageCode}/${c.id}`,
                imageUrl: c.mainImageUrlShadow,
              }}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 80px;

  .swiper-pagination-bullets {
    bottom: -40px;
  }

  .swiper-pagination-bullet {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: none;
    margin: 0 8px !important;

    &-active {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding: 80px 0 40px;
  }
`;

const StyledSwiper = styled(Swiper)`
  overflow: visible;
`;

const NavContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    transform: translateY(calc(-50% + 20px));
  }
`;

const Nav = styled.div`
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Prev = styled(Nav)`
  margin-right: 15px;
`;

const Next = styled(Nav)`
  right: 0;
`;

export default CollectionSlideshow;
