import { useRefScrollProgress } from './useRefScrollProgress';
import { useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { RefObject } from 'react';

export function useTextAnimateOnScroll<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  flip?: boolean,
  textAnimationDirection?: 'horizontal' | 'vertical',
  config: {
    startOffset: number;
    endOffset: number;
  } = {
    startOffset: 0.5,
    endOffset: 0.5,
  },
) {
  const { start, end } = useRefScrollProgress(ref, config);
  const scrollYProgress = useViewportScroll();

  const textTransforms = {
    x: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [flip ? -300 : 300, 0]),
    y: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [100, 0]),
    opacity: useTransform(scrollYProgress.scrollYProgress, [start!, end!], [0, 1]),
  };
  const xText = useSpring(textTransforms.x, { stiffness: 1000, damping: 90 });
  const yText = useSpring(textTransforms.y, { stiffness: 1000, damping: 90 });
  const opacity = useSpring(textTransforms.opacity, { stiffness: 1000, damping: 90 });

  return {
    opacity,
    x: textAnimationDirection === 'horizontal' || !textAnimationDirection ? xText : undefined,
    y: textAnimationDirection === 'vertical' ? yText : undefined,
  };
}
