import { Text } from 'ui';
import { useLocation } from 'providers';
import materialImages from 'media/images/materials';

const MaterialBox = ({ material, size, lightText }: { material: IMaterial; size: number; lightText?: boolean }) => {
  const { translations } = useLocation();

  const getMaterialTranslation = (materialId: string) => {
    return translations && translations[`collection_material_${materialId.toLowerCase()}` as keyof TranslationLabels];
  };

  const getMaterialImage = () => {
    const materialId = material.id === 'PG' ? 'RG' : material.id;
    return materialImages[`${materialId.toLowerCase()}_x${size}` as keyof typeof materialImages];
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <img src={getMaterialImage()} alt={material.name} className="mb-2 w-100" />
      <Text className="pt-1" color={lightText ? 'white' : undefined}>
        {material.id === 'TG' ? (
          <>
            {getMaterialTranslation('YG')}, {getMaterialTranslation('PT')}, {getMaterialTranslation('RG')}
          </>
        ) : (
          getMaterialTranslation(material.id)
        )}
      </Text>
    </div>
  );
};

export default MaterialBox;
