import { Navigate, useNavigate } from 'react-router-dom';
import { collections } from 'constants/collections';
import { Text, H1, Button, Quote } from 'ui';
import styled from 'styled-components';
import PageSection from './components/PageSection';
import { CollectionGallery, CollectionMaterials, CollectionSlideshow } from 'components';
import SplitSection from '../HomeView/components/SplitSection';
import engraveImg from 'media/cta/engrave/engrave_1.jpg';
import engraveVideo from 'media/cta/engrave/engraving-white-3.mp4';
import embossImg from 'media/cta/engrave/emboss_1.jpg';
import embossVideo from 'media/cta/engrave/ECRIN-7white.mp4';
import { useLocation } from 'providers';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

const CollectionView = ({ id }: { id: string }) => {
  const { translations, location } = useLocation();
  const navigate = useNavigate();
  const collection = collections.find((c) => c.id === id);

  if (!collection) {
    return <Navigate to="/page-not-found" />;
  }

  const collection_Text = translations?.[`collection_${collection.translationTextId}_Text`];
  const collection_Quote = translations?.[`collection_${collection.translationTextId}_Quote`];
  const collection_Name = translations?.[`collection_${collection.translationTextId}`];

  return (
    <div>
      <div className="container text-center py-5">
        <H1 introTitle>{collection_Name}</H1>
        <div className="col-12 col-md-8 mx-auto">
          <Text>{collection_Text}</Text>
        </div>
      </div>
      <VideoContainer>
        <StyledVideo src={collection?.videoUrl} muted loop autoPlay playsInline>
          Your browser does not support the video tag
        </StyledVideo>
      </VideoContainer>
      <PageSection background="white" className="text-center" style={{ minHeight: '80vh' }}>
        <div className="container">
          {collection_Quote && <Quote text={collection_Quote} />}
          <Button
            className="mt-5"
            onClick={() =>
              navigate(`/${location?.language.locationLanguageCode}/set-for-you?collection=${collection.id}`)
            }
          >
            {translations?.firstpage_beginYourJourney || 'Begin your journey'}
          </Button>
        </div>
      </PageSection>

      <CollectionMaterials collection={collection} backgroundColor="blue" />

      <div className="container d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
        <div className="col-12 col-md-8 mx-auto">
          <Text $center>{translations?.collection_components_text}</Text>
        </div>
      </div>
      <div className="container">{collection && <CollectionGallery collection={collection} />}</div>

      <SplitSection
        title={translations?.firstpage_engrave || 'Engrave'}
        text={translations?.firstpage_engrave_Text || ''}
        imageUrl={engraveImg}
        videoUrl={engraveVideo}
        flip
      />
      <SplitSection
        title={translations?.firstpage_emboss || 'Emboss'}
        text={translations?.firstpage_emboss_Text || ''}
        imageUrl={embossImg}
        videoUrl={embossVideo}
        animateVideo
        overflowVideo
      />
      <CollectionSlideshowContainer className="container mt-5">
        <CollectionSlideshow />
      </CollectionSlideshowContainer>
    </div>
  );
};

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    height: 60vh;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    height: 80vh;
  }
`;

const StyledVideo = styled.video`
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    top: -15px;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
    min-width: 100%;
    height: auto;
  }
`;

const CollectionSlideshowContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl}) {
    max-width: 1100px;
  }
`;

export default CollectionView;
