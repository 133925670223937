import color_d from './color_d.png';
import color_e from './color_e.png';
import color_f from './color_f.png';
import color_g from './color_g.png';
import color_h from './color_h.png';

export interface IColorImages {
    d: string;
    e: string;
    f: string;
    g: string;
    h: string;
}

const colorImages: IColorImages = {
    d: color_d,
    e: color_e,
    f: color_f,
    g: color_g,
    h: color_h,
};

export default colorImages;