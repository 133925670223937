import React, { useState, useRef, useEffect } from 'react';
import { H2, Text, Button } from 'ui';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import styled, { css } from 'styled-components';
import useDrag from 'hooks/useDrag';
import { useNavigate } from 'react-router-dom';
import { useLocation, useSFY } from 'providers';
import { getFormattedPrice } from 'utils/priceFormat';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { pushTrackingEvent } from 'utils/trackingUtil';
import { modelImages } from 'constants/modelImages';

// used as replacement img for models missing images with front angle
import replacementImg from 'media/images/models/N4162900.png';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const SearchResult = ({
  products,
  isLoading,
  isError,
}: {
  products?: CreationProduct[];
  isLoading: boolean;
  isError: boolean;
}) => {
  const navigate = useNavigate();
  const cardHeaderRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const { configuration, updateRingSelection, ringSelection } = useSFY();
  const { location, translations } = useLocation();
  const [cardHeaderWidth, setCardHeaderWidth] = useState(0);

  useEffect(() => {
    if (products && products.length) {
      setSelectedIndex(0);
      setCardHeaderWidth(cardHeaderRef.current.clientWidth - 30);
    } else {
      setSelectedIndex(undefined);
    }
  }, [products, cardHeaderRef]);

  const isItemSelected = (index: number) => {
    return selectedIndex === index;
  };

  const handleItemClick = (index: number) => () => {
    if (dragging) {
      return false;
    }

    const itemSelected = isItemSelected(index);
    setSelectedIndex(itemSelected ? undefined : index);
  };

  const getModelImage = (modelId: string) => {
    return modelImages.find((img) => img.modelId === modelId && img.angle === 'front')?.imageUrl || replacementImg;
  };

  const handleNextStep = () => {
    if (selectedIndex === undefined || !products) return;

    const selected = products[selectedIndex];

    pushTrackingEvent('esfy_converter_select_diamond', {
      item_reference: selected.model,
      item_id: selected.model,
      item_category: 'Rings',
      item_collection: configuration?.collection?.name,
      item_material: configuration?.model?.material?.name,
      item_diamond_carat: selected.stoneCarat,
      item_diamond_clarity: selected.stoneClarity,
      item_diamond_color: selected.stoneColor,
      currency: selected.currency,
      price: selected.price,
      quantity: 1,
    });

    updateRingSelection &&
      updateRingSelection({
        ...ringSelection,
        ...selected,
        // set fallback images if productMainImageUrl is null
        productMainImageUrl:
          selected.productMainImageUrl ||
          (selected.productImages?.length && selected.productImages[0] && selected.productImages[0].url) ||
          `${window.location.protocol}//${window.location.host}${getModelImage(selected.model)}`,
      });

    navigate(`/${location?.language.locationLanguageCode}/set-for-you/results`);
  };

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <Container fade={isLoading}>
      <div className="container position-relative">
        <H2 center className="mb-5">
          {translations?.sfySetting_yourDiamonds || 'Your diamonds'} {`(${products?.length || 0})`}
        </H2>
        {!isLoading && !products?.length ? (
          <Text $center>No matches found. Please try a different configuration.</Text>
        ) : (
          <>
            <StyledCardHeader ref={cardHeaderRef}>
              <Text>{translations?.sfySetting_carat || 'Carat'}:</Text>
              <Text>{translations?.sfySetting_colour || 'Colour'}:</Text>
              <Text>{translations?.sfySetting_clarity || 'Clarity'}:</Text>
              <Text>{translations?.sfySetting_price || 'Price'}:</Text>
            </StyledCardHeader>
            <div style={{ paddingLeft: cardHeaderWidth }} className="mb-4">
              <ScrollMenu onMouseDown={() => dragStart} onMouseUp={() => dragStop} onMouseMove={handleDrag}>
                {products && products.length
                  ? products.map((product, index) => (
                      <ProductCard
                        key={index}
                        itemId={product.key}
                        product={product}
                        onClick={handleItemClick(index)}
                        selected={isItemSelected(index)}
                        selectedForYou={isItemSelected(index) && product.key === products[0].key}
                      />
                    ))
                  : Array.from(Array(5)).map((i, index) => <ProductCard key={index} isLoading={true} />)}
              </ScrollMenu>
            </div>
            <div className="d-flex">
              {products && selectedIndex !== undefined && products[selectedIndex] ? (
                <StyledButton
                  onClick={() => handleNextStep()}
                  disabled={isLoading}
                  className="mx-auto mx-md-0 ms-md-auto"
                >
                  {translations?.sfySetting_price || 'Price'}{' '}
                  {configuration?.currency &&
                    products[selectedIndex] &&
                    getFormattedPrice(products[selectedIndex].price, configuration.currency)}{' '}
                  - {translations?.sfySetting_continue || 'continue'}
                </StyledButton>
              ) : null}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

interface ICard {
  product?: ISFYProductSelection;
  itemId?: string;
  selected?: boolean;
  selectedForYou?: boolean;
  onClick?: (visibility: any) => void;
  isLoading?: boolean;
}

const ProductCard: React.FC<ICard> = ({ product, selected, selectedForYou, itemId, onClick, isLoading }) => {
  const visibility = React.useContext(VisibilityContext);
  const { translations } = useLocation();

  return (
    <>
      <StyledCard isLoading={isLoading} onClick={() => onClick && onClick(visibility)} selected={selected}>
        <Text size="s" color="lighter" $monoMedium>
          {isLoading ? <Skeleton width={50} enableAnimation={false} /> : product?.stoneCarat}
        </Text>
        <Text size="s" color="lighter" $monoMedium>
          {isLoading ? <Skeleton width={20} enableAnimation={false} /> : product?.stoneColor}
        </Text>
        <Text size="s" color="lighter" $monoMedium>
          {isLoading ? <Skeleton width={30} enableAnimation={false} /> : product?.stoneClarity}
        </Text>
        <Text size="s" $monoMedium>
          {isLoading ? (
            <Skeleton width={80} enableAnimation={false} />
          ) : (
            product && getFormattedPrice(product.price, product.currency)
          )}
        </Text>
      </StyledCard>
      <Text style={{ height: 25 }} $center size="xs" $monoMedium $uppercase color="red">
        {selectedForYou && (translations?.sfySetting_pickedForYou || 'Picked for you')}
      </Text>
    </>
  );
};

const Container = styled.div<{ fade?: boolean }>`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 50px 0;
  transition: opacity 0.3s ease;

  ${({ fade }) =>
    fade &&
    css`
      opacity: 0.4;
    `}
`;

const StyledCard = styled.div<{ selected?: boolean; isLoading?: boolean }>`
  border: 2px solid ${({ theme }) => theme.colors.border};
  padding: 20px 30px;
  margin: 0 10px 5px;
  width: 180px;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  > div:not(:last-child) {
    margin-bottom: 8px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.colors.black};
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      border-color: ${({ theme }) => theme.colors.fill};
      background-color: ${({ theme }) => theme.colors.fill};
    `}
`;

const StyledCardHeader = styled.div`
  position: absolute;
  left: 0;
  top: 74px;
  padding: 20px 10px 20px 30px;
  /* border: 2px solid white; */
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 20px 30px;
  }

  /* &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
  } */

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: auto;
  }
`;
export default SearchResult;
