import clarity_f from './clarity_f.png';
import clarity_if from './clarity_if.png';
import clarity_vs1 from './clarity_vs1.png';
import clarity_vs2 from './clarity_vs2.png';
import clarity_vvs1 from './clarity_vvs1.png';
import clarity_vvs2 from './clarity_vvs2.png';

export interface IClarityImages {
    fl: string;
    if: string;
    vs1: string;
    vs2: string;
    vvs1: string;
    vvs2: string;
}

const clarityImages: IClarityImages = {
    fl: clarity_f,
    if: clarity_if,
    vs1: clarity_vs1,
    vs2: clarity_vs2,
    vvs1: clarity_vvs1,
    vvs2: clarity_vvs2,
};

export default clarityImages;