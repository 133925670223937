import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Box, Button, H2, Icon, Text } from 'ui';
import { useLocation, useSFY } from 'providers';
import YourCreation from './components/YourCreation';
import ModelVisualizer from './components/ModelVisualizer';
import { useNavigate } from 'react-router-dom';
import embossImg from 'media/images/sfy/embossing_personalise.png';
import icons from 'media/icons';
import { pushTrackingEvent } from 'utils/trackingUtil';
import { useOnScreen } from 'hooks/useOnScreen';

// used as replacement img for models missing images with front angle
import replacementImg from 'media/images/models/N4162900.png';

const SetForYouMakeItYours = () => {
  const { configuration, ringSelection } = useSFY();
  const { translations, location, localizedPath } = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const embossingRef = useRef<HTMLDivElement>(null);
  const embossingIsInView = useOnScreen(embossingRef, -160);

  const handleNextStep = () => {
    pushTrackingEvent('esfy_converter_personalise', {
      item_reference: ringSelection?.model,
      item_id: ringSelection?.model,
      item_category: 'Rings',
      item_collection: configuration?.collection?.name,
      item_material: configuration?.model?.material?.name,
      item_diamond_carat: ringSelection?.stoneCarat,
      item_diamond_clarity: ringSelection?.stoneClarity,
      item_diamond_color: ringSelection?.stoneColor,
      currency: ringSelection?.currency,
      price: ringSelection?.price,
      item_engraved: !!ringSelection?.engraving ? 'Yes' : 'No',
      item_embossed: !!ringSelection?.embossing ? 'Yes' : 'No',
      quantity: 1,
    });

    navigate(`/${location?.language.locationLanguageCode}/set-for-you/results`);
  };

  const handleBookAnAppointmentClick = () => {
    pushTrackingEvent('esfy_intention_book_appointment');
    window.open(localizedPath?.bookAnAppointment, '_blank', 'noopener,noreferrer');
  };

  const isEngravable = () => {
    // The destination uses GB as country code for UK in 'productNrOfChar'-properties
    const locationCode = location?.id === 'UK' ? 'GB' : location?.id;

    return (
      locationCode &&
      ringSelection &&
      (ringSelection[`productNrOfChar1Line${locationCode.toUpperCase()}` as keyof ISFYProductSelection] ||
        ringSelection[`productNrOfChar2Line${locationCode.toUpperCase()}` as keyof ISFYProductSelection])
    );
  };

  const getInputMaxLength = (type: 'ENGRAVING' | 'EMBOSSING') => {
    const locationCode = location?.id === 'UK' ? 'GB' : location?.id;

    const line = {
      ENGRAVING: {
        line: 1,
        defaultMaxLength: 12,
      },
      EMBOSSING: {
        line: 2,
        defaultMaxLength: 4,
      },
    };

    const maxLength =
      locationCode &&
      ringSelection &&
      (ringSelection[
        `productNrOfChar${line[type].line}Line${locationCode.toUpperCase()}` as keyof ISFYProductSelection
      ] as number);
    return !isNaN(parseInt(String(maxLength))) && maxLength !== null
      ? parseInt(String(maxLength))
      : line[type].defaultMaxLength;
  };

  return (
    <>
      <div ref={containerRef}>
        {!isEngravable() && (
          <Container>
            <div className="container">
              <Box backgroundColor="white">
                <div className="text-center">
                  <H2 className="mb-4">{translations?.sfySetting_Wearesorry_text}</H2>
                  <Text className="mb-4">{translations?.sfySetting_Wearesorry}</Text>
                  <div className="d-md-flex flex-wrap justify-content-center">
                    <Button onClick={() => handleNextStep()} variant="secondary" className="w-100 w-md-auto mb-3">
                      {translations?.sfySetting_ViewYourCreation || 'View your creation'}
                    </Button>
                    <Button
                      onClick={() => handleBookAnAppointmentClick()}
                      variant="secondary"
                      className="w-100 w-md-auto mb-3 mx-md-4"
                    >
                      {translations?.sfySetting_BookanAppointment || 'Book an appointment'}
                    </Button>
                    <Button
                      onClick={() => window.open(localizedPath?.contactUs, '_blank', 'noopener,noreferrer')}
                      variant="secondary"
                      className="w-100 w-md-auto mb-3"
                    >
                      {translations?.sfySetting_ContactSupport || 'Contact support'}
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </Container>
        )}
        <ConfigView
          id="ENGRAVING"
          useModelVisualizer
          imageUrl={ringSelection?.productMainImageUrl || replacementImg}
          inputConfig={{
            id: 'ENGRAVING',
            title: translations?.sfySetting_Engraving || 'Engraving',
            inputLabel: translations?.sfySetting_addYourMessage || 'Add Your Message',
            inputMaxLength: getInputMaxLength('ENGRAVING'),
            symbols: [
              { id: 'None' },
              { id: 'Heart', imgUrl: icons.heart },
              { id: 'Star', imgUrl: icons.star },
              { id: 'Infinity', imgUrl: icons.infinity },
            ],
            fonts: {
              block: 'avantGarde',
              cursive: 'script412',
            },
          }}
          className="mb-2"
        />
        <div ref={embossingRef}>
          <ConfigView
            id="EMBOSSING"
            useModelVisualizer
            imageUrl={embossImg}
            inputConfig={{
              id: 'EMBOSSING',
              title: translations?.sfySetting_redBoxEmbossing || 'Red Box Embossing',
              inputLabel: translations?.sfySetting_AddEmbosing || 'Add Complimentary Embossing',
              inputMaxLength: getInputMaxLength('EMBOSSING'),
              symbols: [{ id: 'None' }, { id: '.' }, { id: '&' }],
              fonts: {
                block: 'sackers',
                cursive: 'celeste',
              },
            }}
          />
        </div>
      </div>
      {ringSelection && (
        <YourCreation
          creation={{
            name: ringSelection.productName,
            reference: ringSelection.model,
            imageUrl: ringSelection.productMainImageUrl || replacementImg,
            price: ringSelection.price,
            currency: ringSelection.currency,
          }}
          handleNext={() => handleNextStep()}
          containerElement={containerRef}
          shouldBeFixed={embossingIsInView}
        />
      )}
    </>
  );
};

type EngravingValues = {
  message: string;
  messageAfterSymbol?: string;
  font: string;
  symbol: 'None' | 'Heart' | 'Star' | 'Infinity' | '.' | '&';
};

type Symbol = {
  id: 'None' | 'Heart' | 'Star' | 'Infinity' | '.' | '&';
  imgUrl?: string;
};

interface IConfigView extends React.HTMLAttributes<HTMLDivElement> {
  useModelVisualizer?: boolean;
  imageUrl: string;
  inputConfig: {
    id: 'ENGRAVING' | 'EMBOSSING';
    title: string;
    inputLabel: string;
    inputMaxLength: number;
    symbols: Symbol[];
    fonts: {
      block: 'avantGarde' | 'sackers';
      cursive: 'script412' | 'celeste';
    };
  };
}

const ConfigView: React.FC<IConfigView> = ({ useModelVisualizer, imageUrl, inputConfig, ...rest }) => {
  const { ringSelection, updateRingSelection } = useSFY();
  const { translations, location } = useLocation();
  const messageSpan = useRef<HTMLDivElement>(null);
  const [messageWidth, setMessageWidth] = useState<number | undefined>(100);
  const messageInputRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const getDefaultValues = (values?: EngravingConfig) => {
    return values
      ? (values as EngravingValues)
      : ({
          message: '',
          messageAfterSymbol: undefined,
          font: '',
          symbol: 'None',
        } as EngravingValues);
  };

  const [values, setValues] = useState<EngravingValues>(
    inputConfig.id === 'EMBOSSING'
      ? getDefaultValues(ringSelection?.embossing)
      : inputConfig.id === 'ENGRAVING'
      ? getDefaultValues(ringSelection?.engraving)
      : getDefaultValues(undefined),
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setMessageWidth(messageSpan.current?.offsetWidth || 100);
  }, [values]);

  const handleChange = (target: string, value: string) => {
    setValues((values) => ({ ...values, [target]: value }));

    // Save to context (and localStorage)
    if (ringSelection) {
      updateRingSelection &&
        updateRingSelection({
          ...ringSelection,
          [inputConfig.id.toLowerCase()]: {
            ...values,
            [target]: value,
          },
        });
    }
  };

  const handleSymbolChange = (symbol: Symbol) => {
    if (values.message.length === inputConfig.inputMaxLength) {
      setErrorMessage('Too many characters');
      return;
    }

    setErrorMessage(undefined);
    setValues((values) => ({
      ...values,
      symbol: symbol.id,
      messageAfterSymbol: symbol.id === 'None' ? '' : values.messageAfterSymbol,
    }));

    // Save to context (and localStorage)
    if (ringSelection) {
      updateRingSelection &&
        updateRingSelection({
          ...ringSelection,
          [inputConfig.id.toLowerCase()]: {
            ...values,
            symbol: symbol.id,
            messageAfterSymbol: symbol.id === 'None' ? '' : values.messageAfterSymbol,
          },
        });
    }
  };

  // Enable symbol removal using Backspace key
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspace = e?.type === 'keydown' && (e as React.KeyboardEvent<HTMLInputElement>)?.key === 'Backspace';

    if (isBackspace && !values.messageAfterSymbol?.length) {
      setValues((values) => ({ ...values, symbol: 'None' }));
      messageInputRef?.current?.focus();
    }
  };

  const getDistanceRatio = () => {
    const type = inputConfig.id;

    if (type === 'ENGRAVING') {
      return window.innerWidth < 450 ? 0.2 : window.innerWidth < 1000 ? 0.6 : 0.3;
    }

    if (type === 'EMBOSSING') {
      return window.innerWidth < 450 ? 0.2 : window.innerWidth < 900 ? 0.8 : 0.6;
    }
  };

  return (
    <Container {...rest}>
      <div ref={containerRef} className="container">
        <Overlay style={{ width: containerRef.current?.offsetLeft }} $position="LEFT"></Overlay>
        <Overlay
          style={{
            width: containerRef.current?.offsetLeft,
          }}
          $position="RIGHT"
        ></Overlay>
        <div className="row">
          <FormContainer className="col-12 col-md-6 d-flex justify-content-center flex-column order-2 order-md-1 py-md-3">
            <H2 className="mb-4">
              {inputConfig.title}
              {rest.id === 'EMBOSSING' && (
                <Text color="red" size="l" style={{ display: 'inline' }}>
                  {' *'} {location?.id === 'TW' && '(此服務僅於限定專賣店提供)'}
                </Text>
              )}
            </H2>
            <Text className="mb-2">{inputConfig.inputLabel}</Text>
            <HiddenInputText ref={messageSpan}>{values.message.split(' ').join('l')}</HiddenInputText>
            <div className=" mb-2">
              <InputWrapper className="d-flex align-items-center">
                <StyledInput
                  ref={messageInputRef}
                  type="text"
                  placeholder={
                    values.symbol !== 'None' && values.message.length === 0
                      ? ''
                      : translations?.sfySetting_YourTextHere || 'Your text here'
                  }
                  maxLength={
                    inputConfig.inputMaxLength -
                    (values.messageAfterSymbol?.length || 0) -
                    (values.symbol !== 'None' ? 1 : 0)
                  }
                  style={{
                    width:
                      messageWidth && values.symbol !== 'None'
                        ? values.message.length === 0
                          ? '5px'
                          : messageWidth + 'px'
                        : '100%',
                  }}
                  defaultValue={values.message}
                  onChange={(e) => handleChange('message', e.target.value)}
                />
                {values.symbol !== 'None' && (
                  <IconInput>
                    {inputConfig.id === 'ENGRAVING' ? (
                      <Icon
                        size="m"
                        style={{ width: 30, flexShrink: 0 }}
                        imgUrl={inputConfig.symbols.find((s) => s.id === values.symbol)?.imgUrl || ''}
                      />
                    ) : (
                      <Text>{values.symbol}</Text>
                    )}
                  </IconInput>
                )}
                {values.symbol !== 'None' && (
                  <StyledInput
                    type="text"
                    maxLength={inputConfig.inputMaxLength - (values.message.length + 1)}
                    style={{ width: `calc(100% - ${messageWidth}px)` }}
                    defaultValue={values.messageAfterSymbol}
                    onChange={(e) => handleChange('messageAfterSymbol', e.target.value)}
                    onKeyDown={(e) => onKeyDown(e)}
                  />
                )}
              </InputWrapper>
            </div>
            {errorMessage && <Text>{errorMessage}</Text>}
            <Text color="lighter" className="mb-4">
              {`${translations?.sfySetting_addUpTo} ${inputConfig.inputMaxLength} ${translations?.sfySetting_characters}`}
              .
            </Text>
            <Text className="mb-2">{translations?.sfySetting_Select_from_Style || 'Select Font Style'}</Text>
            <div className="d-flex flex-wrap mb-4">
              <CheckButton
                active={values.font === 'Block'}
                onClick={(e) => handleChange('font', 'Block')}
                font={inputConfig.fonts.block}
                className="me-3 mb-3"
              >
                BLOCK
              </CheckButton>
              <CheckButton
                active={values.font === 'Cursive'}
                onClick={(e) => handleChange('font', 'Cursive')}
                font={inputConfig.fonts.cursive}
                style={inputConfig.fonts.cursive === 'celeste' ? { fontSize: 14 } : {}}
              >
                Cursive
              </CheckButton>
            </div>
            <Text className="mb-2">{translations?.sfySetting_AddASymbol || 'Add A Symbol'}</Text>
            <div className="d-flex flex-wrap mb-4">
              {inputConfig.symbols.map((symbol, index) => (
                <CheckButton
                  key={index}
                  active={values.symbol === symbol.id}
                  onClick={(e) => handleSymbolChange(symbol)}
                  className="me-3 mb-3"
                >
                  {symbol.imgUrl ? <Icon imgUrl={symbol.imgUrl} /> : symbol.id}
                </CheckButton>
              ))}
            </div>
            {rest.id === 'EMBOSSING' && location?.id !== 'TW' && (
              <Text
                className="mb-4"
                color="lighter"
                style={{
                  display: 'inline',
                }}
              >
                <Text color="red" style={{ display: 'inline' }}>
                  {'* '}
                </Text>
                {translations?.sfySetting_boutiquesdisclaimer}{' '}
              </Text>
            )}
          </FormContainer>
          {useModelVisualizer && ringSelection ? (
            <IframeContainer>
              <ModelVisualizer variant={inputConfig.id} distanceRatio={getDistanceRatio()} />
            </IframeContainer>
          ) : (
            <ImageContainer $paddedTop={inputConfig.id === 'EMBOSSING'}>
              <StyledImage src={imageUrl} alt={ringSelection?.model} />
            </ImageContainer>
          )}
        </div>
      </div>
    </Container>
  );
};

const FormContainer = styled.div`
  padding-top: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    position: relative;
    z-index: 10;
    min-height: 65vh;
  }
`;

const IframeContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(20%);
    bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    transform: translateX(15%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    transform: translateX(10%);
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray_light};
`;

const Overlay = styled.div<{ $position: 'LEFT' | 'RIGHT' }>`
  position: absolute;
  height: 500px;
  z-index: 100;
  height: 100%;

  ${({ $position }) =>
    $position === 'LEFT'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`;

const ImageContainer = styled.div<{ $paddedTop?: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${({ $paddedTop }) => ($paddedTop ? '40px 40px 60px' : '15px 40px 60px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: ${({ $paddedTop }) => ($paddedTop ? '80px' : '40px 80px 80px')};
  }
`;

const StyledImage = styled.img`
  max-height: 450px;
`;

const InputWrapper = styled.div`
  height: 38px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.colors.gray_light};
  max-width: 350px;
`;

const StyledInput = styled.input`
  height: 34px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  padding: 0;
  font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
  font-size: ${({ theme }) => theme.typography.sizes.base};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.typography.sizes.xs};
  }
`;

const HiddenInputText = styled.div`
  position: absolute;
  opacity: 0;
  border: none;
  padding: 0;
  font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
  font-size: ${({ theme }) => theme.typography.sizes.base};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.typography.sizes.xs};
  }
`;

const IconInput = styled.div`
  height: 38px;
  border-top: 2px solid ${({ theme }) => theme.colors.border};
  border-bottom: 2px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
`;

const CheckButton = styled.div<{ active?: boolean; font?: 'avantGarde' | 'script412' | 'sackers' | 'celeste' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 80px;
  border-radius: 19px;
  padding: 0 20px;
  outline: 2px solid ${({ theme }) => theme.colors.border};
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  font-size: ${({ theme }) => theme.typography.sizes.xs};
  transition: opacity 0.3s ease;
  user-select: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    opacity: 0.7;
  }

  ${({ active }) =>
    active &&
    css`
      outline: 2px solid ${({ theme }) => theme.colors.black};
    `}

  ${({ font }) =>
    font &&
    css`
      font-family: ${({ theme }) => theme.typography.fonts[font]};
      font-weight: bold;
    `}
`;

export default SetForYouMakeItYours;
