import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Arrow, H2 } from 'ui';
import { useIsMobile } from 'hooks/useIsMobile';

// swiper bundle styles
import 'swiper/css/bundle';
// swiper core styles
import 'swiper/css';

import cuts from 'constants/cuts';
import { AnimatePresence, motion } from 'framer-motion';
import { Navigation, Pagination } from 'swiper';
import { useLocation } from 'providers';

const CutSwiper = () => {
  const isMobile = useIsMobile();
  const [slideIdx, setSlideIdx] = useState<number>(0);
  const { translations } = useLocation();

  const handleSlideChange = (activeSlide: number) => {
    setSlideIdx(activeSlide);
  };

  return (
    <>
      <Container>
        <Prev className="prev">
          <Arrow direction="left" />
        </Prev>
        <Swiper
          modules={[Pagination, Navigation]}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          // centeredSlides
          slidesPerView={isMobile ? 1.7 : 2}
          centeredSlides={true}
          // spaceBetween={isMobile ? 20 : 10}
          speed={1000}
          onRealIndexChange={(swiper) => handleSlideChange(swiper.realIndex)}
          style={{
            overflow: 'visible',
            width: '100%',
            height: isMobile ? '350px' : '500px',
          }}
        >
          {cuts.map((item, index: number) => (
            <SwiperSlide key={index} className="text-center">
              <img
                src={item.image}
                alt={`${item.id}`}
                style={{ transform: item.id === 'round' ? 'scale(1)' : 'scale(1.4)' }}
              />
              <AnimatePresence>
                {index === slideIdx && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <H2
                      style={{
                        bottom: isMobile ? 40 : 80,
                        left: 0,
                        right: 0,
                        position: 'absolute',
                      }}
                    >
                      {translations
                        ? translations[`fourC_cut_${item.id.toLowerCase()}` as keyof TranslationLabels]
                        : item.id}
                    </H2>
                  </motion.div>
                )}
              </AnimatePresence>
            </SwiperSlide>
          ))}
        </Swiper>
        <Next className="next">
          <Arrow direction="right" />
        </Next>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;

  .swiper-pagination-bullet {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: none;
    margin: 0 8px !important;

    &-active {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    width: 55%;
    transition: all 1s ease;

    &-active {
      transform: scale(1);
      opacity: 1;
    }

    img {
      height: 80%;
      object-fit: cover;
      transform: scale(1.4);
    }
  }
`;

const Nav = styled.div`
  position: absolute;
  bottom: -25px;
  transform: translateY(-50%);
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  z-index: 2;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.white};
    top: 50%;
    bottom: initial;
  }
`;

const Prev = styled(Nav)`
  margin-right: ${({ theme }) => theme.spacings.l};
  left: calc(50% - 400px);
`;

const Next = styled(Nav)`
  margin-left: ${({ theme }) => theme.spacings.l};
  right: calc(50% - 400px);
`;

export default CutSwiper;
