import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import icons from 'media/icons';
import { Icon, Text, Button } from 'ui';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from 'hooks/useOnClickOutside';

interface IDropdownItem {
  name: string;
  action?: () => void;
}

interface IDropdownProps {
  label: string;
  items: IDropdownItem[];
  defaultSelected?: string;
}

const Dropdown: React.FC<React.HtmlHTMLAttributes<HTMLDivElement> & IDropdownProps> = ({
  items,
  defaultSelected,
  label,
  ...rest
}) => {
  const ref = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDropdownItem | undefined>(undefined);
  const dropdownVariants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  };

  useEffect(() => {
    defaultSelected && setSelectedItem({ name: defaultSelected, action: undefined });
  }, [defaultSelected]);

  const handleItemClick = (item: IDropdownItem) => {
    setSelectedItem(item);
    item.action && item.action();
    setIsOpen(false);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownContainer ref={ref} {...rest}>
      <Button
        style={{ whiteSpace: 'initial' }}
        onClick={() => setIsOpen(!isOpen)}
        className="w-100"
        variant="secondary"
      >
        {selectedItem?.name || label} <StyledIcon imgUrl={icons.downArrow} />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <DropdownItemList
            variants={dropdownVariants}
            transition={{ duration: 0.3 }}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            {items.map((item, index) => (
              <DropdownItem key={index} onClick={() => handleItemClick(item)}>
                <Text size="xs" $monoMedium $uppercase>
                  {item.name}
                </Text>
              </DropdownItem>
            ))}
          </DropdownItemList>
        )}
      </AnimatePresence>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownItemList = styled(motion.div)`
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-top: none;
  background-color: white;
`;

const DropdownItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  text-align: right;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    border: none;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 20px;
`;

export default Dropdown;
