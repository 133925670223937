import { useState, useEffect } from 'react';

export const useOnScreen = (ref: any, rootMargin = 0) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: `${rootMargin}px`,
      },
    );

    const current = ref.current;

    if (current) {
      // eslint-disable-next-line
      observer.observe(current);
    }
    return () => {
      // eslint-disable-next-line
      observer.unobserve(current);
    };
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
};
