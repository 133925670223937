import productImages from 'media/images/products';
import { materials } from 'constants/materials';
import { modelImages } from 'constants/modelImages';
import { collectionIds } from './ids';

export const models: ICollectionModel[] = [
  {
    collectionId: collectionIds._1895,
    id: 'N4162900',
    name: 'Solitaire 1895',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Ballerine_PT_NonPaved_BrilliantCut',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    mainImageUrl: productImages.model_1895,
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4235100',
    name: 'Solitaire 1896',
    materialId: 'YG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 2,
    aestheticDescription: 'sfySetting_1895_YG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_1895_2,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4759400',
    name: 'Solitaire 1900',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'OV',
    type: 'ER',
    score: 3,
    aestheticDescription: 'sfySetting_1895_PT_NonPaved_OvalCut',
    mainImageUrl: productImages.model_1895_3,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4759000',
    name: 'Solitaire 1901',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'CU',
    type: 'ER',
    score: 4,
    aestheticDescription: 'sfySetting_1895_PT_NonPaved_CushionCut',
    mainImageUrl: productImages.model_1895_4,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4743600',
    name: 'Solitaire 1897',
    materialId: 'PG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 5,
    aestheticDescription: 'sfySetting_Love_PG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_1895_5,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4761400',
    name: 'Solitaire 1899',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 6,
    aestheticDescription: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    mainImageUrl: productImages.model_1895_6,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds._1895,
    id: 'N4163900',
    name: 'Solitaire 1895',
    materialId: 'PT',
    paving: 'Classic Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 7,
    aestheticDescription: 'sfySetting_1895_PT_ClassicPaved_BrilliantCut',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.ballerine,
    id: 'N4197900',
    name: 'Cartier Destinée solitaire',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Ballerine_PT_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_ballerine,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.ballerine,
    id: 'N4725100',
    name: 'Cartier Destinée solitaire',
    materialId: 'PG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 2,
    aestheticDescription: 'sfySetting_Love_PG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_ballerine_2,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.ballerine,
    id: 'N4188400',
    name: 'Etincelle de Cartier ring',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 3,
    aestheticDescription: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    mainImageUrl: productImages.model_ballerine_3,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.destinee,
    id: 'N4246000',
    name: 'Ballerine Solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    mainImageUrl: productImages.model_cartierdestinee,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.destinee,
    id: 'N4751800',
    name: 'Cartier Destinée solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'EC',
    type: 'ER',
    score: 2,
    aestheticDescription: 'sfySetting_CartierDestinee_PT_Paved_EmeraldCut',
    mainImageUrl: productImages.model_cartierdestinee_2,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.destinee,
    id: 'N4751700',
    name: 'Cartier Destinée solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'PS',
    type: 'ER',
    score: 3,
    aestheticDescription: 'sfySetting_CartierDestinee_PT_Paved_PearShaped',
    mainImageUrl: productImages.model_cartierdestinee_3,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.destinee,
    id: 'N4751000',
    name: 'Ballerine Solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'CU',
    type: 'ER',
    score: 4,
    aestheticDescription: 'sfySetting_CartierDestinee_PT_Paved_EmeraldCut',
    mainImageUrl: productImages.model_cartierdestinee_4,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.destinee,
    id: 'N4751600',
    name: 'Ballerine Solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'OV',
    type: 'ER',
    score: 5,
    aestheticDescription: 'sfySetting_CartierDestinee_PT_Paved_OvalCut',
    mainImageUrl: productImages.model_cartierdestinee_5,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.etincelle,
    id: 'N4744600',
    name: 'Cartier Destinée solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    mainImageUrl: productImages.model_etincelledecartier,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.love,
    id: 'N4774200',
    name: 'Love Solitaire',
    materialId: 'PG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Love_PG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_love,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.love,
    id: 'N4774300',
    name: 'Love Solitaire',
    materialId: 'PG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Love_PG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_love,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.love,
    id: 'N4724500',
    name: 'Love Solitaire',
    materialId: 'WG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 2,
    aestheticDescription: 'sfySetting_Love_PT_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_love_2,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.love,
    id: 'N4774600',
    materialId: 'PG',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 3,
    aestheticDescription: 'sfySetting_Love_PG_Paved_BrilliantCut',
    mainImageUrl: productImages.model_love_3,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.ruban,
    id: 'N4250400',
    name: 'Love Solitaire',
    materialId: 'PT',
    paving: 'Paved',
    paved: true,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    mainImageUrl: productImages.model_trinityruban,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    collectionId: collectionIds.vendome,
    id: 'N4204200',
    name: 'Vendôme Louis Cartier Solitaire',
    materialId: 'TG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: 'RD',
    type: 'ER',
    score: 1,
    aestheticDescription: 'sfySetting_VendomeLouisCartier_TG_NonPaved_BrilliantCut',
    mainImageUrl: productImages.model_vendomelouiscartier,
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },

  {
    id: 'B4012500',
    collectionId: collectionIds._1895,
    name: '1895 wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4071400',
    collectionId: collectionIds._1895,
    name: '1895 wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4088200',
    collectionId: collectionIds._1895,
    name: '1895 wedding band',
    materialId: 'RG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4031200',
    collectionId: collectionIds._1895,
    name: '1895 wedding band',
    materialId: 'YG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'N4127500',
    collectionId: collectionIds.destinee,
    name: 'Cartier Destinée wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4221900',
    collectionId: collectionIds.etincelle,
    name: 'Étincelle de Cartier wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4077900',
    collectionId: collectionIds.etincelle,
    name: 'Étincelle de Cartier wedding band',
    materialId: 'WG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4092800',
    collectionId: collectionIds.ballerine,
    name: 'Ballerine wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4093000',
    collectionId: collectionIds.ballerine,
    name: 'Ballerine wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4072000',
    collectionId: collectionIds.ballerine,
    name: 'Ballerine wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4093900',
    collectionId: collectionIds.amour,
    name: "Cartier d'Amour wedding band",
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4093600',
    collectionId: collectionIds.amour,
    name: "Cartier d'Amour wedding band",
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'N4744900',
    collectionId: collectionIds.broderie,
    name: 'Broderie de Cartier wedding band',
    materialId: 'WG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4225600',
    collectionId: collectionIds.broderie,
    name: 'Broderie de Cartier wedding band',
    materialId: 'WG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4099400',
    collectionId: collectionIds.ruban,
    name: 'Trinity Ruban wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4054000',
    collectionId: collectionIds.cDeCartier,
    name: 'C de Cartier wedding band',
    materialId: 'PT',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4232500',
    collectionId: collectionIds.cDeCartier,
    name: 'C de Cartier wedding band',
    materialId: 'RG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4086400',
    collectionId: collectionIds.cDeCartier,
    name: 'C de Cartier wedding band',
    materialId: 'RG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4085200',
    collectionId: collectionIds.love,
    name: 'Love wedding band',
    materialId: 'RG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4050800',
    collectionId: collectionIds.love,
    name: 'Love wedding band',
    materialId: 'RG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4083300',
    collectionId: collectionIds.love,
    name: 'Love wedding band, diamond-paved',
    materialId: '18kYG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
  {
    id: 'B4209900',
    collectionId: collectionIds.vendome,
    name: 'Vendôme Louis Cartier Wedding Band',
    materialId: 'TG',
    paving: 'Non Paved',
    paved: false,
    stoneCut: '',
    type: 'WR',
    score: 0,
    aestheticDescription: '',
    mainImageUrl: '',
    get material() {
      return materials.find((m) => m.id === this.materialId);
    },
    get images() {
      return modelImages.filter((i) => i.modelId === this.id);
    },
    get classicPaved() {
      return this.paving.includes('Classic Paved');
    },
  },
];
