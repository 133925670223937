import styled, { css } from 'styled-components';

const Box = styled.div<{ backgroundColor: 'white' | 'gray'; centerContent?: boolean }>`
  padding: 40px;

  @media (min-width: ${({ theme }) => theme.containerMaxWidths.s}) {
    padding: 60px;
  }

  @media (min-width: ${({ theme }) => theme.containerMaxWidths.m}) {
    padding: 100px;
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${({ theme }) => theme.colors[backgroundColor]};
    `}
`;

export default Box;
