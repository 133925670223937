import styled, { css } from 'styled-components';

const variants = {
  left: {
    transform: 'rotate(135deg) ',
  },
  right: {
    transform: 'rotate(-45deg)',
  },
  up: {
    transform: 'rotate(-135deg)',
  },
  down: {
    transform: 'rotate(45deg)',
  },
};

const Arrow = styled.i<{ direction: 'left' | 'right' | 'up' | 'down' }>`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;

  ${({ direction }) =>
    direction &&
    variants[direction] &&
    css`
      transform: ${variants[direction].transform} translate(-2px, -2px);
    `}
`;

export default Arrow;
