import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CartierDiamond from './components/CartierDiamond';
import ArtOfGiving from './components/ArtOfGiving';
import Collections from './components/Collections';
import SplitSection from './components/SplitSection';
import CartierExpertise from './components/CartierExpertise';
import FourCDetails from './components/FourCDetails';
import Beauty from './components/Beauty';
import { useHeaderConfig } from 'providers';
import { useLocation } from 'providers';

import engraveImg from 'media/cta/engrave/engrave_1.jpg';
import engraveVideo from 'media/cta/engrave/engraving-white-3.mp4';
import embossImg from 'media/cta/engrave/emboss_1.jpg';
import embossVideo from 'media/cta/engrave/ECRIN-7white.mp4';
import fourCImg from 'media/cta/cartierexpertise/cartier_expertise_2.jpg';

const HomeView = () => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [bannerContentInView, setBannerContentInView] = useState(false);
  const [scrolledPastBanner, setScrolledPastBanner] = useState(false);
  const { headerConfig, updateHeaderConfig } = useHeaderConfig();
  const { translations } = useLocation();
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    if (updateHeaderConfig && headerConfig) {
      updateHeaderConfig({
        ...headerConfig,
        hide: isAtTop,
      });

      if (bannerContentInView && !scrolledPastBanner) {
        updateHeaderConfig({
          ...headerConfig,
          collapsed: true,
        });
      }

      if (scrolledPastBanner) {
        updateHeaderConfig({
          ...headerConfig,
          transparent: false,
          expanded: false,
          collapsed: false,
        });
      }
    }

    // eslint-disable-next-line
  }, [bannerRef, bannerContentInView, scrolledPastBanner, isAtTop]);

  const setInitialHeaderConfig = () => {
    updateHeaderConfig &&
      updateHeaderConfig({
        transparent: true,
        expanded: true,
        hide: true,
      });
  };

  return (
    <Container>
      <CartierDiamond
        ref={bannerRef}
        title={translations?.firstpage_cartierdiamonds || 'Cartier Diamond'}
        text={
          translations?.firstpage_cartierDiamonds_Text ||
          'In the great jewellery tradition, Cartier offers a service that can meet your dreams. Choose the design of ring you want and the diamond to illuminate it. Let this exclusive service guide you as you move towards a uniquely emotional moment.'
        }
        buttonText={translations?.firstpage_beginYourJourney || 'Begin your journey'}
        init={() => setInitialHeaderConfig()}
        scrolledPastContent={() => setScrolledPastBanner(true)}
        contentInView={() => setBannerContentInView(true)}
        isAtTop={(isAtTop) => setIsAtTop(isAtTop)}
      ></CartierDiamond>
      <ArtOfGiving />
      <Collections />
      <SplitSection
        title={translations?.firstpage_engrave || 'Engrave'}
        text={translations?.firstpage_engrave_Text || 'Engrave text missing'}
        imageUrl={engraveImg}
        videoUrl={engraveVideo}
        flip
      />
      <SplitSection
        title={translations?.firstpage_emboss || 'Emboss'}
        text={translations?.firstpage_emboss_Text || 'Emboss text missing'}
        imageUrl={embossImg}
        videoUrl={embossVideo}
        animateVideo
        overflowVideo
      />
      {/* <Engrave />
      <Emboss /> */}
      <CartierExpertise />
      <SplitSection
        title=""
        text={translations?.firstpage_cartierExpertise_Text || 'Cartier expertise text missing'}
        imageUrl={fourCImg}
        flip
        halfWidth
        textAnimationDirection="vertical"
      />
      <FourCDetails />
      <Beauty />
    </Container>
  );
};

const Container = styled.div``;

export default HomeView;
