// import material_paved from './x1_paved.png';
// import material_paved_closeup from './x2_paved.png';
// import material_classic_paved from './x5_classic_paved.png';
// import material_classic_paved_closeup from './x5_classic_paved.png';
// import material_platinum from './x1_platinum.png';
// import material_platinum_closeup from './x2_platinum.png';
// import material_rose_gold from './x1_rose_gold.png';
// import material_rose_gold_closeup from './x2_rose_gold.png';
// import material_yellow_gold from './x1_yellow_gold.png';
// import material_yellow_gold_closeup from './x2_yellow_gold.png';
// import material_triple_gold from './x1_triple_gold.png';

import pt_x1 from './x1_platinum.png';
import pt_x2 from './x2_platinum.png';
import pt_x3 from './x3_platinum.png';
import pt_x4 from './x4_platinum.png';
import pt_x5 from './x5_platinum.png';

import paved_x1 from './x1_paved.png';
import paved_x2 from './x2_paved.png';
import paved_x3 from './x3_paved.png';
import paved_x4 from './x4_paved.png';
import paved_x5 from './x5_paved.png';

import classicpaved_x5 from './x5_classic_paved.png';

import rg_x1 from './x1_rose_gold.png';
import rg_x2 from './x2_rose_gold.png';
import rg_x3 from './x3_rose_gold.png';
import rg_x4 from './x4_rose_gold.png';
import rg_x5 from './x5_rose_gold.png';

import yg_x1 from './x1_yellow_gold.png';
import yg_x2 from './x2_yellow_gold.png';
import yg_x3 from './x3_yellow_gold.png';
import yg_x4 from './x4_yellow_gold.png';
import yg_x5 from './x5_yellow_gold.png';

import tg_x1 from './x1_triple_gold.png';

const materialImages = {
  pt_x1,
  pt_x2,
  pt_x3,
  pt_x4,
  pt_x5,
  paved_x1,
  paved_x2,
  paved_x3,
  paved_x4,
  paved_x5,
  classicpaved_x5,
  rg_x1,
  rg_x2,
  rg_x3,
  rg_x4,
  rg_x5,
  yg_x1,
  yg_x2,
  yg_x3,
  yg_x4,
  yg_x5,
  tg_x1,
};

export default materialImages;
