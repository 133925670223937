import { useLocation } from 'providers';
import { useEffect } from 'react';
import styled from 'styled-components';

const BookAnAppointmentView = () => {
  const { location } = useLocation();

  useEffect(() => {
    if (!location) return;

    const event = new CustomEvent('book-an-appointment', {
      detail: { location: location?.id, locale: location?.language.id },
    });

    window.dispatchEvent(event);
  }, [location]);

  return <Container id="canvas"></Container>;
};

// alignment fix for separator/border element
const Container = styled.div`
  .card-hr-number.rcc-bg-gray-6 {
    margin: 0 auto;
  }
`;

export default BookAnAppointmentView;
