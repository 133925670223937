import SearchResult from './components/SearchResult';
import Carat from './components/Carat';
import Color from './components/Color';
import Clarity from './components/Clarity';
import Price from './components/Price';
import { useSFY } from 'providers/SFYProvider';
import { useCreationProductsQuery } from './queries';
import { useEffect, useState } from 'react';
import { collectionIds } from 'constants/ids';

const SetForYouDiamondView = () => {
  const { configuration } = useSFY();
  const [creationProducts, setCreationProducts] = useState<CreationProduct[] | undefined>(undefined);

  const getFilter = () => {
    let filter: ICreationProductQueryFilter = {
      collection: configuration?.collection?.apiId || '',
      material: configuration?.collection?.id === collectionIds.vendome ? 'WG' : configuration?.material || '',
      priceFrom: configuration?.priceMin && configuration.priceMin - 1,
      priceTo: configuration?.priceMax && configuration.priceMax + 1,
      caratFrom: configuration?.carat && configuration.carat[0] - 0.01,
      caratTo: configuration?.carat && configuration.carat[1] + 0.01,
      color: configuration?.color && configuration.color.map((c) => c.toUpperCase()),
      clarity: configuration?.clarity && configuration?.clarity.map((c) => c.toUpperCase()),
      cut: configuration?.cut || '',
    };

    const selectedCollectionId = configuration?.collection?.id || '';
    const collectionsWithPavingFilter = [collectionIds._1895, collectionIds.love, collectionIds.ballerine];

    if (collectionsWithPavingFilter.includes(selectedCollectionId)) {
      filter = {
        ...filter,
        paved: configuration?.paved || false,
        classicPaved: configuration?.classicPaved || false,
      };
    }

    return filter;
  };

  const {
    refetch: fetchStock,
    isLoading: stockIsLoading,
    isError: stockIsError,
  } = useCreationProductsQuery(
    {
      ...getFilter(),
    },
    false,
    true,
  );

  useEffect(() => {
    if (configuration) {
      fetchStock().then(({ data }) => {
        setCreationProducts(data?.products);
      });
    }
  }, [configuration, fetchStock]);

  return (
    <>
      <Carat />
      <Color />
      <Clarity />
      {/* <Cut /> */}
      <Price />
      <SearchResult isLoading={stockIsLoading} isError={stockIsError} products={creationProducts} />
    </>
  );
};

export default SetForYouDiamondView;
