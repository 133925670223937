import { useLocation } from 'providers';
import { RefObject, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, H2, H3, Text } from 'ui';
import { getFormattedPrice } from 'utils/priceFormat';
import SaveCreationModal from './SaveCreationModal';
import { useScroll } from 'hooks/useScroll';
import { pushTrackingEvent } from 'utils/trackingUtil';

interface ICreationInfo {
  name: string;
  reference: string;
  imageUrl: string;
  price: number;
  currency: keyof typeof CurrencyCode;
}

const YourCreation = ({
  creation,
  isSummary,
  handleNext,
  containerElement,
  shouldBeFixed,
}: {
  creation: ICreationInfo;
  isSummary?: boolean;
  handleNext?: () => void;
  containerElement: RefObject<HTMLDivElement>;
  shouldBeFixed: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const { translations, localizedPath, location } = useLocation();
  const { scrollY } = useScroll();
  const [fixedPosition, setFixedPosition] = useState(true);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (ref) {
      const offsetTop = (containerElement.current?.offsetTop || 0) + (containerElement.current?.clientHeight || 0);
      const fixedElementHeight = ref.current?.clientHeight || 0;
      const windowHeight = window.innerHeight;

      setOffsetTop(offsetTop + fixedElementHeight - windowHeight);
    }
  }, [
    ref.current?.clientHeight,
    containerElement,
    containerElement.current?.clientHeight,
    containerElement.current?.offsetTop,
  ]);

  useEffect(() => {
    setFixedPosition(shouldBeFixed && scrollY < offsetTop);
  }, [scrollY, offsetTop, shouldBeFixed]);

  const handleBookAnAppointmentClick = () => {
    pushTrackingEvent('esfy_intention_book_appointment');
    window.open(localizedPath?.bookAnAppointment, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container $fixed={fixedPosition} ref={ref}>
      <div className="container py-4">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="d-flex align-items-md-center">
              <ImageContainer className="me-4">
                {creation.imageUrl && <StyledImage src={creation.imageUrl} />}
              </ImageContainer>

              <div className="mb-3 mb-md-0">
                <H3>{translations?.sfySetting_YourCreation || 'Your creation'}</H3>
                <H2>{creation.name}</H2>
                <Text $mono size="s">
                  {translations?.sfySetting_YourUniqueReference || 'Your unique Reference'}: {creation.reference}
                </Text>
                <div className="my-2 d-lg-none">
                  {!location?.language.hidePrice && (
                    <Price
                      price={creation.price}
                      currency={creation.currency}
                      suffix={location?.language.priceSuffix || translations?.sfySetting_IncludesVAT || ''}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8 d-lg-flex align-items-center justify-content-end">
            <div className="mb-4 mb-md-0 me-4 d-none d-lg-block">
              {location?.language.hidePrice ? null : (
                <Price
                  price={creation.price}
                  currency={creation.currency}
                  suffix={location?.language.priceSuffix || translations?.sfySetting_IncludesVAT || ''}
                />
              )}
            </div>
            {isSummary ? (
              <>
                <StyledButton
                  onClick={() => setShowCreationModal(true)}
                  variant="secondary"
                  className="mb-3 mb-lg-0 me-2 w-100 w-lg-auto"
                >
                  {translations?.savemycreation_button_savemycreation || 'Save my creation'}
                </StyledButton>
                <StyledButton className="w-100 w-lg-auto" onClick={() => handleBookAnAppointmentClick()}>
                  {translations?.sfySetting_BookanAppointment || 'Book an appointment'}
                </StyledButton>

                <SaveCreationModal isOpen={showCreationModal} onClose={() => setShowCreationModal(false)} />
              </>
            ) : (
              <>
                <StyledButton
                  onClick={() => handleBookAnAppointmentClick()}
                  className="mb-3 mb-lg-0 me-2 w-100 w-lg-auto"
                >
                  {translations?.sfySetting_BookanAppointment || 'Book an appointment'}
                </StyledButton>
                <StyledButton
                  className="w-100 w-lg-auto"
                  variant="secondary"
                  onClick={() => handleNext && handleNext()}
                >
                  {translations?.sfySetting_RingSummary || 'Ring Summary'}
                </StyledButton>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

const Price = ({ price, currency, suffix }: { price: number; currency: keyof typeof CurrencyCode; suffix: string }) => (
  <div className="d-flex">
    <Text $monoMedium className="me-2">
      {getFormattedPrice(price, currency)}
    </Text>
    <Text color="lighter">{suffix}</Text>
  </div>
);

const Container = styled.div<{ $fixed: boolean }>`
  position: ${({ $fixed }) => ($fixed ? 'fixed' : 'relative')};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;

  ${({ $fixed }) =>
    $fixed &&
    css`
      border-top: 1px solid #e6e6e6;
    `}
`;

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${({ theme }) => theme.colors.gray_light};
  flex-shrink: 0;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const StyledImage = styled.img`
  border: none;
  outline: none;
`;

export default YourCreation;
