import styled from 'styled-components';
import { Text, H2 } from 'ui';
import { CollectionBox } from 'components';
import { useLocation } from 'providers';
import pageNotFoundImg from 'media/images/404/404.png';
import engagementRingsImg from 'media/images/collections/engagement_rings.jpg';
import weddingRingsImg from 'media/images/collections/wedding_rings.jpg';
import { useEffect } from 'react';
import { useHeaderConfig } from 'providers/PageHeaderProvider';

const PageNotFoundView = () => {
  const { translations, location } = useLocation();
  const { headerConfig, updateHeaderConfig } = useHeaderConfig();

  useEffect(() => {
    updateHeaderConfig &&
      updateHeaderConfig({
        ...headerConfig,
        transparent: false,
        hide: false,
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <PageNotFoundContainer className="row" style={{}}>
        <div className="col-12 text-center">
          <img className="d-block mx-auto mt-5" src={pageNotFoundImg} alt="404" />
          <H2 className="mb-3">
            {translations?.pageNotFound_sorrySomethingWentWrong || 'Sorry, the page was not found'}
          </H2>
          <Text>
            {translations?.pageNotFound_sorrySomethingWentWrong_Text ||
              'The page you are looking for might have been removed, or is temporarily unavailable.'}
          </Text>
        </div>
      </PageNotFoundContainer>
      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          <CollectionBox
            cardContent={{
              title: translations?.collection_engagemantRings || 'Engagement rings',
              description: '',
              imageUrl: engagementRingsImg,
              targetPath: `${location?.language.locationLanguageCode}/matching-bands`,
            }}
          />
        </div>
        <div className="col-12 col-md-6 mb-5">
          <CollectionBox
            cardContent={{
              title: translations?.collection_weddingRings || 'Wedding rings',
              description: '',
              imageUrl: weddingRingsImg,
              targetPath: `${location?.language.locationLanguageCode}/collections/engagement-rings`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const PageNotFoundContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 100px;
`;

export default PageNotFoundView;
