export const translationLabels: Translation[] = [
  {
    key: 'header_visitCartier',
    'en-gb': 'Visit cartier.com',
    'en-us': 'Visit cartier.com',
    fr: 'Découvrez cartier.com',
    de: 'Besuchen Sie cartier.com',
    pt: 'Visitar cartier.com',
    nl: 'Bezoek cartier.com',
    es: 'Visita cartier.com',
    it: 'Visita cartier.com',
    zh: '浏览cartier.com',
    ko: 'cartier.com 방문하기',
    'zh-tw': '瀏覽cartier.com',
    'zh-hk': '瀏覽 cartier.hk',
    th: 'เยี่ยมชมเว็บไซต์ cartier.com',
  },
  {
    key: 'header_footer_contactUs',
    'en-gb': 'Contact us',
    'en-us': 'Contact Us',
    fr: 'Nous contacter',
    de: 'Kontaktieren Sie uns',
    pt: 'Contactar-nos',
    nl: 'Contacteer ons',
    es: 'Contacta con nosotros',
    it: 'Contattaci',
    zh: '关注我们',
    ko: '문의하기',
    'zh-tw': '聯絡卡地亞',
    'zh-hk': '聯繫卡地亞',
    th: 'ติดต่อเรา',
  },
  {
    key: 'header_boutiques',
    'en-gb': 'Boutiques',
    'en-us': 'Boutiques',
    fr: 'Boutiques',
    de: 'Boutiquen',
    pt: 'Butiques',
    nl: 'Boutiques',
    es: 'Boutiques',
    it: 'Boutiques',
    zh: '精品店',
    ko: '부티크',
    'zh-tw': '專賣店',
    'zh-hk': '精品店',
    th: 'บูติค',
  },
  {
    key: 'menu_collection',
    'en-gb': 'Collections',
    'en-us': 'Collections',
    fr: 'Collections',
    de: 'Kollektionen',
    pt: 'Coleções',
    nl: 'Collecties',
    es: 'Colecciones',
    it: 'Collezioni',
    zh: '系列',
    ko: '컬렉션',
    'zh-tw': '作品系列',
    'zh-hk': '作品系列',
    th: 'คอลเลคชั่น',
  },
  {
    key: 'menu_matchingBands',
    'en-gb': 'Matching bands',
    'en-us': 'Matching Bands',
    fr: 'Alliances assorties',
    de: 'Passende Eheringe',
    pt: 'Conjuntos de anéis',
    nl: 'Bijpassende ringen',
    es: 'Conjunto de anillos',
    it: 'Fedi abbinate',
    zh: '对戒',
    ko: '어울리는 밴드',
    'zh-tw': '搭配戒指',
    'zh-hk': '搭配戒指',
    th: 'แหวนคู่',
  },
  {
    key: 'menu_personalisation',
    'en-gb': 'Set for you',
    'en-us': 'Set for you',
    fr: 'Set for you',
    de: 'Set for you',
    pt: 'Set for you',
    nl: 'Set for you',
    es: 'Set for you',
    it: 'Set for you',
    zh: '个性化服务',
    ko: '퍼스널라이징 서비스',
    'zh-tw': '個人化訂製',
    'zh-hk': '個人化訂製',
    th: 'รังสรรค์ชิ้นงานในแบบของคุณ',
  },
  {
    key: 'footer_findABoutiqe',
    'en-gb': 'Find a boutique',
    'en-us': 'Find a Boutique',
    fr: 'Trouver une boutique',
    de: 'Boutique finden',
    pt: 'Encontrar uma butique',
    nl: 'Een boutique zoeken',
    es: 'Buscar una boutique',
    it: 'Trova una boutique',
    zh: '查找精品店',
    ko: '부티크 찾기',
    'zh-tw': '尋找專賣店',
    'zh-hk': '搜尋精品店',
    th: 'ค้นหาบูติค',
  },
  {
    key: 'footer_service',
    'en-gb': 'Services',
    'en-us': 'Services',
    fr: 'Service',
    de: 'Service',
    pt: 'Serviço',
    nl: 'Service',
    es: 'Servicio',
    it: 'Il servizio',
    zh: '服务',
    ko: '서비스',
    'zh-tw': '服務',
    'zh-hk': '服務',
    th: 'บริการ',
  },
  {
    key: 'footer_callAnAmbassador',
    'en-gb': 'Call an ambassador',
    'en-us': 'Call an Ambassador',
    fr: 'Contacter un ambassadeur',
    de: 'Eine:n Kundenberater:in anrufen',
    pt: 'Contactar um embaixador',
    nl: 'Bel een ambassadeur',
    es: 'Llamar a un embajador',
    it: 'Contatta un ambasciatore Cartier',
    zh: '联系卡地亚服务大使',
    ko: '상담원에게 문의하기',
    'zh-tw': '致電卡地亞顧問',
    'zh-hk': '致電卡地亞顧問',
    th: 'โทรติดต่อแอมบาสเดอร์',
  },
  {
    key: 'footer_customerCare',
    'en-gb': 'Customer care',
    'en-us': 'Customer Care',
    fr: 'Service client',
    de: 'Kundenservice',
    pt: 'Apoio ao cliente',
    nl: 'Klantenservice',
    es: 'Atención al cliente',
    it: 'Assistenza clienti',
    zh: '顾客关怀',
    ko: '고객 서비스',
    'zh-tw': '客戶服務',
    'zh-hk': '客戶服務',
    th: 'ศูนย์ลูกค้าสัมพันธ์',
  },
  {
    key: 'footer_callNow',
    'en-gb': 'Call now',
    'en-us': 'Call now',
    fr: 'Nous appeler',
    de: 'Jetzt anrufen',
    pt: 'Contactar agora',
    nl: 'Bel nu',
    es: 'Llamar ahora',
    it: 'Chiama ora',
    zh: '即刻致电',
    ko: '지금 전화하기',
    'zh-tw': '立即致電',
    'zh-hk': '立即致電',
    th: 'โทร',
  },
  {
    key: 'footer_shoppingOnline',
    'en-gb': 'Shopping online',
    'en-us': 'FAQ',
    fr: 'Vente en ligne',
    de: 'Online einkaufen',
    pt: 'Comprar online',
    nl: 'Online winkelen',
    es: 'Comprar online',
    it: 'Acquisti online',
    zh: '网上订购',
    ko: '온라인 쇼핑',
    'zh-tw': '網上購物',
    'zh-hk': '網上購物',
    th: 'ซื้อสินค้าออนไลน์',
  },
  {
    key: 'footer_help',
    'en-gb': 'Help',
    'en-us': 'Help',
    fr: 'Aide',
    de: 'Hilfe',
    pt: 'Ajuda',
    nl: 'Help',
    es: 'Ayuda',
    it: 'Help',
    zh: '帮助',
    ko: '도움말',
    'zh-tw': '幫助',
    'zh-hk': '幫助',
    th: 'ความช่วยเหลือ',
  },
  {
    key: 'footer_ourComapny',
    'en-gb': 'Our Company',
    'en-us': 'Our Company',
    fr: 'La Maison Cartier',
    de: 'Unser Unternehmen',
    pt: 'A nossa empresa',
    nl: 'Ons bedrijf',
    es: 'Nuestra empresa',
    it: 'La nostra azienda',
    zh: '帮助',
    ko: '까르띠에 소개',
    'zh-tw': '卡地亞',
    'zh-hk': '卡地亞',
    th: 'บริษัทของเรา',
  },
  {
    key: 'footer_legalPrivacy',
    'en-gb': 'Legal & Privacy',
    'en-us': 'Legal & Privacy',
    fr: 'Informations légales & Confidentialité',
    de: 'Rechtliches und Datenschutz',
    pt: 'Avisos legais e privacidade',
    nl: 'Juridisch en Privacy',
    es: 'Textos legales y privacidad',
    it: 'Informazioni legali e privacy',
    zh: '法律和隐私',
    ko: '법적 고지 & 개인정보보호',
    'zh-tw': '法律及隱私',
    'zh-hk': '法律及私隱',
    th: 'กฎหมายและนโยบายความเป็นส่วนตัว',
  },
  {
    key: 'footer_privacyPolicy',
    'en-gb': 'Privacy policy',
    'en-us': 'Privacy Policy',
    fr: 'Politique de confidentialité',
    de: 'Datenschutzbestimmungen',
    pt: 'Política de privacidade',
    nl: 'Privacybeleid',
    es: 'Política de privacidad',
    it: 'Informativa sulla privacy',
    zh: '隐私政策',
    ko: '개인정보보호정책',
    'zh-tw': '隱私政策',
    'zh-hk': '私隱政策',
    th: 'นโยบายความเป็นส่วนตัว',
  },
  {
    key: 'footer_cookiePolicy',
    'en-gb': 'Cookie policy',
    'en-us': 'Cookie Policy',
    fr: 'Politique de gestion des cookies',
    de: 'Cookie-Richtlinie',
    pt: 'Política de cookies',
    nl: 'Cookiebeleid',
    es: 'Política de cookies',
    it: 'Informativa sui cookie',
    zh: 'Cookie政策',
    ko: '쿠키 정책',
    'zh-tw': 'Cookie政策',
    'zh-hk': 'Cookie 政策',
    th: 'นโยบายคุกกี้',
  },
  {
    key: 'footer_connectWithUs',
    'en-gb': 'Connect with us',
    'en-us': 'Follow us',
    fr: 'Se connecter',
    de: 'In Kontakt treten',
    pt: 'Siga-nos nas redes sociais',
    nl: 'Volg ons',
    es: 'Conecta con nosotros',
    it: 'Contattaci',
    zh: '联系我们',
    ko: '까르띠에 소식',
    'zh-tw': '聯繫我們',
    'zh-hk': '聯繫我們',
    th: 'ติดต่อเรา',
  },
  {
    key: 'footer_shopIn',
    'en-gb': 'Shop in',
    'en-us': 'Shop in',
    fr: 'Acheter en',
    de: 'Einkaufen in',
    pt: 'Comprar em',
    nl: 'Winkelen in',
    es: 'Compra en',
    it: 'Acquista in',
    zh: '语言',
    ko: '쇼핑하기',
    'zh-tw': '語言',
    'zh-hk': '語言',
    th: 'เลือกซื้อสินค้าที่:',
  },
  {
    key: 'footer_changeLangugae',
    'en-gb': 'Change language',
    'en-us': 'Change Language',
    fr: 'Changer de langue',
    de: 'Sprache ändern',
    pt: 'Alterar idioma',
    nl: 'Taal wijzigen',
    es: 'Cambiar idioma',
    it: 'Cambia lingua',
    zh: '更改语言',
    ko: '언어 변경',
    'zh-tw': '更改語言',
    'zh-hk': '更改語言',
    th: 'เปลี่ยนภาษา',
  },
  {
    key: 'footer_browseOtherCreationAtcarier',
    'en-gb': 'Browse other creations at',
    'en-us': 'Browse other creations at',
    fr: 'Découvrez plus de créations sur',
    de: 'Entdecken Sie weitere Kreationen auf',
    pt: 'Pesquisar outras criações em',
    nl: 'Bekijk andere creaties op',
    es: 'Descubre otras creaciones en',
    it: 'Scopri altre creazioni su',
    zh: '前往官网，探索更多作品',
    ko: '다른 제품 살펴보기',
    'zh-tw': '瀏覽其他作品：',
    'zh-hk': '瀏覽其他作品：',
    th: 'ชมชิ้นงานรังสรรค์อื่นๆ ที่',
  },
  {
    key: 'language_chooseYourLocation',
    'en-gb': 'Choose your location',
    'en-us': 'Choose Your Location',
    fr: 'Choisir votre emplacement',
    de: 'Wählen Sie Ihren Standort',
    pt: 'Escolher a localização',
    nl: 'Kies uw locatie',
    es: 'Selecciona tu ubicación',
    it: 'Scegli la tua posizione',
    zh: '选择您的地点',
    ko: '고객님의 위치 선택하기',
    'zh-tw': '選擇您的位置',
    'zh-hk': '選擇您的位置',
    th: 'เลือกตำแหน่งที่ตั้งของคุณ',
  },
  {
    key: 'firstpage_cartierdiamonds',
    'en-gb': 'Cartier Diamonds',
    'en-us': 'Cartier Diamonds',
    fr: 'Les diamants Cartier',
    de: 'Diamanten von Cartier',
    pt: 'Diamantes Cartier',
    nl: 'Cartier Diamanten',
    es: 'Diamantes Cartier',
    it: 'Diamanti Cartier',
    zh: '卡地亚钻石',
    ko: '까르띠에 다이아몬드',
    'zh-tw': '卡地亞鑽石',
    'zh-hk': '卡地亞鑽石',
    th: 'เพชรของคาร์เทียร์',
  },
  {
    key: 'firstpage_cartierDiamonds_Text',
    'en-gb':
      'For Cartier, love is a life-long adventure, an adventure that continues every day. To celebrate it, the Maison invites you to choose your diamond and setting to create a unique ring, to reflect your love, through the Set For You by Cartier service.',
    'en-us':
      'For Cartier, love is a life-long adventure, an adventure that continues every day. To celebrate it, the Maison invites you to choose your diamond and setting to create a unique ring, to reflect your love, through the Set For You by Cartier service.',
    fr: "Chez Cartier, l'amour est l’aventure de toute une vie, une aventure qui s’écrit au quotidien. Pour le célébrer, la Maison vous invite à choisir votre diamant et votre monture pour créer une bague unique, à votre image, grâce au service Set For You by Cartier.",
    de: 'Für Cartier ist Liebe ein lebenslanges Abenteuer, ein Abenteuer, das jeden Tag weitergeht. Um sie zu feiern, lädt die Maison Sie mit ihrem Set For You by Cartier Service dazu ein, Ihren Diamanten und Ihre Fassung auszuwählen, um einen einzigartigen Ring zu kreieren, der Ihre Liebe widerspiegelt.',
    pt: 'Para a Cartier, o amor é uma aventura para toda a vida, uma aventura que continua todos os dias. Para o celebrar, a Maison convida-o(a) a escolher o seu diamante e engaste para criar um anel único que reflita o seu amor, através do serviço Set For You by Cartier.',
    nl: 'Voor Cartier is de liefde een levenslang avontuur, een avontuur dat iedere dag opnieuw begint. Om dit te vieren, nodigt het Maison u uit om zelf een diamant en een zetting te kiezen. Met deze Set For You by Cartier-service kunt u een unieke ring creëren die uw liefde weerspiegelt.',
    es: 'Para Cartier, el amor es una aventura para toda la vida, una aventura que vivimos día tras día. Para celebrarlo, la Maison te invita a elegir tu diamante y su engaste para crear un anillo único que refleje tu amor a través del servicio Cartier Set For You.',
    it: 'Per Cartier, l’amore è l’avventura di una vita, una promessa che si rinnova ogni giorno. Scegli un diamante e una montatura, creando così un anello unico che celebri la bellezza del tuo amore, grazie al servizio Set For You by Cartier.',
    zh: '对于卡地亚而言，爱是一生相守的誓约，凝聚于朝夕相处的珍贵瞬间。诚邀您探索Set For You钻石尊享定制服务，选择心仪的戒托设计和中央钻石，打造一枚专属于您的挚爱见证。',
    ko: "까르띠에에게 사랑이란, 매일 펼쳐지는 일생의 모험입니다. 당신의 아름다운 모험을 기릴 수 있도록 메종은 'Set For You by Cartier' 서비스를 제안합니다. 다이아몬드와 세팅을 선택하고, 사랑을 표현하는 나만의 특별한 링을 만들어 보세요.",
    'zh-tw':
      '對卡地亞而言，愛情堪稱終其一生的冒險之旅，寫下日常的每個時刻。為頌揚永恆真愛，卡地亞誠摯邀請您透過Set For You鑽戒訂製服務挑選心儀的鑽石和鑲嵌方式，製作一款象徵你倆愛情的獨特戒指。',
    'zh-hk':
      '對卡地亞而言，愛是一場人生旅程，也是在每天生活中延續的旅程。為頌揚永恆真愛，卡地亞誠邀您透過 Set For You 服務選擇心儀的鑽石和鑲嵌方式，製作一款象徵您的愛情的獨特戒指。',
    th: 'สำหรับคาร์เทียร์ ความรักคือการผจญภัยในทุกๆ วันตลอดช่วงชีวิต เพื่อเฉลิมฉลองให้กับความรัก เมซงขอเชิญชวนให้คุณเลือกสรรเพชรและรูปแบบการฝังอัญมณีเพื่อรังสรรค์แหวนที่มีเอกลักษณ์เฉพาะที่สะท้อนความรักของคุณผ่านบริการ Set For You โดยคาร์เทียร์',
  },
  {
    key: 'firstpage_beginYourJourney',
    'en-gb': 'Begin your journey',
    'en-us': 'Begin your journey',
    fr: "Commencez l'expérience",
    de: 'Beginnen Sie Ihre Reise',
    pt: 'Inicie a sua jornada',
    nl: 'Begin uw reis',
    es: 'Comienza tu viaje',
    it: 'Inizia il tuo viaggio',
    zh: '开启旅程',
    ko: '여정 시작하기',
    'zh-tw': '展開旅程',
    'zh-hk': '展開旅程',
    th: 'รังสรรค์เครื่องประดับ',
  },
  {
    key: 'firstpage_theArtOfGivingDiamond',
    'en-gb': 'Creating the Perfect Ring',
    'en-us': 'Creating the Perfect Ring',
    fr: 'Créer votre bague unique',
    de: 'Den perfekten Ring kreieren',
    pt: 'Criar o anel perfeito',
    nl: 'De perfecte ring creëren',
    es: 'Creando el anillo perfecto',
    it: 'Crea l’anello perfetto',
    zh: '打造您心仪的钻戒',
    ko: '완벽한 링의 탄생',
    'zh-tw': '製作完美戒指',
    'zh-hk': '製作完美戒指',
    th: 'การรังสรรค์แหวนที่สมบูรณ์แบบ',
  },
  {
    key: 'firstpage_engrave',
    'en-gb': 'Engraving',
    'en-us': 'Engraving',
    fr: 'Gravure',
    de: 'Gravur',
    pt: 'Gravação',
    nl: 'Gravure',
    es: 'Grabado',
    it: 'Incisione',
    zh: '镌刻服务',
    ko: '인그레이빙',
    'zh-tw': '鐫刻',
    'zh-hk': '鐫刻',
    th: 'การแกะสลัก',
  },
  {
    key: 'firstpage_engrave_Text',
    'en-gb': 'Personalise your Cartier creation with complimentary engraving services, for an eternally precious gift.',
    'en-us':
      'Personalize your design with our complimentary engraving services. From a romantic declaration to a secret message, their name, initials, or a memorable date: Inscribe your next love note that will live forever.',
    fr: 'Personnalisez votre création Cartier grâce à notre service de gravure offert.',
    de: 'Verleihen Sie Ihrer Cartier Kreation mit einer kostenlosen Gravur eine persönliche Note und machen Sie sie zu einem einzigartigen Geschenk für die Ewigkeit.',
    pt: 'Personalize a sua criação Cartier com os nossos serviços de gravação gratuitos, para um presente eternamente valioso.',
    nl: 'Personaliseer uw Cartier creatie met onze gratis graveerservice, voor een eeuwig kostbaar geschenk.',
    es: 'Personaliza tu creación Cartier con servicios de grabado gratuitos para un regalo eternamente valioso.',
    it: 'Personalizza la tua creazione Cartier con un servizio di incisione gratuita, per un regalo indimenticabile.',
    zh: '尊享免费镌刻服务，个性化您的卡地亚作品，\n打造亘久珍贵的隽永挚礼。',
    ko: '무료 인그레이빙 서비스를 통해 까르띠에 제품을 퍼스널라이징하세요. 영원히 간직할 수 있는 소중한 선물이 완성됩니다.',
    'zh-tw': '透過免費鐫刻服務，個人化訂製您的卡地亞作品，製作出永恆的珍貴禮物。',
    'zh-hk': '透過免費鐫刻服務，個人化訂製您的卡地亞作品，製作出永恆的珍貴禮物。',
    th: 'รังสรรค์ชิ้นงานคาร์เทียร์ของคุณด้วยบริการแกะสลัก เพื่อเป็นของขวัญอันล้ำค่าชั่วนิรันดร์',
  },
  {
    key: 'firstpage_emboss',
    'en-gb': 'Embossing',
    'en-us': 'Embossing',
    fr: 'Embossage',
    de: 'Prägung',
    pt: 'Gravação em relevo',
    nl: 'Reliëfwerk',
    es: 'Grabado en relieve',
    it: 'Goffratura',
    zh: '红盒刻字服务',
    ko: '엠보싱',
    'zh-tw': '燙印',
    'zh-hk': '壓印',
    th: 'การประทับนูน',
  },
  {
    key: 'firstpage_emboss_Text',
    'en-gb': "Add an embossed monogram to your ring's presentation box.",
    'en-us': 'Personalize the iconic red box with their initials with our complimentary embossing services.',
    fr: 'Personnaliser votre écrin Cartier.',
    de: 'Fügen Sie dem Cartier Écrin Ihres Rings ein geprägtes Monogramm hinzu.',
    pt: 'Adicione um monograma em relevo na caixa de apresentação do seu anel.',
    nl: 'Voeg een monogram in reliëf toe aan het doosje van uw ring.',
    es: 'Añade un monograma grabado en relieve al estuche de presentación de tu anillo.',
    it: 'Aggiungi un monogramma in rilievo sullo scrigno che racchiude il tuo anello.',
    zh: '在红盒上添加刻印字母图案。',
    ko: '링의 기프트 박스에 엠보싱 처리된 모노그램을 추가해 보세요.',
    'zh-tw': '為您的戒指珠寶盒加上燙印文字。',
    'zh-hk': '為您的戒指珠寶盒加上壓印文字。',
    th: 'ประทับนูนอักษรย่อลงบนกล่องแหวนของคุณ',
  },
  {
    key: 'firstpage_cartierExpertise',
    'en-gb': 'What Makes A Cartier Diamond',
    'en-us': 'What Makes A Cartier Diamond',
    fr: 'Comment se distinguent les diamants Cartier ?',
    de: 'Was macht einen Diamanten von Cartier aus?',
    pt: 'Descubra os diamantes Cartier',
    nl: 'Wat maakt een Cartier Diamant?',
    es: 'En qué se distingue un diamante Cartier',
    it: 'L’eccezionalità dei diamanti Cartier',
    zh: '是什么造就了卡地亚钻石？',
    ko: '까르띠에 다이아몬드가 되기까지',
    'zh-tw': '成就卡地亞鑽石的重要元素',
    'zh-hk': '成就卡地亞鑽石的重要元素',
    th: 'การรังสรรค์เพชรของคาร์เทียร์',
  },
  {
    key: 'firstpage_cartierExpertise_Text',
    'en-gb':
      'All centre stone diamonds in Cartier rings are certified by the Gemological Institute of America, based on the 4Cs: carat, colour, clarity and cut. Together with the eye and experience of the Maison’s diamond experts, they guarantee that every Cartier diamond creation is singular, with an incomparable charm and brilliance.',
    'en-us':
      'All center stone diamonds in Cartier rings are certified by the Gemological Institute of America, based on the 4Cs: carat, color, clarity and cut. Together with the eye and experience of the Maison’s diamond experts, they ensure every Cartier diamond is unique and with incomparable charm and brilliance.',
    fr: "Toutes les pierres de centre des bagues Cartier sont certifiées par le Gemological Institute of America, selon les critères des 4C : carat, couleur, pureté et taille. Complété par \"l'œil\" et l'expérience des experts diamants de la Maison, ils garantissent que chaque création en diamants Cartier est singulière, dotée d'un charme et d'un éclat unique.",
    de: 'Alle Zentraldiamanten der Cartier Ringe sind vom Gemological Institute of America zertifiziert, basierend auf den 4 Cs: Carat (Karat), Colour (Farbe), Clarity (Reinheit) und Cut (Schliff). Zusammen mit dem Auge und der Erfahrung der Expert:innen der Maison garantieren sie, dass jede Diamantenkreation von Cartier einzigartig ist und einen unvergleichlichen Charme und Glanz besitzt.',
    pt: 'Todos os diamantes centrais nos anéis Cartier são certificados pelo Gemological Institute of America com base nos 4 C: quilate (carat), cor, claridade e corte. Juntamente com a experiência dos especialistas em diamantes da Maison, garantem a exclusividade de cada criação em diamantes Cartier, com um encanto e brilho incomparáveis.',
    nl: "Alle centrale stenen in de ringen van Cartier zijn gecertificeerd door het Gemological Institute of America, op basis van de 4 C's: carat, colour, clarity en cut. Samen met het deskundig oog en de ervaring van de diamantdeskundigen van het Maison, garanderen zij dat elke diamantcreatie van Cartier uniek is, met een ongeëvenaarde charme en schittering.",
    es: 'Todos los diamantes centrales de los anillos Cartier están certificados por el Instituto gemológico de América (Gemological Institute of America o GIA), basándose en las 4 C: quilate (carat), color (colour), claridad (clarity) y talla (cut). Junto con los ojos y la experiencia de los expertos en diamantes de la Maison, estos valores garantizan que cada creación de diamantes Cartier sea única e irradie un encanto y un brillo incomparables.',
    it: 'Tutti i diamanti Cartier sono certificati GIA (Gemological Institute of America). Tale certificazione valuta la qualità dei diamanti sulla base di 4 rigorosi criteri, noti come 4 C: "carat", "colour", "clarity" e "cut" (caratura, colore, chiarezza e taglio). Insieme all’occhio e all’esperienza degli esperti della Maison, questi criteri garantiscono l’unicità, il fascino e l’ineguagliabile bellezza di ogni diamante Cartier.',
    zh: '卡地亚悉心甄选的中央主钻，符合美国宝石学院（GIA）认证的4C标准：“切工”、“颜色”、“净度”和“克拉”。 运用精准技艺，凭借独到眼光，卡地亚专家确保每颗钻石均绽放卡地亚的非凡魅力，打造独具个性的钻石作品。',
    ko: "까르띠에 링에 사용된 모든 센터 스톤 다이아몬드는 ‘4C(캐럿, 컬러, 투명도, 컷)'를 기반으로 미국 보석 감정위원회(GIA, Gemological Institute of America)에서 인증합니다. 메종 다이아몬드 전문가의 숙련된 눈과 경험을 바탕으로 완성한 모든 까르띠에 다이아몬드 크리에이션은 비할 데 없는 매력과 광채를 지닌 특별한 작품입니다.",
    'zh-tw':
      '所有卡地亞戒指的中央主鑽均獲美國寶石學院（Gemmological Institute of America）根據「4C」標準認證，即克拉（carat）、顏色（colour）、淨度（clarity）和切工（cut）。此外，我們選用的鑽石亦經過卡地亞鑽石專家以獨到眼光和豐富經驗精挑細選，確保所有卡地亞鑽石作品均獨一無二，綻放無可比擬的魅力和光芒。',
    'zh-hk':
      '所有卡地亞戒指的中央主鑽均獲美國寶石學院（Gemmological Institute of America）根據「4C」標準認證，即克拉（carat）、顏色（colour）、淨度（clarity）和切工（cut）。此外，我們選用的鑽石亦經過卡地亞鑽石專家以獨到眼光和豐富經驗精挑細選，確保所有卡地亞鑽石作品均獨一無二，綻放無可比擬的魅力和光芒。',
    th: 'เพชรเม็ดกลางทั้งหมดบนแหวนของคาร์เทียร์ผ่านการรับรองโดยสถาบันอัญมณีศาสตร์ของสหรัฐอเมริกา โดยอ้างอิงจากหลัก 4C ซึ่งประกอบด้วย กะรัต (Carat) สี (Colour) ความใสบริสุทธิ์ (Clarity) และการเจียระไน (Cut) ด้วยสายตาอันเฉียบแหลมและประสบการณ์ของผู้เชี่ยวชาญด้านเพชรของเมซง พวกเขารับประกันว่าชิ้นงานเพชรทั้งหมดของคาร์เทียร์นั้นมีความพิเศษเฉพาะตัว พร้อมด้วยเสน่ห์เฉพาะตัวและความแวววาวเหนือระดับ',
  },
  {
    key: 'fourC_carat',
    'en-gb': 'Carat',
    'en-us': 'Carat',
    fr: 'Carat',
    de: 'Karat',
    pt: 'Quilate',
    nl: 'Carat',
    es: 'Quilate',
    it: 'Caratura',
    zh: '克拉（Carat）',
    ko: '캐럿',
    'zh-tw': '克拉（Carat）',
    'zh-hk': '克拉（Carat）',
    th: 'กะรัต (Carat)',
  },
  {
    key: 'fourC_carat_Text',
    'en-gb':
      'The measure of a diamond’s weight. Each carat is equal to 0.2 grams. In the hands of the Maison’s experts, a diamond of any weight and size becomes sublime.',
    'en-us':
      'The unit of measurement of diamond weight is the carat. One carat is equivalent to 0.20 grams. In the hands of the Maison’s experts, a diamond of any weight and size becomes sublime.',
    fr: 'La mesure du poids du diamant. Chaque carat est égal à 0,2 gramme. Entre les mains des experts de la Maison, tout diamant, quel que soient son poids et sa taille, devient sublime.',
    de: 'Das Maß für das Gewicht eines Diamanten. Jedes Karat entspricht 0,2 Gramm. In den Händen der Expert:innen der Maison wird ein Diamant von beliebigem Gewicht und beliebiger Größe zu etwas Erhabenem.',
    pt: 'A medida do peso de um diamante. Cada quilate equivale a 0,2 g. Nas mãos dos especialistas da Maison, um diamante de qualquer peso e tamanho torna-se sublime.',
    nl: 'De eenheid voor het gewicht van een diamant. Elke karaat is gelijk aan 0,2 gram. In de handen van de deskundigen van het Maison wordt een diamant van elk gewicht en grootte subliem.',
    es: 'Es la medida que indica el peso de un diamante. Cada quilate equivale a 0,2 gramos. Las manos de los expertos de la Maison convierten un diamante de cualquier peso y tamaño en una creación sublime.',
    it: 'La caratura misura il peso di un diamante. Ogni carato equivale a 0,2 grammi. Nelle mani degli esperti della Maison, un diamante di qualsiasi peso e dimensione diventa eccezionale.',
    zh: '用以衡量钻石的重量。1克拉相当于0.2克。任何大小的钻石经卡地亚工匠大师之手，皆能绽放璀璨光华。',
    ko: '다이아몬드 무게를 측정합니다. 1캐럿은 0.2g에 해당합니다. 메종 전문가의 손을 거치면 어떤 중량이나 사이즈의 다이아몬드도 고귀함을 얻게 됩니다.',
    'zh-tw': '鑽石的重量單位。1克拉等於0.2克。卡地亞專家的匠心巧手令不同重量和大小的鑽石更顯高貴非凡。',
    'zh-hk': '鑽石的重量單位。1克拉等於0.2克。卡地亞專家的匠心巧手令不同重量和大小的鑽石更顯高貴非凡。',
    th: 'ค่าวัดน้ำหนักของเพชร เพชรแต่ละกะรัตมีน้ำหนักเท่ากับ 0.2 กรัม ด้วยทักษะพิเศษของช่างอัญมณีผู้เชี่ยวชาญจากเมซง เพชรเม็ดงามทุกขนาดจึงสามารถนำมารังสรรค์เป็นชิ้นงานอันล้ำค่า',
  },
  {
    key: 'fourC_clarity',
    'en-gb': 'Clarity',
    'en-us': 'Clarity',
    fr: 'Pureté',
    de: 'Reinheit',
    pt: 'Claridade',
    nl: 'Clarity',
    es: 'Claridad',
    it: 'Chiarezza',
    zh: '净度（Clarity）',
    ko: '투명도',
    'zh-tw': '淨度（Clarity）',
    'zh-hk': '淨度（Clarity）',
    th: 'ความใสบริสุทธิ์ (Clarity)',
  },
  {
    key: 'fourC_clarity_Text',
    'en-gb':
      'The marker of a diamond’s purity. Natural imperfections called ‘inclusions’ affect the quality of a diamond. The Maison chooses only centre stones with zero inclusions visible to the naked eye.',
    'en-us':
      'This is a marker of a diamond’s purity. Inclusions and blemishes are natural characteristics in a diamond. The clarity of a diamond is determined by the size, number, position, relief and type of characteristics.',
    fr: 'Indique la pureté du diamant. Des imperfections naturelles, appelées inclusions, peuvent altérer la qualité du diamant. La Maison ne choisit que des pierres de centre sans la moindre inclusion visible à l’œil nu.',
    de: 'Der Marker für die Reinheit eines Diamanten. Natürliche Unvollkommenheiten, sogenannte „Einschlüsse“, beeinträchtigen die Qualität eines Diamanten. Die Maison wählt nur Zentralsteine aus, die keine mit bloßem Auge sichtbaren Einschlüsse aufweisen.',
    pt: 'O indicador da pureza de um diamante. As imperfeições naturais chamadas “inclusões” afetam a qualidade de um diamante. A Maison escolhe apenas pedras centrais sem imperfeições visíveis a olho nu.',
    nl: 'De aanduiding voor de zuiverheid van een diamant. Natuurlijke onvolkomenheden, "insluitsels" genaamd, beïnvloeden de kwaliteit van een diamant. Het Maison selecteert enkel hoofdstenen met nul insluitsels zichtbaar meer het blote oog.',
    es: 'Es el indicador de la pureza de un diamante. Las imperfecciones naturales llamadas “inclusiones” afectan a la calidad de un diamante. La Maison solo elige piedras centrales sin ninguna inclusión apreciable a simple vista.',
    it: 'È l’indicatore della purezza di un diamante. Le imperfezioni naturali, chiamate “inclusioni”, influiscono sulla qualità di un diamante. La Maison sceglie solo diamanti centrali privi di inclusioni visibili a occhio nudo.',
    zh: '用以衡量钻石的纯净度。内含物是钻石内部的天然瑕疵，会影响钻石的品质。卡地亚仅选用没有肉眼可见内含物的中央主钻。',
    ko: '다이아몬드 순수함의 척도입니다. ‘내포물’이라 불리는 천연 불순물은 다이아몬드 품질에 영향을 미칩니다. 메종은 육안으로 내포물이 전혀 보이지 않는 센터 스톤만을 선택합니다.',
    'zh-tw':
      '用以判別鑽石是否純淨。「內含物」為天然瑕疵，會影響到鑽石的品質。卡地亞僅選擇不能以肉眼看到內含物的鑽石作為中央主石。',
    'zh-hk':
      '用以判別鑽石是否純淨。「內含物」為天然瑕疵，會影響到鑽石的品質。卡地亞僅選擇不能以肉眼看到內含物的鑽石作為中央主石。',
    th: 'ความบริสุทธิ์อันโดดเด่นของเพชร ความไม่สมบูรณ์ตามธรรมชาติที่เรียกว่า ‘ตำหนิ’ ส่งผลต่อคุณภาพของเพชร เมซงเลือกใช้เพชรเม็ดกลางที่ปราศจากตำหนิใดๆ นิยามแห่งความบริสุทธิ์ซึ่งมองเห็นได้ด้วยตาเปล่า',
  },
  {
    key: 'fourC_colour',
    'en-gb': 'Colour',
    'en-us': 'Color',
    fr: 'Couleur',
    de: 'Farbe',
    pt: 'Cor',
    nl: 'Colour',
    es: 'Color',
    it: 'Colore',
    zh: '颜色（Colour）',
    ko: '컬러',
    'zh-tw': '顏色（Colour）',
    'zh-hk': '顏色（Colour）',
    th: 'สี (Colour)',
  },
  {
    key: 'fourC_colour_Text',
    'en-gb':
      'The colour of a diamond instantly affects how we perceive it. The most elusive, and the most valuable, are colourless.',
    'en-us':
      'The color of a diamond affects instantly how we perceive it. The closer a white diamond is to colorless, the more exceptional the stone.',
    fr: "La couleur d'un diamant est la première chose que nous percevons. \nPlus un diamant est proche de l’incolore, plus il est exceptionnel.",
    de: 'Die Farbe eines Diamanten beeinflusst umgehend, wie wir ihn wahrnehmen. Die wertvollsten Diamanten sind farblos.',
    pt: 'A cor de um diamante afeta instantaneamente a forma como o percecionamos. Os mais elusivos, e mais valiosos, não têm cor.',
    nl: 'De kleur van een diamant beïnvloedt onmiddellijk hoe we hem waarnemen. De meest ongrijpbare, en de meest waardevolle, zijn kleurloos.',
    es: 'El color de un diamante influye inmediatamente en cómo lo percibimos. Los más valiosos y más difíciles de encontrar son incoloros.',
    it: 'Il colore di un diamante influisce immediatamente su come viene percepito allo sguardo. Le gemme più scintillanti e preziose sono incolori.',
    zh: '钻石的颜色会直接影响观感。品质越上乘的钻石越接近无色。',
    ko: '다이아몬드 컬러는 다이아몬드를 인지하는 방식에 곧바로 영향을 끼칩니다. 가장 찾기 어려운 고귀한 다이아몬드는 바로 무색(colourless)입니다.',
    'zh-tw': '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。',
    'zh-hk': '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。',
    th: 'สีของเพชรนั้นดึงดูดความสนใจของเราได้ในทันที เพชรที่ไร้สีคือเพชรที่หายากที่สุดและมีค่าที่สุด',
  },
  {
    key: 'fourC_cut',
    'en-gb': 'Cut',
    'en-us': 'Cut',
    fr: 'Taille',
    de: 'Schliff',
    pt: 'Corte',
    nl: 'Cut',
    es: 'Talla',
    it: 'Taglio',
    zh: '切工（Cut）',
    ko: '컷',
    'zh-tw': '切工（Cut）',
    'zh-hk': '切工（Cut）',
    th: 'การเจียระไน (Cut)',
  },
  {
    key: 'fourC_cut_Text',
    'en-gb':
      'A stone’s shape, symmetry, and balance. The virtuosity of the Maison’s artisans and their choice of cuts and proportions allows them to reveal the magnificent light of these exceptional gems.',
    'en-us':
      'This refers to a stone’s shape, symmetry and balance. The virtuoso of the Maison’s artisans and their choice of cuts and proportions allows them to reveal the magnificent light of these exceptional gems.',
    fr: "La taille du diamant – sa symétrie, son équilibre – est un critère essentiel qui dépend entièrement du talent et de l’expertise du tailleur de diamants. C'est en choisissant la juste taille et les bonnes proportions qu'il va libérer la lumière de ces pierres exceptionnelles.",
    de: 'Die Form, Symmetrie und Ausgewogenheit eines Steins. Die Virtuosität der Juwelier:innen der Maison und die Wahl der Schliffe und Proportionen ermöglichen es ihnen, das besondere Licht dieser außergewöhnlichen Edelsteine zu enthüllen.',
    pt: 'A forma, a simetria e o equilíbrio de uma pedra. A virtuosidade dos artesãos da Maison e a sua escolha de cortes e proporções permitem revelar a luz magnífica destas pedras preciosas excecionais.',
    nl: 'De vorm, symmetrie en balans van een steen. De virtuositeit van de ambachtslieden van het Maison en de keuze van de slijpkwaliteit en proporties maken het mogelijk het prachtige licht van deze uitzonderlijke edelstenen te onthullen.',
    es: 'La forma, simetría y el equilibrio de una piedra. El virtuosismo de los artesanos de la Maison y su selección de tallas y proporciones les permiten revelar la magnífica luz de estas excepcionales gemas.',
    it: 'Il taglio determina la forma, la simmetria e l’equilibrio di una pietra. Il virtuosismo degli artigiani della Maison e la loro scelta in merito a tagli e proporzioni permette di rivelare la magnifica luce di queste gemme eccezionali.',
    zh: '即钻石的形状、对称性和平衡度。卡地亚工匠的精湛技艺和恰到好处的切割比例，让钻石折射出耀眼光芒。',
    ko: '스톤의 형태, 대칭, 균형을 의미합니다. 메종 장인의 전문성을 바탕으로 선택한 다이아몬드의 컷과 비율은 스톤의 눈부신 빛을 드러냅니다.',
    'zh-tw':
      '即鑽石的形狀、對稱和平衡。卡地亞工匠運用其高超工藝，配合合適的切割方式和比例，創作出類拔萃的優雅作品，讓這些非凡寶石綻放璀璨光芒。',
    'zh-hk':
      '即鑽石的形狀、對稱和平衡。卡地亞工匠運用其高超工藝，配合合適的切割方式和比例，創作出類拔萃的優雅作品，讓這些非凡寶石綻放璀璨光芒。',
    th: 'รูปทรงของอัญมณี ความสมมาตร และความสมดุล ด้วยทักษะความชำนาญของช่างฝีมือจากเมซง ควบคู่กับการเลือกสรรรูปทรงสำหรับการเจียระไนและสัดส่วนอันสมบูรณ์แบบ เผยให้เห็นถึงการสะท้อนแสงอันวิจิตรงดงามของอัญมณีอันล้ำค่านี้',
  },
  {
    key: 'fourC_cut_marquis',
    'en-gb': 'Marquise',
    'en-us': 'Marquis',
    fr: 'Marquise',
    de: 'Marquise',
    pt: 'Marquesa',
    nl: 'Marquise',
    es: 'Marquesa',
    it: 'Marquise',
    zh: '榄尖形切割',
    ko: '마퀴즈',
    'zh-tw': '欖尖形',
    'zh-hk': '欖尖形',
    th: 'ทรงมาร์คีส',
  },
  {
    key: 'fourC_cut_emerald',
    'en-gb': 'Emerald',
    'en-us': 'Emerald',
    fr: 'Emeraude',
    de: 'Smaragd',
    pt: 'Esmeralda',
    nl: 'Smaragd',
    es: 'Esmeralda',
    it: 'Smeraldo',
    zh: '祖母绿型切割',
    ko: '에메랄드',
    'zh-tw': '祖母綠形',
    'zh-hk': '祖母綠式',
    th: 'ทรงสี่เหลี่ยมผืนผ้า',
  },
  {
    key: 'fourC_cut_pear',
    'en-gb': 'Pear',
    'en-us': 'Pear',
    fr: 'Poire',
    de: 'Tropfen',
    pt: 'Pera',
    nl: 'Peer',
    es: 'Pera',
    it: 'Pera',
    zh: '梨形切割',
    ko: '페어',
    'zh-tw': '梨形',
    'zh-hk': '梨形',
    th: 'ทรงลูกแพร์',
  },
  {
    key: 'fourC_cut_oval',
    'en-gb': 'Oval',
    'en-us': 'Oval',
    fr: 'Ovale',
    de: 'Oval',
    pt: 'Ovalo',
    nl: 'Ovaal',
    es: 'Oval',
    it: 'Ovale',
    zh: '椭圆形切割',
    ko: '오벌',
    'zh-tw': '橢圓形',
    'zh-hk': '橢圓形',
    th: 'ทรงรี',
  },
  {
    key: 'fourC_cut_round',
    'en-gb': 'Brilliant Round',
    'en-us': 'Round',
    fr: 'Brilliant',
    de: 'Rund',
    pt: 'Brillante',
    nl: 'Rond',
    es: 'Brillante',
    it: 'Brillante',
    zh: '圆形明亮式切割',
    ko: '브릴리언트 라운드',
    'zh-tw': '圓形明亮式',
    'zh-hk': '圓形明亮式',
    th: 'ทงกลม',
  },
  {
    key: 'fourC_cut_square',
    'en-gb': 'Square',
    'en-us': 'Square',
    fr: 'Princesse',
    de: 'Carrée',
    pt: 'Princesa',
    nl: 'Vierkant',
    es: 'Princesa',
    it: 'Princess',
    zh: '方形切割',
    ko: '스퀘어',
    'zh-tw': '方形',
    'zh-hk': '方形',
    th: 'ทรงสี่เหลี่ยมจัตุรัส',
  },
  {
    key: 'fourC_discoverCartierDiamonds',
    'en-gb': 'Discover Cartier diamonds',
    'en-us': 'Discover Cartier Diamonds',
    fr: 'Découvrez les diamants Cartier',
    de: 'Entdecken Sie die Diamanten von Cartier.',
    pt: 'Descobrir os diamantes Cartier',
    nl: 'Ontdek Cartier diamanten',
    es: 'Descubre los diamantes Cartier',
    it: 'Scopri i diamanti Cartier',
    zh: '探索卡地亚钻石',
    ko: '까르띠에 다이아몬드 더 알아보기',
    'zh-tw': '探索卡地亞鑽石',
    'zh-hk': '探索卡地亞鑽石',
    th: 'ชมเครื่องประดับเพชรของคาร์เทียร์เพิ่มเติม',
  },
  {
    key: 'firstpage_recogniseBeautyWhereeverItMayBe',
    'en-gb': 'Rings of Brilliance and Passion',
    'en-us': 'Cartier finds beauty wherever it may be',
    fr: "Bagues d'éclat et de passion",
    de: 'Ringe aus Brillanz und Leidenschaft',
    pt: 'Anéis de brilho e paixão',
    nl: 'Ringen vol schittering en passie',
    es: 'Anillos de esplendor y pasión',
    it: 'Anelli che racchiudono emozioni uniche',
    zh: '璀璨钻戒，热情不息',
    ko: '눈부신 광채와 열정이 담긴 링',
    'zh-tw': '閃閃生光、滿溢濃情的戒指',
    'zh-hk': '閃閃生光、滿溢濃情的戒指',
    th: 'แหวนอันเจิดจรัสและงดงามดึงดูดใจ',
  },
  {
    key: 'firstpage_recogniseBeautyWhereeverItMayBe_Text',
    'en-gb':
      "Immerse yourself in the savoir-faire of Cartier's designs and learn how our precious stone experts reveal the beauty of each diamond. Guided by their audacious creativity; it's their vision that creates the Cartier difference.",
    'en-us':
      "Immerse yourself in the savoir-faire of Cartier's designs and learn how our precious stone experts reveal the beauty of each diamond. Guided by their audacious creativity; it is a question of vision that creates the Cartier difference.",
    fr: 'Plongez dans le savoir-faire de Cartier et découvrez comment nos experts en pierres précieuses, guidés par leur créativité et leur audace, révèlent la beauté de chaque diamant. Une question de regard qui fait la différence Cartier.',
    de: 'Tauchen Sie ein in das Savoir-faire von Cartier und erfahren Sie, wie unsere Edelsteinexpert:innen die Schönheit jedes Diamanten enthüllen.',
    pt: 'Entregue-se ao savoir-faire dos designs da Cartier e aprenda como os nossos especialistas em pedras preciosas revelam a beleza de cada diamante, guiados pela sua criatividade audaciosa; é uma questão de visão que cria a Diferença Cartier.',
    nl: "Bewonder de savoir-faire van Cartier's ontwerpen en ontdek hoe onze edelsteendeskundigen de schoonheid van elke diamant onthullen. Gedreven door hun gedurfde creativiteit; het is een kwestie van visie die het Cartier verschil maakt.",
    es: 'Sumérgete en el savoir-faire de los diseños de Cartier y aprende cómo nuestros expertos en piedras preciosas revelan la belleza de cada diamante. Guiados por su audaz creatividad; lo que crea la Diferencia Cartier es una cuestión de visión.',
    it: "Immergiti nello straordinario savoir-faire dei design Cartier e scopri come i nostri esperti rivelano la bellezza di ogni diamante. L'audace creatività della loro visione è ciò che contraddistingue la Maison e rende unico Cartier nel mondo.",
    zh: '诚邀您置身于卡地亚的设计国度，了解我们的珍贵宝石专家如何呈现每颗钻石的非凡美感。以大胆创意作为指引，专家们的远瞩高瞻，成就了卡地亚的匠心独具。',
    ko: '까르띠에 디자인에 담긴 노하우를 만나보고 메종의 프레셔스 스톤 전문가가 각 다이아몬드의 아름다움을 드러내는 방식을 자세히 살펴보세요. 까르띠에의 차별점은 대담한 독창성을 발휘하는 메종 전문가의 비전에 있습니다.',
    'zh-tw':
      '深入探索卡地亞的精巧設計和精湛工藝，了解卡地亞寶石專家如何讓每顆鑽石盡展美態。他們的大膽創意和獨到眼光造就了卡地亞的獨特之處。',
    'zh-hk':
      '深入探索卡地亞的精巧設計和精湛工藝，了解卡地亞寶石專家如何讓每顆鑽石盡展美態。他們的大膽創意和獨到眼光造就了卡地亞的獨特之處。',
    th: 'สำรวจความเชี่ยวชาญในการออกแบบของคาร์เทียร์และเรียนรู้ว่าผู้เชี่ยวชาญด้านอัญมณีล้ำค่าของเราเผยให้เห็นความงดงามของเพชรแต่ละเม็ดได้อย่างไร สำรวจความเชี่ยวชาญในการออกแบบของคาร์เทียร์ และเรียนรู้วิธีที่ผู้เชี่ยวชาญด้านอัญมณีล้ำค่าของเราเผยให้เห็นความงดงามของเพชรแต่ละเม็ด',
  },
  {
    key: 'firstpage_skipIntroVideo',
    'en-gb': 'Skip',
    'en-us': 'Skip',
    fr: 'Passer',
    de: 'Überspringen',
    pt: 'Avançar',
    nl: 'Overslaan',
    es: 'Saltar',
    it: 'Salta',
    zh: '跳过',
    ko: '건너뛰기',
    'zh-tw': '跳過',
    'zh-hk': '略過',
    th: 'ข้าม',
  },
  {
    key: 'collection_allCollection',
    'en-gb': 'All collections',
    'en-us': 'All Collections',
    fr: 'Toutes les collections',
    de: 'Alle Kollektionen',
    pt: 'Todas as coleções',
    nl: 'Alle collecties',
    es: 'Todas las colecciones',
    it: 'Tutte le collezioni',
    zh: '所有系列',
    ko: '모든 컬렉션',
    'zh-tw': '所有系列',
    'zh-hk': '所有作品系列',
    th: 'คอลเลคชั่นทั้งหมด',
  },
  {
    key: 'collection_engagemantRings',
    'en-gb': 'Engagement rings',
    'en-us': 'Engagement rings',
    fr: 'Bague de fiançailles',
    de: 'Verlobungsringe',
    pt: 'Anéis de noivado',
    nl: 'Verlovingsringen',
    es: 'Anillos de compromiso',
    it: 'Anelli di fidanzamento',
    zh: '订婚钻戒',
    ko: '웨딩 링',
    'zh-tw': '訂婚戒指',
    'zh-hk': '訂婚戒指',
    th: 'แหวนหมั้น',
  },
  {
    key: 'collection_weddingRings',
    'en-gb': 'Wedding bands',
    'en-us': 'Wedding bands',
    fr: 'Alliances',
    de: 'Eheringe',
    pt: 'Alianças de casamento',
    nl: 'Trouwringen',
    es: 'Alianzas de boda',
    it: 'Fedi',
    zh: '结婚对戒',
    ko: '웨딩 밴드',
    'zh-tw': '婚戒',
    'zh-hk': '結婚戒指',
    th: 'แหวนแต่งงาน',
  },
  {
    key: 'collection_1895',
    'en-gb': 'Solitaire 1895',
    'en-us': 'Solitaire 1895',
    fr: 'Solitaire 1895',
    de: 'Solitaire 1895',
    pt: 'Solitaire 1895',
    nl: 'Solitaire 1895',
    es: 'Solitario 1895',
    it: 'Solitario 1895',
    zh: 'Solitaire 1895系列',
    ko: '솔리테어 1895',
    'zh-tw': 'Solitaire 1895',
    'zh-hk': 'Solitaire 1895',
    th: 'แหวน Solitaire 1895',
  },
  {
    key: 'collection_Ballerine',
    'en-gb': 'Ballerine',
    'en-us': 'Ballerine',
    fr: 'Ballerine',
    de: 'Ballerine',
    pt: 'Ballerine',
    nl: 'Ballerine',
    es: 'Ballerine',
    it: 'Ballerine',
    zh: 'Ballerine系列',
    ko: '발레린',
    'zh-tw': 'Ballerine',
    'zh-hk': 'Ballerine',
    th: 'Ballerine',
  },
  {
    key: 'collection_VendomeLouisCartier',
    'en-gb': 'Vendôme Louis Cartier',
    'en-us': 'Vendôme Louis Cartier',
    fr: 'Vendôme Louis Cartier',
    de: 'Vendôme Louis Cartier',
    pt: 'Vendôme Louis Cartier',
    nl: 'Vendôme Louis Cartier',
    es: 'Vendôme Louis Cartier',
    it: 'Vendôme Louis Cartier',
    zh: 'Vendôme Louis Cartier系列',
    ko: '루이 까르띠에 방돔',
    'zh-tw': 'Vendôme Louis Cartier',
    'zh-hk': 'Vendôme Louis Cartier',
    th: 'Vendôme Louis Cartier',
  },
  {
    key: 'collection_CartierDestinee',
    'en-gb': 'Cartier Destinée',
    'en-us': 'Cartier Destinée',
    fr: 'Cartier Destinée',
    de: 'Cartier Destinée',
    pt: 'Cartier Destinée',
    nl: 'Cartier Destinée',
    es: 'Cartier Destinée',
    it: 'Cartier Destinée',
    zh: 'Cartier Destinée系列',
    ko: '까르띠에 데스티네',
    'zh-tw': 'Cartier Destinée',
    'zh-hk': 'Cartier Destinée',
    th: 'Cartier Destinée',
  },
  {
    key: 'collection_Etincelle',
    'en-gb': 'Etincelle de Cartier',
    'en-us': 'Etincelle de Cartier',
    fr: 'Etincelle de Cartier',
    de: 'Etincelle de Cartier',
    pt: 'Etincelle de Cartier',
    nl: 'Etincelle de Cartier',
    es: 'Etincelle de Cartier',
    it: 'Etincelle de Cartier',
    zh: 'Etincelle de Cartier系列',
    ko: '에땅셀 드 까르띠에',
    'zh-tw': 'Etincelle de Cartier',
    'zh-hk': 'Etincelle de Cartier',
    th: 'Etincelle de Cartier',
  },
  {
    key: 'collection_Love',
    'en-gb': 'Love',
    'en-us': 'Love',
    fr: 'Love',
    de: 'Love',
    pt: 'Love',
    nl: 'Love',
    es: 'Love',
    it: 'Love',
    zh: 'Love系列',
    ko: 'LOVE',
    'zh-tw': 'Love',
    'zh-hk': 'Love',
    th: 'Love',
  },
  {
    key: 'collection_TrinityRuban',
    'en-gb': 'Trinity Ruban',
    'en-us': 'Trinity Ruban',
    fr: 'Trinity Ruban',
    de: 'Trinity Ruban',
    pt: 'Trinity Ruban',
    nl: 'Trinity Ruban',
    es: 'Trinity Ruban',
    it: 'Trinity Ruban',
    zh: 'Trinity Ruban系列',
    ko: '트리니티 루반',
    'zh-tw': 'Trinity Ruban',
    'zh-hk': 'Trinity Ruban',
    th: 'Trinity Ruban',
  },
  {
    key: 'collection_1895_Text',
    'en-gb':
      "Named for the year of its creation, the 1895 setting seals an eternal message of love, and presents a timeless expression of the Maison's diamond savoir-faire.",
    'en-us':
      'A Cartier signature for over a century and named after the year of its creation, the Solitaire 1895 ring has withstood the test of time.',
    fr: "Solitaire 1895 - qui tient son nom de son année de création - scelle un message d'amour éternel à travers l'expression atemporelle du savoir-faire de la Maison en matière de diamants.",
    de: 'Die Fassung 1895, benannt nach dem Jahr ihrer Entstehung, besiegelt eine ewige Botschaft der Liebe und ist ein zeitloser Ausdruck des Savoir-faire der Maison.',
    pt: 'Com o nome inspirado no seu ano de criação, o engaste 1895 confirma uma mensagem de amor eterno e demonstra uma expressão intemporal do savoir-faire de diamantes da Maison.',
    nl: 'De 1895-zetting, vernoemd naar het jaar van haar creatie, bezegelt een eeuwige boodschap van liefde en is een tijdloze weerspiegeling van de savoir-faire van het Maison op het gebied van diamanten.',
    es: 'Llamado así por el año de su creación, el anillo de engaste 1895 transmite un mensaje de amor eterno, y manifiesta la atemporalidad del savoir-faire en diamantes de la Maison.',
    it: 'Chiamata come l’anno della sua creazione, la montatura 1895 è simbolo di un eterno messaggio di amore ed è espressione senza tempo del savoir-faire della Maison in materia di diamanti.',
    zh: '以诞生年份命名的1895系列钻戒，以精致的镶嵌工艺传递爱的隽永讯息，尽显卡地亚钻石历久弥新的精湛工艺。',
    ko: "처음 선보인 1895년에서 이름을 딴 '1895 세팅'은 영원한 사랑의 메시지를 담고 있으며, 메종의 다이아몬드 노하우를 고스란히 보여줍니다.",
    'zh-tw': '此系列得名自其創作年份1895年，其採用的鑲嵌方式凝聚愛的永恆訊息，雋永演繹卡地亞的鑽石精湛工藝。',
    'zh-hk': '此系列得名自其創作年份1895年，其採用的鑲嵌方式凝聚愛的永恆訊息，雋永演繹卡地亞的鑽石精湛工藝。',
    th: 'คอลเลคชั่น 1895 ตั้งชื่อตามปีที่รังสรรค์ชิ้นงานรุ่นนี้ขึ้นมา พร้อมปิดผนึกข้อความแห่งรักนิรันดร์ และนำเสนอรูปลักษณ์ที่งดงามเหนือกาลเวลาของเพชรด้วยความเชี่ยวชาญเหนือระดับจากเมซง',
  },
  {
    key: 'collection_1895_Quote',
    'en-gb': 'A majestically placed diamond on a Cartier solitaire will bear your message of love for eternity.',
    'en-us':
      'A trademark setting of four prongs and a basket illuminates the power of the diamond, resulting in a symbol of commitment that surpasses a lifetime.',
    fr: "Eternel, le diamant de votre solitaire symbolise l'amour.",
    de: 'Ein majestätisch platzierter Diamant auf einem Solitär von Cartier wird Ihre Liebesbotschaft für die Ewigkeit tragen.',
    pt: 'Um diamante colocado majestosamente num solitaire levará a sua mensagem de amor para a eternidade.',
    nl: 'Een majestueus geplaatste diamant op een Cartier-juweel draagt uw boodschap van liefde voor de eeuwigheid.',
    es: 'Un diamante majestuosamente engastado en un solitario Cartier llevará tu mensaje de amor eterno.',
    it: 'Un meraviglioso diamante in un solitario Cartier simboleggia il tuo messaggio di amore eterno.',
    zh: '中央精心镶嵌的一颗钻石，象征永恒不渝的爱情。',
    ko: '까르띠에 솔리테어가 당당한 자태를 뽐내는 다이아몬드로 영원한 사랑의 메시지를 전합니다.',
    'zh-tw': '華美鑽石鑲嵌於卡地亞單鑽戒指之上，象徵著愛的永恆訊息。',
    'zh-hk': '華美鑽石鑲嵌於卡地亞單鑽戒指之上，象徵著愛的永恆訊息。',
    th: 'เพชรที่ประดับไว้อย่างสง่างามบนตัวเรือนแหวนโซลิแทร์ (Solitaire) ของคาร์เทียร์นี้สื่อถึงรักนิรันดร์',
  },
  {
    key: 'collection_Ballerine_Text',
    'en-gb':
      'The Ballerine silhouette evokes the world of dance, harmony and balance. The setting will provide a delicate, feminine backdrop to your chosen diamond.',
    'en-us': 'Inspired by ballet, the emblematic Ballerine setting evokes a sense of grace, harmony and balance.',
    fr: 'Le nom de ce solitaire évoque l’univers de la danse, l’harmonie et l’équilibre. Sa monture raffinée et féminine sublimera le diamant que vous avez choisi.',
    de: 'Die Silhouette der Ballerina erinnert an die Welt des Tanzes, der Harmonie und des Gleichgewichts. Die Fassung bietet eine zarte, feminine Kulisse für Ihren ausgewählten Diamanten.',
    pt: 'A silhueta Ballerine evoca o mundo da dança, da harmonia e do equilíbrio. O engaste vai garantir um pano de fundo delicado e feminino para o diamante escolhido.',
    nl: 'Het Ballerine silhouet roept de wereld van dans, harmonie en evenwicht op. De zetting vormt een delicate, vrouwelijke achtergrond voor de diamant van uw keuze.',
    es: 'La forma de Ballerine evoca el mundo de la danza, la armonía y el equilibrio. Este anillo aportará un toque delicado y femenino al diamante que escojas.',
    it: 'La silhouette Ballerine evoca il mondo della danza, dell’armonia e dell’equilibrio. La montatura dona un design delicato e femminile al tuo diamante.',
    zh: 'Ballerine系列造型柔美，令人不由联想起翩翩舞姿、富有和谐与平衡之感。此款镶嵌工艺，将为您选择的钻石带来精致柔美的氛围。',
    ko: '발레린 실루엣은 춤과 조화, 균형의 세계를 표현합니다. 당신이 선택한 다이아몬드를 더욱 돋보이게 하는 섬세하고 여성스러운 세팅이 특징입니다.',
    'zh-tw': 'Ballerine系列的設計取材自芭蕾舞，展現和諧與平衡之美。其鑲嵌方式柔美典雅，完美襯托您所選的鑽石。',
    'zh-hk': 'Ballerine 系列的設計取材自芭蕾舞，展現和諧與平衡之美。其鑲嵌方式柔美典雅，完美襯托您所選的鑽石。',
    th: 'รูปลักษณ์อันงดงามของบาลเลอรีน (Ballerine) นี้ชวนให้นึกถึงโลกแห่งการเต้นรำ ความกลมกลืน และความสมดุล รูปแบบการฝังอัญมณีเปรียบเสมือนฉากหลังอันประณีตและงดงามให้กับเพชรที่คุณเลือกสรร',
  },
  {
    key: 'collection_Ballerine_Quote',
    'en-gb': 'A precious ring of essential lines in perfect harmony, to declare your love with romantic intensity.',
    'en-us':
      'The center stone is nestled between a blooming bouquet of precious stones, providing a delicate and feminine backdrop for Cartier diamonds.',
    fr: 'Une bague précieuse, harmonieuse dans son dessin aux lignes épurées, pour déclarer votre amour avec intensité.',
    de: 'Ein kostbarer Ring mit klaren Linien in perfekter Harmonie, um Ihre Liebe auf eine sehr romantische Art zu erklären.',
    pt: 'Um anel precioso de linhas essenciais em perfeita harmonia, para declarar o seu amor com uma intensidade romântica.',
    nl: 'Een kostbare ring van essentiële lijnen in perfecte harmonie, om uw liefde te verklaren met een romantische intensiteit.',
    es: 'Un precioso anillo de líneas esenciales en perfecta armonía para declarar tu amor con una intensidad romántica.',
    it: 'Un anello prezioso, dalle linee essenziali e armoniche, per dichiarare con passione il tuo amore.',
    zh: '戒指线条和谐优雅、简约利落，以其蕴含的浪漫深情，大胆告白您的爱意。',
    ko: '심플한 라인이 완벽한 조화를 이루는 우아한 링과 함께 강렬하고 로맨틱한 사랑을 고백하세요.',
    'zh-tw': '珍貴戒指以簡約線條流露和諧美感，宛如一則浪漫愛的宣言。',
    'zh-hk': '珍貴戒指以簡約線條流露和諧美感，宛如一則浪漫愛的宣言。',
    th: 'แหวนอันล้ำค่าในคอลเลคชั่นเอสเซนเชียล ไลน์ (Essential Lines) กลมกลืนกันอย่างสมบูรณ์แบบ เป็นชิ้นงานที่สื่อถึงความรักอันลึกซึ้งของคุณ',
  },
  {
    key: 'collection_VendomeLouisCartier_Text',
    'en-gb':
      'The Vendôme Louis Cartier solitaire ring has a clean and elegant design that showcases a singular brilliant-cut diamond.',
    'en-us':
      "Designed by Louis Cartier in 1924, the Trinity is a signature design of the Maison. This creative reinterpretation of the Maison's iconic three gold bands showcases a singular diamond.",
    fr: 'Lignes claires et élégantes, le solitaire Vendôme Louis Cartier sublime, par son design, chaque diamant.',
    de: 'Der Solitärring Vendôme Louis Cartier hat ein klares und elegantes Design, das jeden einzelnen Diamanten zur Geltung bringt.',
    pt: 'O anel Solitaire Vendôme Louis Cartier tem um design limpo e elegante, que mostra cada diamante de forma individual.',
    nl: 'De Vendôme Louis Cartier solitaire ring heeft een strak en elegant ontwerp dat elke afzonderlijke diamant benadrukt.',
    es: 'El solitario Vendôme Louis Cartier tiene un diseño limpio y elegante que resalta la singularidad de cada diamante.',
    it: 'Il solitario Vendôme Louis Cartier vanta un design pulito ed elegante che mette in risalto ogni singolo diamante.',
    zh: 'Vendôme Louis Cartier系列订婚钻戒以简洁优雅的设计，衬托每颗钻石的闪耀光采。',
    ko: '루이 까르띠에 방돔 솔리테어 링은 최상급 다이아몬드들이 각각 돋보이는 깔끔하고 우아한 디자인을 선보입니다.',
    'zh-tw': 'Vendôme Louis Cartier單鑽戒指的設計優雅俐落，讓鑽石的美態淋漓盡顯。',
    'zh-hk': 'Vendôme Louis Cartier 單鑽戒指的設計優雅俐落，讓鑽石的美態淋漓盡顯。',
    th: 'แหวนวองโดม หลุยส์ คาร์เทียร์ โซลิแทร์ (Vendôme Louis Cartier Solitaire) มีการออกแบบที่เด่นชัดและสง่างามซึ่งแสดงถึงเอกลักษณ์เฉพาะตัวของเพชรแต่ละเม็ด',
  },
  {
    key: 'collection_VendomeLouisCartier_Quote',
    'en-gb':
      'Striking, modern and graphic, this design features three bands of the Maison’s colours of gold, carrying on decades of Cartier stylistic heritage.',
    'en-us':
      'Striking, modern and graphic, this design features three bands, carrying on decades of Cartier stylistic heritage.',
    fr: "Moderne et graphique, ce solitaire arbore les trois anneaux aux trois couleurs d'or - code stylistique inscrit au répertoire de la Maison depuis plusieurs décennies.",
    de: 'Dieses auffällige, moderne und grafische Design zeigt drei Bänder in den Goldfarben der Maison und führt die jahrzehntelange stilistische Tradition von Cartier fort.',
    pt: 'Impressionante, moderno e realista, este design conta com três aros nas cores douradas da Maison, carregando consigo décadas da herança estilística da Cartier.',
    nl: 'Dit opvallende, moderne en grafisch ontwerp is voorzien van drie ringen in de drie gouden kleuren van het Maison en zet het jarenlange stilistisch erfgoed van Cartier voort.',
    es: 'Atrevido, moderno y expresivo, este diseño presenta tres franjas en los tres colores de oro de la Maison, continuando con el largo legado estilístico de Cartier.',
    it: "Sorprendentemente moderno, questo design presenta tre fedi in tre diverse declinazioni d'oro, tramandnado decenni di tradizione stilistica Cartier.",
    zh: '醒目、摩登且富有几何美感，采用卡地亚三色金环圈，延续品牌历经岁月的风格传承。',
    ko: '시선을 사로잡으며 모던하면서도 살아있는 느낌의 이 디자인은 메종의 세 가지 골드 컬러 밴드가 특징입니다. 수십 년간 까르띠에 스타일 유산을 계승해왔습니다. ',
    'zh-tw': '設計採用了卡地亞的三色金環，時尚矚目，同時傳承卡地亞源遠流長的品牌風格。',
    'zh-hk': '設計採用了卡地亞的三色金環，時尚矚目，同時傳承卡地亞源遠流長的世家風格。',
    th: 'การออกแบบที่ทันสมัย เด่นชัด และงดงามสะกดทุกสายตาด้วยวงแหวนสามวงที่รังสรรค์จากไวท์โกลด์ เยลโลว์โกลด์ และโรสโกลด์อันเลื่องชื่อของเมซง เป็นการสานต่อสไตล์ที่สั่งสมมานานหลายทศวรรษของคาร์เทียร์',
  },
  {
    key: 'collection_CartierDestinee_Text',
    'en-gb':
      'The Destinée setting is easily recognised by its shining halo of brilliant cut diamonds, a harmonious composition and an eternal promise of a shared lifetime.',
    'en-us':
      'Cartier Destinée is easily recognized by its delicate halo of brilliant cut diamonds with a powerful center stone rising up above this magnetic constellation.',
    fr: "Cartier Destinée, c'est d'abord un halo de diamants taille brillant. Une composition qui conjugue éclat et harmonie, comme la promesse éternelle d'une vie à deux.",
    de: 'Die Destinée-Fassung ist leicht an ihrem glänzenden Halo aus Diamanten im Brillantschliff zu erkennen. Eine harmonische Komposition und ein ewiges Versprechen für ein gemeinsames Leben.',
    pt: 'O engaste Destinée é facilmente reconhecido pela sua “auréola” brilhante de diamantes, uma composição harmoniosa e a promessa eterna de uma vida partilhada.',
    nl: 'De Destinée-zetting is snel te herkennen aan de stralende halo van briljant geslepen diamanten, een harmonieuze compositie en een eeuwige belofte van een leven samen.',
    es: 'El anillo de engaste Destinée se reconoce fácilmente por su luminoso halo de diamantes de talla brillante: una composición armoniosa y una promesa eterna de una vida compartida.',
    it: 'La montatura Destinée è facilmente riconoscibile per la sua lucente aura di diamanti dal taglio brillante: una composizione armoniosa per una promessa eterna di vita insieme.',
    zh: 'Destinée系列镶嵌一圈闪耀的圆形明亮式切割钻石，可谓一眼可辨的珠宝佳作，构造和谐，见证矢志不渝的承诺。',
    ko: '데스티네 세팅은 브릴리언트 컷 다이아몬드의 눈부신 광채와 조화로운 구성으로 한눈에 알아볼 수 있으며, 평생 함께하는 영원한 약속을 전합니다.',
    'zh-tw':
      'Destinée系列以鑲嵌一圈精緻的圓形明亮式切割鑽石而成為一眼可辨的珠寶佳作，作品佈局和諧，印證廝守一生的永恆承諾。',
    'zh-hk':
      'Destinée 系列以鑲嵌一圈精緻的明亮式切割圓鑽而成為一眼可辨的珠寶佳作，作品佈局和諧，印證廝守一生的永恆承諾。',
    th: 'การฝังเพชรบนแหวนเดสติเน (Destinée) โดดเด่นด้วยรัศมีที่ส่องประกายของเพชรเจียระไนทรงบริลเลียนท์อันวิจิตรบรรจง เป็นองค์ประกอบที่กลมกลืนกันและสื่อถึงคำมั่นสัญญาแห่งรักนิรันดร์',
  },
  {
    key: 'collection_CartierDestinee_Quote',
    'en-gb':
      'A magnetic constellation of diamonds, with an unparalleled craftsmanship that creates a timeless celebration of love.',
    'en-us': 'Unparalleled craftsmanship makes for an effortless celebration of love.',
    fr: "Une constellation magnétique de diamants, servie par un savoir-faire unique, pour mettre en scène une déclaration d'amour faite pour traverser le temps.",
    de: 'Eine magnetische Konstellation aus Diamanten, die einer unvergleichlichen Handwerkskunst bedarf.',
    pt: 'Uma constelação magnética de diamantes, com uma técnica artesanal sem igual que cria uma celebração intemporal do amor.',
    nl: 'Een magnetische constellatie van diamanten, met een ongeëvenaard vakmanschap dat een tijdloze viering van de liefde creëert.',
    es: 'Una fascinante constelación de diamantes, resultado de un trabajo artesano incomparable, que crea una celebración atemporal del amor.',
    it: "Una magnetica serie di diamanti che, con ineguagliata maestria, celebra l'amore senza tempo.",
    zh: '充满魅力的璀璨钻石犹如繁星密布，以卓越非凡的工艺，传达隽永的爱意。',
    ko: '탁월한 장인 정신으로 완성한 화려한 다이아몬드 링은 시간이 흘러도 변치 않는 영원한 사랑을 약속합니다.',
    'zh-tw': '糅合璀璨迷人的鑽石和無與倫比的精湛工藝，頌揚永恆真愛。',
    'zh-hk': '糅合璀璨迷人的鑽石和無與倫比的精湛工藝，頌揚永恆真愛。',
    th: 'กลุ่มเพชรอันงดงามดึงดูดใจ ประกอบกับความเชี่ยวชาญเหนือระดับรังสรรค์เป็นชิ้นงานแห่งการเฉลิมฉลองแด่ความรักชั่วนิจนิรันดร์',
  },
  {
    key: 'collection_Etincelle_Text',
    'en-gb':
      'Simple, feminine, and modern, the Etincelle de Cartier is the Maison’s most discreet diamond-paved collection.',
    'en-us':
      'Simple, feminine, and modern, the Étincelle de Cartier is the Maison’s most discreet diamond-paved collection.',
    fr: 'Simple, féminine et moderne, Étincelle de Cartier est la plus raffinée et la plus discrète des collections de bagues pavées de diamants.',
    de: 'Die schlichte, feminine und moderne Etincelle de Cartier Kollektion ist die puristischte diamantbesetzte Kollektion der Maison.',
    pt: 'Simples, feminina e moderna, a coleção Etincelle de Cartier é a coleção de diamantes mais discreta da Maison.',
    nl: 'De ingetogen, vrouwelijke en moderne Etincelle de Cartier is de meest discrete collectie met diamanten van het Maison.',
    es: 'Sencillo, femenino y moderno, Etincelle de Cartier es la colección de pavé de diamantes más discreta de la Maison.',
    it: 'Semplice, femminile e moderna: Etincelle de Cartier è la collezione di anelli con pavé di diamanti per chi predilige linee essenziali.',
    zh: 'Etincelle de Cartier系列简约柔美，充满现代气息，是卡地亚最简约含蓄的铺镶钻石系列。',
    ko: '에땅셀 드 까르띠에 컬렉션은 섬세한 파베 세팅 다이아몬드로 간결하면서도 여성스럽고 현대적인 매력을 선보입니다.',
    'zh-tw': 'Etincelle de Cartier系列簡約柔美，充滿現代氣息，是卡地亞最簡約含蓄的鋪鑲鑽石系列。',
    'zh-hk': 'Etincelle de Cartier 系列簡約柔美，充滿現代氣息，是卡地亞最簡約含蓄的鋪鑲鑽石系列。',
    th: 'เอแตงแซลล์ เดอ คาร์เทียร์ (Etincelle de Cartier) ที่แลดูเรียบหรู สง่างามและทันสมัย คือคอลเลคชั่นเครื่องประดับฝังเพชรที่หรูหราที่สุดของคาร์เทียร์',
  },
  {
    key: 'collection_Etincelle_Quote',
    'en-gb': 'With eternal femininity and elegance, these delicate creations are a testament to pure Cartier style.',
    'en-us':
      'From bridal jewels to stackable fashion pieces, these delicate creations are a testament of timeless Cartier elegance.',
    fr: "Féminines et élégantes : ces créations sont l'expression la plus pure du style Cartier.",
    de: 'Mit ihrer Femininität und Eleganz sind diese zarten Kreationen ein wahres Zeugnis des Stils von Cartier.',
    pt: 'Com uma feminilidade e elegância eternas, estas criações delicadas são um testemunho puro do estilo Cartier.',
    nl: 'Met hun eeuwige vrouwelijkheid en elegantie, zijn deze delicate creaties een bewijs van de pure stijl van Cartier.',
    es: 'Con su eterna feminidad y elegancia, estas delicadas creaciones ponen de manifiesto el estilo puro de Cartier.',
    it: "Caratterizzate da un’eterna femminilità ed eleganza, queste delicate creazioni esprimono l'autentico stile Cartier.",
    zh: '精致作品蕴含隽永的女性魅力和优雅，彰显卡地亚纯粹经典之美。',
    ko: '영원한 여성성과 우아함을 담은 섬세한 크리에이션들이 순수한 까르띠에 스타일을 보여줍니다.',
    'zh-tw': '這些精緻作品以永恆的柔美和優雅，印證了卡地亞的風格精髓。',
    'zh-hk': '這些精緻作品以永恆的柔美和優雅，印證了卡地亞的風格精髓。',
    th: 'ด้วยความงดงามชั่วนิรันดร์ตามแบบฉบับของผู้หญิง ชิ้นงานรังสรรค์อันประณีตเหล่านี้แสดงให้เห็นถึงสไตล์อันเป็นเอกลักษณ์เฉพาะของคาร์เทียร์',
  },
  {
    key: 'collection_Love_Text',
    'en-gb': 'Born in New York, the Love ring seals unconventional yet eternal love stories.',
    'en-us':
      'A love child of ’70s New York, the LOVE collection is a symbol of free-spirited love. Lock in your love, forever.',
    fr: 'Né à New York dans les années 1970, Love scelle les amours affranchies de conventions.',
    de: 'Der in New York kreierte Love Ring besiegelt unkonventionelle und ewige Liebesgeschichten.',
    pt: 'Nascido em Nova Iorque, o anel Love sela histórias de amor eternas e não convencionais.',
    nl: 'De Love-ring, geboren in New York, bezegelt onconventionele en eeuwige liefdesverhalen.',
    es: 'Creado en Nueva York, el anillo Love simboliza las historias de amor poco convencionales y eternas.',
    it: 'Nato a New York, l’anello Love suggella storie d’amore non convenzionali ed eterne.',
    zh: '诞生于纽约的Love戒指设计别具一格、率性自由，见证冲破世俗又恒久动人的爱情故事。',
    ko: '뉴욕에서 탄생한 LOVE 링은 독특하면서도 영원한 러브 스토리를 표현합니다.',
    'zh-tw': '誕生於紐約的Love戒指，為您印證永恆而不凡的愛情故事。',
    'zh-hk': '誕生於紐約的 Love 戒指，為您印證永恆而不凡的愛情故事。',
    th: 'แหวนเลิฟ (Love) ซึ่งถือกำเนิดขึ้นที่นิวยอร์กได้ผนึกเรื่องราวความรักแสนพิเศษที่จะคงอยู่ชั่วนิรันดร์ไว้',
  },
  {
    key: 'collection_Love_Quote',
    'en-gb': 'A graphic, timeless emblem of passion and intensity.',
    'en-us':
      'Its binding closure and screw motif give it true permanence, while diverse interpretations allow for a unique expression of feelings.',
    fr: "Un emblème graphique, atemporel de passion et d'intensité.",
    de: 'Ein grafische, zeitlose Ikone der Leidenschaft.',
    pt: 'Um símbolo realista e eterno de paixão e intensidade.',
    nl: 'Een grafisch, tijdloos symbool van passie en intensiteit.',
    es: 'Un símbolo atemporal y expresivo de la pasión y la intensidad.',
    it: 'Un emblema intramontabile di vera passione.',
    zh: '风格醒目、隽永经典，成就炙热真爱。',
    ko: '열정과 강렬함을 상징하는 타임리스 디자인입니다.',
    'zh-tw': '鮮明雋永的設計，凝聚萬千濃情蜜意。',
    'zh-hk': '鮮明雋永的設計，凝聚萬千濃情蜜意。',
    th: 'สัญลักษณ์แห่งความหลงใหลและความผูกพันอันลึกซึ้งที่จะคงอยู่เหนือกาลเวลา',
  },
  {
    key: 'collection_TrinityRuban_Text',
    'en-gb':
      'The Trinity Ruban presents an iconic, bejewelled ribbon paved with brilliant-cut diamonds that coil around your chosen central diamond.',
    'en-us':
      'The iconic Trinity is re-interpreted as a bejeweled ribbon of platinum paved with diamonds that coils around the central diamond.',
    fr: 'L’emblématique Trinity devient un ruban pavé de brillants venant enlacer le diamant central.',
    de: 'Der Trinity Ruban präsentiert ein ikonisches, mit Brillanten besetztes Band, das sich um den von Ihnen gewählten zentralen Diamanten windet.',
    pt: 'O Trinity Ruban apresenta um laço icónico, coberto de diamantes, que forma uma espiral em volta do diamante central escolhido por si.',
    nl: 'De Trinity Ruban presenteert een iconisch lint gezet met briljant geslepen diamanten die zich om de door u gekozen centrale diamant wikkelen.',
    es: 'El diseño Trinity Ruban presenta un icónico lazo engastado con pavé de diamantes de talla brillante que envuelve el diamante central de tu elección.',
    it: "Il Trinity Ruban presenta un'iconica silhouette tempestata di diamanti taglio brillante che ricorda un nastro  avvolto intorno alla pietra centrale.",
    zh: '标志性的Trinity Ruban戒指宛如铺镶圆形明亮式切割钻石的铂金缎带，优雅盘绕于您选择的中央主钻之间。',
    ko: '트리니티 루반은 브릴리언트 컷 다이아몬드를 파베 세팅하고 아이코닉한 리본 장식을 더해 가운데 다이아몬드를 감싸고 있습니다.',
    'zh-tw': 'Trinity Ruban系列的經典珠寶緞帶鋪鑲圓形明亮式切割鑽石，繾綣在您所選的中央主鑽。',
    'zh-hk': 'Trinity Ruban 系列的經典珠寶絲帶鋪鑲明亮式切割圓鑽，繾綣在您所選的中央主鑽。',
    th: 'ทรินิตี้ รูบอง (Trinity Ruban) เปี่ยมด้วยเอกลักษณ์อันโดดเด่นของตัวเรือนที่เปรียบเสมือนริบบิ้นประดับเพชรเจียระไนทรงบริลเลียนท์โอบล้อมรอบเพชรเม็ดกลางที่คุณเลือกสรร',
  },
  {
    key: 'collection_TrinityRuban_Quote',
    'en-gb': 'Entwined in a swirl of love, this setting celebrates the infinite connection of an eternal bond.',
    'en-us':
      'A solitaire entwined by a swirl of love, the radiant Trinity Ruban celebrates the infinite connection of an eternal bond.',
    fr: "Par sa construction en tourbillon, cette monture célèbre la connexion infinie d'un lien éternel.",
    de: 'In einem Strudel der Liebe verwoben, zelebriert diese Fassung die unendliche Verbindung.',
    pt: 'Entrelaçado numa espiral de amor, este engaste celebra a ligação infinita de um laço eterno.',
    nl: 'Verstrengeld in een werveling van liefde, viert deze zetting de oneindige verbinding van een eeuwige band.',
    es: 'Entrelazado en una espiral de amor, este anillo de engaste celebra la unión infinita de un vínculo eterno.',
    it: "Un intreccio che celebra l'amore: questa montatura simboleggia legami destinati a durare per sempre.",
    zh: '灵动闪耀，爱意缱绻，缔结相伴一生的承诺，见证忠贞不渝的爱情。',
    ko: '사랑으로 둘러싸인 이 세팅은 영원하고 무한한 사랑을 기념합니다.',
    'zh-tw': '作品採用的鑲嵌方式禮讚無盡的愛，讓人投入愛的漩渦之中。',
    'zh-hk': '作品採用的鑲嵌方式禮讚無盡的愛，讓人投入愛的漩渦之中。',
    th: 'ตัวเรือนที่โอบล้อมด้วยความรักเพื่อเฉลิมฉลองสายสัมพันธ์แห่งรักอันไร้ขอบเขต',
  },
  {
    key: 'collection_exploreCollection',
    'en-gb': 'Explore the Collection',
    'en-us': 'Explore the Collection',
    fr: 'Explorer la Collection',
    de: 'Entdecken Sie die Kollektion.',
    pt: 'Explorar a coleção',
    nl: 'Ontdek de collectie',
    es: 'Explorar la colección',
    it: 'Esplora la collezione',
    zh: '探索系列',
    ko: '컬렉션 더 알아보기',
    'zh-tw': '探索此系列',
    'zh-hk': '探索作品系列',
    th: 'ชมคอลเลคชั่นเพิ่มเติม',
  },
  {
    key: 'collection_selectAndBeginCreating',
    'en-gb': 'Begin Your Creation',
    'en-us': 'Select Design',
    fr: 'Commencez votre création',
    de: 'Auswählen und mit der eigenen Kreation beginnen',
    pt: 'Comece o seu desenho',
    nl: 'Selecteer om uw creatie te maken',
    es: 'Comienza tu creación',
    it: 'Seleziona per iniziare la tua creazione',
    zh: '开始定制您的卡地亚作品',
    ko: '나만의 링 제작하기',
    'zh-tw': '開始創作',
    'zh-hk': '開始創作',
    th: 'เริ่มต้นสร้างสรรค์ชิ้นงานของคุณ',
  },
  {
    key: 'collection_material_yg',
    'en-gb': 'Yellow Gold',
    'en-us': 'Yellow Gold',
    fr: 'Or jaune',
    de: 'Gelbgold',
    pt: 'Ouro amarelo',
    nl: 'Geelgoud',
    es: 'Oro amarillo',
    it: 'Oro giallo',
    zh: '黄金',
    ko: '옐로우 골드',
    'zh-tw': '黃K金',
    'zh-hk': '黃金',
    th: 'เยลโลว์โกลด์',
  },
  {
    key: 'collection_material_pt',
    'en-gb': 'Platinum',
    'en-us': 'Platinum',
    fr: 'Platine',
    de: 'Platin',
    pt: 'Platina',
    nl: 'Platina',
    es: 'Platino',
    it: 'Platino',
    zh: '铂金',
    ko: '플래티늄',
    'zh-tw': '鉑金',
    'zh-hk': '鉑金',
    th: 'แพลทินัม',
  },
  {
    key: 'collection_material_rg',
    'en-gb': 'Rose Gold',
    'en-us': 'Rose Gold',
    fr: 'Or rose',
    de: 'Roségold',
    pt: 'Ouro rosa',
    nl: 'Roségoud',
    es: 'Oro rosa',
    it: 'Oro rosa',
    zh: '玫瑰金',
    ko: '핑크 골드',
    'zh-tw': '玫瑰金',
    'zh-hk': '玫瑰金',
    th: 'โรสโกลด์',
  },
  {
    key: 'collection_material_paved',
    'en-gb': 'Paved',
    'en-us': 'Paved',
    fr: 'Pavé',
    de: 'Besetzt',
    pt: 'Pavé',
    nl: 'Pavé',
    es: 'Pavé',
    it: 'Pavé',
    zh: '铺镶',
    ko: '파베 세팅',
    'zh-tw': '鋪鑲鑽石',
    'zh-hk': '鋪鑲鑽石',
    th: 'ประดับอัญมณี',
  },
  {
    key: 'collection_material_wg',
    'en-gb': 'White Gold',
    'en-us': 'White Gold',
    fr: 'Or gris',
    de: 'Weißgold',
    pt: 'Ouro branco',
    nl: 'Witgoud',
    es: 'Oro blanco',
    it: 'Oro bianco',
    zh: '白金',
    ko: '화이트 골드',
    'zh-tw': '白K金',
    'zh-hk': '白色黃金',
    th: 'ไวท์โกลด์',
  },
  {
    key: 'collection_material_pg',
    'en-gb': 'Rose Gold',
    'en-us': 'Rose Gold',
    fr: 'Or rose',
    de: 'Roségold',
    pt: 'Ouro rosa',
    nl: 'Roségoud',
    es: 'Oro rosa',
    it: 'Oro rosa',
    zh: '玫瑰金',
    ko: '핑크 골드',
    'zh-tw': '玫瑰金',
    'zh-hk': '玫瑰金',
    th: 'โรสโกลด์',
  },
  {
    key: 'collection_material_classicpaved',
    'en-gb': 'Classic Paved',
    'en-us': 'Classic Paved',
    fr: 'Pavé Classique',
    de: 'Besetzt',
    pt: 'Pavé Clasico',
    nl: 'Pavé',
    es: 'Pavé Clasico',
    it: 'Pavé',
    zh: '经典铺镶',
    ko: '클래식 파베 세팅',
    'zh-tw': '經典鋪鑲鑽石',
    'zh-hk': '經典鋪鑲鑽石',
    th: 'ประดับอัญมณีแบบคลาสสิก',
  },
  {
    key: 'collection_preciousMaterial',
    'en-gb': 'Precious Materials',
    'en-us': 'Precious Metals',
    fr: 'Matières précieuses',
    de: 'Edle Materialien',
    pt: 'Materiais preciosos',
    nl: 'Kostbare materialen',
    es: 'Materiales preciosos',
    it: 'Materiali preziosi',
    zh: '珍贵材质',
    ko: '최고급 소재들',
    'zh-tw': '珍貴材質',
    'zh-hk': '珍貴材質',
    th: 'วัสดุล้ำค่า',
  },
  {
    key: 'collection_components_text',
    'en-gb':
      'Each Cartier ring is designed to be timeless and luminous throughout a shared lifetime of intense love, passion and adventure.',
    'en-us': 'Each Cartier ring is designed to be timeless and luminous throughout its lifetime.',
    fr: "Les bagues Cartier sont créées pour être atemporelles et éclatantes tout au long d'une vie d'amour intense, de passion et d'aventure.",
    de: 'Jeder Ring von Cartier ist so konzipiert, dass er zeitlos und glanzvoll ist für ein gemeinsames Leben voller Liebe, Leidenschaft und Abenteuer.',
    pt: 'Cada anel Cartier é desenhado para ser eterno e luminoso durante uma vida partilhada de amor, paixão e aventuras intensas.',
    nl: 'Elke ring van Cartier is ontworpen om gedurende een gedeeld leven van intense liefde, passie en avontuur tijdloos te schitteren.',
    es: 'Cada anillo Cartier está diseñado para ser atemporal y luminoso a lo largo de toda una vida compartiendo amor, pasión y aventura.',
    it: 'Luminoso e intramontabile: ogni anello Cartier è creato per celebrare amori intensi, passione e avventure.',
    zh: '每一款卡地亚戒指的设计皆璀璨动人、凝聚无限爱意，是炙热真情的颂歌，是一生誓约的见证，亦是冒险征程的邀约。',
    ko: '모든 까르띠에 링은 존재하는 동안 강렬한 사랑과 열정, 모험이 공존하며 시대를 초월해 빛나도록 디자인되었습니다.',
    'zh-tw': '每款卡地亞戒指均璀璨雋永，伴隨佩戴者踏上一場充滿濃情愛意、廝守終生的新旅程。',
    'zh-hk': '每款卡地亞戒指均璀璨雋永，伴隨佩戴者踏上一場充滿濃情愛意、廝守終生的新旅程。',
    th: 'แหวนคาร์เทียร์แต่ละวงได้รับการออกแบบให้เปี่ยมด้วยความงดงามเหนือกาลเวลาและเปล่งประกายตลอดช่วงเวลาแห่งความรัก ความหลงใหล และการผจญภัยร่วมกันไปชั่วนิรันดร์',
  },
  {
    key: 'pageNotFound_sorrySomethingWentWrong',
    'en-gb': 'Sorry, this page is unavailable.',
    'en-us': 'Sorry, this page is unavailable.',
    fr: "Désolé, cette page n'est pas disponible.",
    de: 'Entschuldigen Sie bitte, diese Seite ist nicht verfügbar.',
    pt: 'Lamentamos, mas esta página está indisponível.',
    nl: 'Sorry, deze pagina is niet beschikbaar.',
    es: 'Lo sentimos. Esta página no está disponible.',
    it: 'Siamo spiacenti, questa pagina non è disponibile.',
    zh: '抱歉，无法查看该页面。',
    ko: '죄송합니다. 이 페이지는 사용할 수 없습니다.',
    'zh-tw': '抱歉，此頁面無法使用。',
    'zh-hk': '抱歉，此頁面無法使用。',
    th: 'ขออภัย หน้านี้ยังไม่พร้อมใช้งาน',
  },
  {
    key: 'pageNotFound_sorrySomethingWentWrong_Text',
    'en-gb': 'The page you are looking for may have been removed, or it is temporarily unavailable.',
    'en-us': 'The page you are looking for may have been removed or is temporarily unavailable.',
    fr: 'La page que vous recherchez a été supprimée ou est temporairement non disponible.',
    de: 'Die Seite, die Sie suchen, wurde möglicherweise entfernt oder ist vorübergehend nicht verfügbar.',
    pt: 'A página que procura poderá ter sido removida ou estar temporariamente indisponível.',
    nl: 'De pagina die u zoekt is mogelijk verwijderd, of is tijdelijk niet beschikbaar.',
    es: 'La página que estás buscando no está disponible temporalmente o puede que se haya eliminado.',
    it: 'La pagina che si sta cercando potrebbe essere stata rimossa o è temporaneamente non disponibile.',
    zh: '您要找的页面已被移除，或暂时无法查看。',
    ko: '페이지가 삭제되었거나 일시적으로 사용할 수 없습니다.',
    'zh-tw': '您要找的頁面可能已被移除，或暫時無法使用。',
    'zh-hk': '您要找的頁面可能已被刪除，或暫時無法使用。',
    th: 'ไม่พบหน้าที่คุณต้องการค้นหาหรือหน้าดังกล่าวใช้งานไม่ได้ชั่วคราว',
  },
  {
    key: 'pageNotFound_discovery',
    'en-gb': 'Discover',
    'en-us': 'Discover',
    fr: 'Découvrir',
    de: 'Entdecken',
    pt: 'Descobrir',
    nl: 'Ontdek',
    es: 'Descubrir',
    it: 'Scopri',
    zh: '即刻探索',
    ko: '더 알아보기',
    'zh-tw': '探索',
    'zh-hk': '探索',
    th: 'ชมเพิ่มเติม',
  },
  {
    key: 'matchingbands_filter',
    'en-gb': 'Filter',
    'en-us': 'Filter',
    fr: 'Filtrer',
    de: 'Filter',
    pt: 'Filtrar',
    nl: 'Filter',
    es: 'Filtrar',
    it: 'Filtra',
    zh: '筛选',
    ko: '필터',
    'zh-tw': '篩選',
    'zh-hk': '篩選',
    th: 'เลือก',
  },
  {
    key: 'matchingbands_mayWeSuggest',
    'en-gb': "The Maison's Suggestions",
    'en-us': 'You May Also Like',
    fr: 'Les suggestions de Cartier',
    de: 'Die Empfehlungen der Maison',
    pt: 'As sugestões da Maison',
    nl: 'Suggesties van het Maison',
    es: 'Las sugerencias de la Maison',
    it: 'I suggerimenti della Maison',
    zh: '卡地亚心选好礼',
    ko: '메종의 제안',
    'zh-tw': '卡地亞精選',
    'zh-hk': '卡地亞精選',
    th: 'ชิ้นงานคัดสรรของเมซง',
  },
  {
    key: 'matchingbands_readyToPurchase',
    'en-gb': 'Ready to Purchase?',
    'en-us': 'Ready to Purchase?',
    fr: 'Prêt à acheter ?',
    de: 'Bereit einzukaufen?',
    pt: 'Preparado(a) para comprar?',
    nl: 'Wilt u een aankoop doen?',
    es: '¿Listo para comprar?',
    it: 'Desideri acquistare?',
    zh: '准备订购？',
    ko: '제품을 구매하시겠습니까?',
    'zh-tw': '準備好選購？',
    'zh-hk': '準備好選購？',
    th: 'เลือกซื้อสินค้า',
  },
  {
    key: 'matchingbands_bookAnAppointment',
    'en-gb': 'Book an Appointment',
    'en-us': 'Book an Appointment',
    fr: 'Prendre rendez-vous.',
    de: 'Vereinbaren Sie einen Termin.',
    pt: 'Faça uma marcação',
    nl: 'Maak een afspraak',
    es: 'Concertar una cita',
    it: 'Prenota un appuntamento',
    zh: '预约会面',
    ko: '방문 예약하기',
    'zh-tw': '預約',
    'zh-hk': '預約',
    th: 'ทำการนัดหมาย',
  },
  {
    key: 'diamond_header',
    'en-gb': 'What Makes a Cartier Diamond',
    'en-us': 'What Makes a Cartier Diamond',
    fr: 'Comment se distinguent les diamants Cartier?',
    de: 'Was macht einen Diamanten von Cartier aus?',
    pt: 'Descubra os diamantes Cartier',
    nl: 'Wat maakt een Cartier Diamant?',
    es: 'En qué se distingue un diamante Cartier',
    it: 'L’eccezionalità dei diamanti Cartier',
    zh: '是什么造就了卡地亚钻石？',
    ko: '까르띠에의 다이아몬드가 되기까지',
    'zh-tw': '成就卡地亞鑽石的重要元素',
    'zh-hk': '成就卡地亞鑽石的重要元素',
    th: 'การรังสรรค์เพชรของคาร์เทียร์',
  },
  {
    key: 'diamond_Text',
    'en-gb':
      'In love, what counts is the intensity. The intense emotion of the gesture, of the heartbeat, and of the Cartier ring whose central stone reverberates with brilliance and passion inside its iconic red box. \n\nBut how does the Maison choose these diamonds - and how should you choose yours?',
    'en-us':
      'In love, what counts is the intensity. The intense emotion of the gesture, of the heartbeat, and of the Cartier ring whose central stone reverberates with brilliance and passion inside its signature red box. \n\nBut how does the Maison choose these diamonds—and how should you choose yours?',
    fr: "En amour, ce qui compte, c'est l'intensité. L'émotion du geste, du cœur qui bat, et d'une bague Cartier dont la pierre de centre reflète l'éclat et la passion contenus dans l'iconique écrin rouge. \n\nDécouvrez comment la Maison choisit ses diamants et comment choisir le vôtre.",
    de: 'Ein Ring von Cartier mit einem Zentraldiamant, der in seiner ikonischen roten Box funkelt, ist etwas ganz Besonderes.\n\nWie wählt die Maison diese Diamanten aus – und wie sollten Sie Ihren auswählen?',
    pt: 'No amor, o que conta é a intensidade. A emoção intensa do gesto, do bater do coração e do anel Cartier, cuja pedra central reverbera com brilho e paixão no interior da sua emblemática caixa vermelha.\n\nMas como é que a Maison escolhe estes diamantes e como você deve escolher o seu?',
    nl: 'In de liefde draait het om de intensiteit. De intense emotie van het gebaar, van de hartslag, en van de Cartier-ring waarvan de centrale edelsteen zindert van schittering en passie in zijn iconische rode doos. \n\nMaar hoe selecteert het Maison deze diamanten - en hoe selecteert u de uwe?',
    es: 'En el amor, lo que cuenta es la intensidad. La intensa emoción de un gesto, de un latido y del anillo Cartier, cuya piedra central emana brillo y pasión dentro de su icónico estuche rojo. \n\nPero, ¿cómo escoge la Maison estos diamantes, y cómo deberías escoger tú el tuyo?',
    it: "In amore è l’intensità che conta, trasmessa attraverso gesti ed emozioni. Queste ultime possono essere racchiuse in un anello Cartier, la cui pietra centrale  - custodita all'interno dell'iconico scrigno rosso - brilla di una luce unica. \n\nMa come fa la Maison a scegliere questi diamanti e come dovresti scegliere il tuo?",
    zh: '爱的意义，在于情意之深。许下承诺，心跳加速，卡地亚戒指上璀璨夺目的主钻折射出炙热真情，在品牌标志性红盒内共鸣回荡，闪烁着热烈光芒。\n \n\n卡地亚是如何选择钻石？您又该如何选择？',
    ko: '사랑에서 중요한 것은 강렬함입니다. 설레는 제스처와 두근거림 그리고 상징적인 레드 박스. 그 안에서 화사한 광채와 열정을 뿜어내는 센터 스톤을 품고 있는 까르띠에 링. 이 모든 것이 이 모든 감정을 끌어냅니다.\n\n메종은 이러한 다이아몬드를 어떻게 선택하며, 여러분은 어떻게 선택해야 할까요?',
    'zh-tw':
      '成就動人浪漫，告白熾熱真愛。戀人的舉手投足、搏動心跳和卡地亞戒指凝聚熾熱情感，璀璨的中央主鑽在經典紅盒之中綻放光芒，洋溢濃情蜜意。\n\n然而，卡地亞挑選鑽石有何準則？而您又該如何選擇鑽石？',
    'zh-hk':
      '成就動人浪漫，告白熾熱真愛。戀人的舉手投足、搏動心跳和卡地亞戒指凝聚熾熱情感，璀璨的中央主鑽在經典紅盒之中綻放光芒，洋溢濃情蜜意。\n\n然而，卡地亞挑選鑽石有何準則？而您又該如何選擇鑽石？',
    th: 'สิ่งสำคัญของความรักคือความปรารถนาอันแรงกล้า ความรักอันลึกซึ้งที่สื่อออกมาด้วยท่าทาง จังหวะการเต้นของหัวใจ และแหวนคาร์เทียร์ที่โดดเด่นด้วยอัญมณีเม็ดกลางสะท้อนความเจิดจรัสและความหลงใหลภายในกล่องสีแดงที่มีเอกลักษณ์\n\nสำรวจวิธีที่เมซงใช้เลือกสรรเพชรเหล่านี้และคำแนะนำในการเลือกสรรเพชรของคุณ',
  },
  {
    key: 'diamond_header_4c',
    'en-gb': 'The 4Cs',
    'en-us': 'The 4Cs',
    fr: 'Les 4C',
    de: 'Die 4 Cs',
    pt: 'Os 4 C',
    nl: 'De 4 C’s',
    es: 'Las 4 C',
    it: 'Le 4 C',
    zh: '4C标准',
    ko: '4C',
    'zh-tw': '「4C」標準',
    'zh-hk': '「4C」標準',
    th: 'หลักเกณฑ์ 4C',
  },
  {
    key: 'diamond_Text_4c',
    'en-gb':
      'With their precise skills and trained eyes, Cartier chooses only centre stone diamonds of singularity and charm, of a beauty that is absolute and remarkable. This process is based on experience, supported by the criteria defined and certified by the Gemological Institute of America, known as the 4Cs. This is how Cartier applies Cut, Colour, Clarity and Carat to its GIA certified diamonds.',
    'en-us':
      "A Cartier diamond should reflect the light with perfect brilliance, fire and sparkle. The Cartier signature lies in the Maison's expertise in a unique creative vision. This process is based on experience, supported by the criteria defined and certified by the Gemological Institute of America, known as the 4Cs. This is how Cartier applies Cut, Color, Clarity and Carat to its GIA certified diamonds.",
    fr: 'D’un œil expert, s’appuyant sur des savoir-faire toujours plus exigeants, les joailliers Cartier sélectionnent uniquement les diamants de centre qu’ils ont repérés pour leur singularité, leur charme exceptionnel et leur beauté absolue. Ce choix s’appuie sur l’expérience de la Maison et sur des critères universels, définis et certifiés par le Gemmological Institute of America : les 4 C. Voici comment Cartier applique ces critères de taille (cut), couleur, pureté (clarity) et caratage à ses diamants certifiés par le GIA.',
    de: 'Mit präzisem Geschick und geschultem Blick wählt Cartier nur Diamanten als Zentralsteine aus, die einzigartig und charmant sind, von absoluter und bemerkenswerter Schönheit. Dieser Prozess basiert auf Erfahrung, unterstützt durch die vom Gemological Institute of America definierten und zertifizierten Kriterien, die als die 4 Cs bekannt sind. So wendet Cartier Cut, Colour, Clarity und Carat auf seine GIA-zertifizierten Diamanten an.',
    pt: 'Com as suas técnicas precisas e olhos experientes, a Cartier escolhe apenas diamantes centrais singulares e com encanto, de uma beleza absoluta e notável. Este processo é baseado na experiência e apoiado pelos critérios definidos e certificados pelo Gemological Institute of America, conhecidos como os 4 C. É assim que a Cartier aplica o Corte, a Cor, a Claridade e o Quilate (Carat) aos seus diamantes certificados pelo GIA.',
    nl: "Met haar precieze vakmanschap en oog voor detail kiest Cartier alleen centrale diamanten die uniek en charmant zijn, van een absolute en opmerkelijke schoonheid. Dit proces is gebaseerd op ervaring, ondersteund door de criteria gedefinieerd en gecertificeerd door het Gemological Institute of America, bekend als de 4 C's. Dit is hoe Cartier Cut, Colour, Clarity en Carat toepast op haar GIA gecertificeerde diamanten.",
    es: 'Gracias a sus cuidadas habilidades y a sus ojos expertos, Cartier solo escoge diamantes centrales extraordinarios y fascinantes, de una belleza absoluta y notable. Este proceso se basa en la experiencia y se apoya en los criterios definidos y certificados por el Instituto gemológico de América (Gemological Institute of America o GIA), conocidos como las 4 C. Así es como Cartier aplica la talla, el color, la claridad y el peso en quilates a sus diamantes certificados por el GIA.',
    it: 'Con il suo straordinario savoir-faire e la sua magistrale competenza in materia di pietre preziose, Cartier sceglie solo diamanti centrali dal fascino e dalla bellezza impareggiabili. Questo processo si basa sull’esperienza, supportata dai criteri definiti e certificati dal Gemological Institute of America, noti come 4 C: "carat", "colour", "clarity" e "cut" (caratura, colore, chiarezza e taglio). Ecco come Cartier applica questi criteri ai suoi diamanti certificati GIA.',
    zh: '运用精准技艺，凭借独到眼光，卡地亚专家悉心甄选独具个性与美感的中央主钻，华美光彩令人难忘。这一甄选过程以专家的经验为基础，同时参照美国宝石鉴定学院（GIA）制定并认证的4C标准。接下来我们会一一介绍，卡地亚如何依据GIA认证标准，选出“切工”、“颜色”、“净度”和“克拉”皆出类拔萃的钻石。',
    ko: '까르띠에는 정교한 기술과 탁월한 안목을 바탕으로 절대적인 아름다움과 독보적인 매력을 지닌 센터 스톤 다이아몬드만을 선별합니다. 이 과정은 경험뿐 아니라 ‘4C’로 알려진, 미국 보석 감정위원회(GIA, Gemological Institute of America)에서 정의하고 인증한 기준을 기반으로 합니다. 이는 까르띠에가 GIA 인증 다이아몬드에 컷, 컬러, 투명도와 캐럿을 적용하는 방법입니다.',
    'zh-tw':
      '卡地亞專家以精確技術和獨到眼光，僅選擇璀璨獨特、出類拔萃的中央主鑽。挑選過程十分講求經驗，並遵從由美國寶石學院（Gemological Institute of America）所定義和認證的「4C」標準。以下為您介紹卡地亞對 GIA 認證鑽石的切工（Cut）、顏色（Colour）、淨度（Clarity）和克拉（Carat）準則。',
    'zh-hk':
      '卡地亞專家以精確技術和獨到眼光，僅選擇璀璨獨特、出類拔萃的中央主鑽。挑選過程十分講求經驗，並遵從由美國寶石學院（Gemological Institute of America）所定義和認證的「4C」標準。以下為您介紹卡地亞對 GIA 認證鑽石的切工（Cut）、顏色（Colour）、淨度（Clarity）和克拉（Carat）準則。',
    th: 'ด้วยทักษะและสายตาที่ผ่านการฝึกฝนมาเป็นอย่างดี คาร์เทียร์เลือกใช้เพชรเม็ดกลางที่มีเอกลักษณ์และเสน่ห์ดึงดูดใจ ซึ่งเป็นความงามที่สมบูรณ์แบบและโดดเด่น กระบวนการนี้ต้องอาศัยประสบการณ์ที่สั่งสมมาอย่างยาวนาน เสริมด้วยหลักเกณฑ์ที่กำหนดและรับรองโดยสถาบันอัญมณีศาสตร์ของสหรัฐอเมริกา ที่เรียกว่า 4C คาร์เทียร์คัดสรรเพชรโดยอ้างอิงหลักเกณฑ์การเจียระไน สี ความใสบริสุทธิ์ และกะรัต ซึ่งนำไปสู่เพชรที่ได้รับการรับรองจาก GIA',
  },
  {
    key: 'diamond_header_carat',
    'en-gb': 'Carat',
    'en-us': 'Carat',
    fr: 'Carat',
    de: 'Karat',
    pt: 'Quilate',
    nl: 'Carat',
    es: 'Quilate',
    it: 'Caratura',
    zh: '克拉（Carat）',
    ko: '캐럿',
    'zh-tw': '克拉（Carat）',
    'zh-hk': '克拉（Carat）',
    th: 'กะรัต (Carat)',
  },
  {
    key: 'diamond_text_carat',
    'en-gb':
      'Carat measures the weight of a diamond; each one is equal to 0.2 grams. At Cartier, our diamonds are chosen firstly for their beauty, then set into a creation according to their size. The Cartier Difference ensures that a singular diamond of any size becomes sublime through the work of our experts.',
    'en-us':
      'The unit of measurement of diamond weight is the carat; its name comes from the carob seed that was once used to weigh diamonds. One carat is equivalent to 0.20 grams. Cartier offers a very wide range of carat weights, depending on the setting.',
    fr: 'Le carat est l’unité de mesure du poids des diamants. Un carat équivaut à 0,2 gramme. Chez Cartier, les diamants sont d’abord choisis pour leur beauté, puis placés sur une création en fonction de leur poids. La Maison affirme sa différence en garantissant que tous nos diamants, quel que soit leur poids, sont sublimés par le travail de nos experts.',
    de: 'Karat misst das Gewicht eines Diamanten; jedes Karat entspricht 0,2 Gramm. Bei Cartier werden unsere Diamanten zunächst aufgrund ihrer Schönheit ausgewählt und dann entsprechend ihrer Größe in eine Kreation gefasst. Cartier ermöglicht, dass ein einzigartiger Diamant jeder Größe durch die Arbeit unserer Expert:innen zu etwas Erhabenem wird.',
    pt: 'O quilate mede o peso de um diamante, equivalendo cada a 0,2 g. Na Cartier, os nossos diamantes são escolhidos, em primeiro lugar, pela sua beleza e depois engastados numa criação de acordo com o seu tamanho. A Diferença Cartier assegura que um diamante singular, de qualquer tamanho, se torna sublime com o trabalho de especialistas.',
    nl: 'Karaat meet het gewicht van een diamant; elke karaat is gelijk aan 0,2 gram. Bij Cartier worden onze diamanten in de eerste plaats gekozen om hun schoonheid en vervolgens naargelang hun grootte in een creatie gezet. Het Cartier Difference zorgt ervoor dat een unieke diamant van elke grootte subliem wordt door het vakmanschap van onze deskundigen.',
    es: 'El quilate es la medida que indica el peso de un diamante; cada uno equivale a 0,2 gramos. En Cartier, primero escogemos nuestros diamantes por su belleza y, luego, los engastamos en una creación acorde a su tamaño. La Diferencia Cartier garantiza que un diamante especial de cualquier tamaño se convierta en una creación sublime gracias al trabajo de nuestros expertos.',
    it: 'La caratura misura il peso di un diamante. Ogni carato equivale a 0,2 grammi. Cartier sceglie i propri diamanti innanzitutto per la loro bellezza e poi li incastona in una creazione in base alle loro dimensioni.  Cartier assicura che un diamante di qualsiasi dimensione diventi eccezionale grazie al lavoro dei nostri esperti.',
    zh: '克拉，即钻石重量单位。1克拉相当于0.2克。钻石的美感，是卡地亚的首要甄选标准，随后根据其不同大小镶嵌至作品之中。卡地亚的匠心之处，是确保任何大小的钻石经工匠大师的巧手，皆能绽放璀璨光华。',
    ko: '캐럿은 다이아몬드 무게를 측정합니다. 1캐럿은 0.2g입니다. 까르띠에는 아름다운 다이아몬드를 최우선으로 선택하고 다이아몬드 크기에 따라 제품을 세팅합니다. 까르띠에의 특별함은 전문가의 작업을 통해 어떤 크기의 다이아몬드라도 고귀해진다는 점에 있습니다.',
    'zh-tw':
      '克拉是量度鑽石重量的單位，1克拉等於0.2克。卡地亞挑選鑽石的首要條件是美態，然後再根據其尺寸，將其鑲嵌於珠寶作品之上。  卡地亞的獨特之處是確保任何大小的鑽石在我們專家的匠心巧手下均變得高貴非凡。',
    'zh-hk':
      '克拉是量度鑽石重量的單位，1克拉等於0.2克。卡地亞挑選鑽石的首要條件是美態，然後再根據其尺寸，將其鑲嵌於珠寶作品之上。  卡地亞的獨特之處是確保任何大小的鑽石在我們專家的匠心巧手下均變得高貴非凡。',
    th: 'กะรัตคือหน่วยวัดน้ำหนักของเพชร โดยที่แต่ละกะรัตมีค่าเท่ากับน้ำหนัก 0.2 กรัม ที่คาร์เทียร์ เราคัดสรรเพชรจากความงามก่อนเป็นอันดับแรก จากนั้นจึงจัดวางองค์ประกอบของชิ้นงานตามขนาด คาร์เทียร์สร้างความแตกต่างโดยการรับประกันว่าเพชรเม็ดเดี่ยวทุกขนาดนั้นจะกลายเป็นชิ้นงานที่เจิดจรัสด้วยความความมุ่งมั่นของผู้เชี่ยวชาญของเรา',
  },
  {
    key: 'diamond_header_colour',
    'en-gb': 'Colour',
    'en-us': 'Color',
    fr: 'Couleur',
    de: 'Farbe',
    pt: 'Cor',
    nl: 'Colour',
    es: 'Color',
    it: 'Colore',
    zh: '颜色（Colour）',
    ko: '컬러',
    'zh-tw': '顏色（Colour）',
    'zh-hk': '顏色（Colour）',
    th: 'สี (Colour)',
  },
  {
    key: 'diamond_text_colour',
    'en-gb':
      'The colour of a diamond affects instantly how we perceive it - the most elusive and most valuable are colourless. The official scale runs across 23 grades from D, colourless, to Z, light yellow. Colour is key to the Cartier Difference, and the Maison chooses its stones only in the range of D to H.',
    'en-us':
      'The closer a white diamond is to colorless, the more exceptional the stone. The color grade of a diamond is evaluated by the GIA on a scale from D (colorless) to Z (light yellow or brown). Cartier center stone diamonds are selected exclusively from those rated D to H.',
    fr: 'La couleur d’un diamant affecte la perception immédiate que nous en avons. Les diamants incolores sont les plus rares et les plus précieux. L’échelle de notation se compose de 23 grades, de D (incolore) à Z (jaune pâle). La couleur est une composante fondamentale de la différence Cartier. La Maison choisit uniquement des pierres notées de D à H.',
    de: 'Die Farbe eines Diamanten beeinflusst umgehend, wie wir ihn wahrnehmen – die Wertvollsten sind farblos. Die offizielle Skala umfasst 23 Stufen von D, farblos, bis Z, hellgelb. Die Farbe macht den Unterschied von Cartier aus, und die Maison wählt ihre Steine nur in den Stufen D bis H aus.',
    pt: 'A cor de um diamante afeta instantaneamente a forma como o percecionamos; os mais elusivos e valiosos não têm cor. A escala oficial de 23 níveis varia de D, incolor, a Z, amarelo claro. A cor é essencial para a Diferença Cartier e a Maison escolhe as suas pedras na escala de D até H.',
    nl: 'De kleur van een diamant beïnvloedt onmiddellijk hoe we hem waarnemen - de meest ongrijpbare, en de meest waardevolle, zijn kleurloos. De officiële schaal omvat 23 gradaties, van D, kleurloos, tot Z, lichtgeel. Kleur is de sleutel tot het Cartier Difference, en het Maison selecteert haar stenen alleen in het bereik van D tot H.',
    es: 'El color de un diamante influye inmediatamente en cómo lo percibimos: los más valiosos y más difíciles de encontrar son incoloros. La escala oficial abarca 23 niveles que van desde el D, incoloro, hasta el Z, amarillo claro. El color es un concepto clave para la Diferencia Cartier, y la Maison solo escoge piedras clasificadas entre los niveles D y H.',
    it: 'Il colore di un diamante influisce immediatamente su come viene percepito allo sguardo. Le gemme più scintillanti e preziose sono incolori. La scala ufficiale comprende 23 gradi da D, incolore, a Z, giallo chiaro. Il colore è ciò che distingue i diamanti Cartier: la Maison sceglie le proprie pietre appartenenti alle categorie comprese tra D e H.',
    zh: '钻石的颜色会直接影响观感——品质越上乘的钻石越最具价值接近无色。钻石颜色的标准共分为23个等级，从D，优质无色，到Z，光芒黄色。颜色是卡地亚钻石不同之处的关键，卡地亚仅选用D至H色的钻石。',
    ko: '다이아몬드 컬러는 우리가 다이아몬드를 인지하는 방식에 곧바로 영향을 끼칩니다. 가장 찾기 어려운 고귀한 다이아몬드는 바로 무색(colourless)입니다. 공식적인 척도는 D(무색)에서 Z(밝은 노란색)까지 23개 등급으로 이루어져 있습니다. 까르띠에의 특별함에서 컬러는 중요한 역할을 하며, 메종은 D에서 H 등급 사이의 스톤만을 선택합니다.',
    'zh-tw':
      '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。官方設有23個等級，由D級無色，至Z級淡黃色。顏色是卡地亞獨特之處的關鍵，卡地亞僅選用D至H級的鑽石。',
    'zh-hk':
      '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。官方設有23個等級，由 D 級無色，至 Z 級淡黃色。顏色是卡地亞獨特之處的關鍵，卡地亞僅選用 D 至 H 級的鑽石。',
    th: 'สีของเพชรนั้นดึงดูดความสนใจของเราได้ในทันที ซึ่งเพชรที่ไร้สีคือเพชรที่หายากที่สุดและมีค่าที่สุด โดยสเกลการวัดอย่างเป็นทางการมีทั้งหมด 23 ระดับ จากระดับ D หรือไม่มีสี ไปจนถึงระดับ Z หรือสีอ่อน สีคือกุญแจสำคัญสำหรับความแตกต่างของคาร์เทียร์ และเมซงคัดสรรเฉพาะอัญมณีที่มีค่าสีระดับ D ถึง H เท่านั้น',
  },
  {
    key: 'diamond_colour_d',
    'en-gb': 'D',
    'en-us': 'D',
    fr: 'D',
    de: 'D',
    pt: 'D',
    nl: 'D',
    es: 'D',
    it: 'D',
    zh: 'D',
    ko: 'D',
    'zh-tw': 'D',
    'zh-hk': 'D',
    th: 'D',
  },
  {
    key: 'diamond_colour_e',
    'en-gb': 'E',
    'en-us': 'E',
    fr: 'E',
    de: 'E',
    pt: 'E',
    nl: 'E',
    es: 'E',
    it: 'E',
    zh: 'E',
    ko: 'E',
    'zh-tw': 'E',
    'zh-hk': 'E',
    th: 'E',
  },
  {
    key: 'diamond_colour_f',
    'en-gb': 'F',
    'en-us': 'F',
    fr: 'F',
    de: 'F',
    pt: 'F',
    nl: 'F',
    es: 'F',
    it: 'F',
    zh: 'F',
    ko: 'F',
    'zh-tw': 'F',
    'zh-hk': 'F',
    th: 'F',
  },
  {
    key: 'diamond_colour_g',
    'en-gb': 'G',
    'en-us': 'G',
    fr: 'G',
    de: 'G',
    pt: 'G',
    nl: 'G',
    es: 'G',
    it: 'G',
    zh: 'G',
    ko: 'G',
    'zh-tw': 'G',
    'zh-hk': 'G',
    th: 'G',
  },
  {
    key: 'diamond_colour_h',
    'en-gb': 'H',
    'en-us': 'H',
    fr: 'H',
    de: 'H',
    pt: 'H',
    nl: 'H',
    es: 'H',
    it: 'H',
    zh: 'H',
    ko: 'H',
    'zh-tw': 'H',
    'zh-hk': 'H',
    th: 'H',
  },
  {
    key: 'diamond_colour_iz',
    'en-gb': 'I.Z',
    'en-us': 'I.Z',
    fr: 'I.Z',
    de: 'I.Z',
    pt: 'I.Z',
    nl: 'I.Z',
    es: 'I.Z',
    it: 'I.Z',
    zh: 'I.Z',
    ko: 'I.Z',
    'zh-tw': 'I.Z',
    'zh-hk': 'I.Z',
    th: 'I.Z',
  },
  {
    key: 'diamond_clarity_header',
    'en-gb': 'Clarity',
    'en-us': 'Clarity',
    fr: 'Clarté',
    de: 'Reinheit',
    pt: 'Claridade',
    nl: 'Clarity',
    es: 'Claridad',
    it: 'Chiarezza',
    zh: '净度（Clarity）',
    ko: '투명도',
    'zh-tw': '淨度（Clarity）',
    'zh-hk': '淨度（Clarity）',
    th: 'ความใสบริสุทธิ์ (Clarity)',
  },
  {
    key: 'diamond_clarity_Text',
    'en-gb':
      'Natural imperfections called inclusions affect the quality of a diamond. Each stone is placed on an 11-point scale that runs from flawless diamonds to stones with many visible inclusions to the naked eye. The Cartier difference means that diamonds are chosen only from FL to VS2. These are stones of exceptional purity, with zero inclusions visible to the naked eye.',
    'en-us':
      'Inclusions and blemishes are natural characteristics in a diamond. The clarity of a diamond is determined by the size, number, position, relief and type of characteristics. It is rated by the GIA on a scale from FL (Flawless = no characteristics are detectable under 10x magnification) to I3 (Included = numerous inclusions visible to the naked eye). Cartier diamonds are only selected between FL and VS2, which guarantees exceptional clarity and the absence of the slightest inclusion visible to the naked eye.',
    fr: 'La pureté indique la présence ou non d’imperfections naturelles, appelées « inclusions », affectant la qualité du diamant. Chaque pierre est notée de 0, pour aucune imperfection visible à la loupe, à 11 lorsque de nombreuses inclusions sont visibles à l’œil nu. La différence Cartier ? Nous sélectionnons uniquement des pierres notées entre FL et VS2. Ces diamants à la pureté exceptionnelle ne présentent aucune inclusion visible à l’œil nu pour une réfraction incroyable de la lumière.',
    de: 'Natürliche Unvollkommenheiten, sogenannte Einschlüsse, beeinträchtigen die Qualität eines Diamanten. Jeder Stein wird auf einer 11-Punkte-Skala eingestuft, die von lupenreinen Diamanten bis zu Steinen mit vielen mit bloßem Auge sichtbaren Einschlüssen reicht. Cartier wählt nur Diamanten von FL bis VS2 aus. Hierbei handelt es sich um Steine von außergewöhnlicher Reinheit, die keine mit bloßem Auge sichtbaren Einschlüsse aufweisen.',
    pt: 'As imperfeições naturais, chamadas inclusões, afetam a qualidade de um diamante. Cada pedra é classificada segundo uma escala de 11 pontos que varia dos diamantes perfeitos às pedras com várias inclusões visíveis a olho nu. A Diferença Cartier significa que os diamantes são escolhidos apenas na escala de FL até VS2. Estas são pedras de pureza excecional, com zero inclusões visíveis a olho nu.',
    nl: 'Natuurlijke onvolkomenheden, "insluitsels" genaamd, beïnvloeden de kwaliteit van een diamant. Elke steen wordt op een schaal van 11 punten geplaatst, die gaat van onberispelijke diamanten tot stenen met veel met het blote oog zichtbare insluitsels. Het Cartier Difference houdt in dat uitsluitend diamanten worden gekozen van FL tot VS2. Dit zijn stenen van uitzonderlijke zuiverheid, zonder insluitsels die met het blote oog te zien zijn.',
    es: 'Las imperfecciones naturales llamadas “inclusiones” afectan a la calidad de un diamante. Cada piedra se clasifica en una escala de 11 puntos que va desde diamantes impecables hasta piedras con muchas inclusiones apreciables a simple vista. La Diferencia Cartier significa que solo se escogen diamantes entre los niveles FL (del inglés Flawless, impecable) al VS2 (del inglés Very Slightly Included 2, inclusiones muy leves 2). Se trata de piedras de una pureza excepcional, sin ninguna inclusión apreciable a simple vista.',
    it: 'Le imperfezioni naturali, chiamate “inclusioni”, influiscono sulla qualità di un diamante. Ogni pietra è collocata su una scala a 11 punti che va da diamanti privi di difetti a pietre con numerose inclusioni visibili a occhio nudo. Cartier sceglie esclusivamente diamanti compresi tra le categorie FL e VS2. Si tratta di pietre di eccezionale purezza, prive di inclusioni visibili a occhio nudo.',
    zh: '内含物是钻石内部的天然瑕疵，会影响钻石的品质。净度被分为11个等级，从无瑕级至明显肉眼可见瑕疵的内含级。卡地亚独具匠心，仅选用净度为FL至VS2级的钻石。它们拥有高等级的净度，同时没有肉眼可见的内含物。',
    ko: '내포물이라 불리는 천연 불순물은 다이아몬드 품질에 영향을 끼칩니다. 모든 스톤은 내포물이 전혀 보이지 않는 무결점 다이아몬드에서 육안으로 보이는 내포물이 많은 단계까지 총 11개의 등급으로 나뉩니다. 까르띠에의 특별함은 FL에서 VS2 단계의 다이아몬드만을 선택한다는 점에 있습니다. 이들은 눈으로는 거의 내포물이 보이지 않을 만큼 극도로 순수한 스톤입니다.',
    'zh-tw':
      '「內含物」為天然瑕疵，會影響到鑽石的品質。每顆鑽石均分為11級，由無暇到存有很多肉眼可見的內含物。卡地亞的獨特之處是我們只選用由FL至VS2級極為純淨的鑽石，並不能以肉眼看到內含物。',
    'zh-hk':
      '「內含物」為天然瑕疵，會影響到鑽石的品質。每顆鑽石均分為11級，由無暇到存有很多肉眼可見的內含物。卡地亞的獨特之處是我們只選用由 FL 至 VS2 級極為純淨的鑽石，並不能以肉眼看到內含物。',
    th: 'ความไม่สมบูรณ์ตามธรรมชาติที่เรียกว่าตำหนิส่งผลต่อคุณภาพของเพชร เราจะเทียบอัญมณีแต่ละเม็ดจากระดับ 0 ถึง 11 ซึ่งเป็นระดับตั้งแต่เพชรที่ไร้ตำหนิไปจนถึงอัญมณีที่มีตำหนิมากมายที่มองเห็นได้ด้วยตาเปล่า ความแตกต่างของคาร์เทียร์คือการเลือกสรรเพชรระดับไร้ตำหนิ FL ไปจนถึงระดับ VS2 เท่านั้น โดยเพชรเหล่านี้มีความบริสุทธิ์เป็นพิเศษ ปราศจากตำหนิที่มองเห็นได้ด้วยตาเปล่า',
  },
  {
    key: 'diamond_clarity_f',
    'en-gb': 'FL',
    'en-us': 'FL',
    fr: 'FL',
    de: 'FL',
    pt: 'FL',
    nl: 'FL',
    es: 'FL',
    it: 'FL',
    zh: 'FL',
    ko: 'FL',
    'zh-tw': 'FL',
    'zh-hk': 'FL',
    th: 'FL',
  },
  {
    key: 'diamond_clarity_if',
    'en-gb': 'IF',
    'en-us': 'IF',
    fr: 'IF',
    de: 'IF',
    pt: 'IF',
    nl: 'IF',
    es: 'IF',
    it: 'IF',
    zh: 'IF',
    ko: 'IF',
    'zh-tw': 'IF',
    'zh-hk': 'IF',
    th: 'IF',
  },
  {
    key: 'diamond_clarity_vvs1',
    'en-gb': 'VVS1',
    'en-us': 'VVS1',
    fr: 'VVS1',
    de: 'VVS1',
    pt: 'VVS1',
    nl: 'VVS1',
    es: 'VVS1',
    it: 'VVS1',
    zh: 'VVS1',
    ko: 'VVS1',
    'zh-tw': 'VVS1',
    'zh-hk': 'VVS1',
    th: 'VVS1',
  },
  {
    key: 'diamond_clarity_vvs2',
    'en-gb': 'VVS2',
    'en-us': 'VVS2',
    fr: 'VVS2',
    de: 'VVS2',
    pt: 'VVS2',
    nl: 'VVS2',
    es: 'VVS2',
    it: 'VVS2',
    zh: 'VVS2',
    ko: 'VVS2',
    'zh-tw': 'VVS2',
    'zh-hk': 'VVS2',
    th: 'VVS2',
  },
  {
    key: 'diamond_clarity_vs1',
    'en-gb': 'VS1',
    'en-us': 'VS1',
    fr: 'VS1',
    de: 'VS1',
    pt: 'VS1',
    nl: 'VS1',
    es: 'VS1',
    it: 'VS1',
    zh: 'VS1',
    ko: 'VS1',
    'zh-tw': 'VS1',
    'zh-hk': 'VS1',
    th: 'VS1',
  },
  {
    key: 'diamond_clarity_vs2',
    'en-gb': 'VS2',
    'en-us': 'VS2',
    fr: 'VS2',
    de: 'VS2',
    pt: 'VS2',
    nl: 'VS2',
    es: 'VS2',
    it: 'VS2',
    zh: 'VS2',
    ko: 'VS2',
    'zh-tw': 'VS2',
    'zh-hk': 'VS2',
    th: 'VS2',
  },
  {
    key: 'diamond_clarity_s1113',
    'en-gb': '',
    'en-us': '',
    fr: '',
    de: '',
    pt: '',
    nl: '',
    es: '',
    it: '',
    zh: '',
    ko: '',
    'zh-tw': '',
    'zh-hk': '',
    th: '',
  },
  {
    key: 'diamond_headert_cut',
    'en-gb': 'Cut',
    'en-us': 'Cut',
    fr: 'Taille',
    de: 'Schliff',
    pt: 'Corte',
    nl: 'Cut',
    es: 'Talla',
    it: 'Taglio',
    zh: '切工（Cut）',
    ko: '컷',
    'zh-tw': '切工（Cut）',
    'zh-hk': '切工（Cut）',
    th: 'การเจียระไน',
  },
  {
    key: 'diamond_text_cut',
    'en-gb':
      "Cut refers to a stone’s shape, symmetry, and balance. The Cartier difference lies in the virtuosity of the Maison’s artisans and their choice of cuts and proportions for these exceptional gems, allowing them to reveal their magnificent light. Cut is graded on a five-point scale from 'Excellent' to 'Poor'. Our creations use only diamonds from the 'Excellent' to 'Good' range with a singular fire and brilliance.",
    'en-us':
      "The cut of a diamond, which leads to its symmetry and balance, is a crucial factor in the 4Cs. The GIA rates a round brilliant diamond's cut on a scale of five grades ranging from “Excellent” to “Poor.” This is entirely dependent upon the talent and expertise of the diamond cutter. Cartier center stone diamonds are selected exclusively from “Excellent” to “Good” cuts. Diamonds used for paving are also selected according to rigorous cut criteria to ensure they harmonize with the central gems. Whether they are center stone diamonds or used for paving, they all play with light, thanks to their elegant shape and perfect balance.",
    fr: "La taille fait référence à la forme, la symétrie et l'équilibre du diamant. La différence Cartier tient à la virtuosité des artisans de la Maison et à un choix de tailles et de proportions qui sublime et exalte l’éclat de ces pierres d’exception. La taille est notée sur une échelle de cinq niveaux, d’Excellent à Poor. Pour nos créations, nous utilisons uniquement des diamants notés d’Excellent à Good, remarquables pour leur brillance et leur feu.",
    de: 'Der Schliff bezieht sich auf die Form, Symmetrie und Ausgewogenheit eines Steins. Das Besondere an Cartier ist die Virtuosität der Kunsthandwerker:innen der Maison und die Wahl der Schliffe und Proportionen für diese außergewöhnlichen Edelsteine, die es ihnen ermöglichen, ihr prächtiges Licht zu entfalten. Der Schliff wird auf einer Fünf-Punkte-Skala von Ausgezeichnet bis Schlecht bewertet. Für unsere Kreationen verwenden wir ausschließlich Diamanten aus dem Bereich Exzellent bis Gut mit einzigartigem Feuer und Brillanz.',
    pt: 'O corte refere-se à forma, à simetria e ao equilíbrio de uma pedra. A Diferença Cartier reside na virtuosidade dos artesãos da Maison e na sua escolha dos talhes e das proporções para permitir que estas pedras preciosas excecionais revelem a sua luz magnífica. O corte é classificado a partir de uma escala de cinco pontos, que varia de Excelente a Mau. As nossas criações usam apenas diamantes na escala de Excelente a Bom, com um ardor e brilho singulares.',
    nl: 'De slijpkwaliteit verwijst naar de vorm, symmetrie en balans van een steen. Het Cartier Difference schuilt in de virtuositeit van de ambachtslieden van het Maison en hun keuze van de slijpkwaliteit en proporties van deze uitzonderlijke edelstenen, waardoor ze hun prachtige licht kunnen onthullen. De slijpkwaliteit wordt beoordeeld op een schaal van vijf punten, van uitstekend tot slecht. Voor onze creaties gebruiken wij uitsluitend diamanten uit de Excellent tot Good reeks met een uitzonderlijk vuur en schittering.',
    es: 'La talla hace referencia a la forma, la simetría y el equilibrio de una piedra. La Diferencia Cartier radica en el virtuosismo de los artesanos de la Maison y en su selección de tallas y proporciones para estas excepcionales gemas, lo que les permite revelar su magnífica luz. La talla se califica en una escala de cinco puntos que va de “excelente” a “pobre”. Para nuestras creaciones solo empleamos diamantes con una talla clasificada entre excelente y buena, con una luz y un brillo únicos.',
    it: 'Il taglio determina la forma, la simmetria e l’equilibrio di una pietra. Cartier si distingue per il virtuosismo dei suoi artigiani e nella scelta di tagli e proporzioni che permettono di rivelare la magnifica luce di queste gemme eccezionali. Il taglio è classificato su una scala a cinque punti che va da “eccellente” a “scarso”. Per tutte le sue creazioni, Cartier  utilizza solo diamanti con un taglio da “eccellente” a “buono” e con un fuoco e una brillantezza straordinari.',
    zh: '即钻石的形状、对称性和平衡度。卡地亚钻石独具匠心之处，在于品牌工匠的精湛技艺。他们选择恰到好处的切割比例，让钻石折射出耀眼光芒。切工标准设立了5种等级，从“卓越”到“不良”。卡地亚作品仅选用“卓越”至“良好”等级，且呈现独特火彩和光芒的钻石。',
    ko: '컷은 스톤의 형태, 대칭, 균형을 의미합니다. 까르띠에의 특별함은 메종 장인들의 전문성에 있습니다. 메종 장인들은 탁월한 스톤의 눈부신 광채를 더욱 돋보이게 해주는 컷과 비율을 선택합니다. 컷은 ‘탁월함(Excellent)’에서 ‘좋지 않음(Poor)’에 이르기까지 다섯 등급으로 나뉩니다. 까르띠에 크리에이션은 오로지 놀랍도록 뛰어난 광채를 갖춘 ‘탁월함(Excellent)’에서 ‘우수함(Good)’ 등급 사이의 다이아몬드만 사용합니다.',
    'zh-tw':
      '切工即鑽石的形狀、對稱和平衡。卡地亞的獨特之處在於品牌工匠的高超工藝，以及他們對非凡寶石的切割方式和比例選擇可讓鑽石盡顯光芒。切工分為「優秀」到「不良」五個等級。卡地亞作品只選用「優秀」至「良好」，且綻放獨特光芒的鑽石。',
    'zh-hk':
      '切工即鑽石的形狀、對稱和平衡。卡地亞的獨特之處在於世家工匠的高超工藝，以及他們對非凡寶石的切割方式和比例選擇可讓鑽石盡顯光芒。切工分為「極優」到「不良」五個等級。卡地亞作品只選用「極優」至「良好」，且綻放獨特光芒的鑽石。',
    th: 'การเจียระไนหมายถึงรูปทรงของอัญมณี ความสมมาตร และความสมดุล ความแตกต่างของคาร์เทียร์อยู่ที่ทักษะความชำนาญของเมซงและการเลือกสรรการเจียระไนและสัดส่วนสำหรับอัญมณีอันล้ำค่าเหล่านี้ เพื่อเผยให้เห็นประกายเจิดจรัสของอัญมณีอย่างงดงาม การเจียระไนจะผ่านการจัดอันดับจากสเกลทั้งห้า ตั้งแต่ ‘Excellent’ หรือดีเลิศไปจนถึง ‘Poor’ หรือแย่มาก เราเลือกใช้เพชรตั้งแต่ระดับ ‘Excellent’ หรือดีเลิศถึง ‘Good’ หรือดี พร้อมด้วยประกายสุกสว่างและความแวววาวที่มีเอกลักษณ์เฉพาะตัวเท่านั้น',
  },
  {
    key: 'diamond_cut_emerald',
    'en-gb': 'Emerald',
    'en-us': 'Emerald',
    fr: 'Emeraude',
    de: 'Smaragd',
    pt: 'Esmeralda',
    nl: 'Emerald',
    es: 'Esmeralda',
    it: 'Smeraldo',
    zh: '祖母绿型切割',
    ko: '에메랄드',
    'zh-tw': '祖母綠形',
    'zh-hk': '祖母綠式',
    th: 'ทรงสี่เหลี่ยมผืนผ้า',
  },
  {
    key: 'diamond_cut_brilliant',
    'en-gb': 'Brilliant',
    'en-us': 'Brilliant',
    fr: 'Brillant',
    de: 'Brillant',
    pt: 'Brilhante',
    nl: 'Briljant',
    es: 'Brillante',
    it: 'Brillante',
    zh: '圆形明亮式切割',
    ko: '브릴리언트',
    'zh-tw': '圓形明亮式',
    'zh-hk': '圓形明亮式',
    th: 'ทรงบริลเลียนท์',
  },
  {
    key: 'diamond_cut_oval',
    'en-gb': 'Oval',
    'en-us': 'Oval',
    fr: 'Ovale',
    de: 'Oval',
    pt: 'Oval',
    nl: 'Ovaal',
    es: 'Oval',
    it: 'Ovale',
    zh: '椭圆形切割',
    ko: '오벌',
    'zh-tw': '橢圓形',
    'zh-hk': '橢圓形',
    th: 'ทรงรี',
  },
  {
    key: 'diamond_cut_cushion',
    'en-gb': 'Cushion',
    'en-us': 'Cushion',
    fr: 'Coussin',
    de: 'Prinzess',
    pt: 'Almofada',
    nl: 'Kussen',
    es: 'Cojín',
    it: 'Cuscino',
    zh: '枕形切割',
    ko: '쿠션',
    'zh-tw': '枕形',
    'zh-hk': '枕形',
    th: 'ทรงสี่เหลี่ยมขอบมน',
  },
  {
    key: 'diamond_cut_pear',
    'en-gb': 'Pear',
    'en-us': 'Pear',
    fr: 'Poire',
    de: 'Tropfen',
    pt: 'Pera',
    nl: 'Peer',
    es: 'Pera',
    it: 'Pera',
    zh: '梨形切割',
    ko: '페어',
    'zh-tw': '梨形',
    'zh-hk': '梨形',
    th: 'ทรงลูกแพร์',
  },
  {
    key: 'diamond_header_setForYou',
    'en-gb': 'SET FOR YOU BY CARTIER',
    'en-us': 'SET FOR YOU BY CARTIER',
    fr: 'SET FOR YOU BY CARTIER',
    de: 'SET FOR YOU BY CARTIER',
    pt: 'SET FOR YOU BY CARTIER',
    nl: 'SET FOR YOU BY CARTIER',
    es: 'CARTIER SET FOR YOU',
    it: 'SET FOR YOU BY CARTIER',
    zh: '卡地亚SET FOR YOU钻石尊享定制服务',
    ko: '卡地亞SET FOR YOU鑽戒訂製服務',
    'zh-tw': '卡地亞SET FOR YOU鑽戒訂製服務',
    'zh-hk': '卡地亞 SET FOR YOU 鑽戒訂製服務',
    th: 'บริการ SET FOR YOU โดยคาร์เทียร์',
  },
  {
    key: 'diamond_text_setForYou',
    'en-gb':
      'For Cartier, love is a life-long adventure, an adventure that continues every day. To celebrate it, the Maison invites you to choose your diamond and setting to create a unique ring, to reflect your love, through the Set For You by Cartier service.',
    'en-us':
      'For Cartier, love is a life-long adventure, an adventure that continues every day. To celebrate it, the Maison invites you to choose your diamond and setting to create a unique ring, to reflect your love, through the Set For You by Cartier service.',
    fr: "Chez Cartier, l'amour est l’aventure de toute une vie, une aventure qui s’écrit au quotidien. Pour le célébrer, la Maison vous invite à choisir votre diamant et votre monture pour créer une bague unique, à votre image, grâce au service Set For You by Cartier.",
    de: 'Für Cartier ist Liebe ein lebenslanges Abenteuer, ein Abenteuer, das jeden Tag weitergeht. Um sie zu feiern, lädt die Maison Sie mit ihrem Set For You by Cartier Service dazu ein, Ihren Diamanten und Ihre Fassung auszuwählen, um einen einzigartigen Ring zu kreieren, der Ihre Liebe widerspiegelt.',
    pt: 'Para a Cartier, o amor é uma aventura para toda a vida, uma aventura que continua todos os dias. Para o celebrar, a Maison convida-o(a) a escolher o seu diamante e engaste para criar um anel único que reflita o seu amor, através do serviço Set For You by Cartier.',
    nl: 'Voor Cartier is de liefde een levenslang avontuur, een avontuur dat iedere dag opnieuw begint. Om dit te vieren, nodigt het Maison u uit om zelf een diamant en een zetting te kiezen. Met deze Set For You by Cartier-service kunt u een unieke ring creëren die uw liefde weerspiegelt.',
    es: 'Para Cartier, el amor es una aventura para toda la vida, una aventura que vivimos día tras día. Para celebrarlo, la Maison te invita a elegir tu diamante y su engaste para crear un anillo único que refleje tu amor a través del servicio Cartier Set For You.',
    it: 'Per Cartier, l’amore è l’avventura di una vita, una promessa che si rinnova ogni giorno. Scegli un diamante e una montatura, creando così un anello unico che celebri la bellezza del tuo amore, grazie al servizio Set For You by Cartier.',
    zh: '对于卡地亚而言，爱是一生相守的誓约，凝聚于朝夕相处的珍贵瞬间。诚邀您探索Set For You钻石尊享定制服务，选择心仪的戒托设计和中央钻石，打造一枚专属于您的挚爱见证。',
    ko: "까르띠에에게 사랑이란, 매일 펼쳐지는 일생의 모험입니다. 당신의 아름다운 모험을 기릴 수 있도록 메종은 'Set For You by Cartier' 서비스를 제안합니다. 다이아몬드와 세팅을 선택하고, 사랑을 표현하는 나만의 특별한 링을 만들어 보세요.",
    'zh-tw':
      '對卡地亞而言，愛情堪稱終其一生的冒險之旅，寫下日常的每個時刻。為頌揚永恆真愛，卡地亞誠摯邀請您透過Set For You鑽戒訂製服務挑選心儀的鑽石和鑲嵌方式，製作一款象徵你倆愛情的獨特戒指。',
    'zh-hk':
      '對卡地亞而言，愛是一場人生旅程，也是在每天生活中延續的旅程。為頌揚永恆真愛，卡地亞誠邀您透過 Set For You 服務選擇心儀的鑽石和鑲嵌方式，製作一款象徵您倆愛情的獨特戒指。',
    th: 'สำหรับคาร์เทียร์ ความรักคือการผจญภัยในทุกๆ วันตลอดช่วงชีวิต เพื่อเฉลิมฉลองให้กับความรัก เมซงขอเชิญชวนให้คุณเลือกสรรเพชรและรูปแบบการฝังอัญมณีเพื่อรังสรรค์แหวนที่มีเอกลักษณ์เฉพาะที่สะท้อนความรักของคุณผ่านบริการ Set For You โดยคาร์เทียร์',
  },
  {
    key: 'sfyLanding_Header',
    'en-gb': 'Set For You',
    'en-us': 'Set For You',
    fr: 'Set For You',
    de: 'Set For You',
    pt: 'Set For You',
    nl: 'Set For You',
    es: 'Set For You',
    it: 'Set For You',
    zh: 'Set For You尊享定制服务',
    ko: 'Set For You',
    'zh-tw': 'Set For You鑽戒訂製服務',
    'zh-hk': 'Set For You 鑽戒訂製服務',
    th: 'เครื่องประดับที่รังสรรค์ขึ้นเพื่อคุณโดยเฉพาะ',
  },
  {
    key: 'sfyLanding_text',
    'en-gb':
      "The Maison's online ring creator allows you to create your own engagement ring and visualise it in 360°, complete with personalised engraving. You can then book an appointment either in store or by phone to complete your purchase with a precious stone expert.",
    'en-us':
      'Our virtual ring builder allows you to design the perfect engagement ring. Select a setting, uncover an exceptional diamond and finish with a custom engraving. To complete the process, we invite you to schedule an appointment at one of our boutiques or contact our precious stone experts by phone.',
    fr: "Cet atelier en ligne vous permet de créer votre propre bague de fiançailles et de la visualiser dans son intégralité avec une gravure personnalisée. Prenez rendez-vous en boutique ou par téléphone pour finaliser votre achat avec un expert pierres précieuses. N'oubliez pas d'enregistrer les critères de personnalisation que vous avez choisi en enregistrant votre création.",
    de: 'Mit dem Online-Ringdesigner der Maison können Sie Ihren eigenen Verlobungsring entwerfen und ihn in 360° visualisieren, auch mit persönlicher Gravur. Sie können dann entweder in einer Boutique oder telefonisch einen Termin vereinbaren, um Ihren Kauf mit unseren Edelsteinexpert:innen abzuschließen.',
    pt: 'O simulador online de anéis da Maison permite-lhe criar o seu próprio anel de noivado e visualizá-lo em 360o, completo com uma gravação personalizada. Depois pode fazer uma marcação na loja ou por telefone para completar a sua compra com um especialista em pedras preciosas.',
    nl: 'U kunt nu online bij het Maison zelf uw eigen verlovingsring creëren en deze in 360° visualiseren, compleet met gepersonaliseerde gravure. U kunt vervolgens een afspraak maken in de winkel of per telefoon om samen met een edelsteendeskundige uw aankoop af te ronden.',
    es: 'El servicio de creación de anillos online de la Maison te permite crear tu propio anillo de compromiso y visualizarlo terminado en 360°, incluyendo el grabado personalizado. Después, podrás concertar una cita presencial o telefónica con un experto en piedras preciosas para completar tu compra.',
    it: 'Il "ring builder" online della Maison ti permette di creare il tuo anello di fidanzamento e visualizzarlo a 360°, completo di incisione personalizzata. Potrai poi prenotare un appuntamento in boutique o telefonico per completare il tuo acquisto con l’assistenza di un esperto di pietre preziose.',
    zh: '前往卡地亚在线钻戒定制平台，打造专属订婚钻戒，360°可视化，享有个性化镌刻服务。可于精品店内或通过电话预约会面，珍贵宝石专家将提供睿智建议，陪伴您挑选订购。',
    ko: '메종의 온라인 링 크리에이터를 사용하여 인그레이빙이 더해진 나만의 웨딩 링을 제작하고 360° 이미지를 자세히 확인해 보세요. 그다음 부티크를 방문하거나 또는 전화 예약하고 프레셔스 스톤 전문가의 도움을 받아 구매하세요. ',
    'zh-tw':
      '您可透過卡地亞網上戒指製作工具創作專屬於您的訂婚戒指，並以360度立體顯示及加上個人化鐫刻。之後，您可預約專賣店或透過電話購買，我們的寶石專家將為您完成購買程序。',
    'zh-hk':
      '您可透過卡地亞網上戒指製作工具創作專屬於您的訂婚戒指，並以360度立體顯示及加上個人化鐫刻。之後，您可預約精品店或透過電話購買，我們的寶石專家將為您完成購買程序。',
    th: 'ระบบการรังสรรค์แหวนผ่านทางออนไลน์ของเมซงจะช่วยคุณสร้างสรรค์แหวนหมั้น และออกแบบภาพแหวนแบบ 360° พร้อมกำหนดการแกะสลักในแบบเฉพาะสำหรับคุณ จากนั้น คุณสามารถนัดหมายกับผู้เชี่ยวชาญด้านอัญมณีล้ำค่าของเราเพื่อซื้อชิ้นงานเฉพาะของคุณได้ทั้งการนัดหมายที่ร้านหรือทางโทรศัพท์',
  },
  {
    key: 'sfyLandingPageStep1',
    'en-gb': 'Step 1',
    'en-us': 'Step 1',
    fr: 'Etape 1',
    de: 'Schritt 1',
    pt: 'Passo 1',
    nl: 'Stap 1',
    es: 'Paso 1',
    it: 'Step 1',
    zh: '步骤1',
    ko: '1단계',
    'zh-tw': '第1步',
    'zh-hk': '第1步',
    th: 'ขั้นตอนที่ 1',
  },
  {
    key: 'sfyLandingPageStep1_text',
    'en-gb': 'Choose Your Setting',
    'en-us': 'Choose a Setting',
    fr: 'Choisir sa monture',
    de: 'Wählen Sie eine Fassung',
    pt: 'Escolha um engaste',
    nl: 'Kies een zetting',
    es: 'Seleccionar un anillo de engaste',
    it: 'Scegli una montatura',
    zh: '挑选您的戒托设计',
    ko: '세팅 선택하기',
    'zh-tw': '選擇鑲嵌方式',
    'zh-hk': '選擇鑲嵌方式',
    th: 'เลือกรูปแบบการฝังอัญมณี',
  },
  {
    key: 'sfyLandingPageStep2',
    'en-gb': 'Step 2',
    'en-us': 'Step 2',
    fr: 'Etape 2',
    de: 'Schritt 2',
    pt: 'Passo 2',
    nl: 'Stap 2',
    es: 'Paso 2',
    it: 'Step 2',
    zh: '步骤2',
    ko: '2단계',
    'zh-tw': '第2步',
    'zh-hk': '第2步',
    th: 'ขั้นตอนที่ 2',
  },
  {
    key: 'sfyLandingPageStep2_text',
    'en-gb': 'Select Your Diamond',
    'en-us': 'Select Your Diamond',
    fr: 'Choisir son diamant',
    de: 'Wählen Sie Ihren Diamanten aus',
    pt: 'Selecione o seu diamante',
    nl: 'Kies uw diamant',
    es: 'Seleccionar tu diamante',
    it: 'Seleziona il tuo diamante',
    zh: '挑选您的钻石',
    ko: '다이아몬드 선택하기',
    'zh-tw': '選擇鑽石',
    'zh-hk': '選擇鑽石',
    th: 'เลือกเพชรของคุณ',
  },
  {
    key: 'sfyLandingPageStep3',
    'en-gb': 'Step 3',
    'en-us': 'Step 3',
    fr: 'Etape 3',
    de: 'Schritt 3',
    pt: 'Passo 3',
    nl: 'Stap 3',
    es: 'Paso 3',
    it: 'Step 3',
    zh: '步骤3',
    ko: '3단계',
    'zh-tw': '第3步',
    'zh-hk': '第3步',
    th: 'ขั้นตอนที่ 3',
  },
  {
    key: 'sfyLandingPageStep3_text',
    'en-gb': 'Personalise',
    'en-us': 'Personalize',
    fr: 'Personnalisation',
    de: 'Personalisierung',
    pt: 'Personalização',
    nl: 'Personalisatie',
    es: 'Personalización',
    it: 'Personalizzazione',
    zh: '个性化',
    ko: '퍼스널라이징 서비스',
    'zh-tw': '個人化訂製',
    'zh-hk': '個人化訂製',
    th: 'รังสรรค์ชิ้นงานในแบบของคุณ',
  },
  {
    key: 'sfyLandingPageStep4',
    'en-gb': 'Step 3',
    'en-us': 'Step 3',
    fr: 'Etape 3',
    de: 'Schritt 3',
    pt: 'Passo 3',
    nl: 'Stap 3',
    es: 'Paso 3',
    it: 'Step 3',
    zh: '步骤3',
    ko: '3단계',
    'zh-tw': '第3步',
    'zh-hk': '第3步',
    th: 'ขั้นตอนที่ 3',
  },
  {
    key: 'sfyLandingPageStep4_text',
    'en-gb': 'View Your Creation and Book an Appointment',
    'en-us': 'View your design & book an appointment',
    fr: 'Visualiser votre création et prendre rendez-vous',
    de: 'Sehen Sie sich Ihre Kreation an und vereinbaren Sie einen Termin',
    pt: 'Veja a sua criação e faça uma marcação',
    nl: 'Bekijk uw creatie en maak een afspraak',
    es: 'Ver tu creación y concertar una cita',
    it: 'Visualizza la tua creazione e prenota un appuntamento',
    zh: '查看作品，预约会面',
    ko: '제품 보기 및 방문 예약하기',
    'zh-tw': '查看您的作品並預約',
    'zh-hk': '查看您的作品並預約',
    th: 'ชมผลงานรังสรรค์ของคุณและทำการนัดหมาย',
  },
  {
    key: 'sfyLandingPageBeginCreating',
    'en-gb': 'Create Your Ring',
    'en-us': 'Begin',
    fr: 'Créer votre bague',
    de: 'Kreieren Sie Ihren Ring',
    pt: 'Crie o seu anel',
    nl: 'Creëer uw ring',
    es: 'Crear tu anillo',
    it: 'Crea il tuo anello',
    zh: '定制专属于您的戒指',
    ko: '링 제작하기',
    'zh-tw': '創作您的戒指',
    'zh-hk': '創作您的戒指',
    th: 'รังสรรค์แหวนของคุณ',
  },
  {
    key: 'sfySetting_1',
    'en-gb': 'Choose a Setting',
    'en-us': 'Choose a setting',
    fr: 'Choisir une monture',
    de: 'Wählen Sie eine Fassung',
    pt: 'Escolha um engaste',
    nl: 'Kies een zetting',
    es: 'Seleccionar un anillo de engaste',
    it: 'Scegli una montatura',
    zh: '选择一种戒托设计',
    ko: '세팅 선택하기',
    'zh-tw': '選擇鑲嵌方式',
    'zh-hk': '選擇鑲嵌方式',
    th: 'เลือกรูปแบบการฝังอัญมณี',
  },
  {
    key: 'sfySetting_1_button',
    'en-gb': 'Select this setting',
    'en-us': 'Select this Setting',
    fr: 'Sélectionner cette monture',
    de: 'Diese Fassung auswählen',
    pt: 'Selecione este engaste',
    nl: 'Selecteer deze zetting',
    es: 'Seleccionar este anillo de engaste',
    it: 'Seleziona questa montatura',
    zh: '选择此种戒托设计',
    ko: '이 세팅 선택하기',
    'zh-tw': '選取此鑲嵌方式',
    'zh-hk': '選取此鑲嵌方式',
    th: 'เลือกรูปแบบการฝังอัญมณีนี้',
  },
  {
    key: 'sfySetting_2',
    'en-gb': 'Select Your Diamond',
    'en-us': 'Select Your Diamond',
    fr: 'Choisir un diamant',
    de: 'Wählen Sie Ihren Diamanten aus',
    pt: 'Selecione o seu diamante',
    nl: 'Selecteer uw diamant',
    es: 'Selecciona tu diamante',
    it: 'Seleziona il tuo diamante',
    zh: '挑选您的钻石',
    ko: '다이아몬드 선택하기',
    'zh-tw': '選擇鑽石',
    'zh-hk': '選擇鑽石',
    th: 'เลือกเพชรของคุณ',
  },
  {
    key: 'sfySetting_3',
    'en-gb': 'Personalise',
    'en-us': 'Personalise',
    fr: 'Personnalisation',
    de: 'Personalisierung',
    pt: 'Personalização',
    nl: 'Personalisatie',
    es: 'Personalización',
    it: 'Personalizzazione',
    zh: '个性化',
    ko: '퍼스널라이징 서비스',
    'zh-tw': '個人化訂製',
    'zh-hk': '個人化訂製',
    th: 'รังสรรค์ชิ้นงานในแบบของคุณ',
  },
  {
    key: 'sfySetting_4',
    'en-gb': 'View & Book',
    'en-us': 'View & Book',
    fr: 'Visualiser ma création et prendre un rendez-vous',
    de: 'Sehen Sie sich Ihre Kreation an und vereinbaren Sie einen Termin',
    pt: 'Veja a minha criação e marque uma consulta',
    nl: 'Bekijk uw creatie en maak een afspraak',
    es: 'Ver mi creación y concertar una cita',
    it: 'Visualizza la tua creazione e prenota un appuntamento',
    zh: '查看并预约',
    ko: '보기 & 예약',
    'zh-tw': '查看並預約',
    'zh-hk': '查看並預約',
    th: 'ชมชิ้นงานและนัดหมาย',
  },
  {
    key: 'sfySetting_carat',
    'en-gb': 'Carat',
    'en-us': 'Carat',
    fr: 'Carat',
    de: 'Karat',
    pt: 'Quilate',
    nl: 'Carat',
    es: 'Quilate',
    it: 'Caratura',
    zh: '克拉（Carat）',
    ko: '캐럿',
    'zh-tw': '克拉（Carat）',
    'zh-hk': '克拉（Carat）',
    th: 'กะรัต (Carat)',
  },
  {
    key: 'sfySetting_carat_text',
    'en-gb':
      'Carat measures the weight of a diamond; each one is equal to 0.2 grams. At Cartier, our diamonds are chosen firstly for their beauty, then set into a creation according to their size.',
    'en-us':
      'The unit of measurement of diamond weight is the carat; its name comes from the carob seed that was once used to weigh diamonds. One carat is equivalent to 0.20 grams. Cartier offers a very wide range of carat weights, depending on the setting.',
    fr: 'Le carat est l’unité de mesure du poids des diamants. Un carat équivaut à 0,2 gramme. Chez Cartier, les diamants sont d’abord choisis pour leur beauté, puis placés sur une création en fonction de leur poids.',
    de: 'Karat misst das Gewicht eines Diamanten; jedes Karat entspricht 0,2 Gramm. Bei Cartier werden unsere Diamanten zunächst aufgrund ihrer Schönheit ausgewählt und dann entsprechend ihrer Größe in eine Kreation gefasst.',
    pt: 'O quilate mede o peso de um diamante, equivalendo cada a 0,2 g. Na Cartier, os nossos diamantes são escolhidos, em primeiro lugar, pela sua beleza e depois engastados numa criação de acordo com o seu tamanho.',
    nl: 'Karaat meet het gewicht van een diamant; elke karaat is gelijk aan 0,2 gram. Bij Cartier worden onze diamanten in de eerste plaats gekozen om hun schoonheid en vervolgens naargelang hun grootte in een creatie gezet.',
    es: 'El quilate es la medida que indica el peso de un diamante; cada uno equivale a 0,2 gramos. En Cartier, primero escogemos nuestros diamantes por su belleza y, luego, los engastamos en una creación acorde a su tamaño.',
    it: 'La caratura misura il peso di un diamante. Ogni carato equivale a 0,2 grammi. Cartier sceglie i propri diamanti innanzitutto per la loro bellezza e poi li incastona in una creazione in base alle loro dimensioni.',
    zh: '克拉，即钻石重量单位。1克拉相当于0.2克。钻石的美感，是卡地亚的首要甄选标准，随后根据其不同大小镶嵌至作品之中。',
    ko: '캐럿은 다이아몬드 무게를 측정합니다. 1캐럿은 0.2g입니다. 까르띠에는 아름다운 다이아몬드를 최우선으로 선택하고 다이아몬드의 크기에 따라 제품을 세팅합니다.',
    'zh-tw':
      '克拉是量度鑽石重量的單位，1克拉等於0.2克。卡地亞挑選鑽石的首要條件是美態，然後再根據其尺寸，將其鑲嵌於珠寶作品之上。',
    'zh-hk':
      '克拉是量度鑽石重量的單位，1克拉等於0.2克。卡地亞挑選鑽石的首要條件是美態，然後再根據其尺寸，將其鑲嵌於珠寶作品之上。',
    th: 'กะรัตคือหน่วยวัดน้ำหนักของเพชร โดยที่แต่ละกะรัตมีค่าเท่ากับน้ำหนัก 0.2 กรัม ที่คาร์เทียร์ เราคัดสรรเพชรจากความงามก่อนเป็นอันดับแรก จากนั้นจึงจัดวางองค์ประกอบของชิ้นงานตามขนาด',
  },
  {
    key: 'sfySetting_colour',
    'en-gb': 'Colour',
    'en-us': 'Color',
    fr: 'Couleur',
    de: 'Farbe',
    pt: 'Cor',
    nl: 'Colour',
    es: 'Color',
    it: 'Colore',
    zh: '颜色（Colour）',
    ko: '컬러',
    'zh-tw': '顏色（Colour）',
    'zh-hk': '顏色（Colour）',
    th: 'สี (Colour)',
  },
  {
    key: 'sfySetting_colour_text',
    'en-gb':
      'The colour of a diamond instantly affects how we perceive it – the most elusive and most valuable are colourless. The official scale runs across 23 grades: from D, colourless, to Z, light yellow. The Maison chooses its stones only in the range of D to H.',
    'en-us':
      'The closer a white diamond is to colorless, the more exceptional the stone. The color grade of a diamond is evaluated by the GIA on a scale from D (colorless) to Z (light yellow or brown). Cartier center stone diamonds are selected exclusively from those rated D to H.',
    fr: 'La couleur d’un diamant affecte la perception immédiate que nous en avons. Les diamants incolores sont les plus rares et les plus précieux. L’échelle de notation se compose de 23 grades, de D (incolore) à Z (jaune pâle). La couleur est une composante fondamentale de la différence Cartier. La Maison choisit uniquement des pierres notées de D à H.',
    de: 'Die Farbe eines Diamanten beeinflusst augenblicklich, wie wir ihn wahrnehmen – die schwer fassbaren und wertvollsten sind farblos. Die offizielle Skala umfasst 23 Stufen: von D, farblos, bis Z, hellgelb. Die Maison wählt ihre Steine nur im Bereich von D bis H aus.',
    pt: 'A cor de um diamante afeta instantaneamente a forma como o percecionamos; os mais elusivos e valiosos não têm cor. A escala oficial de 23 níveis varia de D, incolor, a Z, amarelo claro. A Maison escolhe as suas pedras na escala de D até H.',
    nl: 'De kleur van een diamant beïnvloedt onmiddellijk hoe we hem waarnemen - de meest ongrijpbare, en de meest waardevolle, zijn kleurloos. De officiële schaal omvat 23 gradaties: van D, kleurloos, tot Z, lichtgeel. Het Maison selecteert haar stenen alleen in het bereik van D tot H.',
    es: 'El color de un diamante influye inmediatamente en cómo lo percibimos: los más valiosos y más difíciles de encontrar son incoloros. La escala oficial abarca 23 niveles: desde el nivel D, incoloro, hasta el Z, amarillo claro. La Maison solo escoge piedras clasificadas entre los niveles D y H.',
    it: 'Il colore di un diamante influisce immediatamente su come viene percepito allo sguardo. Le gemme più scintillanti e preziose sono incolori. La scala ufficiale comprende 23 gradi: da D, incolore, a Z, giallo chiaro. La Maison sceglie le proprie pietre appartenenti alle categorie comprese tra D e H.',
    zh: '钻石的颜色会直接影响观感——品质越上乘的钻石越接近无色。钻石颜色的标准共分为23个等级，从D，优质无色，到Z，淡黄色。卡地亚仅选用D至H色的钻石。',
    ko: '다이아몬드 컬러는 우리가 다이아몬드를 인지하는 방식에 곧바로 영향을 끼칩니다. 가장 찾기 어려운 고귀한 다이아몬드는 바로 무색(colourless)입니다. 공식적인 척도는 D(무색)에서 Z(밝은 노란색)까지 23개 등급으로 이루어져 있습니다. 메종은 D에서 H 등급 사이의 스톤만을 선택합니다.',
    'zh-tw':
      '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。官方設有23個等級，由D級無色，至Z級淡黃色。卡地亞僅選用D至H級的鑽石。',
    'zh-hk':
      '鑽石的顏色對其觀感帶來即時影響，而無色鑽石最是珍罕貴重。官方設有23個等級，由 D 級無色，至 Z 級淡黃色。卡地亞僅選用 D 至 H 級的鑽石。',
    th: 'สีของเพชรส่งผลต่อลักษณะความงามโดยรวมผ่านทางสายตา ซึ่งเพชรที่ดีที่สุดจะต้องใสสะอาด ไม่มีสี โดยสเกลการวัดอย่างเป็นทางการมีทั้งหมด 23 ระดับ จากระดับ D หรือไม่มีสี ไปจนถึงระดับ Z หรือสีอ่อน เมซงคัดสรรเฉพาะอัญมณีที่มีค่าสีระดับ D ถึง H เท่านั้น',
  },
  {
    key: 'sfySetting_colourless',
    'en-gb': 'Colourless',
    'en-us': 'Colorless',
    fr: 'Incolore',
    de: 'Farblos',
    pt: 'Incolor',
    nl: 'Kleurloos',
    es: 'Incoloro',
    it: 'Incolore',
    zh: '无色',
    ko: '무색',
    'zh-tw': '無色',
    'zh-hk': '無色',
    th: 'ไม่มีสี',
  },
  {
    key: 'sfySetting_nearColourless',
    'en-gb': 'Near Colourless',
    'en-us': 'Near Colorless',
    fr: 'Presque incolore',
    de: 'Nahezu farblos',
    pt: 'Quase incolor',
    nl: 'Bijna kleurloos',
    es: 'Casi incoloro',
    it: 'Quasi incolore',
    zh: '接近无色',
    ko: '무색에 가까움',
    'zh-tw': '接近無色',
    'zh-hk': '接近無色',
    th: 'เกือบจะไม่มีสี',
  },
  {
    key: 'sfySetting_clarity',
    'en-gb': 'Clarity',
    'en-us': 'Clarity',
    fr: 'Clarté',
    de: 'Reinheit',
    pt: 'Claridade',
    nl: 'Clarity',
    es: 'Claridad',
    it: 'Chiarezza',
    zh: '净度（Clarity）',
    ko: '투명도',
    'zh-tw': '淨度（Clarity）',
    'zh-hk': '淨度（Clarity）',
    th: 'ความใสบริสุทธิ์ (Clarity)',
  },
  {
    key: 'sfySetting_clarity_text',
    'en-gb':
      'Natural imperfections called ‘inclusions’ affect the quality of a diamond. Each stone is placed on an 11-point scale that runs from flawless diamonds to stones with many visible inclusions to the naked eye. The Cartier difference means that diamonds are chosen only from FL to VS2. These are stones of exceptional purity, with zero inclusions visible to the naked eye.',
    'en-us':
      'Inclusions and blemishes are natural characteristics in a diamond. The clarity of a diamond is determined by the size, number, position, relief and type of characteristics. It is rated by the GIA on a scale from FL (Flawless = no characteristics are detectable under 10x magnification) to I3 (Included = numerous inclusions visible to the naked eye). Cartier diamonds are only selected between FL and VS2, which guarantees exceptional clarity and the absence of the slightest inclusion visible to the naked eye.',
    fr: 'La pureté indique la présence ou non d’imperfections naturelles, appelées « inclusions », affectant la qualité du diamant. Chaque pierre est notée de 0, pour aucune imperfection visible à la loupe, à 11 lorsque de nombreuses inclusions sont visibles à l’œil nu. La différence Cartier ? Nous sélectionnons uniquement des pierres notées entre FL et VS2. Ces diamants à la pureté exceptionnelle ne présentent aucune inclusion visible à l’œil nu pour une réfraction incroyable de la lumière.',
    de: 'Natürliche Unvollkommenheiten, sogenannte „Einschlüsse“, beeinträchtigen die Qualität eines Diamanten. Jeder Stein wird auf einer 11-Punkte-Skala eingestuft, die von lupenreinen Diamanten bis zu Steinen mit vielen mit bloßem Auge sichtbaren Einschlüssen reicht. Die Maison wählt nur Diamanten von FL bis VS2 aus. Hierbei handelt es sich um Steine von außergewöhnlicher Reinheit, die keine mit bloßem Auge sichtbaren Einschlüsse aufweisen.',
    pt: 'As imperfeições naturais, chamadas inclusões, afetam a qualidade de um diamante. Cada pedra é classificada segundo uma escala de 11 pontos que varia dos diamantes perfeitos às pedras com várias inclusões visíveis a olho nu. A Diferença Cartier significa que os diamantes são escolhidos apenas na escala de FL até VS2. Estas são pedras de pureza excecional, com zero inclusões visíveis a olho nu.',
    nl: 'Natuurlijke onvolkomenheden, "insluitsels" genaamd, beïnvloeden de kwaliteit van een diamant. Elke steen wordt op een schaal van 11 punten geplaatst, die gaat van onberispelijke diamanten tot stenen met veel met het blote oog zichtbare insluitsels. Het Cartier Difference houdt in dat uitsluitend diamanten worden gekozen van FL tot VS2. Dit zijn stenen van uitzonderlijke zuiverheid, zonder insluitsels die met het blote oog te zien zijn.',
    es: 'Las imperfecciones naturales llamadas “inclusiones” afectan a la calidad de un diamante. Cada piedra se clasifica en una escala de 11 puntos que va desde diamantes impecables hasta piedras con muchas inclusiones apreciables a simple vista. La Diferencia Cartier significa que solo se escogen diamantes entre los niveles FL (del inglés Flawless, impecable) al VS2 (del inglés Very Slightly Included 2, inclusiones muy leves 2). Se trata de piedras con una pureza excepcional, sin ninguna inclusión apreciable a simple vista.',
    it: 'Le imperfezioni naturali, chiamate “inclusioni”, influiscono sulla qualità di un diamante. Ogni pietra è collocata su una scala a 11 punti che va da diamanti privi di difetti a pietre con numerose inclusioni visibili a occhio nudo. Cartier sceglie esclusivamente diamanti compresi tra le categorie FL e VS2. Si tratta di pietre di eccezionale purezza, prive di inclusioni visibili a occhio nudo.',
    zh: '内含物是钻石内部的天然瑕疵，会影响钻石的品质。净度被分为11个等级，从无瑕级至明显肉眼可见瑕疵的内含级。卡地亚独具匠心，仅选用净度为FL至VS2级的钻石。它们拥有高等级的净度，同时没有肉眼可见的内含物。',
    ko: '‘내포물’이라 불리는 천연 불순물은 다이아몬드 품질에 영향을 미칩니다. 모든 스톤은 내포물이 전혀 보이지 않는 무결점 다이아몬드에서 육안으로 보이는 내포물이 많은 단계까지 총 11개의 등급으로 나뉩니다. 까르띠에의 특별함은 FL에서 VS2 단계의 다이아몬드만을 선택한다는 점에 있습니다. 이들은 눈으로는 거의 내포물이 보이지 않을 만큼 극도로 순수한 스톤입니다.',
    'zh-tw':
      '「內含物」為天然瑕疵，會影響到鑽石的品質。每顆鑽石均分為11級，由無暇到存有很多肉眼可見的內含物。卡地亞的獨特之處是我們只選用由 FL 至 VS2 級極為純淨的鑽石，並不能以肉眼看到內含物。',
    'zh-hk':
      '「內含物」為天然瑕疵，會影響到鑽石的品質。每顆鑽石均分為11級，由無暇到存有很多肉眼可見的內含物。卡地亞的獨特之處是我們只選用由 FL 至 VS2 級極為純淨的鑽石，並不能以肉眼看到內含物。',
    th: 'ความไม่สมบูรณ์ตามธรรมชาติที่เรียกว่า ‘ตำหนิ’ ส่งผลต่อคุณภาพของเพชร เราจะเทียบอัญมณีแต่ละเม็ดจากระดับ 0 ถึง 11 ซึ่งเป็นระดับตั้งแต่เพชรที่ไร้ตำหนิไปจนถึงอัญมณีที่มีตำหนิมากมายที่มองเห็นได้ด้วยตาเปล่า ความแตกต่างของคาร์เทียร์คือการเลือกสรรเพชรระดับไร้ตำหนิ FL ไปจนถึงระดับ VS2 เท่านั้น โดยเพชรเหล่านี้มีความบริสุทธิ์เป็นพิเศษ ปราศจากตำหนิที่มองเห็นได้ด้วยตาเปล่า',
  },
  {
    key: 'sfySetting_clarity_flawless',
    'en-gb': 'Flawless',
    'en-us': 'Flawless',
    fr: 'Sans inclusion',
    de: 'Lupenrein',
    pt: 'Perfeito',
    nl: 'Loepzuiver',
    es: 'Impecable',
    it: 'Senza difetti',
    zh: '无瑕级',
    ko: '완전 무결점',
    'zh-tw': '無暇',
    'zh-hk': '無暇',
    th: 'ไม่มีตำหนิ',
  },
  {
    key: 'sfySetting_clarity_veryslightinclusions',
    'en-gb': 'Very Slightly Included',
    'en-us': 'Very slight inclusions',
    fr: 'Inclusions très légères',
    de: 'Sehr leichte Einschlüsse',
    pt: 'Inclusões muito leves',
    nl: 'Zeer kleine insluitsels',
    es: 'Inclusiones muy leves',
    it: 'Leggerissime inclusioni',
    zh: '轻微内含级',
    ko: '매우 미세한 내포물이 있음',
    'zh-tw': '具肉眼不可見的很微小內含物',
    'zh-hk': '具肉眼不可見的很微小內含物',
    th: 'มีตำหนิน้อยมาก',
  },
  {
    key: 'sfySetting_cut',
    'en-gb': 'Cut',
    'en-us': 'Cut',
    fr: 'Taille',
    de: 'Schliff',
    pt: 'Corte',
    nl: 'Cut',
    es: 'Talla',
    it: 'Taglio',
    zh: '切工（Cut）',
    ko: '컷',
    'zh-tw': '切工（Cut）',
    'zh-hk': '切工（Cut）',
    th: 'การเจียระไน (Cut)',
  },
  {
    key: 'sfySetting_cut_text',
    'en-gb':
      "Cut – a stone’s shape, symmetry and balance – is graded on a five-point scale from ‘Excellent’ to ‘Poor’. The Maison's creations use only diamonds from the ‘Excellent’ to ‘Good’ range with a singular fire and brilliance.",
    'en-us':
      "The cut of a diamond, which leads to its symmetry and balance, is a crucial factor in the 4Cs. The GIA rates a round brilliant diamond's cut on a scale of five grades ranging from “Excellent” to “Poor.” This is entirely dependent upon the talent and expertise of the diamond cutter. Cartier center stone diamonds are selected exclusively from “Excellent” to “Good” cuts. Diamonds used for paving are also selected according to rigorous cut criteria to ensure they harmonize with the central gems. Whether they are center stone diamonds or used for paving, they all play with light, thanks to their elegant shape and perfect balance.",
    fr: 'La taille de la pierre, « cut » en anglais : sa forme, sa symétrie et son équilibre, est notée sur une échelle de cinq niveaux, d’Excellent à Poor. Pour nos créations, nous utilisons uniquement des diamants notés d’Excellent à Good, remarquables pour leur brillance et leur feu.',
    de: 'Der Schliff – die Form, Symmetrie und Ausgewogenheit eines Steins – wird auf einer fünfstufigen Skala von „Ausgezeichnet“ bis „Schlecht“ bewertet. Für die Kreationen der Maison werden ausschließlich Diamanten der Kategorie „Exzellent“ bis „Gut“ mit einzigartigem Feuer und Brillanz verwendet.',
    pt: 'O corte (ou seja, a forma, a simetria e o equilíbrio de uma rocha) é classificado numa escala de cinco pontos, que varia de Excelente a Mau. As criações da Maison usam diamantes que se encontram apenas na escala de Excelente a Bom, com um ardor e brilho singulares.',
    nl: 'De slijpkwaliteit - de vorm, symmetrie en balans van een steen - wordt beoordeeld op een schaal van vijf punten, van "uitstekend" tot "slecht". De creaties van ons Maison gebruiken uitsluitend diamanten uit de ‘Excellent’ en ‘Good’ reeks met een uitzonderlijk vuur en schittering.',
    es: 'La talla (la forma, la simetría y el equilibrio de una piedra) se califica en una escala de cinco puntos que va de “excelente” a “pobre”. Para nuestras creaciones, en La Maison solo empleamos diamantes con una talla clasificada entre “excelente” y “buena”, con una luz y un brillo únicos.',
    it: 'Il taglio – cioè la forma, la simmetria e l’equilibrio di una pietra – è classificato su una scala a cinque punti che va da “eccellente” a “scarso”. Per le nostre creazioni, utilizziamo solo diamanti con un taglio da “eccellente” a “buono” e con un fuoco e una brillantezza straordinari.',
    zh: '切工标准设立了5种等级，从“卓越”到“不良”。卡地亚作品仅选用“卓越”至“良好”等级，且呈现独特火彩和光芒的钻石。',
    ko: '스톤의 형태, 대칭, 균형을 의미하는 컷은 ‘탁월함(Excellent)’에서 ‘좋지 않음(Poor)’에 이르기까지 다섯 등급으로 나뉩니다. 까르띠에 크리에이션은 오로지 놀랍도록 뛰어난 광채를 갖춘 ‘탁월함(Excellent)’에서 ‘우수함(Good)’ 등급 사이의 다이아몬드만 사용합니다.',
    'zh-tw':
      '切工即鑽石的形狀、對稱和平衡，分為「優秀」到「不良」五個等級。卡地亞作品只選用「優秀」至「良好」，且綻放獨特光芒的鑽石。',
    'zh-hk':
      '切工即鑽石的形狀、對稱和平衡，分為「極優」到「不良」五個等級。卡地亞作品只選用「極優」至「良好」，且綻放獨特光芒的鑽石。',
    th: 'การเจียระไนคือรูปทรงของอัญมณี ความสมมาตร และความสมดุล โดยมีสเกลการวัดค่าห้าระดับ ตั้งแต่ ‘Excellent’ หรือดีเลิศไปจนถึง ‘Poor’ หรือแย่มาก เมซงเลือกใช้เพชรตั้งแต่ระดับ ‘Excellent’ หรือดีเลิศถึง ‘Good’ หรือดี พร้อมด้วยประกายสุกสว่างและความแวววาวที่มีเอกลักษณ์เฉพาะตัวเท่านั้น',
  },
  {
    key: 'sfySetting_good',
    'en-gb': 'Good',
    'en-us': 'Good',
    fr: 'Bonne',
    de: 'Gut',
    pt: 'Bom',
    nl: 'Goed',
    es: 'Buena',
    it: 'Buono',
    zh: '良好',
    ko: '우수함',
    'zh-tw': '良好',
    'zh-hk': '良好',
    th: 'ดี',
  },
  {
    key: 'sfySetting_excellent',
    'en-gb': 'Excellent',
    'en-us': 'Excellent',
    fr: 'Excellent',
    de: 'Exzellent',
    pt: 'Excelente',
    nl: 'Uitstekend',
    es: 'Excelente',
    it: 'Eccellente',
    zh: '卓越',
    ko: '탁월함',
    'zh-tw': '優秀',
    'zh-hk': '極優',
    th: 'ดีเลิศ',
  },
  {
    key: 'sfySetting_brilliant',
    'en-gb': 'Brilliant',
    'en-us': 'Brilliant',
    fr: 'Brillant',
    de: 'Brillant',
    pt: 'Brilhante',
    nl: 'Briljant',
    es: 'Brillante',
    it: 'Brillante',
    zh: '圆形明亮式切割',
    ko: '브릴리언트',
    'zh-tw': '圓形明亮式',
    'zh-hk': '圓形明亮式',
    th: 'ทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_fancy',
    'en-gb': 'Fancy',
    'en-us': 'Fancy',
    fr: 'Fancy',
    de: 'Besondere Schliffe',
    pt: 'Extravagante',
    nl: 'Luxe',
    es: 'Sofisticado',
    it: 'Fancy',
    zh: '花式',
    ko: '팬시',
    'zh-tw': '花式',
    'zh-hk': '花式',
    th: 'ทรงแฟนซี',
  },
  {
    key: 'sfySetting_price',
    'en-gb': 'Price',
    'en-us': 'Price',
    fr: 'Prix',
    de: 'Preis',
    pt: 'Preço',
    nl: 'Prijs',
    es: 'Precio',
    it: 'Prezzo',
    zh: '价格',
    ko: '가격',
    'zh-tw': '價格',
    'zh-hk': '價格',
    th: 'ราคา',
  },
  {
    key: 'sfySetting_price_text',
    'en-gb': 'Please note: the price of your creation will change depending on your selection above.',
    'en-us': 'Please note: the price of your creation will change depending on your selection above.',
    fr: 'Veuillez noter que la valeur de votre création pourra varier selon la sélection que vous avez faite.',
    de: 'Bitte beachten Sie: Der Preis für Ihre Kreation ändert sich je nach Ihrer Auswahl oben.',
    pt: 'Lembre-se de que o preço da sua criação vai depender da seleção anterior.',
    nl: 'Houd er rekening mee dat de prijs van uw creatie zal veranderen afhankelijk van uw bovenstaande selectie.',
    es: 'Nota: el precio de tu creación puede cambiar en función de tu selección anterior.',
    it: 'Ti preghiamo di notare che il prezzo della tua creazione cambierà a seconda della combinazione scelta.',
    zh: '请注意：您的作品价格将根据您上述选择而变化。',
    ko: '참고: 제품 가격은 위의 선택사항에 따라 달라질 수 있습니다.',
    'zh-tw': '請注意：作品價格會視乎上列選擇而改變。',
    'zh-hk': '請注意：作品價格會視乎上列選擇而改變。',
    th: 'โปรดทราบว่าราคาชิ้นงานของคุณอาจมีการเปลี่ยนแปลงได้ ทั้งนี้ขึ้นอยู่กับตัวเลือกด้านบนของคุณ',
  },
  {
    key: 'sfySetting_assistance',
    'en-gb': 'Assistance',
    'en-us': 'Assistance',
    fr: 'Aide',
    de: 'Hilfe',
    pt: 'Assistência',
    nl: 'Assistentie',
    es: 'Ayuda',
    it: 'Assistenza',
    zh: '帮助',
    ko: '지원',
    'zh-tw': '幫助',
    'zh-hk': '幫助',
    th: 'ความช่วยเหลือ',
  },
  {
    key: 'sfySetting_yourDiamonds',
    'en-gb': 'Your Diamonds',
    'en-us': 'Your Diamonds',
    fr: 'Vos diamants',
    de: 'Ihre Diamanten',
    pt: 'Os seus diamantes',
    nl: 'Uw diamanten',
    es: 'Tus diamantes',
    it: 'I tuoi diamanti',
    zh: '您的钻石',
    ko: '다이아몬드',
    'zh-tw': '您的鑽石',
    'zh-hk': '您的鑽石',
    th: 'เพชรของคุณ',
  },
  {
    key: 'sfySetting_pickedForYou',
    'en-gb': 'Selected for you',
    'en-us': 'Selected for You',
    fr: 'Choisis pour vous',
    de: 'Ausgewählt für Sie',
    pt: 'Selecionado para si',
    nl: 'Voor u geselecteerd',
    es: 'Seleccionados para ti',
    it: 'Selezionati per te',
    zh: '为您选择',
    ko: '당신을 위한 선택',
    'zh-tw': '特別為您選擇',
    'zh-hk': '特別為您選擇',
    th: 'คัดสรรมาเป็นพิเศษสำหรับคุณ',
  },
  {
    key: 'sfySetting_continue',
    'en-gb': 'continue',
    'en-us': 'continue',
    fr: 'continuer',
    de: 'weiter',
    pt: 'continuar',
    nl: 'ga verder',
    es: 'continuar',
    it: 'continua',
    zh: '继续',
    ko: '계속',
    'zh-tw': '繼續',
    'zh-hk': '繼續',
    th: 'ดำเนินการต่อ',
  },
  {
    key: 'sfySetting_Engraving',
    'en-gb': 'Engraving',
    'en-us': 'Engraving',
    fr: 'Gravure',
    de: 'Gravur',
    pt: 'Gravação',
    nl: 'Gravure',
    es: 'Grabado',
    it: 'Incisione',
    zh: '镌刻服务',
    ko: '인그레이빙',
    'zh-tw': '鐫刻',
    'zh-hk': '鐫刻',
    th: 'บริการสลัก',
  },
  {
    key: 'sfySetting_addYourMessage',
    'en-gb': 'Add Your Message',
    'en-us': 'Add Your Message',
    fr: 'Ajoutez votre message',
    de: 'Ihre persönliche Nachricht hinzufügen',
    pt: 'Adicione a sua mensagem',
    nl: 'Voeg uw boodschap toe',
    es: 'Añadir tu mensaje',
    it: 'Aggiungi il tuo messaggio',
    zh: '添加您的镌刻文字',
    ko: '메시지 추가하기',
    'zh-tw': '添加訊息',
    'zh-hk': '添加訊息',
    th: 'เพิ่มข้อความของคุณ',
  },
  {
    key: 'sfySetting_Xcharacter',
    'en-gb': 'Add up to 12 characters',
    'en-us': 'Add up to 12 characters',
    fr: "Jusqu'à 12 caractères",
    de: 'Bis zu 12 Zeichen hinzufügen',
    pt: 'Adicionar até 12 carateres',
    nl: 'Voeg tot 12 tekens toe',
    es: 'Añadir hasta 12 caracteres',
    it: 'Aggiungi fino a 12 caratteri',
    zh: '最多添加12个字符',
    ko: '최대 12자 추가',
    'zh-tw': '最多可加入12個字元。',
    'zh-hk': '最多可加入12個字元',
    th: 'ระบุอักขระได้มากถึง 12 ตัว',
  },
  {
    key: 'sfySetting_addUpTo',
    'en-gb': 'Add up to',
    'en-us': 'Add up to',
    fr: "Jusqu'à",
    de: 'Bis zu',
    pt: 'Adicionar até',
    nl: 'Voeg tot',
    es: 'Añadir hasta',
    it: 'Aggiungi fino a',
    zh: '最多添加',
    ko: '최대 추가',
    'zh-tw': '最多可加入',
    'zh-hk': '最多可加入',
    th: 'เพิ่มอักขระได้ถึง',
  },
  {
    key: 'sfySetting_characters',
    'en-gb': 'characters',
    'en-us': 'characters',
    fr: 'caractères',
    de: 'Zeichen hinzufügen',
    pt: 'carateres',
    nl: 'tekens toe',
    es: 'caracteres',
    it: 'caratteri',
    zh: '个字符',
    ko: '자',
    'zh-tw': '個字元',
    'zh-hk': '個字元',
    th: 'ตัว',
  },
  {
    key: 'sfySetting_Select_from_Style',
    'en-gb': 'Select Font Style',
    'en-us': 'Select Font Style',
    fr: 'Sélectionnez la police de caractère',
    de: 'Schriftart auswählen',
    pt: 'Selecionar a fonte',
    nl: 'Selecteer lettertype',
    es: 'Seleccionar estilo de fuente',
    it: 'Seleziona lo stile del carattere',
    zh: '选择字体',
    ko: '폰트 스타일 선택하기',
    'zh-tw': '選擇字體',
    'zh-hk': '選擇字體',
    th: 'เลือกรูปแบบตัวอักษร',
  },
  {
    key: 'sfySetting_AddASymbol',
    'en-gb': 'Add a Symbol',
    'en-us': 'Add A Symbol',
    fr: 'Ajouter un symbole',
    de: 'Ein Symbol hinzufügen',
    pt: 'Adicionar um símbolo',
    nl: 'Voeg een symbool toe',
    es: 'Añadir un símbolo',
    it: 'Aggiungi un simbolo',
    zh: '添加符号',
    ko: '특수문자 추가하기',
    'zh-tw': '添加符號',
    'zh-hk': '添加符號',
    th: 'เพิ่มสัญลักษณ์',
  },
  {
    key: 'sfySetting_LearnMore',
    'en-gb': 'Learn More',
    'en-us': 'Learn more',
    fr: 'En savoir plus',
    de: 'Mehr erfahren',
    pt: 'Saber mais',
    nl: 'Meer lezen',
    es: 'Saber más',
    it: 'Per saperne di più',
    zh: '了解更多',
    ko: '더 알아보기',
    'zh-tw': '了解更多',
    'zh-hk': '了解更多',
    th: 'ชมข้อมูลเพิ่มเติม',
  },
  {
    key: 'sfySetting_redBoxEmbossing',
    'en-gb': 'Red Box Embossing',
    'en-us': 'Red Box Embossing',
    fr: "Embossage de l'écrin",
    de: 'Prägung auf der roten Box',
    pt: 'Gravação com relevo na caixa vermelha',
    nl: 'Reliëfwerk op de rode doos',
    es: 'Grabado en relieve del estuche rojo',
    it: 'Goffratura dello scrigno rosso',
    zh: '红盒刻字服务',
    ko: '레드 박스 엠보싱',
    'zh-tw': '紅盒燙印',
    'zh-hk': '紅盒壓印',
    th: 'บริการประทับนูนบนกล่องสีแดง',
  },
  {
    key: 'sfySetting_AddEmbosing',
    'en-gb': 'Add Complimentary Embossing',
    'en-us': 'Add Complimentary Embossing',
    fr: 'Ajoutez un embossage supplémentaire',
    de: 'Kostenlose Prägung hinzufügen',
    pt: 'Adicionar gravação com relevo gratuita',
    nl: 'Voeg kosteloos reliëfwerk toe',
    es: 'Añadir grabado en relieve gratuito',
    it: 'Aggiungi una goffratura gratuita',
    zh: '尊享免费刻字服务',
    ko: '무상 엠보싱 추가하기',
    'zh-tw': '添加免費燙印',
    'zh-hk': '添加免費壓印',
    th: 'เพิ่มการประทับนูนโดยไม่มีค่าใช้จ่าย',
  },
  {
    key: 'sfySetting_YourTextHere',
    'en-gb': 'Your text here',
    'en-us': 'Your Text Here',
    fr: 'Votre texte',
    de: 'Ihr Text hier',
    pt: 'Adicione o seu texto aqui',
    nl: 'Uw tekst',
    es: 'Tu texto aquí',
    it: 'Il tuo testo qui',
    zh: '在此输入',
    ko: '여기에 텍스트를 입력하세요',
    'zh-tw': '請輸入文字',
    'zh-hk': '請輸入文字',
    th: 'ระบุข้อความของคุณที่นี่',
  },
  {
    key: 'sfySetting_XCharacterEmbossing',
    'en-gb': 'Add up to 4 characters',
    'en-us': 'Add up to 4 characters',
    fr: "Ajoutez jusqu'à 4 caractères",
    de: 'Bis zu 4 Zeichen hinzufügen',
    pt: 'Adicionar até 4 carateres',
    nl: 'Voeg tot 4 tekens toe',
    es: 'Añadir hasta 4 caracteres',
    it: 'Aggiungi fino a 4 caratteri',
    zh: '最多添加4个字符',
    ko: '최대 4자 추가',
    'zh-tw': '最多可加入4個字元',
    'zh-hk': '最多可加入4個字元',
    th: 'เพิ่มอักขระได้ 4 ตัว',
  },
  {
    key: 'sfySetting_YourCreation',
    'en-gb': 'Your creation',
    'en-us': 'Your design',
    fr: 'Votre création',
    de: 'Ihre Kreation',
    pt: 'A sua criação',
    nl: 'Uw creatie',
    es: 'Tu creación',
    it: 'La tua creazione',
    zh: '您的作品',
    ko: '제품',
    'zh-tw': '您的作品',
    'zh-hk': '您的作品',
    th: 'ชิ้นงานรังสรรค์ของคุณ',
  },
  {
    key: 'sfySetting_YourUniqueReference',
    'en-gb': 'Your unique reference',
    'en-us': 'Your Unique Reference',
    fr: 'Votre référence unique',
    de: 'Ihre einzigartigen Referenzen',
    pt: 'A sua referência única',
    nl: 'Uw unieke referentie',
    es: 'Tu referencia única',
    it: 'La tua referenza',
    zh: '您的专属编号',
    ko: '고유 레퍼런스',
    'zh-tw': '您的獨立參考編號',
    'zh-hk': '您的獨立參考編號',
    th: 'หมายเลขอ้างอิงเฉพาะของคุณ',
  },
  // ! 'sfySetting_IncludesVAT' is obselete and has been moved to be handled in 'priceSuffix' property in locations.ts.
  {
    key: 'sfySetting_IncludesVAT',
    'en-gb': 'Includes VAT',
    'en-us': 'Excluding sales tax',
    fr: 'TVA incluse',
    de: 'Inklusive Mehrwertsteuer',
    pt: 'Inclui IVA',
    nl: 'Inclusief btw',
    es: 'IVA incluido',
    it: 'IVA inclusa',
    zh: '包含增值税',
    ko: 'VAT 포함',
    'zh-tw': '含增值稅',
    'zh-hk': '含增值稅',
    th: 'รวมภาษีมูลค่าเพิ่ม',
  },
  {
    key: 'sfySetting_RingSummary',
    'en-gb': 'Creation Summary',
    'en-us': 'Ring Summary',
    fr: 'Informations sur la bague',
    de: 'Informationen über die Ringe',
    pt: 'Resumo do anel',
    nl: 'Overzicht ring',
    es: 'Resumen del anillo',
    it: 'Riepilogo anello',
    zh: '作品摘要',
    ko: '제품 요약',
    'zh-tw': '作品摘要',
    'zh-hk': '作品摘要',
    th: 'ข้อมูลสรุปเกี่ยวกับชิ้นงาน',
  },
  {
    key: 'sfySetting_Wearesorry_text',
    'en-gb': 'This Service is Unavailable',
    'en-us': 'This Service is Unavailable',
    fr: "Ce service n'est pas disponible",
    de: 'Diese Serviceleistung ist nicht verfügbar.',
    pt: 'Este serviço está indisponível',
    nl: 'Deze service is niet beschikbaar',
    es: 'Este servicio no está disponible',
    it: 'Questo servizio non è disponibile',
    zh: '服务目前不可用',
    ko: '서비스를 사용할 수 없습니다',
    'zh-tw': '服務無法使用',
    'zh-hk': '服務無法使用',
    th: 'บริการนี้ไม่พร้อมใช้งาน',
  },
  {
    key: 'sfySetting_Wearesorry',
    'en-gb':
      "The Maison is unable to offer engraving or embossing on the ring you've created. Please contact us for further information.",
    'en-us':
      'We are unable to offer engraving or embossing on this ring design. Please contact us for further information.',
    fr: "La Maison ne peut vous proposer de gravure sur la bague que vous avez créée, ni d'embossage sur l'écrin. Merci de nous contacter pour plus d'informations.",
    de: 'Die Maison kann keine Gravur oder Prägung auf dem von Ihnen entworfenen Ring anbieten. Bitte kontaktieren Sie uns für weitere Informationen.',
    pt: 'A Maison não oferece a gravação, nem a gravação com relevo, no anel que escolheu. Contacte-nos para obter mais informações.',
    nl: 'Het Maison kan helaas geen gravures of reliëfwerk aanbrengen op de door u gecreëerde ring. Neem contact op voor meer informatie.',
    es: 'La Maison no puede ofrecer el servicio de grabado ni de grabado en relieve sobre el anillo que has creado. Contacta con nosotros para obtener más información.',
    it: 'Non è possibile prevedere incisioni o goffrature sull’anello che hai creato. Ti preghiamo di contattarci per ulteriori informazioni.',
    zh: '品牌无法为您定制的戒指提供镌刻或刻字服务。请联系我们，以了解详情。',
    ko: '고객님께서 선택한 링에는 인그레이빙 또는 엠보싱 서비스가 제공되지 않습니다. 자세한 내용은 문의해 주시기 바랍니다.',
    'zh-tw': '卡地亞無法為您創作的戒指提供鐫刻或燙印服務。請聯絡我們以了解詳情。',
    'zh-hk': '卡地亞無法為您創作的戒指提供鐫刻或壓印服務。請聯絡我們以了解詳情。',
    th: 'เมซงไม่สามารถให้บริการแกะสลักหรือประทับนูนบนแหวนที่คุณเลือกสรรไว้ได้ โปรดติดต่อเราเพื่อสอบถามข้อมูลเพิ่มเติม',
  },
  {
    key: 'sfySetting_ViewYourCreation',
    'en-gb': 'View your creation',
    'en-us': 'View your design',
    fr: 'Visualiser votre création et prendre rendez-vous',
    de: 'Sehen Sie sich Ihre Kreation an',
    pt: 'Veja a sua criação',
    nl: 'Bekijk uw creatie',
    es: 'Ver tu creación',
    it: 'Visualizza la tua creazione',
    zh: '查看您的卡地亚作品',
    ko: '제품 보기',
    'zh-tw': '查看您的作品',
    'zh-hk': '查看您的作品',
    th: 'ชมชิ้นงานรังสรรค์ของคุณ',
  },
  {
    key: 'sfySetting_Reference',
    'en-gb': 'Reference',
    'en-us': 'Reference',
    fr: 'Référence',
    de: 'Referenz',
    pt: 'Referência',
    nl: 'Referentie',
    es: 'Referencia',
    it: 'Referenza',
    zh: '编号',
    ko: '레퍼런스',
    'zh-tw': '參考編號',
    'zh-hk': '參考編號',
    th: 'หมายเลขอ้างอิง',
  },
  {
    key: 'sfySetting_selectRingSize',
    'en-gb': 'Select ring size',
    'en-us': 'Select Ring Size',
    fr: 'Choisir la taille de la bague',
    de: 'Ringröße auswählen',
    pt: 'Selecione o tamanho do anel',
    nl: 'Selecteer ringmaat',
    es: 'Seleccionar talla del anillo',
    it: 'Seleziona la misura dell’anello',
    zh: '选择戒指尺寸',
    ko: '링 사이즈 선택하기',
    'zh-tw': '選擇戒指尺寸',
    'zh-hk': '選擇戒指尺寸',
    th: 'เลือกขนาดแหวน',
  },
  {
    key: 'sfySetting_BookanAppointment',
    'en-gb': 'Book an appointment',
    'en-us': 'Book an Appointment',
    fr: 'Prendre rendez-vous',
    de: 'Vereinbaren Sie einen Termin',
    pt: 'Faça uma marcação',
    nl: 'Maak een afspraak',
    es: 'Concertar una cita',
    it: 'Prenota un appuntamento',
    zh: '预约会面',
    ko: '방문 예약하기',
    'zh-tw': '預約',
    'zh-hk': '預約',
    th: 'ทำการนัดหมาย',
  },
  {
    key: 'sfySetting_ContactSupport',
    'en-gb': 'Contact support',
    'en-us': 'Contact Support',
    fr: 'Contacter un ambassadeur',
    de: 'Kundenservice kontaktieren',
    pt: 'Contacte a assistência',
    nl: 'Klantenservice',
    es: 'Contactar con el servicio de ayuda',
    it: 'Contatta il Servizio Clienti',
    zh: '联系卡地亚服务大使',
    ko: '고객 지원 문의하기',
    'zh-tw': '聯繫客戶服務中心',
    'zh-hk': '聯繫客戶服務中心',
    th: 'ติดต่อฝ่ายลูกค้าสัมพันธ์',
  },
  {
    key: 'sfySetting_sizeGuide',
    'en-gb': 'Size guide',
    'en-us': 'Size Guide',
    fr: 'Guide des tailles',
    de: 'Größentabelle',
    pt: 'Guia de tamanhos',
    nl: 'Maattabel',
    es: 'Guía de tallas',
    it: 'Guida alle misure',
    zh: '尺寸指南',
    ko: '사이즈 가이드',
    'zh-tw': '尺寸指南',
    'zh-hk': '尺寸指南',
    th: 'คู่มือการเทียบขนาด',
  },
  {
    key: 'sfySetting_saveMyCreation',
    'en-gb': 'SHARE MY DESIGN',
    'en-us': 'Share this design',
    fr: 'Partager ma création',
    de: 'Meine Kreation abschicken',
    pt: 'Enviar a minha criação',
    nl: 'Verstuur mijn creatie',
    es: 'Enviar mi creación',
    it: 'Invia un riepilogo della mia creazione',
    zh: '发送我的作品',
    ko: '제품 전송하기',
    'zh-tw': '發送我的作品',
    'zh-hk': '發送我的作品',
    th: 'ส่งการสร้างสรรค์ชิ้นงานของฉัน',
  },
  {
    key: 'sfySetting_sizenotavalible',
    'en-gb': 'Size unavailable or need help?',
    'en-us': 'Size unavailable or need help?',
    fr: "Taille non disponible ? Besoin d'aide ?",
    de: 'Ist die gewünschte Größe nicht verfügbar oder benötigen Sie Hilfe?',
    pt: 'Tamanho não disponível ou precisa de ajuda?',
    nl: 'Maat niet beschikbaar of hulp nodig?',
    es: '¿La talla no está disponible o necesitas ayuda?',
    it: 'Una misura non è disponibile o hai bisogno di aiuto?',
    zh: '尺寸不可选或需要帮助？',
    ko: '원하는 사이즈가 없거나 도움이 필요하신가요?',
    'zh-tw': '找不到您所需的尺寸或需要協助？',
    'zh-hk': '找不到您所需的尺寸或需要協助？',
    th: 'ไม่มีขนาดหรือต้องการความช่วยเหลือใช่หรือไม่?',
  },
  {
    key: 'sfySetting_contactanambassador',
    'en-gb': 'Contact an Ambassador',
    'en-us': 'Contact an Ambassador',
    fr: 'Contacter un ambassadeur',
    de: 'Eine:n Kundenberater:in kontaktieren',
    pt: 'Contacte um embaixador',
    nl: 'Contacteer een ambassadeur',
    es: 'Contactar con un embajador',
    it: 'Contatta un ambasciatore Cartier',
    zh: '联系卡地亚服务大使',
    ko: '상담원 연결',
    'zh-tw': '聯繫卡地亞顧問',
    'zh-hk': '聯繫卡地亞顧問',
    th: 'ติดต่อแอมบาสเดอร์',
  },
  {
    key: 'sfySetting_disclaimer',
    'en-gb':
      'Simulation with a standard-sized diamond. The size and number of pavé diamonds (if your design contains any) may vary depending on the size of your ring. For more details, please contact us.',
    'en-us':
      'Simulation with a standard-sized diamond. The size and number of pavé diamonds (if your design contains any) may vary depending on the size of your ring. For more details, please contact us.',
    fr: 'Simulation avec un diamant de taille standard. Les dimensions et le nombre de diamants de pavage (si votre création en contient) peuvent varier selon la taille de votre bague. Pour plus de détails, contactez-nous.',
    de: 'Simulation mit einem Diamanten in Standardgröße. Die Maße und die Anzahl der Pavé-Diamanten (falls Ihre Kreation mit dieser Fassung gestaltet ist) können je nach Größe des Rings variieren. Bei weiteren Fragen können Sie uns gerne kontaktieren.',
    pt: 'Simulação com um diamante de tamanho padrão. O tamanho e o número de diamantes em pavé (caso a sua criação contiver algum) pode variar em função do tamanho do seu anel. Para mais informação, por favor, contacte-nos.',
    nl: 'Simulatie met een diamant in standaardformaat. Het aantal pavé-diamanten en hun afmetingen kunnen (indien van toepassing op uw creatie) variëren al naargelang de grootte van uw ring. Neem contact met ons op voor meer details.',
    es: 'Simulación con un diamante de tamaño estándar. Las dimensiones y el número de diamantes engastados (si están incluidos en tu diseño) pueden variar en función del tamaño de tu anillo. Ponte en contacto con nosotros para más información.',
    it: 'Simulazione con un diamante di dimensioni standard. Le dimensioni e il numero del pavé di diamanti (in caso la tua creazione ne prevedesse) possono variare a seconda della misura dell’anello. Per maggiori dettagli, ti invitiamo a contattarci.',
    zh: '图片仅供参考。根据您所订购作品的尺寸不同，宝石克拉重量，宝石数量和作品大小可能存在差异。如需了解详情，请联系卡地亚服务大使。',
    ko: '기본 사이즈 다이아몬드의 시뮬레이션입니다. 파베 세팅된 다이아몬드의 크기와 개수(디자인에 파베 세팅된 다이아몬드가 포함된 경우)는 링 사이즈에 따라 조금씩 다를 수 있습니다. 문의해 주시면 자세한 정보를 안내해 드리겠습니다.',
    'zh-tw':
      '以標準尺寸的鑽石進行模擬。鋪鑲鑽石的尺寸和數量（如作品設計包含任何鋪鑲鑽石）或會因您的戒指尺寸而異。請聯絡我們以了解詳情。',
    'zh-hk':
      '以標準尺寸的鑽石進行模擬。鋪鑲鑽石的尺寸和數量（如作品設計包含任何鋪鑲鑽石）視乎您的戒指尺寸而異。請聯絡我們以了解詳情。',
    th: 'จำลองด้วยเพชรขนาดมาตรฐาน ขนาดและจำนวนของเพชรที่ประดับชิ้นงาน (หากมีในรูปแบบดีไซน์) อาจแตกต่างกันไปขึ้นอยู่กับขนาดของแหวน โปรดติดต่อเรา สำหรับรายละเอียดเพิ่มเติม',
  },
  {
    key: 'sfySetting_StartNewCreation',
    'en-gb': 'Start a New Creation',
    'en-us': 'Start a new design',
    fr: 'Commencer une nouvelle création',
    de: 'Eine neue Kreation starten',
    pt: 'Iniciar uma nova criação',
    nl: 'Begin een nieuwe creatie',
    es: 'Empezar una nueva creación',
    it: 'Inizia a creare il tuo anello',
    zh: '开始全新创作',
    ko: '새로운 작품 시작하기',
    'zh-tw': '創作新作品',
    'zh-hk': '創作新作品',
    th: 'เริ่มต้นการสร้างสรรค์ใหม่',
  },
  {
    key: 'sfySetting_DiamondSummary',
    'en-gb': 'Diamond Summary',
    'en-us': 'Diamond Summary',
    fr: 'Informations sur le diamant',
    de: 'Wahl',
    pt: 'Resumo de diamantes',
    nl: 'Overzicht diamant',
    es: 'Resumen del diamante',
    it: 'Riepilogo diamante',
    zh: '钻石摘要',
    ko: '다이아몬드 요약',
    'zh-tw': '鑽石摘要',
    'zh-hk': '鑽石摘要',
    th: 'ข้อมูลสรุปเกี่ยวกับเพชร',
  },
  {
    key: 'sfySetting_DiamondSummary_header',
    'en-gb': 'Your Diamond Summary',
    'en-us': 'Your Diamond Summary',
    fr: 'Informations sur votre diamant',
    de: 'Ihre Auswahl',
    pt: 'O seu resumo de diamantes',
    nl: 'Een overzicht van uw diamant',
    es: 'Resumen de tu diamante',
    it: 'Il riepilogo del tuo diamante',
    zh: '您的钻石摘要',
    ko: '다이아몬드 요약',
    'zh-tw': '您的鑽石摘要',
    'zh-hk': '您的鑽石摘要',
    th: 'ข้อมูลสรุปเกี่ยวกับเพชรของคุณ',
  },
  {
    key: 'sfySetting_DiamondSummary_Text',
    'en-gb':
      'Thank you for choosing a Cartier diamond, the Maison is very pleased to say that what makes our stones different goes beyond appearances. As a responsible jeweller and watchmaker, we strive to source our diamonds only from conflict-free areas and from countries that are participants in the Kimberley Process and the System of Warranties since 2003.',
    'en-us':
      'Thank you for selecting a Cartier diamond. As a responsible jeweler and watchmaker, we strive to source our diamonds only from conflict-free areas and from countries that are participants in the Kimberley Process and the System of Warranties since 2003.',
    fr: "Merci d'avoir choisi un diamant Cartier. La Maison se réjouit de pouvoir affirmer que ce qui distingue les diamants Cartier va au-delà des apparences. En tant que joaillier et horloger responsable, nous nous efforçons de n'utiliser que des diamants provenant de zones sans conflits et de pays qui participent au Kimberley Process et au Système de Garanties depuis 2003.",
    de: 'Vielen Dank, dass Sie sich für einen Diamanten von Cartier entschieden haben. Die Maison freut sich, Ihnen mitteilen zu können, dass das, was unsere Steine auszeichnet, über das Äußere hinausgeht. Als verantwortungsbewusster Juwelier und Uhrmacher bemühen wir uns, unsere Diamanten nur aus konfliktfreien Gebieten und aus Ländern zu beziehen, die seit 2003 am Kimberley-Prozess und dem System of Warranties teilnehmen.',
    pt: 'Obrigado por escolher um diamante Cartier. A Maison tem o prazer de lhe dizer que aquilo que diferencia as nossas pedras vai muito para além da aparência. Como joalheiros e relojoeiros, esforçamo-nos por procurar os nossos diamantes apenas em regiões livres de conflitos e países que pertençam ao Processo e Sistema de Garantias Kimberley desde 2003.',
    nl: 'Dank u voor het kiezen van een Cartier-diamant. Bij ons Maison gaat dat wat onze stenen anders maakt verder dan enkel het uiterlijk. Als verantwoordelijke juwelier en horlogemaker streven wij ernaar onze diamanten alleen te winnen uit conflictvrije gebieden en uit landen die sinds 2003 deelnemen aan het Kimberleyproces en het garantiesysteem.',
    es: 'Gracias por escoger un diamante Cartier. Para la Maison, es un placer poder afirmar que lo que distingue a nuestras piedras va más allá de las apariencias. Como firma joyera y relojera responsable, trabajamos para abastecernos únicamente de diamantes procedentes de zonas libres de conflictos y de países adheridos al Proceso Kimberley y al Sistema de Garantías desde 2003.',
    it: 'Grazie per aver scelto un diamante Cartier, unico non solo per le sue qualità eccezionali. Con orgoglio, infatti, la Maison -  in qualità di gioielliere e orologiaio responsabile -  si impegna a ricavare i propri diamanti esclusivamente da aree non interessate da conflitti e da Paesi che aderiscono al Processo di Kimberley e al Sistema di Garanzie dal 2003.',
    zh: '感谢您选购卡地亚钻石。卡地亚宝石的脱颖而出，源自超越美感的坚持。作为负责任的珠宝商和腕表商，卡地亚坚持从没有争议的国家与地区进行钻石采购，严格依据2003年制定的金伯利进程证书制度（KPCS）和世界钻石理事会担保制度（SoW）。',
    ko: '까르띠에 다이아몬드를 선택해 주셔서 감사합니다. 메종은 아름다움 그 이상의 매력을 지닌 특별한 스톤만을 사용합니다. 까르띠에는 책임감 있는 주얼러와 워치메이커로서 2003년 이래 킴벌리 프로세스 인증 체계 회원국과 미분쟁 지역에서만 다이아몬드를 공수하기 위해 노력하고 있습니다.',
    'zh-tw':
      '感謝您選擇卡地亞鑽石。我們謹此為您說明為何卡地亞鑽石內外皆美。卡地亞作為負責任的珠寶及製錶品牌，自2003年起致力僅從非衝突地區，以及參與金伯利進程（Kimberley Process）和保證體系（System of Warranties）的國家/地區採購鑽石。',
    'zh-hk':
      '感謝您選擇卡地亞鑽石。我們謹此為您說明為何卡地亞鑽石內外皆美。卡地亞作為負責任的珠寶及製錶品牌，自2003年起致力僅從非衝突地區，以及參與金伯利進程（Kimberley Process）和保證體系（System of Warranties）的國家/地區採購鑽石。',
    th: 'ขอขอบคุณที่เลือกซื้อเพชรจากคาร์เทียร์ เมซงมีความยินดีอย่างยิ่งที่จะแจ้งให้ทราบว่าสิ่งที่สร้างความแตกต่างให้แก่อัญมณีของเราคือความล้ำค่าที่เหนือกว่ารูปลักษณ์ภายนอก ในฐานะผู้ค้าอัญมณีและผู้รังสรรค์เรือนเวลาที่มีความรับผิดชอบ เรามุ่งมั่นในการคัดสรรเพชรจากแหล่งที่ปราศจากความขัดแย้งและจากประเทศที่เข้าร่วมกระบวนการคิมเบอรี่ (Kimberley Process) และระบบการรับประกันสำหรับเพชรเจียระไน (System of Warranties) มาตั้งแต่ปี ค.ศ. 2003',
  },
  {
    key: 'sfySetting_DiamondSummary_GIACertificate',
    'en-gb': 'GIA Certificate supplied',
    'en-us': 'GIA Certificate supplied',
    fr: 'Certificat GIA',
    de: 'GIA Zertifikat',
    pt: 'Certificado GIA',
    nl: 'GIA Certificaat',
    es: 'Certificado GIA',
    it: 'Certificato GIA',
    zh: '附有美国宝石学院（GIA）证书',
    ko: 'GIA 인증 제공',
    'zh-tw': '附有美國寶石學院（GIA）證書',
    'zh-hk': '附有美國寶石學院（GIA）證書',
    th: 'มีใบรับรอง GIA',
  },
  {
    key: 'sfySetting_DiamondSummary_Amendyourselection',
    'en-gb': 'Amend Your Diamond Selection',
    'en-us': 'Edit your diamond selection',
    fr: 'Modifier votre sélection de diamants',
    de: 'Ändern Sie Ihre Diamantenauswahl',
    pt: 'Alterar a sua seleção de diamantes',
    nl: 'Wijzig uw diamantselectie',
    es: 'Modificar la selección de tu diamante',
    it: 'Modifica la selezione del tuo diamante',
    zh: '修改您选择的钻石',
    ko: '다이아몬드 선택 수정하기',
    'zh-tw': '更改您選擇的鑽石',
    'zh-hk': '更改您選擇的鑽石',
    th: 'แก้ไขการเลือกเพชรของคุณ',
  },
  {
    key: 'sfySetting_FindYourMatchingBand',
    'en-gb': 'Find your matching band',
    'en-us': 'Find a matching band',
    fr: "Trouver l'alliance correspondante",
    de: 'Finden Sie Ihren passenden Ring',
    pt: 'Encontre um anel a combinar',
    nl: 'Vind uw bijpassende ring',
    es: 'Encontrar tu anillo a juego',
    it: 'Trova la tua fede abbinata',
    zh: '找到您心仪的对戒',
    ko: '어울리는 밴드 찾기',
    'zh-tw': '尋找搭配戒指',
    'zh-hk': '尋找搭配戒指',
    th: 'ค้นหาแหวนที่เข้าคู่กัน',
  },
  {
    key: 'sfySetting_FindYourMatchingBand_text',
    'en-gb': "Choose from the Maison's selection of matching wedding bands.",
    'en-us': "Choose from the Maison's selection of matching wedding bands.",
    fr: 'Choisir dans la sélection des alliances de la Maison',
    de: 'Wählen Sie aus der Auswahl an passenden Eheringen der Maison',
    pt: 'Escolha a partir da seleção Maison de anéis de casamento combinados.',
    nl: 'Kies uit onze selectie van bijpassende trouwringen.',
    es: 'Escoger en la selección de anillos de matrimonio a juego de la Maison.',
    it: 'Scegli dalla selezione di fedi coordinate della Maison',
    zh: '从卡地亚精选的结婚对戒中，进行选择。',
    ko: '메종의 셀렉션에서 어울리는 웨딩 밴드를 선택해 보세요.',
    'zh-tw': '您可從卡地亞精選搭配婚戒中選擇。',
    'zh-hk': '您可從卡地亞精選搭配結婚戒指中選擇。',
    th: 'เลือกจากแบบแหวนแต่งงานที่เมซงคัดสรรมาอย่างพิถีพิถัน',
  },
  {
    key: 'sfySetting_Service_YourPurchase',
    'en-gb': 'Your Purchase',
    'en-us': 'Your Purchase',
    fr: 'Votre achat',
    de: 'Ihr Einkauf',
    pt: 'A sua compra',
    nl: 'Uw aankoop',
    es: 'Tu compra',
    it: 'Il tuo acquisto',
    zh: '您的订单',
    ko: '구매',
    'zh-tw': '您選購的作品',
    'zh-hk': '您選購的作品',
    th: 'การสั่งซื้อของคุณ',
  },
  {
    key: 'sfySetting_Service_YourPurchase_Text',
    'en-gb':
      'The Maison is committed to making your purchasing experience as tailor-made as possible during this special moment. Please do not hesitate to ask your precious stone expert about the services available to you.',
    'en-us':
      'The Maison is committed to making your purchasing experience as personal as possible at this special time. Please do not hesitate to ask your precious stone expert about the services available to you.',
    fr: "Nous avons à cœur de faire de votre achat une expérience sur mesure à l'occasion de ce moment très particulier. Nous vous invitons à interroger un expert en pierres précieuses sur les services dont vous pouvez bénéficier.",
    de: 'Die Maison ist bestrebt, Ihr Einkaufserlebnis in diesem besonderen Moment so maßgeschneidert wie möglich zu gestalten. Bitte zögern Sie nicht, unsere Edelsteinexpert:innen nach den für Sie verfügbaren Dienstleistungen zu fragen.',
    pt: 'A Maison assumiu o compromisso de tornar a sua experiência de compra o mais personalizada possível neste momento tão especial da sua vida. Não hesite em perguntar ao seu especialista em pedras preciosas os serviços que temos para si.',
    nl: 'Het Maison stelt alles in het werk om uw aankoopbeleving tijdens dit bijzondere moment zo persoonlijk mogelijk te maken. Aarzel niet om uw edelsteendeskundige te vragen naar de diensten die voor u beschikbaar zijn.',
    es: 'La Maison se compromete a personalizar tu experiencia de compra todo lo posible en este momento tan especial. No dudes en consultar a tu experto en piedras preciosas sobre los servicios a tu disposición.',
    it: 'In questo momento speciale della tua vita, la Maison si impegna ad offrirti un’esperienza di acquisto su misura. Non esitare a chiedere al tuo esperto di pietre preziose i servizi a tua disposizione.',
    zh: '品牌致力于在此特殊时段，为您提供量身定制的购物体验。如需咨询可用的服务，请随时与您的珍贵宝石专家联系。',
    ko: '메종은 이 특별한 순간에 고객님께 맞춤형 구매 경험을 선사하고자 합니다. 이용 가능한 서비스가 궁금하시면 프레셔스 스톤 전문가에게 문의하시기 바랍니다.',
    'zh-tw': '卡地亞致力在目前的特別時刻為您提供量身訂製的購物體驗。歡迎向卡地亞寶石專家查詢您可享有的服務。',
    'zh-hk': '卡地亞致力在目前的特別時刻為您提供量身訂製的購物體驗。歡迎向卡地亞寶石專家查詢您可享有的服務。',
    th: 'เมซงมุ่งมั่นที่จะทำให้ประสบการณ์การซื้อของคุณดำเนินไปในลักษณะที่เหมาะสมกับคุณที่สุดเท่าที่จะเป็นไปได้ในช่วงเวลาพิเศษนี้ โปรดสอบถามผู้เชี่ยวชาญด้านอัญมณีล้ำค่าเกี่ยวกับบริการที่เราพร้อมนำเสนอให้แก่คุณ',
  },
  {
    key: 'sfySetting_Service_GiftWrapping',
    'en-gb': 'Gift Wrapping',
    'en-us': 'Gift Wrapping',
    fr: 'Emballage cadeau signature de la maison Cartier',
    de: 'Geschenkverpackung',
    pt: 'Embrulhos',
    nl: 'Cadeauverpakking',
    es: 'Empaquetado de regalo',
    it: 'Confezione regalo',
    zh: '礼品包装',
    ko: '선물 포장',
    'zh-tw': '禮品包裝',
    'zh-hk': '禮品包裝服務',
    th: 'บริการห่อของขวัญ',
  },
  {
    key: 'sfySetting_Service_GiftWrapping_Text',
    'en-gb':
      'Cartier takes pride in the Art of Gifting, and this is reflected in the wrapping of all our creations. Speak to our experts to arrange signature packaging with a personalised greeting card.',
    'en-us':
      'Cartier takes pride in the Art of Gifting, and this is reflected in the wrapping of all our creations. Speak to our experts to arrange signature packaging with a personalized card.',
    fr: "Cartier apporte le plus grand soin à l'art d'emballer ses créations. Nous vous invitons à demander à votre ambassadeur un emballage signature de la maison auquel vous pourrez ajouter un message personnalisé.",
    de: 'Cartier ist stolz auf die Kunst des Schenkens, und das spiegelt sich auch in der Verpackung all unserer Kreationen wider. Sprechen Sie mit unseren Expert:innen, um eine Geschenkverpackung mit einer personalisierten Grußkarte zu arrangieren.',
    pt: 'A Cartier orgulha-se da sua experiência na Arte de dar Presentes e isto reflete-se no embrulho de todas as nossas criações. Fale com os nossos especialistas para criar uma embalagem especial com um cartão personalizado.',
    nl: 'Cartier is trots op de Kunst van het inpakken en dit wordt weerspiegeld in de verpakking van al onze creaties. Neem contact op met onze deskundigen voor een speciale verpakking met een gepersonaliseerde wenskaart.',
    es: 'En Cartier, nos sentimos orgullosos del arte de regalar, y esto se refleja en el empaquetado de todas nuestras creaciones. Habla con nuestros expertos si deseas solicitar un empaquetado de la firma con una tarjeta de felicitación personalizada.',
    it: 'Cartier è maestra nell’arte del regalo: contatta i nostri esperti per scegliere una confezione regalo, accompagnata da un biglietto di auguri personalizzato.',
    zh: '品牌所有作品皆精心包装，诠释了卡地亚对于馈赠艺术的珍视。联系我们的专家，尊享随附个性化祝福卡片的标志性礼物包装。',
    ko: '까르띠에는 품격 있는 선물 포장 서비스에 자부심이 있습니다. 이는 메종의 모든 제품 포장에도 반영되고 있습니다. 까르띠에 전문가에게 문의하여 맞춤형 메시지 카드가 포함된 까르띠에 시그니처 선물 포장을 준비해 보세요.',
    'zh-tw':
      '卡地亞的禮品包裝服務彰顯品牌引以為傲的贈禮藝術。歡迎向我們的專家查詢，以為您安排卡地亞經典包裝，並附上個人化訂製賀卡。',
    'zh-hk':
      '卡地亞的禮品包裝服務彰顯世家引以為傲的饋贈之藝術。歡迎向我們的專家查詢，以為您安排卡地亞經典包裝，並附上個人化心意卡。',
    th: 'คาร์เทียร์มีความภาคภูมิใจในศิลปะการห่อของขวัญ และสิ่งนี้สะท้อนให้เห็นในการห่อของขวัญสำหรับชิ้นงานทั้งหมดของเรา แจ้งผู้เชี่ยวชาญของเราเพื่อจัดเตรียมบรรจุภัณฑ์ที่เป็นเอกลักษณ์ของแบรนด์พร้อมด้วยการ์ดอวยพรเฉพาะสำหรับผู้รับของขวัญ',
  },
  {
    key: 'sfySetting_Service_DeliveryReturns',
    'en-gb': 'Delivery & Returns',
    'en-us': 'Delivery & Returns',
    fr: 'Livraisons et Retours',
    de: 'Lieferung und Rückgabe',
    pt: 'Entregas e devoluções',
    nl: 'Levering en retourzendingen',
    es: 'Envío y devoluciones',
    it: 'Consegna e resi',
    zh: '配送及退货',
    ko: '배송 & 반품',
    'zh-tw': '送遞及退換服務',
    'zh-hk': '送遞及退換服務',
    th: 'การส่งมอบและการคืนสินค้า',
  },
  {
    key: 'sfySetting_Service_DeliveryReturns_Text',
    'en-gb':
      'During your personal appointment, delivery of your ring will be arranged. Please note that personalised pieces cannot be exchanged or returned.',
    'en-us':
      "During your in-person appointment, we can arrange your ring's delivery. Please note that personalized pieces can not be exchanged or returned.",
    fr: 'Lors de votre rendez-vous personnel, la livraison de votre bague sera organisée. Merci de noter que les créations personnalisées ne pourront être échangées ou reprises.',
    de: 'Während Ihres persönlichen Termins wird die Lieferung Ihres Rings vereinbart. Bitte beachten Sie, dass personalisierte Stücke nicht umgetauscht oder zurückgegeben werden können.',
    pt: 'A entrega do anel será organizada aquando da sua marcação. Tenha em atenção que as peças personalizadas não poderão ser trocadas nem devolvidas.',
    nl: 'Tijdens uw persoonlijke afspraak zal de levering van uw ring geregeld worden. Gepersonaliseerde artikelen kunnen niet worden geruild of geretourneerd.',
    es: 'Durante tu cita personal, se organizará la entrega de tu anillo. Ten en cuenta que las piezas personalizadas no pueden cambiarse ni devolverse.',
    it: 'Durante il tuo appuntamento verrà organizzata la consegna del tuo anello. Ti preghiamo di notare che le creazioni personalizzate non possono essere cambiate o restituite.',
    zh: '我们将在您的私人会面期间，确认戒指配送的安排。请注意，个性化定制作品不可换货或退回。',
    ko: '부티크 예약 방문 시 링 배송이 준비됩니다. 맞춤형 제품은 교환 또는 반품할 수 없습니다.',
    'zh-tw': '我們會在提供預約個人化服務期間為您安排戒指送遞。請注意，個人化訂製作品不設更換或退換。',
    'zh-hk': '我們會在提供預約個人化服務期間為您安排戒指送遞。請注意，個人化訂製作品不設更換或退換。',
    th: 'ระหว่างการนัดหมายส่วนตัวของคุณ เราจะส่งมอบแหวนให้แก่คุณ โปรดทราบว่าชิ้นงานที่มีการปรับแต่งตามความต้องการของลูกค้านั้นจะไม่สามารถเปลี่ยนหรือคืนได้',
  },
  {
    key: 'sfySetting_Service_Payment',
    'en-gb': 'Payment',
    'en-us': 'Payment',
    fr: 'Paiement',
    de: 'Bezahlung',
    pt: 'Pagamento',
    nl: 'Betaling',
    es: 'Pago',
    it: 'Pagamento',
    zh: '支付方式',
    ko: '결제',
    'zh-tw': '付款',
    'zh-hk': '付款',
    th: 'การชำระเงิน',
  },
  {
    key: 'sfySetting_Service_Payment_text',
    'en-gb':
      'The Maison offers multiple secure payment methods for your convenience. The precious stone expert can answer any questions you may have during your appointment.',
    'en-us':
      'The Maison offers multiple secure payment methods for your convenience. The precious stone expert can answer any questions you may have during your appointment.',
    fr: 'La Maison vous propose de multiples moyens de paiement sécurisés. Notre expert en pierres précieuses est à votre disposition pour répondre à toutes vos questions.',
    de: 'Die Maison bietet mehrere sichere Zahlungsmethoden für Ihren Komfort. Unsere Edelsteinexpert:innen können Ihnen während Ihres Termins alle Fragen beantworten, die Sie haben.',
    pt: 'A Maison oferece várias formas convenientes de pagamento seguro. O especialista em pedras preciosas poderá responder a qualquer questão que tenha durante a sua marcação.',
    nl: 'Het Maison biedt voor uw gemak meerdere veilige betaalmethoden. De edelsteendeskundige kan al uw vragen beantwoorden tijdens uw afspraak.',
    es: 'La Maison ofrece múltiples métodos de pago seguro para tu comodidad. El experto en piedras preciosas podrá responder a todas las preguntas que tengas durante tu cita.',
    it: 'Per tua comodità, la Maison ti offre diversi metodi di pagamento sicuri. L’esperto di pietre preziose potrà rispondere a qualsiasi domanda durante il tuo appuntamento.',
    zh: '为打造便利体验，品牌提供多种安全支付方式。在会面期间，珍贵宝石专家可帮助解答您的任何疑问。',
    ko: '메종은 고객 편의를 위해 다양한 안전 결제 방식을 제공합니다. 부티크 예약 방문 시 궁금한 점이 있으시면 프레셔스 스톤 전문가에게 문의하시기 바랍니다.',
    'zh-tw': '卡地亞為您提供多種方便安全的付款方式。如有任何問題，歡迎在寶石專家為您提供服務時提出。',
    'zh-hk': '卡地亞為您提供多種方便安全的付款方式。如有任何問題，歡迎在寶石專家為您提供服務時提出。',
    th: 'เมซงมีวิธีการชำระเงินที่ปลอดภัยหลายวิธีเพื่อความสะดวกของคุณ ผู้เชี่ยวชาญด้านอัญมณีล้ำค่าสามารถตอบคำถามต่างๆ ของคุณได้ระหว่างการนัดหมาย',
  },
  {
    key: 'sfySetting_1895_PT_NonPaved_BrilliantCut',
    'en-gb': 'Platinum, Brilliant Cut',
    'en-us': 'Platinum, Brilliant Cut',
    fr: 'Platine, Taille Brillant',
    de: 'Platin, Brillantschliff',
    pt: 'Platina, corte brilhante',
    nl: 'Platina, briljant geslepen',
    es: 'Platino, talla brillante',
    it: 'Platino, taglio brillante',
    zh: '铂金，圆形明亮式切割',
    ko: '플래티늄, 브릴리언트 컷 ',
    'zh-tw': '鉑金，圓形明亮式切割',
    'zh-hk': '鉑金，圓形明亮式切割',
    th: 'แพลทินัม เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_1895_YG_NonPaved_BrilliantCut',
    'en-gb': 'Yellow Gold, Brilliant Cut',
    'en-us': 'Yellow Gold, Brilliant Cut',
    fr: 'Or jaune, Taille Brillant',
    de: 'Gelbgold, Brillantschliff',
    pt: 'Ouro amarelo, corte brilhante',
    nl: 'Geelgoud, briljant geslepen',
    es: 'Oro amarillo, talla brillante',
    it: 'Oro giallo, taglio brillante',
    zh: '黄金，圆形明亮式切割',
    ko: '옐로우 골드, 브릴리언트 컷',
    'zh-tw': '黃K金，圓形明亮式切割',
    'zh-hk': '黃金，圓形明亮式切割',
    th: 'เยลโลว์โกลด์ เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_1895_PG_NonPaved_BrilliantCut',
    'en-gb': 'Rose Gold, Brilliant Cut',
    'en-us': 'Rose Gold, Brilliant Cut',
    fr: 'Or rose, Taille Brillant',
    de: 'Roségold, Brillantschliff',
    pt: 'Ouro rosa, corte brilhante',
    nl: 'Roségoud, briljant geslepen',
    es: 'Oro rosa, talla brillante',
    it: 'Oro rosa, taglio brillante',
    zh: '玫瑰金，圆形明亮式切割',
    ko: '핑크 골드, 브릴리언트 컷',
    'zh-tw': '玫瑰金，圓形明亮式切割',
    'zh-hk': '玫瑰金，圓形明亮式切割',
    th: 'โรสโกลด์ เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_1895_PT_ClassicPaved_BrilliantCut',
    'en-gb': 'Platinum, Classic Paved, Brilliant Cut',
    'en-us': 'Platinum, Classic Pavé, Brilliant Cut',
    fr: 'Platine, Classic Pavé, Taille Brillant',
    de: 'Platin, klassisch besetzt, Brillantschliff',
    pt: 'Platina, pavé clássico, corte brilhante',
    nl: 'Platina, klassiek pavé, briljant geslepen',
    es: 'Platino, pavé clásico, talla brillante',
    it: 'Platino, pavé classico, taglio brillante',
    zh: '铂金，经典铺镶，圆形明亮式切割',
    ko: '플래티늄, 클래식 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，經典鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，經典鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ประดับอัญมณีแบบคลาสสิก เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_1895_PT_Paved_BrilliantCut',
    'en-gb': 'Platinum, Paved, Brilliant Cut',
    'en-us': 'Platinum, Pavé, Brilliant Cut',
    fr: 'Platine, Pavé, Taille Brillant',
    de: 'Platin, besetzt, Brillantschliff',
    pt: 'Platina, pavé, corte brilhante',
    nl: 'Platina, pavé, briljant geslepen',
    es: 'Platino, pavé, talla brillante',
    it: 'Platino, pavé, taglio brillante',
    zh: '铂金，铺镶，圆形明亮式切割',
    ko: '플래티늄, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ประดับอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_1895_PT_NonPaved_OvalCut',
    'en-gb': 'Platinum, Oval Cut',
    'en-us': 'Platinum, Oval Cut',
    fr: 'Platine, Taille Ovale',
    de: 'Platin, Ovalschliff',
    pt: 'Platina, corte oval',
    nl: 'Platina, ovaal geslepen',
    es: 'Platino, talla ovalada',
    it: 'Platino, taglio ovale',
    zh: '铂金，椭圆形切割',
    ko: '플래티늄, 오벌 컷',
    'zh-tw': '鉑金，橢圓形切割',
    'zh-hk': '鉑金，橢圓形切割',
    th: 'แพลทินัม เจียระไนทรงรี',
  },
  {
    key: 'sfySetting_1895_PT_NonPaved_CushionCut',
    'en-gb': 'Platinum, Cushion Cut',
    'en-us': 'Platinum, Cushion Cut',
    fr: 'Platine, Taille Coussin',
    de: 'Platin, Prinzessschliff',
    pt: 'Platina, corte almofada',
    nl: 'Platina, kussenvormig geslepen',
    es: 'Platino, talla cojín',
    it: 'Platino, taglio a cuscino',
    zh: '铂金，枕形切割',
    ko: '플래티늄, 쿠션 컷',
    'zh-tw': '鉑金，枕形切割',
    'zh-hk': '鉑金，枕形切割',
    th: 'แพลทินัม เจียระไนทรงสี่เหลี่ยมขอบมน',
  },
  {
    key: 'sfySetting_Ballerine_PT_NonPaved_BrilliantCut',
    'en-gb': 'Platinum, Brilliant Cut',
    'en-us': 'Platinum, Brilliant Cut',
    fr: 'Platine, Taille Brillant',
    de: 'Platin, Brillantschliff',
    pt: 'Platina, corte brilhante',
    nl: 'Platina, briljant geslepen',
    es: 'Platino, talla brillante',
    it: 'Platino, taglio brillante',
    zh: '铂金，圆形明亮式切割',
    ko: '플래티늄, 브릴리언트 컷',
    'zh-tw': '鉑金，圓形明亮式切割',
    'zh-hk': '鉑金，圓形明亮式切割',
    th: 'แพลทินัม เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Ballerine_PG_NonPaved_BrilliantCut',
    'en-gb': 'Rose Gold, Brilliant Cut',
    'en-us': 'Rose Gold, Brilliant Cut',
    fr: 'Or rose, Taille Brillant',
    de: 'Roségold, Brillantschliff',
    pt: 'Ouro rosa, corte brilhante',
    nl: 'Roségoud, briljant geslepen',
    es: 'Oro rosa, talla brillante',
    it: 'Oro rosa, taglio brillante',
    zh: '玫瑰金，圆形明亮式切割',
    ko: '핑크 골드, 브릴리언트 컷',
    'zh-tw': '玫瑰金，圓形明亮式切割',
    'zh-hk': '玫瑰金，圓形明亮式切割',
    th: 'โรสโกลด์ เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Ballerine_PT_Paved_BrilliantCut',
    'en-gb': 'Platinum, Paved, Brilliant Cut',
    'en-us': 'Platinum, Pavé, Brilliant Cut',
    fr: 'Platine, Pavé, Taille Brillant',
    de: 'Platin, besetzt, Brillantschliff',
    pt: 'Platina, pavé, corte brilhante',
    nl: 'Platina, pavé, briljant geslepen',
    es: 'Platino, pavé, talla brillante',
    it: 'Platino, pavé, taglio brillante',
    zh: '铂金，铺镶，圆形明亮式切割',
    ko: '플래티늄, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_VendomeLouisCartier_TG_NonPaved_BrilliantCut',
    'en-gb': '3 Gold, Brilliant Cut',
    'en-us': '3 Gold, Brilliant Cut',
    fr: '3 Or, Pavé, Taille Brillant',
    de: '3 Gold, Brillantschliff',
    pt: '3 dourados, corte brilhante',
    nl: '3 kleuren goud, briljant geslepen',
    es: '3 oros, talla brillante',
    it: '3 ori, taglio brillante',
    zh: '三色金，圆形明亮式切割',
    ko: '3가지 골드, 브릴리언트 컷',
    'zh-tw': '三色金，圓形明亮式切割',
    'zh-hk': '三色金，圓形明亮式切割',
    th: 'ทอง 3 ชนิด เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_CartierDestinee_PT_Paved_BrilliantCut',
    'en-gb': 'Platinum, Paved, Brilliant Cut',
    'en-us': 'Platinum, Pavé, Brilliant Cut',
    fr: 'Platine, Pavé, Taille Brillant',
    de: 'Platin, besetzt, Brillantschliff',
    pt: 'Platina, pavé, corte brilhante',
    nl: 'Platina, pavé, briljant geslepen',
    es: 'Platino, pavé, talla brillante',
    it: 'Platino, pavé, taglio brillante',
    zh: '铂金，铺镶，圆形明亮式切割',
    ko: '플래티늄, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ประดับอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_CartierDestinee_PT_Paved_OvalCut',
    'en-gb': 'Platinum, Paved, Oval Cut',
    'en-us': 'Platinum, Pavé, Oval Cut',
    fr: 'Platine, Pavé, Taille Ovale',
    de: 'Platin, besetzt, Ovalschliff',
    pt: 'Platina, pavé, corte oval',
    nl: 'Platina, pavé, ovaal geslepen',
    es: 'Platino, pavé, talla ovalada',
    it: 'Platino, pavé, taglio ovale',
    zh: '铂金，铺镶，椭圆形切割',
    ko: '플래티늄, 파베 세팅, 오벌 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，橢圓形切割',
    'zh-hk': '鉑金，鋪鑲鑽石，橢圓形切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงรี',
  },
  {
    key: 'sfySetting_CartierDestinee_PT_Paved_EmeraldCut',
    'en-gb': 'Platinum, Paved, Emerald Cut',
    'en-us': 'Platinum, Pavé, Emerald Cut',
    fr: 'Platine, Pavé, Taille Emeraude',
    de: 'Platin, besetzt, Smaragdschliff',
    pt: 'Platina, pavé, corte esmeralda',
    nl: 'Platina, pavé, emerald geslepen',
    es: 'Platino, pavé, talla esmeralda',
    it: 'Platino, pavé, taglio smeraldo',
    zh: '铂金，铺镶，祖母绿型切割',
    ko: '플래티늄, 파베 세팅, 에메랄드 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，祖母綠形切割',
    'zh-hk': '鉑金，鋪鑲鑽石，祖母綠式切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงสี่เหลี่ยมผืนผ้า',
  },
  {
    key: 'sfySetting_CartierDestinee_PT_Paved_PearShaped',
    'en-gb': 'Platinum, Paved, Pear Shaped Cut',
    'en-us': 'Platinum, Pavé, Pear-Shaped Cut',
    fr: 'Platine, Pavé, Taille Poire',
    de: 'Platin, besetzt, Tropfenschliff',
    pt: 'Platina, pavé, corte pera',
    nl: 'Platina, pavé, peervormig geslepen',
    es: 'Platino, pavé, talla con forma de pera',
    it: 'Platino, pavé, taglio a pera',
    zh: '铂金，铺镶，梨形切割',
    ko: '플래티늄, 파베 세팅, 페어형 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，梨形切割',
    'zh-hk': '鉑金，鋪鑲鑽石，梨形切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงลูกแพร์',
  },
  {
    key: 'sfySetting_CartierDestinee_PT_Paved_CushionCut',
    'en-gb': 'Platinum, Paved, Cushion Cut',
    'en-us': 'Platinum, Pavé, Cushion Cut',
    fr: 'Platine, Pavé, Taille Coussin',
    de: 'Platin, besetzt, Prinzessschliff',
    pt: 'Platina, pavé, corte almofada',
    nl: 'Platina, pavé kussenvormig geslepen',
    es: 'Platino, pavé, talla cojín',
    it: 'Platino, pavé, taglio a cuscino',
    zh: '铂金，铺镶，枕形切割',
    ko: '플래티늄, 파베 세팅, 쿠션 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，枕形切割',
    'zh-hk': '鉑金，鋪鑲鑽石，枕形切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงสี่เหลี่ยมขอบมน',
  },
  {
    key: 'sfySetting_Etincelle_PT_Paved_BrilliantCut',
    'en-gb': 'Platinum, Paved, Brilliant Cut',
    'en-us': 'Platinum, Pavé, Brilliant Cut',
    fr: 'Platine, Pavé, Taille Brillant',
    de: 'Platin, besetzt, Brillantschliff',
    pt: 'Platina, pavé, corte brilhante',
    nl: 'Platina, pavé, briljant geslepen',
    es: 'Platino, pavé, talla brillante',
    it: 'Platino, pavé, taglio brillante',
    zh: '铂金，铺镶，圆形明亮式切割',
    ko: '플래티늄, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Love_PT_NonPaved_BrilliantCut',
    'en-gb': 'White Gold, Brilliant Cut',
    'en-us': 'White Gold, Brilliant Cut',
    fr: 'Or blanc, Taille Brillant',
    de: 'Weißgold, Brillantschliff',
    pt: 'Ouro branco, corte brilhante',
    nl: 'Witgoud, briljant geslepen',
    es: 'Oro blanco, talla brillante',
    it: 'Oro bianco, taglio brillante',
    zh: '白金，圆形明亮式切割',
    ko: '화이트 골드, 브릴리언트 컷',
    'zh-tw': '白K金，圓形明亮式切割',
    'zh-hk': '白色黃金，圓形明亮式切割',
    th: 'ไวท์โกลด์ เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Love_PG_NonPaved_BrilliantCut',
    'en-gb': 'Rose Gold, Brilliant Cut',
    'en-us': 'Rose Gold, Brilliant Cut',
    fr: 'Or rose, Taille Brillant',
    de: 'Roségold, Brillantschliff',
    pt: 'Ouro rosa, corte brilhante',
    nl: 'Roségoud, briljant geslepen',
    es: 'Oro rosa, talla brillante',
    it: 'Oro rosa, taglio brillante',
    zh: '玫瑰金，圆形明亮式切割',
    ko: '핑크 골드, 브릴리언트 컷',
    'zh-tw': '玫瑰金，圓形明亮式切割',
    'zh-hk': '玫瑰金，圓形明亮式切割',
    th: 'โรสโกลด์ เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Love_PG_Paved_BrilliantCut',
    'en-gb': 'Rose Gold, Paved, Brilliant Cut',
    'en-us': 'Rose Gold, Pavé, Brilliant Cut',
    fr: 'Or rose, Pavé, Taille Brillant',
    de: 'Roségold, besetzt, Brillantschliff',
    pt: 'Ouro rosa, pavé, corte brilhante',
    nl: 'Roségoud, pavé, briljant geslepen',
    es: 'Oro rosa, pavé, talla brillante',
    it: 'Oro rosa, pavé, taglio brillante',
    zh: '玫瑰金，铺镶，圆形明亮式切割',
    ko: '핑크 골드, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '玫瑰金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '玫瑰金，鋪鑲鑽石，圓形明亮式切割',
    th: 'โรสโกลด์ ฝังอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_Love_RG_Paved_BrilliantCut',
    'en-gb': 'Rose Gold, Paved, Brilliant Cut',
    'en-us': 'Rose Gold, Pavé, Brilliant Cut',
    fr: 'Or rose, Pavé, Taille Brillant',
    de: 'Roségold, besetzt, Brillantschliff',
    pt: 'Ouro rosa, pavé, corte brilhante',
    nl: 'Roségoud, pavé, briljant geslepen',
    es: 'Oro rosa, pavé, talla brillante',
    it: 'Oro rosa, pavé, taglio brillante',
    zh: '玫瑰金，铺镶，圆形明亮式切割',
    ko: '핑크 골드, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '玫瑰金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '玫瑰金，鋪鑲鑽石，圓形明亮式切割',
    th: 'โรสโกลด์ ฝังอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'sfySetting_TrinityRuban_PT_Paved_BrilliantCut',
    'en-gb': 'Platinum, Paved, Brilliant Cut',
    'en-us': 'Platinum, Pavé, Brilliant Cut',
    fr: 'Platine, Pavé, Taille Brillant',
    de: 'Platin, besetzt, Brillantschliff',
    pt: 'Platina, pavé, corte brilhante',
    nl: 'Platina, pavé, briljant geslepen',
    es: 'Platino, pavé, talla brillante',
    it: 'Platino, pavé, taglio brillante',
    zh: '铂金，铺镶，圆形明亮式切割',
    ko: '플래티늄, 파베 세팅, 브릴리언트 컷',
    'zh-tw': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    'zh-hk': '鉑金，鋪鑲鑽石，圓形明亮式切割',
    th: 'แพลทินัม ฝังอัญมณี เจียระไนทรงบริลเลียนท์',
  },
  {
    key: 'savemycreation_header',
    'en-gb': 'Send My Creation',
    'en-us': 'Share this design',
    fr: 'Envoyer ma création',
    de: 'Kreation abschicken',
    pt: 'Enviar a minha criação',
    nl: 'Verstuur mijn creatie',
    es: 'Enviar mi creación',
    it: 'Invia un riepilogo della mia creazione',
    zh: '发送我的作品',
    ko: '제품 전송하기',
    'zh-tw': '發送我的作品',
    'zh-hk': '發送我的作品',
    th: 'ส่งผลงานรังสรรค์ของฉัน',
  },
  {
    key: 'savemycreation_text',
    'en-gb':
      'Please enter your email address below if you wish to receive a message with the details of your creation. \n\nWe will not collect or store your email address in our database, it will only be used to send you the summary.',
    'en-us':
      'Please enter an email below if you wish to send or receive a message with the details of this design. \n\nWe will not collect or store your email address in our database, it will only be used to send a summary.',
    fr: "Si vous souhaitez recevoir un message avec les détails de votre création, merci d'entrer votre e-mail.\n\nIl ne sera ni récupéré ni intégré à notre base de données, et ne servira qu'à vous envoyer ce récapitulatif.",
    de: 'Bitte geben Sie unten Ihre E-Mail-Adresse ein, wenn Sie eine Nachricht mit den Details Ihrer Kreation erhalten möchten. \n\nWir werden Ihre E-Mail-Adresse nicht in unserer Datenbank erfassen oder speichern, sondern nur dazu verwenden, Ihnen die Zusammenfassung zu schicken.',
    pt: 'Insira o seu correio eletrónico abaixo se quiser receber uma mensagem com os detalhes da sua criação.\n\nNão vamos guardar o endereço de correio eletrónico na nossa base de dados, que apenas será usado para lhe enviar o resumo.',
    nl: 'Vul hieronder uw e-mailadres in als u een bericht wilt ontvangen met de details van uw creatie. \n\nWij zullen uw e-mailadres niet verzamelen of opslaan in onze database, het wordt alleen gebruikt om u het overzicht te sturen.',
    es: 'Introduce tu dirección de correo electrónico abajo si deseas recibir un mensaje con los detalles de tu creación. \n\nNo recopilaremos ni almacenaremos tu dirección de correo electrónico en nuestra base de datos. Solo se usará para enviarte el resumen.',
    it: 'Inserisci la tua e-mail qui sotto se desideri ricevere un messaggio con i dettagli della tua creazione. \n\nIl tuo indirizzo e-mail non verrà  acquisito e memorizzato nel nostro database; verrà utilizzato solo per inviarti il riepilogo.',
    zh: '如果您希望收到关于您作品的详细信息，请在下方输入您的电子邮箱地址。\n\n我们不会在数据库中收集或存储您的电子邮件地址，只会将其用于向您发送摘要信息。',
    ko: '제품 관련 정보가 포함된 메시지를 수신하려면 아래에 이메일 주소를 입력하세요.\n\n까르띠에는 당사 데이터베이스에 귀하의 이메일 주소를 수집하거나 저장하지 않으며, 이메일 주소는 요약 정보를 보내는 데만 사용됩니다.',
    'zh-tw':
      '如要獲取作品資料相關訊息，請在下方輸入您的電郵地址。\n\n我們不會收集或儲存您的電郵地址，並只會將其用於向您發送相關摘要。',
    'zh-hk':
      '如要獲取作品資料相關訊息，請在下方輸入您的電郵地址。\n\n我們不會收集或儲存您的電郵地址，並只會將其用於向您發送相關摘要。',
    th: 'โปรดระบุอีเมลของคุณที่ด้านล่าง หากคุณต้องการรับข้อความพร้อมรายละเอียดเกี่ยวกับชิ้นงานรังสรรค์ของคุณ\n\nเราจะไม่รวบรวมหรือจัดเก็บอีเมลของคุณในฐานข้อมูล แต่เราจะใช้อีเมลเพื่อส่งข้อมูลสรุปให้คุณเท่านั้น',
  },
  {
    key: 'savemycreation_email',
    'en-gb': 'Email address',
    'en-us': 'Email Address',
    fr: 'Adresse e-mail',
    de: 'E-Mail-Adresse',
    pt: 'Endereço de correio eletrónico',
    nl: 'E-mailadres',
    es: 'Dirección de correo electrónico',
    it: 'Indirizzo e-mail',
    zh: '电子邮箱',
    ko: '이메일 주소',
    'zh-tw': '電郵地址',
    'zh-hk': '電郵地址',
    th: 'อีเมล',
  },
  {
    key: 'savemycreation_button_savemycreation',
    'en-gb': 'Send My Creation',
    'en-us': 'Send',
    fr: 'Envoyer ma création',
    de: 'Kreation abschicken',
    pt: 'Enviar a minha criação',
    nl: 'Verstuur mijn creatie',
    es: 'Enviar mi creación',
    it: 'Invia un riepilogo della mia creazione',
    zh: '发送我的作品',
    ko: '제품 전송하기',
    'zh-tw': '發送我的作品',
    'zh-hk': '發送我的作品',
    th: 'ส่งผลงานรังสรรค์ของฉัน',
  },
  {
    key: 'savemycreation_email_ref',
    'en-gb': 'Reference',
    'en-us': 'Ref',
    fr: 'Réf',
    de: 'Referenz',
    pt: 'Ref.ª',
    nl: 'Ref',
    es: 'Ref',
    it: 'Ref',
    zh: '编号',
    ko: '레퍼런스',
    'zh-tw': '參考編號',
    'zh-hk': '參考編號',
    th: 'หมายเลขอ้างอิง',
  },
  {
    key: 'savemycreation_email_ringsize',
    'en-gb': 'Ring size',
    'en-us': 'Ring Size',
    fr: 'Taille de la bague',
    de: 'Ringgröße',
    pt: 'Tamanho do anel',
    nl: 'Ringmaat',
    es: 'Talla del anillo',
    it: 'Misura dell’anello',
    zh: '戒指尺寸',
    ko: '링 사이즈',
    'zh-tw': '戒指尺寸',
    'zh-hk': '戒指尺寸',
    th: 'ขนาดแหวน',
  },
  {
    key: 'savemycreation_email_engraving',
    'en-gb': 'Engraving',
    'en-us': 'Engraving',
    fr: 'Gravure',
    de: 'Gravur',
    pt: 'Gravação',
    nl: 'Gravure',
    es: 'Grabado',
    it: 'Incisione',
    zh: '镌刻',
    ko: '인그레이빙',
    'zh-tw': '鐫刻',
    'zh-hk': '鐫刻',
    th: 'เกี่ยวกับบริการสลัก',
  },
  {
    key: 'savemycreation_email_engraving_blocks',
    'en-gb': 'Block',
    'en-us': 'Block',
    fr: 'Block',
    de: 'Blockschrift',
    pt: 'Bloco',
    nl: 'Blokletters',
    es: 'Bloque',
    it: 'Blocco',
    zh: '印刷体',
    ko: '블록',
    'zh-tw': '正體',
    'zh-hk': '正體',
    th: 'บล็อค',
  },
  {
    key: 'savemycreation_email_embossing',
    'en-gb': 'Embossing',
    'en-us': 'Embossing',
    fr: 'Embossage',
    de: 'Prägung',
    pt: 'Gravação em relevo',
    nl: 'Reliëfwerk',
    es: 'Grabado en relieve',
    it: 'Goffratura',
    zh: '刻字',
    ko: '엠보싱',
    'zh-tw': '燙印',
    'zh-hk': '壓印',
    th: 'การประทับนูน',
  },
  {
    key: 'savemycreation_email_embossing_cursive',
    'en-gb': 'Cursive',
    'en-us': 'Cursive',
    fr: 'Ecriture cursive',
    de: 'Kursiv',
    pt: 'Itálico',
    nl: 'Cursief',
    es: 'Cursiva',
    it: 'Corsivo',
    zh: '手写体',
    ko: '필기체',
    'zh-tw': '草書',
    'zh-hk': '斜體',
    th: 'อักษรตัวเขียน',
  },
  {
    key: 'savemycreation_email_findaboutique',
    'en-gb': 'Find a Boutique',
    'en-us': 'Find a Boutique',
    fr: 'Trouver une boutique',
    de: 'Boutique finden',
    pt: 'Encontrar uma butique',
    nl: 'Een boutique zoeken',
    es: 'Buscar una boutique',
    it: 'Trova una boutique',
    zh: '查找精品店',
    ko: '부티크 찾기',
    'zh-tw': '尋找專賣店',
    'zh-hk': '搜尋精品店',
    th: 'ค้นหาบูติค',
  },
  {
    key: 'savemycreation_email_contactanambassador',
    'en-gb': 'Contact an Ambassador',
    'en-us': 'Contact an Ambassador',
    fr: 'Contacter un ambassadeur',
    de: 'Eine:n Kundenberater:in kontaktieren',
    pt: 'Contactar um embaixador',
    nl: 'Contacteer een ambassadeur',
    es: 'Ponte en contacto con un embajador',
    it: 'Contatta un ambasciatore Cartier',
    zh: '联系卡地亚服务大使',
    ko: '상담원 연결',
    'zh-tw': '聯繫卡地亞顧問',
    'zh-hk': '聯繫卡地亞顧問',
    th: 'ติดต่อแอมบาสเดอร์',
  },
  {
    key: 'savemycreation_email_header',
    'en-gb': 'Your Cartier Diamond Summary',
    'en-us': 'Your Cartier Diamond Summary',
    fr: 'Informations sur votre diamant.',
    de: 'Ihre Auswahl',
    pt: 'O seu resumo de diamantes',
    nl: 'Een overzicht van uw diamant',
    es: 'Resumen de tu diamante',
    it: 'Il riepilogo del tuo diamante',
    zh: '您的卡地亚钻石总结',
    ko: '다이아몬드 요약',
    'zh-tw': '您的卡地亚钻石总结',
    'zh-hk': '您的卡地亚钻石总结',
    th: 'ข้อมูลสรุปเกี่ยวกับเพชรของคุณ',
  },
  {
    key: 'savemycreation_email_text',
    'en-gb':
      'Thank you for choosing a Cartier Diamond. The Maison is very pleased to say that what makes our stones different goes beyond appearances. As a responsible jeweller and watchmaker, we strive to source our diamonds only from conflict-free areas and from countries that are participants in the Kimberley Process and the System of Warranties since 2003.',
    'en-us':
      'Thank you for choosing a Cartier diamond. What makes a Cartier diamond unique goes beyond appearances. As a responsible jeweler and watchmaker, we strive to source our diamonds only from conflict-free areas and from countries that are participants in the Kimberley Process and the System of Warranties since 2003.',
    fr: "Merci d'avoir choisi un diamant Cartier. La Maison se réjouit de pouvoir affirmer que ce qui distingue les diamants Cartier va au-delà des apparences. En tant que joaillier et horloger responsable, nous nous efforçons de n'utiliser que des diamants provenant de zones sans conflits et de pays qui participent au Kimberley Process et au Système de Garanties depuis 2003.",
    de: 'Danke, dass Sie sich für einen Diamanten von Cartier entschieden haben. Die Maison freut sich sehr, behaupten zu können, dass das, was unsere Steine auszeichnet, über das Äußere hinausgeht. Als verantwortungsbewusster Juwelier und Uhrmacher bemühen wir uns, unsere Diamanten nur aus konfliktfreien Gebieten und aus Ländern zu beziehen, die seit 2003 am Kimberley-Prozess und dem System of Warranties teilnehmen.',
    pt: 'Obrigado por escolher um diamante Cartier. A Maison tem o prazer de lhe dizer que aquilo que diferencia as nossas pedras vai muito para além da aparência. Como joalheiros e relojoeiros, esforçamo-nos por procurar os nossos diamantes apenas em regiões livres de conflitos e países que pertençam ao Processo e Sistema de Garantias Kimberley desde 2003.',
    nl: 'Dank u voor het kiezen van een Cartier-diamant. Bij ons Maison gaat dat wat onze stenen anders maakt verder dan enkel het uiterlijk. Als verantwoordelijke juwelier en horlogemaker streven wij ernaar onze diamanten alleen te winnen uit conflictvrije gebieden en uit landen die sinds 2003 deelnemen aan het Kimberleyproces en het garantiesysteem.',
    es: 'Gracias por escoger un diamante Cartier. Para la Maison, es un placer poder afirmar que lo que distingue a nuestras piedras va más allá de las apariencias. Como firma joyera y relojera responsable, trabajamos para abastecernos únicamente de diamantes procedentes de zonas libres de conflictos y de países adheridos al Proceso Kimberley y al Sistema de Garantías desde 2003.',
    it: 'Grazie per aver scelto un diamante Cartier, unico non solo per le sue qualità eccezionali. Con orgoglio, infatti, la Maison -  in qualità di gioielliere e orologiaio responsabile -  si impegna a ricavare i propri diamanti esclusivamente da aree non interessate da conflitti e da Paesi che aderiscono al Processo di Kimberley e al Sistema di Garanzie dal 2003.',
    zh: '感谢您选购卡地亚钻石。卡地亚宝石的脱颖而出，源自超越美感的坚持。作为负责任的珠宝商和腕表商，卡地亚坚持从没有争议的国家与地区进行钻石采购，严格依据2003年制定的金伯利进程证书制度（KPCS）和世界钻石理事会担保制度（SoW）。',
    ko: '까르띠에 다이아몬드를 선택해 주셔서 감사합니다. 메종은 아름다움 그 이상의 매력을 지닌 특별한 스톤만을 사용합니다. 까르띠에는 책임감 있는 주얼러와 워치메이커로서 2003년 이래 킴벌리 프로세스 인증 체계 회원국과 미분쟁 지역에서만 다이아몬드를 공수하기 위해 노력하고 있습니다.',
    'zh-tw':
      '感謝您選擇卡地亞鑽石。我們謹此為您說明為何卡地亞鑽石內外皆美。卡地亞作為負責任的珠寶及製錶品牌，自2003年起致力僅從非衝突地區，以及參與金伯利進程（Kimberley Process）和保證體系（System of Warranties）的國家/地區採購鑽石。',
    'zh-hk':
      '感謝您選擇卡地亞鑽石。我們謹此為您說明為何卡地亞鑽石內外皆美。卡地亞作為負責任的珠寶及製錶品牌，自2003年起致力僅從非衝突地區，以及參與金伯利進程（Kimberley Process）和保證體系（System of Warranties）的國家/地區採購鑽石。',
    th: 'ขอขอบคุณที่เลือกซื้อเพชรจากคาร์เทียร์ เมซงมีความยินดีอย่างยิ่งที่จะแจ้งให้ทราบว่าสิ่งที่สร้างความแตกต่างให้แก่อัญมณีของเราคือความล้ำค่าที่เหนือกว่ารูปลักษณ์ภายนอก ในฐานะผู้ค้าอัญมณีและผู้รังสรรค์เรือนเวลาที่มีความรับผิดชอบ เรามุ่งมั่นในการคัดสรรเพชรจากแหล่งที่ปราศจากความขัดแย้งและจากประเทศที่เข้าร่วมกระบวนการคิมเบอรี่ (Kimberley Process) และระบบการรับประกันสำหรับเพชรเจียระไน (System of Warranties) มาตั้งแต่ปี ค.ศ. 2003',
  },
  {
    key: 'savemycreation_email_GIACertificateSupplied',
    'en-gb': 'GIA Certificate',
    'en-us': 'GIA Certificate',
    fr: 'Certificat GIA',
    de: 'GIA Zertifikat',
    pt: 'Certificado GIA',
    nl: 'GIA Certificaat',
    es: 'Certificado GIA',
    it: 'Certificato GIA',
    zh: '美国宝石学院（GIA）证书',
    ko: 'GIA 인증',
    'zh-tw': '美國寶石學院（GIA）證書',
    'zh-hk': '美國寶石學院（GIA）證書',
    th: 'ใบรับรอง GIA',
  },
  {
    key: 'savemycreation_email_carat',
    'en-gb': 'Carat',
    'en-us': 'Carat',
    fr: 'Carat',
    de: 'Karat',
    pt: 'Quilate',
    nl: 'Karaat',
    es: 'Quilates',
    it: 'Caratura',
    zh: '克拉（Carat）',
    ko: '캐럿',
    'zh-tw': '克拉（Carat）',
    'zh-hk': '克拉',
    th: 'กะรัต',
  },
  {
    key: 'savemycreation_email_colour',
    'en-gb': 'Colour',
    'en-us': 'Color',
    fr: 'Couleur',
    de: 'Farbe',
    pt: 'Cor',
    nl: 'Kleur',
    es: 'Color',
    it: 'Colore',
    zh: '颜色（Colour）',
    ko: '컬러',
    'zh-tw': '顏色（Colour）',
    'zh-hk': '顏色',
    th: 'สี',
  },
  {
    key: 'savemycreation_email_clarity',
    'en-gb': 'Clarity',
    'en-us': 'Clarity',
    fr: 'Pureté',
    de: 'Reinheit',
    pt: 'Claridade',
    nl: 'Zuiverheid',
    es: 'Claridad',
    it: 'Chiarezza',
    zh: '净度（Clarity）',
    ko: '투명도',
    'zh-tw': '淨度（Clarity）',
    'zh-hk': '淨度',
    th: 'ความใสบริสุทธิ์',
  },
  {
    key: 'savemycreation_email_price',
    'en-gb': 'Price',
    'en-us': 'Price',
    fr: 'Prix',
    de: 'Preis',
    pt: 'Preço',
    nl: 'Prijs',
    es: 'Precio',
    it: 'Prezzo',
    zh: '价格',
    ko: '가격',
    'zh-tw': '價格',
    'zh-hk': '價格',
    th: 'ราคา',
  },
  {
    key: 'savemycreation_email_Ambassodornots',
    'en-gb': 'Ambassador notes',
    'en-us': 'Ambassador Notes',
    fr: "Notes de l'ambassadeur",
    de: 'Anmerkungen der Kundenberater:innen',
    pt: 'Notas do embaixador',
    nl: 'Notities ambassadeur',
    es: 'Anotaciones del embajador',
    it: 'Note dell’ambasciatore Cartier',
    zh: '卡地亚大使笔记',
    ko: '상담원 메모',
    'zh-tw': '卡地亞顧問備註',
    'zh-hk': '卡地亞顧問備註',
    th: 'หมายเหตุสำหรับแอมบาสเดอร์',
  },
  {
    key: 'savemycreation_email_imageDisclaimer',
    'en-gb': 'The product may differ from the images shown.',
    'en-us': 'The product may differ from the images shown.',
    fr: 'Le produit réel peut différer des images.',
    de: 'Das Produkt kann von den gezeigten Bildern abweichen.',
    pt: 'O produto poderá ser diferente das imagens apresentadas.',
    nl: 'Het product kan afwijken van de getoonde afbeeldingen.',
    es: 'El producto puede diferir de las imágenes mostradas.',
    it: 'Il prodotto può differire dalle immagini.',
    zh: '作品可能与显示的图像不同。',
    ko: '제품은 표시된 이미지와 다를 수 있습니다.',
    'zh-tw': '作品可能會與參考圖片有所不同',
    'zh-hk': '作品可能會與參考圖片有所不同。',
    th: 'ผลิตภัณฑ์อาจแตกต่างจากรูปภาพที่แสดง โปรดทราบว่า',
  },
  {
    key: 'menu_viewall',
    'en-gb': 'View all',
    'en-us': 'View all',
    fr: 'Voir tout',
    de: 'Alle ansehen',
    pt: 'Ver todos',
    nl: 'Bekijk alles',
    es: 'Ver todo',
    it: 'Visualizza tutto',
    zh: '查看所有',
    ko: '전체 보기',
    'zh-tw': '查看全部',
    'zh-hk': '查看全部',
    th: 'ชมทั้งหมด',
  },
  {
    key: 'sfy_creationInProgress',
    'en-gb': 'You have a creation in progress. Would you like to continue or start again?',
    'en-us': 'You have a creation in progress. Would you like to continue or start again?',
    fr: 'Une création est en cours de réalisation.  Souhaitez-vous continuer ou recommencer ?',
    de: 'Sie haben eine Kreation in Arbeit. Möchten Sie fortfahren oder neu beginnen?',
    pt: 'Tem uma criação em progresso. Deseja continuar ou começar novamente?',
    nl: 'U bent nog bezig met een creatie. Wilt u doorgaan of opnieuw beginnen?',
    es: 'Tienes una creación en curso. ¿Te gustaría continuar o volver a comenzar?',
    it: 'Hai una creazione in corso. Desideri continuare o ricominciare?',
    zh: '您有一项作品正在制作中。希望继续流程或重新开始？',
    ko: '진행 중인 제품이 있습니다. 계속하시겠습니까, 아니면 다시 시작하시겠습니까?',
    'zh-tw': '您有一項創作中的作品。您想繼續還是重新開始？',
    'zh-hk': '您有一項創作中的作品。您想繼續還是重新開始？',
    th: 'คุณมีการสร้างสรรค์ชิ้นงานที่รอดำเนินการอยู่ คุณต้องการดำเนินการต่อหรือเริ่มต้นใหม่หรือไม่',
  },
  {
    key: 'sfy_finishCreation',
    'en-gb': 'Finish my creation',
    'en-us': 'Finish my creation',
    fr: 'Finir ma création',
    de: 'Meine Kreation abschließen',
    pt: 'Terminar a minha criação',
    nl: 'Mijn creatie voltooien',
    es: 'Terminar mi creación',
    it: 'Termina la mia creazione',
    zh: '完成我的作品',
    ko: '제품 완성하기',
    'zh-tw': '完成我的作品',
    'zh-hk': '完成我的作品',
    th: 'เสร็จสิ้นการสร้างสรรค์ชิ้นงานของฉัน',
  },
  {
    key: 'sfy_startNew',
    'en-gb': 'Start again',
    'en-us': 'Start again',
    fr: 'Recommencer',
    de: 'Neu beginnen',
    pt: 'Começar novamente',
    nl: 'Opnieuw beginnen',
    es: 'Volver a empezar',
    it: 'Ricomincia',
    zh: '重新开始',
    ko: '다시 시작하기',
    'zh-tw': '重新開始',
    'zh-hk': '重新開始',
    th: 'เริ่มต้นใหม่',
  },
  {
    key: 'footer_termsOfUse',
    'en-gb': 'Terms of use',
    'en-us': 'Terms of use',
    fr: "Conditions d'utilisation",
    de: 'Nutzungsbedingungen',
    pt: 'Termos de uso',
    nl: 'Gebruiksvoorwaarden',
    es: 'Condiciones de uso',
    it: "Condizioni d'uso",
    zh: '使用条款',
    ko: '이용약관',
    'zh-tw': '使用條款',
    'zh-hk': '使用條款',
    th: 'เงื่อนไขการใช้บริการ',
  },
  {
    key: 'savemycreation_emailSent',
    'en-gb': 'A summary of your creation has been sent to your email address.',
    'en-us': 'A summary of your creation has now been sent to your inbox.',
    fr: 'Les détails de votre création vous ont été envoyés à votre adresse e-mail.',
    de: 'Die Details Ihrer Kreation wurden an Ihre E-Mail-Adresse gesendet.',
    pt: 'Os detalhes da sua criação foram enviados para o seu endereço electrónico',
    nl: 'Een samenvatting van de door u geselecteerde creatie is nu naar uw inbox gestuurd.',
    es: 'Los detalles de su creación han sido enviados a su dirección de e-mail',
    it: 'Un riepilogo della tua creazione è stato inviato al tuo indirizzo email.',
    zh: '您创作的作品摘要已发送至您提供的电子邮箱。',
    ko: '고객님의 이메일 주소로 제품 요약 정보가 발송되었습니다.',
    'zh-tw': '作品摘要已發送至您的電郵地址。',
    'zh-hk': '作品摘要已發送至您的電郵地址。',
    th: 'เราส่งข้อมูลสรุปเกี่ยวกับการสร้างสรรค์ชิ้นงานของคุณไปยังที่อยู่อีเมลของคุณแล้ว',
  },
  {
    key: 'button_close',
    'en-gb': 'Close',
    'en-us': 'Close',
    fr: 'Fermer',
    de: 'Schließen',
    pt: 'Fechar',
    nl: 'Sluiten',
    es: 'Cerrar',
    it: 'Chiudi',
    zh: '关闭',
    ko: '닫기',
    'zh-tw': '關閉',
    'zh-hk': '關閉',
    th: 'ปิด',
  },
  {
    key: 'error',
    'en-gb': 'Something went wrong. Please try again or contact our Client Relations Centre.',
    'en-us': 'Something went wrong. Please try again or contact customer support.',
    fr: 'Un problème est survenu. Veuillez réessayer ou contacter le service clientèle.',
    de: 'Momentan nicht verfügbar. Bitte wählen Sie eine andere Fassung oder Material aus.',
    pt: 'Ocorreu um problema. Por favor, tente novamente ou contacte o serviço ao cliente.',
    nl: 'Er is iets mis gegaan. Probeer het aub opnieuw of contacteer de klantenservice.',
    es: 'Se ha producido un problema. Por favor, inténtelo de nuevo o póngase en contacto con el servicio de atención al cliente.',
    it: "Si è verificato un errore. Riprova o contatta l'assistenza clienti.",
    zh: '出现错误。请重试，或联系卡地亚顾客关怀中心。',
    ko: '오류가 발생했습니다. 다시 시도하거나 고객 서비스 센터로 연락 주시기 바랍니다.',
    'zh-tw': '出現錯誤。請再試一次或聯繫卡地亞客戶服務中心。',
    'zh-hk': '出現錯誤。請再試一次或聯繫卡地亞客戶服務中心。',
    th: 'ขออภัย มีข้อผิดพลาดบางประการ โปรดลองอีกครั้งหรือติดต่อศูนย์ลูกค้าสัมพันธ์ของเรา',
  },
  {
    key: 'sfySetting_boutiquesdisclaimer',
    'en-gb': 'Service available in selected boutiques.',
    'en-us': 'Service available in selected boutiques.',
    fr: 'Service disponible dans une sélection de boutiques.',
    de: 'Unser Service ist nur in ausgewählten Boutiquen verfügbar.',
    pt: 'Serviço disponível em boutiques selecionadas.',
    nl: 'Deze service is beschikbaar in geselecteerde boutiques.',
    es: 'Servicio disponible en boutiques seleccionadas.',
    it: 'Servizio disponibile in una selezione di boutique.',
    zh: '服务于指定精品店提供。',
    ko: '일부 부티크에서만 제공됩니다.',
    'zh-tw': '服務於指定專賣店提供。',
    'zh-hk': '服務於指定精品店提供。',
    th: 'มีบริการในบูติคสาขาที่เลือก',
  },
];
