import styled from 'styled-components';
import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  &.disabled {
    .thumb {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }

  .track {
    height: 12px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.fill};
    box-shadow: inset 0px 2px 5px -1px rgb(0 0 0 / 15%);

    &--dark {
      background-color: ${({ theme }) => theme.colors.fill_dark};
    }

    &-1 {
      background-color: ${({ theme }) => theme.colors.gray};
    }
  }

  .thumb {
    top: 5px;
    font-family: ${({ theme }) => theme.typography.fonts.monoMedium};
    height: 60px;
    width: 40px;
    text-align: center;
    cursor: pointer;
    outline: none;
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.border};
      z-index: -1;
      box-shadow: 0px 7px 12px -4px rgb(0 0 0 / 20%);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.fill_gray};
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }
  }

  .marks {
    background-color: white;
    width: 3px;
    height: 12px;
    margin: 0px calc(19px);

    &:first-of-type,
    &:last-of-type {
      background-color: transparent;
    }
  }
`;

export default StyledSlider;
