import React from 'react';
import styled, { css } from 'styled-components';
import { appTheme } from 'styles/theme';
import { motion, MotionProps } from 'framer-motion';

interface TextProps extends React.HtmlHTMLAttributes<HTMLElement> {
  size?: 'xs' | 's' | 'l';
  color?: 'white' | 'black' | 'red' | 'light' | 'lighter' | 'warning' | 'error' | 'success';
  level?: 'h1' | 'h2' | 'h3';
  $small?: boolean;
  $mono?: boolean;
  $monoMedium?: boolean;
  $uppercase?: boolean;
  $center?: boolean;
  $underline?: boolean;
}

const variantOptions = {
  $mono: {
    fontFamily: appTheme.typography.fonts.mono,
  },
  $monoMedium: {
    fontFamily: appTheme.typography.fonts.monoMedium,
  },
  size: {
    xs: appTheme.typography.sizes.xs,
    s: appTheme.typography.sizes.s,
    l: appTheme.typography.sizes.l,
  },
  color: {
    white: appTheme.colors.white,
    black: appTheme.colors.black,
    red: appTheme.colors.red,
    light: appTheme.colors.text_light,
    lighter: appTheme.colors.text_lighter,
    warning: appTheme.colors.warning,
    error: appTheme.colors.error,
    success: appTheme.colors.success,
  },
  level: {
    h1: {
      fontFamily: appTheme.typography.fonts.mono,
      fontSize: '30px',
      fontSize_desktop: '48px',
      lineHeight: '40px',
      lineHeight_desktop: '58px',
      letterSpacing: '3px',
      letterSpacing_desktop: '6px',
    },
    h2: {
      fontFamily: appTheme.typography.fonts.monoMedium,
      fontSize: '22px',
      fontSize_desktop: '22px',
      lineHeight: '28px',
      lineHeight_desktop: '28px',
      letterSpacing: '0.2px',
      letterSpacing_desktop: '0.2px',
    },
    h3: {
      fontFamily: appTheme.typography.fonts.monoMedium,
      fontSize: '16px',
      fontSize_desktop: '14px',
      lineHeight: '16px',
      lineHeight_desktop: '18px',
      letterSpacing: '0.2px',
      letterSpacing_desktop: '0.2px',
    },
  },
};

const StyledText = styled(motion.div)<TextProps>`
  font-family: ${({ theme }) => theme.typography.fonts.base};
  color: ${(props) => props.theme.colors.text};
  font-size: ${({ theme }) => theme.typography.sizes.base};
  line-height: 22px;
  letter-spacing: 0.5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  ${({ level }) =>
    level &&
    variantOptions.level[level] &&
    css`
      font-family: ${variantOptions.level[level].fontFamily};
      font-size: ${variantOptions.level[level].fontSize};
      line-height: ${variantOptions.level[level].lineHeight};
      letter-spacing: ${variantOptions.level[level].letterSpacing};

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        font-size: ${variantOptions.level[level].fontSize_desktop};
        line-height: ${variantOptions.level[level].lineHeight_desktop};
        letter-spacing: ${variantOptions.level[level].letterSpacing_desktop};
      }
    `}

  ${({ $small }) =>
    $small &&
    css`
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: ${({ theme }) => theme.colors.text_light};

      @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
      }
    `}

  ${({ size }) =>
    size &&
    variantOptions.size[size] &&
    css`
      font-size: ${variantOptions.size[size]} !important;
    `}

  ${({ color }) =>
    color &&
    variantOptions.color[color] &&
    css`
      color: ${variantOptions.color[color]};
    `}

  ${({ $mono }) =>
    $mono &&
    variantOptions.$mono &&
    css`
      font-family: ${variantOptions.$mono.fontFamily};
    `}

  ${({ $monoMedium }) =>
    $monoMedium &&
    variantOptions.$monoMedium &&
    css`
      font-family: ${variantOptions.$monoMedium.fontFamily};
    `}

  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}

  ${({ $underline }) =>
    $underline
      ? css`
          text-decoration: underline;
        `
      : css`
          text-decoration: none;
        `}
`;

export const Text: React.FC<TextProps & MotionProps> = ({ color, size, ...rest }) => (
  <StyledText size={size} color={color} {...rest} />
);

export const Heading: React.FC<TextProps & MotionProps> = ({ color, level = 'h1', ...rest }) => (
  <StyledText level={level} color={color} {...rest} />
);
