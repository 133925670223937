import React, { useContext, useState } from 'react';

interface IContext {
  configuration: ISFYConfig;
  defaultConfiguration: ISFYConfig;
  updateConfiguration: (config: ISFYConfig) => void;
  updateDefaultConfiguration: (config: ISFYConfig) => void;
  clearConfiguration: () => void;
  ringSelection: ISFYProductSelection;
  updateRingSelection: (ring: ISFYProductSelection) => void;
  clearRingSelection: () => void;
}

const SFYContext = React.createContext<Partial<IContext>>({});

const getConfig = () => {
  const config = localStorage.getItem('sfyConfig');
  return config ? JSON.parse(config) : false;
};

const getDefaultConfig = () => {
  const config = localStorage.getItem('sfyDefaultConfig');
  return config ? JSON.parse(config) : false;
};

const saveConfig = (config: ISFYConfig) => {
  localStorage.setItem('sfyConfig', JSON.stringify(config));
};

const saveDefaultConfig = (config: ISFYConfig) => {
  localStorage.setItem('sfyDefaultConfig', JSON.stringify(config));
};

const clearConfig = () => {
  localStorage.removeItem('sfyConfig');
  localStorage.removeItem('sfyDefaultConfig');
};

const saveSelectedRing = (ring: ISFYProductSelection) => {
  localStorage.setItem('sfyRingSelection', JSON.stringify(ring));
};

const getSavedRingSelection = () => {
  const ring = localStorage.getItem('sfyRingSelection');
  return ring ? JSON.parse(ring) : undefined;
};

const clearSavedRingSelection = () => {
  localStorage.removeItem('sfyRingSelection');
};

export const SFYProvider = ({ ...props }) => {
  // Used as initial input min/max values in SFY flow
  const [defaultConfiguration, setDefaultConfiguration] = useState<ISFYConfig>(getDefaultConfig());

  // Used as facet filter in SFY flow
  const [configuration, setConfiguration] = useState<ISFYConfig>(getConfig());

  // Used for storing the selected product in SFY flow
  const [ringSelection, setRingSelection] = useState<ISFYProductSelection | undefined>(getSavedRingSelection());

  const updateConfiguration = (config: ISFYConfig) => {
    setConfiguration(config);
    saveConfig(config);
  };

  const updateDefaultConfiguration = (config: ISFYConfig) => {
    setDefaultConfiguration(config);
    saveDefaultConfig(config);
  };

  const clearConfiguration = () => {
    clearConfig();
    setConfiguration({});
    setDefaultConfiguration({});
  };

  const updateRingSelection = (ring: ISFYProductSelection) => {
    setRingSelection(ring);
    saveSelectedRing(ring);
  };

  const clearRingSelection = () => {
    clearSavedRingSelection();
    setRingSelection(undefined);
  };

  return (
    <SFYContext.Provider
      value={{
        configuration,
        defaultConfiguration,
        updateConfiguration,
        updateDefaultConfiguration,
        clearConfiguration,
        ringSelection,
        updateRingSelection,
        clearRingSelection,
      }}
    >
      {props.children}
    </SFYContext.Provider>
  );
};

export const useSFY = () => {
  const context = useContext(SFYContext);

  if (context === null) {
    throw new Error("'useSFY' must be used inside 'SFYProvider'");
  }

  return context;
};

export default SFYProvider;
