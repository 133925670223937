import findBoutiqueIcon from 'media/icons/find-boutique.svg';
import servicesIcon from 'media/icons/services.svg';
import contactIcon from 'media/icons/assistance.svg';
import { collections } from '../constants/collections';
import { locations } from '../constants/locations';

import { useLocation } from 'providers/LocationProvider';

export interface INavigation {
  header: INavigationItem[];
  footer: INavigationItem[];
  burger_footer: INavigationItem[];
}

export interface INavigationItem {
  name: string;
  path?: string;
  externalPath?: string;
  children?: this[];
  type?: 'COLLECTION';
  id?: string;
  img?: string;
  icon?: string;
}

const useNavigationItems = () => {
  const { location, translations, localizedPath } = useLocation();
  const navigationItems: INavigation = {
    header: [
      {
        name: translations?.firstpage_cartierdiamonds || 'Cartier diamonds',
        path: `/${location?.language.locationLanguageCode}/4c`,
      },
      {
        name: translations?.menu_collection || 'Collections',
        path: `/${location?.language.locationLanguageCode}/collections`,
        children: [
          // {
          //   name: 'All collections',
          //   children: collections.map((c) => ({
          //     name: c.name,
          //     path: c.id,
          //     type: 'COLLECTION',
          //     img: c.mainImageUrl,
          //   })),
          // },
          {
            name: translations?.collection_engagemantRings || 'Engagement rings',
            id: 'ER',
            path: 'engagement-rings',
            children: collections
              .filter((c) => c.type === 'ER')
              .map((c) => ({
                name: translations?.[`collection_${c.translationTextId}`]!,
                path: `/${location?.language.locationLanguageCode}/${c.id}`,
                type: 'COLLECTION',
                img: c.mainImageUrl,
              })),
          },
          {
            name: translations?.collection_weddingRings || 'Wedding rings',
            id: 'WR',
            path: 'wedding-rings',
            children: collections
              .filter((c) => c.type === 'WR')
              .map((c) => ({
                name: translations?.[`collection_${c.translationTextId}`]!,
                path: `/${location?.language.locationLanguageCode}/${c.id}`,
                type: 'COLLECTION',
                img: c.mainImageUrl,
              })),
          },
        ],
      },
      {
        name: translations?.menu_matchingBands || 'Matching Bands',
        path: `/${location?.language.locationLanguageCode}/matching-bands`,
      },
      {
        name: translations?.menu_personalisation || 'Personalisation',
        path: `/${location?.language.locationLanguageCode}/set-for-you`,
      },
    ],
    footer: [
      {
        name: translations?.footer_customerCare || 'Customer care',
        path: '/',
        children: [
          {
            name: translations?.header_footer_contactUs || 'Contact us',
            externalPath: localizedPath?.contactUs,
          },
          {
            name:
              (translations?.footer_callNow || 'Call') +
              ': ' +
              locations.find((l) => l.id === location?.id)?.customerService.phone.displayNumber,
            externalPath: 'tel:' + locations.find((l) => l.id === location?.id)?.customerService.phone.number,
          },
          // {
          //   name: 'Shopping online',
          //   path: '/shopping-online',
          // },
          {
            name: translations?.footer_help || 'Help',
            externalPath: localizedPath?.help,
          },
        ],
      },
      {
        name: translations?.footer_ourComapny || 'Our company',
        path: '/',
        children: [
          {
            name: translations?.footer_findABoutiqe || 'Find a boutique',
            // path: '/',
            externalPath: localizedPath?.findBoutique,
          },
          {
            name: translations?.footer_service || 'Services',
            externalPath: localizedPath?.services,
          },
        ],
      },
      {
        name: translations?.footer_legalPrivacy || 'Legal & privacy',
        path: '/',
        children: [
          {
            name: translations?.footer_termsOfUse || 'Terms of use',
            externalPath: localizedPath?.termsOfUse,
          },
          {
            name: translations?.footer_privacyPolicy || 'Privacy policy',
            externalPath: localizedPath?.privacyPolicy,
          },
          {
            name: translations?.footer_cookiePolicy || 'Cookie policy',
            externalPath: localizedPath?.cookiePolicy,
          },
        ],
      },
    ],
    burger_footer: [
      {
        name: translations?.footer_findABoutiqe || 'Find a boutique',
        // path: '/find-boutique',
        externalPath: localizedPath?.findBoutique,
        icon: findBoutiqueIcon,
      },
      {
        name: translations?.footer_service || 'Services',
        path: '/services',
        icon: servicesIcon,
      },
      {
        name: translations?.header_footer_contactUs || 'Contact us',
        // path: '/contact-us',
        externalPath: localizedPath?.contactUs,
        icon: contactIcon,
      },
    ],
  };

  return navigationItems;
};

export default useNavigationItems;
