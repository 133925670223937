import { useState } from 'react';
import { Button, Icon, Link, Text } from 'ui';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import Slideshow from './Slideshow';
import { Portal } from 'react-portal';
import { AnimatePresence, motion } from 'framer-motion';
import { Tabs } from 'components';
import closeIcon from 'media/icons/close.svg';
import useNavigationItems, { INavigationItem } from '../../../hooks/useNavigationItems';
import { useLocation } from '../../../providers';

const MegaMenu = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<INavigationItem | undefined>(undefined);

  // currently not in use
  // eslint-disable-next-line
  const [_, setSelectedSubItem] = useState<INavigationItem | undefined>(undefined);
  const navigationItems = useNavigationItems();
  const { translations } = useLocation();

  /**
   * Set item's first child as selected sub item if it contains any children
   * @param menuItem
   */
  const handleNavigationMouseOver = (menuItem: INavigationItem) => {
    setSelectedItem(menuItem);

    const subItemWithChildren = menuItem.children?.find((i) => i.children?.length);
    if (subItemWithChildren) {
      setSelectedSubItem(subItemWithChildren);
    }
  };

  const handleNavigationClick = (menuItem: INavigationItem) => {
    if (!menuItem.children && menuItem.path) {
      navigate(menuItem.path);
      return;
    }

    setSelectedItem(menuItem);

    const subItemWithChildren = menuItem.children?.find((i) => i.children?.length);
    if (subItemWithChildren) {
      setSelectedSubItem(subItemWithChildren);
    }
  };

  const resetMenu = () => {
    setSelectedItem(undefined);
    setSelectedSubItem(undefined);
  };

  // const isCollection = (menuItem: INavigationItem) => {
  //   return menuItem.children?.find((i) => i.type === 'COLLECTION');
  // };

  return (
    <>
      <div className="d-flex py-2">
        {navigationItems.header.map((i, index) => (
          <MenuItem
            key={index}
            selected={i === selectedItem}
            onMouseOver={() => handleNavigationMouseOver(i)}
            onClick={() => handleNavigationClick(i)}
            className="mx-3 py-2"
          >
            <Text size={'xs'} $mono $uppercase key={index}>
              {i.name}
            </Text>
          </MenuItem>
        ))}
        <AnimatePresence>
          {selectedItem && selectedItem.children?.length && (
            <SubMenuContainer
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3 }}
              onMouseLeave={() => resetMenu()}
            >
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                transition={{ duration: 0.3, delay: 0.5 }}
              >
                <CloseButton>
                  <Icon imgUrl={closeIcon} onClick={() => resetMenu()} />
                </CloseButton>
                {/* <CloseButton onClick={() => resetMenu()} /> */}
                <div className="container">
                  <AnimatePresence>
                    {selectedItem.name === translations?.menu_collection && (
                      <CollectionMenu items={selectedItem?.children?.filter((submenu) => submenu?.children?.length)} />
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            </SubMenuContainer>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {selectedItem && selectedItem.children?.length && (
          <Portal>
            <Shade
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              transition={{ duration: 0.3, delay: 0.2 }}
            />
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};

const CollectionMenu = ({ items }: { items: INavigationItem[] }) => {
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { translations, location } = useLocation();

  const tabs = items.map((item) => ({
    name: item.name,
    content: (
      <div className="d-flex align-items-center">
        {item.children?.length ? (
          <Slideshow>
            {item.children?.map((c, index) => (
              <div
                key={c.name}
                onClick={() => c.path && navigate(c.path)}
                className="text-center d-flex flex-column align-items-center justify-content-center"
              >
                <CollectionMenuItem>
                  <AnimatePresence>
                    <motion.img
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                      transition={{
                        duration: 0.3,
                      }}
                      src={c.img}
                      alt=""
                    />
                  </AnimatePresence>
                </CollectionMenuItem>
                <CollectionMenuItemText $mono $small className="pt-2 pb-3">
                  {c.name}
                </CollectionMenuItemText>
              </div>
            ))}
          </Slideshow>
        ) : null}
      </div>
    ),
  }));

  return (
    <div className="d-flex flex-column">
      <Tabs bold color="red" items={tabs} handleTabSelect={setCurrentTabIndex} className="d-flex flex-column" />
      <Button
        variant="underline"
        className="align-self-center"
        onClick={() => {
          navigate(`/${location?.language.locationLanguageCode}/collections/${items[currentTabIndex].path}`);
        }}
      >
        {translations?.menu_viewall || 'View all'}
      </Button>
    </div>
  );
};

const SubMenuContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: absolute;
  z-index: 100;
  top: ${({ theme }) => theme.sizes.header};
  left: 0;
  right: 0;
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  text-transform: uppercase;
  overflow-y: scroll;
  padding: ${({ theme }) => theme.spacings.l};
  display: flex;
  justify-content: center;
`;

const MenuItem = styled(Link)<{ selected: boolean; passiveBorder?: boolean }>`
  background-size: 0 3px;
  background-image: ${({ theme }) => `linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`};
  cursor: pointer;
  user-select: none;

  &:hover {
    font-weight: bold;
    background-size: 100% 3px;
  }

  ${({ passiveBorder }) =>
    passiveBorder &&
    `
    border-bottom: 2px solid #ececec;
  `}
`;

const CollectionMenuItemText = styled(Text)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fonts.mono};
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const CollectionMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 140px;
  width: 140px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.fill};

  &:hover {
    + ${CollectionMenuItemText} {
      text-decoration: underline;
    }
  }
`;

const Shade = styled(motion.div)`
  position: fixed;
  top: ${({ theme }) => theme.sizes.header};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
`;

const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Icon} {
    width: 15px;
    height: 15px;
  }
`;

export default MegaMenu;
