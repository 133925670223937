import React, { useRef } from 'react';
import { Button, Text } from 'ui';
import styled, { css } from 'styled-components';
import { motion, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { collections } from 'constants/collections';
import { modelImages } from 'constants/modelImages';
import { models } from 'constants/models';
import { collectionIds } from 'constants/ids';
import Slideshow from './Slideshow';
import { useRefScrollProgress } from 'hooks/useRefScrollProgress';

// used as replacement img for models missing images with front angle
import replacementImg from 'media/images/models/N4162900.png';
import { useLocation } from 'providers';

const Collections = () => {
  const ref = useRef<any>();
  const { end } = useRefScrollProgress(ref, { endOffset: 0.2 });
  const scrollYProgress = useViewportScroll();
  const navigate = useNavigate();
  const { translations } = useLocation();

  const xRange = useTransform(scrollYProgress.scrollYProgress, [end! * 0.6, end! * 0.9], [window.innerWidth, 0]);
  const x = useSpring(xRange, { stiffness: 1000, damping: 90 });

  const slideshowItems = [
    Slide({ collectionId: collectionIds._1895, modelId: 'N4162900' }, navigate),
    GridSlide(
      [
        { collectionId: collectionIds.destinee, modelIds: ['N4246000', 'N4751600'] },
        { collectionId: collectionIds.love, modelIds: ['N4724500', 'N4774200'] },
      ],
      navigate,
    ),
    Slide({ collectionId: collectionIds.etincelle, modelId: 'N4744600' }, navigate),
    Slide({ collectionId: collectionIds.ruban, modelId: 'N4250400' }, navigate),
    GridSlide([{ collectionId: collectionIds.ballerine, modelIds: ['N4197900', 'N4725100'] }], navigate),
    Slide({ collectionId: collectionIds.vendome, modelId: 'N4204200' }, navigate),
  ];

  return (
    <Container ref={ref} className="container py-5 mb-5 mt-md-5">
      <Text $mono $uppercase className="mb-5">
        <StyledHeading style={{ x }}>{translations?.menu_collection || 'Collections'}</StyledHeading>
      </Text>
      <div className="d-none d-md-block">
        <Slideshow>
          {slideshowItems.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Slideshow>
      </div>
      <div className="d-md-none">
        <GridRow gap={100}>
          {slideshowItems.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </GridRow>
      </div>
    </Container>
  );
};

const Slide = (item: { collectionId: string; modelId: string }, navigate: NavigateFunction) => {
  const { translations } = useLocation();
  const c = collections.find((c) => c.id === item.collectionId);
  const imgUrl =
    modelImages.find((img) => img.modelId === item.modelId && img.angle === 'front')?.imageUrl || replacementImg;

  return (
    c && (
      <CollectionBox size="fullscreen">
        <CollectionImageBox size="fullscreen" onClick={() => navigate(c.id)}>
          <CollectionImage src={imgUrl} alt={c.name} />
          {}
        </CollectionImageBox>
        <Button className="mt-2" variant="underline" onClick={() => navigate(c.id)}>
          {(translations && translations[`collection_${c.translationTextId}`]) || c.name}
        </Button>
      </CollectionBox>
    )
  );
};

const GridSlide = (items: { collectionId: string; modelIds: string[] }[], navigate: NavigateFunction) => {
  const { translations } = useLocation();
  const detailedItems = items.map((item) => ({
    ...item,
    collection: collections.find((col) => col.id === item.collectionId),
    models: models.filter((model) => item.modelIds.includes(model.id)),
  }));

  const getModelImage = (modelId: string) => {
    return modelImages.find((img) => img.modelId === modelId && img.angle === 'front')?.imageUrl || replacementImg;
  };

  return (
    <Grid colCount={detailedItems.length}>
      {detailedItems.length
        ? detailedItems.map((item, index) => (
            <React.Fragment key={item.collection?.id}>
              <CollectionBox
                size="small"
                $closeUp={!(index % 2)}
                $smallCloseup={items.length === 1 || item.collectionId === collectionIds.destinee}
              >
                <CollectionImageBox size="small" onClick={() => navigate(item.collection?.id || '/')}>
                  <CollectionImage src={getModelImage(item.modelIds[0])} alt={item.modelIds[2]} />
                </CollectionImageBox>
              </CollectionBox>
              <CollectionBox
                size="small"
                $closeUp={!!(index % 2)}
                $smallCloseup={items.length === 1 || item.collectionId === collectionIds.destinee}
              >
                <CollectionImageBox size="small" onClick={() => navigate(item.collection?.id || '/')}>
                  <CollectionImage src={getModelImage(item.modelIds[1])} alt={item.modelIds[1]} />
                </CollectionImageBox>
                <Button className="mt-2" variant="underline" onClick={() => navigate(item.collection?.id || '/')}>
                  {(translations && translations[`collection_${item.collection?.translationTextId}`]) ||
                    item.collection?.name}
                </Button>
              </CollectionBox>
            </React.Fragment>
          ))
        : null}
    </Grid>
  );
};

const collectionBoxSizeVariants = {
  fullscreen: {
    height: 380,
    height_desktop: 680,
  },
  small: {
    height: 130,
    height_desktop: 310,
  },
};

const Grid = styled.div<{ colCount?: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ colCount }) => (colCount ? `repeat(${colCount}, 1fr)` : 'repeat(2, 1fr)')};
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    column-gap: 60px;
    row-gap: 60px;
  }
`;

const GridRow = styled.div<{ gap: number }>`
  display: grid;
  row-gap: ${({ gap }) => gap && `${gap}px`};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CollectionBox = styled.div<{ size?: 'fullscreen' | 'small'; $closeUp?: boolean; $smallCloseup?: boolean }>`
  ${({ size }) =>
    size &&
    collectionBoxSizeVariants[size] &&
    css`
      height: ${collectionBoxSizeVariants[size].height}px;

      @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
        height: ${collectionBoxSizeVariants[size].height_desktop}px;
      }
    `}

  ${({ size, $closeUp }) =>
    size === 'small' &&
    !$closeUp &&
    css`
      &:hover img {
        transform: translate(-50%, -50%) scale(1.1);
      }
    `}

  ${({ size, $closeUp }) =>
    size === 'fullscreen' &&
    !$closeUp &&
    css`
      img {
        transition-duration: 0.5s;
        transition-delay: 0.3s;

        @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
          transform: translate(-40%, -40%) rotate(35deg) scale(1.3);
        }
      }

      &:hover img {
        transform: translate(-50%, -50%) rotate(0) scale(1);
      }
    `}

  ${({ $closeUp, $smallCloseup }) =>
    $closeUp &&
    css`
      img {
        top: 10%;
        transform: translateX(-50%);
        width: ${$smallCloseup ? '90%' : '150%'};
        max-height: none;
        max-width: none;

        &:hover {
          transform: translateX(-50%) scale(1.05);
        }
      }
    `}
`;

const CollectionImageBox = styled.div<{ size?: 'fullscreen' | 'small' }>`
  position: relative;
  background-color: #f6f6f6;
  text-align: center;
  cursor: pointer;
  height: 100%;
  overflow: hidden;
`;

const CollectionImage = styled.img<{ fullwidth?: boolean }>`
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  user-select: none;

  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: 100%;
      max-height: none;
      max-width: none;
    `}
`;

const StyledHeading = styled(motion.h1)`
  font-size: 45px;
  color: #f6f5f6;
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 150px;
    position: absolute;
    top: -20px;
    white-space: nowrap;
  }
`;

export default Collections;
