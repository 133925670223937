import React from 'react';
import styled from 'styled-components';
import { H1, Text } from 'ui';

interface IStandardView {
  title: string;
  subTitle: string;
  bigTitle?: boolean;
}

const StandardView: React.FC<IStandardView> = ({ title, subTitle, bigTitle, children }) => {
  return (
    <>
      <Container className="container">
        <H1 introTitle={bigTitle} className="mb-2 mb-sm-4">
          {title}
        </H1>
        <div className="col-12 col-md-8 mx-auto">
          <Text>{subTitle}</Text>
        </div>
      </Container>
      {children}
    </>
  );
};

const Container = styled.div`
  padding: 50px 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 60px 0;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 20px 0;
  }
`;

export default StandardView;
